<template>
      <div class="tab bg-white shadow rounded-t-md p-8 hidden-print">
        <nav class="float-left">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[
                  tab.current
                    ? 'current'
                    : 'border-transparent hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap mr-5 pb-4 px-1 border-b-2 text-md cursor-pointer',
                ]"
          @click="changeTab(tab.name)"
        >
          {{ tab.name }}
        </a>
    </nav>
      </div>
  
      <div v-show="activeTab === 'List Activity'">
        <JobList v-if="data?.length" :data="data" @next-page="getNextPage" @prev-page="getPrevPage" :totalPages="totalPages" :currentPage="currentPage" @update="updateselectedPage"/>
        <JobsPlaceholder v-else />
    </div>
      <div v-show="activeTab === 'Realtime Activity'">
        <RealTimeList></RealTimeList>
      </div> 
   

  </template>
  
  <script>
  import JobList from "@/components/basic/common/JobList";
  import RealTimeList from "@/components/basic/common/RealTimeList";
  import JobsPlaceholder from "@/components/basic/dashboard-page/JobsPlaceholder";
  import { ref, reactive } from "vue";
  import eventBus from "@/utils/eventBus";
export default {
    name: "EmailCheckDetails",
    components: {
    JobList,
    JobsPlaceholder,
    RealTimeList
  },
    data() {
      return {
        data: ref([]),
      urlParams: reactive({ limit: 10, ordering: "-created", offset:0 }),
        tabs: [
          { name: "List Activity", current: true },
          { name: "Realtime Activity", current: false },
        ],
        activeTab: "List Activity",
        currentPage: 1,
        totalPages: 1
      };
    },
    async beforeMount() {
    await this.fetchJobs();
    eventBus.on("update", this.fetchJobs);
  },
  beforeUnmount() {
    eventBus.off("update", this.fetchJobs);
  },
    methods: {
      getNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.urlParams.offset = (this.currentPage - 1) * this.urlParams.limit;
        if (this.urlParams.offset < 1001) {
        this.fetchJobs();
    }
      }
    },
    async getPrevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.urlParams.offset = (this.currentPage - 1) * this.urlParams.limit;
        this.fetchJobs();
      }
    },
        openUploadListModal() {
      this.uploadListModal.openModal();
    },
    async fetchJobs(urlParams = {}) {
      this.urlParams = { ...this.urlParams, ...urlParams };
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.fetchDashboard(this.urlParams),
      });
      if (response) {
        this.data = response.results || [];
        this.totalPages = Math.ceil(parseInt(response.total_count) / this.urlParams.limit );
        delete response.results;
      }
    },
  
    changeTab(tabName) {
      this.tabs.forEach((tab) => {
        tab.current = tab.name === tabName;
      });
      this.activeTab = tabName; 
    },
    updateselectedPage(value) {
    this.selectedPage = value;
    this.urlParams.limit = this.selectedPage;      
    this.fetchJobs()
  }
  },
  };
  </script>
  
  <style scoped>
  .new-page-with-tabs {
    /* Add styles for the container of the tabs and their content */
  }
  
  .tabs {
    /* Add styles for the tab buttons container */
  }
  
  .tab button:hover {
}
  
  .tab .active-tab {
  }
  .current {
  border-color: var(--main-color);
  color: var(--main-color);
}


  </style>
  