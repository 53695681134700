<template>
  <Container>
    <template v-slot:header>Billing</template>
    <template v-slot:content>
      <BillingCards />
      <TransactionTable @transaction="transactionDetails"/>
      <BillingReceipt :transaction="transaction" />
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import BillingCards from "@/components/basic/billing-page/BillingCards";
import TransactionTable from "@/components/basic/billing-page/TransactionTable";
import BillingReceipt from "@/components/basic/billing-page/BillingReceipt";
export default {
  name: "Billing",
  components: {
    Container,
    BillingCards,
    TransactionTable,
    BillingReceipt
  },
  data() {
    return {
      transaction: ''
    };
  },
  methods: {
    transactionDetails(transaction){
      this.transaction = transaction
    },
  }
};
</script>
