<template>
  <DialogContainer
    :isOpen="isOpen"
    :onClose="onClose"
    :maxWidth="'lg'"
    :hasCloseBtn="false"
    :isConfirmation="true"
  >
    <template v-slot:title>{{ modalTitle }}</template>
    <template v-slot:content>
      <div class="mt-2 ml-10 pl-5">
        <p class="text-sm text-gray-500" v-html="modalMessage"></p>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="button-submit sm:ml-3 bg-red-600 hover:bg-red-700"
        :disabled="pendingUpdate"
        @click="onSubmit"
      >
      <p v-if="modalPrimaryButton">{{modalPrimaryButton}}</p>
      <p v-else>Confirm</p>
      </button>
      <button
        type="button"
        :disabled="pendingUpdate"
        class="button-cancel"
        @click="onClose"
      >
      <p v-if="modalSecondaryButton">{{modalSecondaryButton}}</p>
      <p v-else>Cancel</p>
      </button>
    </template>
  </DialogContainer>
</template>

<script>
import { ref } from "vue";
import DialogContainer from "@/components/common/DialogContainer";
import useConfirmationModal from "@/api/confirmationModal";
import modalMixin from "@/mixins/modalMixin";
export default {
  name: "ConfirmationModal",
  mixins: [modalMixin],
  components: {
    DialogContainer,
  },
  setup() {
    const { isOpen, submitCallback, closeCallback, modalTitle, modalMessage, modalPrimaryButton, modalSecondaryButton } =
      useConfirmationModal();
    const pendingUpdate = ref(false);
    return {
      isOpen,
      submitCallback,
      closeCallback,
      modalTitle,
      modalMessage,
      pendingUpdate,
      modalPrimaryButton,
      modalSecondaryButton
    };
  },
  beforeUnmount() {
    this.isOpen = false;
  },
  methods: {
    async onSubmit() {
      if (this.pendingUpdate) {
        return;
      }
      this.pendingUpdate = true;
      try {
        if (this.submitCallback) {
          await this.submitCallback();
        }
      } finally {
        this.isOpen = false;
        window.setTimeout(() => {
          this.pendingUpdate = false;
        }, 1000);
      }
    },
    async onClose() {
      if (this.pendingUpdate) {
        return;
      }
      this.pendingUpdate = true;
      try {
        if (this.closeCallback) {
          this.closeCallback();
        }
      } finally {
        this.isOpen = false;
        window.setTimeout(() => {
          this.pendingUpdate = false;
        }, 1000);
      }
    },
  },
};
</script>
