const thresholdsMixin = {
  methods: {
    determineThresholds(creditTable) {
      let thresholds = [];
      for (const creditAmount of this.creditPack) {
        const { price, initial_price, savings } = creditTable[creditAmount];
        thresholds.push({
          primaryPrice: initial_price,
          credits: creditAmount,
          price,
          savings,
        });
      }
      return thresholds;
    },
  },
};

export default thresholdsMixin;
