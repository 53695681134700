<template>
    <DialogContainer :isOpen="isOpen" :onClose="closeModal" :maxWidth="'5xl'">
      <template v-slot:content>
        <div
          class="text-lg flex py-3 pl-5 pt-4"
        >
        <span class="font-bold">Hygiene</span>&nbsp;&nbsp;<span class="truncate max-w-2xl"
            >{{ job.name }}&nbsp;
          </span>
          ({{ job.basicData ? job.basicData.emailsCount : job.emails_count }}
          emails)
          <span class="font-bold"></span>       
        </div>
        <span class="pl-5 text-sm font-bold">Select Service:</span>
        <div class="md:flex sm:flex-none item-center justify-center">
        {{  }}
    <!-- First Box with Highlighted Top Border and Text -->
    <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-60">
    <span class="bg-red-100 flex justify-center text-red-800 px-2 py-1 rounded-t-lg block">Most Popular</span>
    <div
      @click="selectBox(1)"
      :class="{ 'selected-box': selectedBox === 1, 'unselected-box': selectedBox !== 1 }"
      class="box-item best-value rounded-b-lg" 
    >

      <p class="font-bold">Deliverability + Threat Detection</p>
      <ul class="ml-3 pl-3 list-disc">
      <li class="text-sm text-gray-500 text-left">Recommended for most lists</li>
      <li class="text-sm text-gray-500 text-left">Best value</li>
    </ul>
    <div :class="{ 'selected-color': selectedBox === 1}" class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
      <p class="pt-3 absolute bottom-2">{{job.basicData.emailsCount}} credits</p>
    </div>
      <!-- <input v-if="selectedBox === 1" type="checkbox" class="checkbox m-2" checked disabled/> -->
    </div>
    </div>

    <!-- Second Box -->
    <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-56">
        <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
    <div
     @click="job.isDeliv === false || job.isUploaded == true ? selectBox(2) : null"
      :class="{ 'selected-box': selectedBox === 2, 'used-box': job.isDeliv === true }"
      class="box-item rounded-lg"
    >
    <p class="font-bold">Deliverability Check</p>
    <ul class="ml-3 pl-3 list-disc">
      <li class="text-sm text-gray-500 text-left">Validates email addresses</li>
      <li class="text-sm text-gray-500 text-left">Excludes threat detection</li>
      </ul>
      <div :class="{ 'selected-color': selectedBox === 2}" class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
      <p class="pt-3 absolute bottom-2">{{ formatN(Math.ceil(parseInt(job.basicData.emailsCount.replace(/,/g, '')) * 0.9))}} credits</p>
      <!-- <input v-if="selectedBox === 2" type="checkbox" class="checkbox m-2" checked disabled/> -->
    </div>
    </div>
    </div>
    
    <!-- Third Box -->
    <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-56">
        <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
        <div
        @click="job.isThreatAssessed === false || job.isUploaded == true ? selectBox(3) : null"
      :class="{ 'selected-box': selectedBox === 3, 'used-box': job.isThreatAssessed === true }"
      class="box-item rounded-lg"
    >
    <p class="font-bold">Threat Detection</p>
    <ul class="ml-3 pl-3 list-disc">
      <li class="text-sm text-gray-500 text-left">Identifies toxic addresses</li>
      <li class="text-sm text-gray-500 text-left">Excludes deliverability check</li>
      </ul>
      <div :class="{ 'selected-color': selectedBox === 3}" class="bg-gray-300 p-5 w-full absolute left-0 bottom-0 rounded-b-lg">
      <p class="pt-3 absolute bottom-2">{{ formatN(Math.ceil(parseInt(job.basicData.emailsCount.replace(/,/g, '')) * 0.4))}} credits</p>
      <!-- <input v-if="selectedBox === 3" type="checkbox" class="checkbox m-2" checked disabled/> -->
      </div>
    </div>
    </div>

        <!-- Fourth Box -->
        <!-- <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-56">
        <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
        <div
        @click="job.isThreatAssessed === false && job.isDeliv === false && job.statusData.displayName != 'Evaluated' || job.isUploaded == true ? selectBox(4) : null"
      :class="{ 'selected-box': selectedBox === 4, 'used-box': job.isDeliv === true || job.isThreatAssessed === true || job.statusData.displayName === 'Evaluated'}"
      class="box-item rounded-lg"
    >
    <p class="font-bold">Evaluation</p>
    <ul class="ml-3 pl-3 list-disc">
      <li class="text-sm text-gray-500 text-left">Determine the basic hygiene level of the file</li>
      </ul>
      <div :class="{ 'selected-color': selectedBox === 4}" class="bg-gray-300 p-5 w-full absolute left-0 bottom-0 rounded-b-lg">
      <p class="pt-3 absolute bottom-2">0 credits</p>
      <input v-if="selectedBox === 4" type="checkbox" class="checkbox m-2" checked disabled/>
      </div>
    </div>
    </div> -->
        </div>
      </template>
      <template v-slot:buttons>
        <button type="button" @click="proceedJob(job, selectedBox)" class="button-submit ml-3">
          Proceed
        </button>
        <button type="button" @click="closeModal" class="button-cancel">
          Cancel
        </button>
        
      </template>
    </DialogContainer>
  
  </template>
  
  <script>
  import formMixin from "@/mixins/formMixin";
  import DialogContainer from "@/components/common/DialogContainer";
  import { reactive, ref } from "vue";
  import eventBus from "@/utils/eventBus";
  import useConfirmationModal from "@/api/confirmationModal";
  import humanize from "humanize";
  import { mapGetters } from "vuex";

  export default {
    name: "JobsModal",
  
    components: {
      DialogContainer, 
    },
    mixins: [formMixin],
    setup() {
      const isOpen = ref(false);
      const showPopup = ref(false);
      const job = reactive({});
  
      const maximumReachCount = ref(0);
      const bestDeliveryCount = ref(0);
      const emailsCount = ref(0);
      const input_maximumReach = ref(false);
      const input_bestDeliverability = ref(false);
      
      const selectedBox = ref(1);
      const isCleanJobLoading = ref({});
      const selectBox = (boxNumber) => {
      selectedBox.value = boxNumber;
};
  
      return {
        job,
        isOpen,
        ExportGRList: ref(),
        uploadListModal: ref(),
        showPopup,
        input_bestDeliverability,
        input_maximumReach,
        emailsCount,
        bestDeliveryCount,
        maximumReachCount,
        isCleanJobLoading,
        selectedBox,
      selectBox,
      };
    },
    computed:{
      ...mapGetters({
      userData: "auth/userData",
    }),
    Credits() {
      let isParent = this.userData.is_parent;
      let Credits = 0;
      if (isParent == true || this.userData.credits === null) {
        Credits = this.userData.organization.credits;
      } else {
        Credits = this.userData.credits;
      }
    return Credits;
    },
    },
    methods: {
      closeModal() {
        this.isOpen = false;
        this.selectedBox = 1;
      },
  
      openModal({ job }) {
        this.job = job;
        this.isOpen = true;
      },
      proceedJob(job, selectedBox){
        if (selectedBox == 1){
            this.onCleanTheList(job)
        }
        else if (selectedBox == 2){
            this.deliverability(job)
        }
        else if (selectedBox == 3){
            this.threat_assessment(job)
        }
        else if (selectedBox == 4){
          this.evaluation(job)
        }
      },
      async evaluation(job) {    
      this.price = 0;
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to evaluate this list?",
        message: `It is going to cost <strong>${this.price} Credits</strong>.`,
        onSubmit: () => this.evaluation_func(job),
      });
    },
    async evaluation_func(job){
    const response = await this.handleSubmissionErrors(
        async () =>
          this.$alfredServiceV2.createJob({
            input_file:{
              id: job.upload_id
            },
            actions: ["evaluation"],
          }),
        { throwExceptions: true }
      );
      eventBus.trigger("update");
      return response;
  },
  formatN(count) {
      return humanize.numberFormat(count, 0);
    },
      async deliverability(job) {
      this.price = this.formatN( Math.ceil(parseInt(job.basicData.emailsCount.replace(/,/g, '')) * 0.9));
      let cleanPrice = parseInt(this.price.replace(/,/g, ''), 10);
      let totalcredits = this.Credits;
      let missingcredits = cleanPrice-totalcredits;
      if(cleanPrice > totalcredits){
        const {is_parent, allow_billing} = this.userData;
          if(!allow_billing && !is_parent){
            eventBus.trigger("notify", {
              notification: {
                group: "top-right",
                type: "warning",
                text: "Insufficient credits.",
              },
            });
            return false;
          }
          eventBus.trigger("select-credits", {
          onSubmit:this.onCheckoutDeliverability,
          missingCredits: missingcredits,
        });
      }
      else{
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to check deliverability of this list?",
        message: `It is going to cost <strong>${this.price} Credits</strong>.`,
        onSubmit: () => this.deliverability_func(job),
      });
      }
    },
    async deliverability_func(job){
    const response = await this.handleSubmissionErrors(
        async () =>
          this.$alfredServiceV2.createJob({
            input_file:{
              id: job.upload_id
            },
            actions: ["deliverability"],
          }),
        { throwExceptions: true }
      );
      eventBus.trigger("update");
      return response;
  },
      async threat_assessment(job) {      
      this.price = this.formatN(Math.ceil(parseInt(job.basicData.emailsCount.replace(/,/g, '')) * 0.4));
      let cleanPrice = parseInt(this.price.replace(/,/g, ''), 10);
      let totalcredits = this.Credits;
      let missingcredits = cleanPrice-totalcredits;
      if(cleanPrice > totalcredits){
        const {is_parent, allow_billing} = this.userData;
          if(!allow_billing && !is_parent){
            eventBus.trigger("notify", {
              notification: {
                group: "top-right",
                type: "warning",
                text: "Insufficient credits.",
              },
            });
            return false;
          }
          eventBus.trigger("select-credits", {
          onSubmit:this.onCheckoutThreat,
          missingCredits: missingcredits,
        });
      }
      else{
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to threat assess this list?",
        message: `It is going to cost <strong>${this.price} Credits</strong>.`,
        onSubmit: () => this.threat_assessment_func(job),
      });
      }
    },
    async threat_assessment_func(job){
    const response = await this.handleSubmissionErrors(
        async () =>
          this.$alfredServiceV2.createJob({
            input_file:{
              id: job.upload_id
            },
            actions: ["threat_assessment"],
          }),
        { throwExceptions: true }
      );
      eventBus.trigger("update");
      return response;
  },
      async onCleanTheList(job) {
      this.isCleanJobLoading[job.upload_id] = true;
      eventBus.trigger("clean-the-list", {
        job,
        callback: () => (this.isCleanJobLoading[job.upload_id] = false),
      });
    },
    async onCheckoutDeliverability(data) {
      eventBus.trigger("checkout", {
        onSubmit: this.deliverability_func,
        data: {
          primaryPrice: data.primaryPrice,
          credits: data.credits,
          price: data.price,
          discount: data.discount,
        },
      });
    },
    async onCheckoutThreat(data) {
      eventBus.trigger("checkout", {
        onSubmit: this.threat_assessment_func,
        data: {
          primaryPrice: data.primaryPrice,
          credits: data.credits,
          price: data.price,
          discount: data.discount,
        },
      });
    },
    },
  };
  </script>
  
  <style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .box-item {
    background-color: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    position: relative;
    height: 10rem;
  }
  .box-item:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .selected-box {
    background-color: #fee2e2;
    border: 1px solid #fd7e7e !important;
  }
  .box-color {
    background-color: #c9c9c9;
  }
  .selected-color{
    background-color: #ffc4c4 !important;
  }
  .used-box {
    opacity: 0.5;
    cursor:not-allowed;
  }
  .unselected-box {
    opacity: 0.8;
  }

  .checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid #ff0000; 
    background-color: #ff0000; 
  }

  .checkbox:checked {
    background-color: #ff0000; 
  }
  [type='checkbox'], [type='radio'] {
    color: #ff0000;
  }
  </style>