<template>
  <div class="min-h-screen bg-gray-50 p-2">
    <div class="flex justify-center sm:justify-start">
      <router-link to="/">
        <img
          class="h-10"
          :src="require('@/assets/images/logo.png')"
          alt="Alfred Knows"
        />
      </router-link>
    </div>
    <div
      class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Set new password
        </h2>
      </div>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form
            @submit="confirmResetPassword"
            v-slot="{ errors }"
            class="space-y-6"
            action="#"
          >
            <div>
              <label for="password"> Password </label>
              <div class="mt-1">
                <Field
                  id="password"
                  name="password"
                  type="password"
                  required
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
              </div>
              <label for="confirm_password" class="mt-6">
                Confirm password
              </label>
              <div class="mt-1">
                <Field
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  required
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.confirm_password }}
                </p>
              </div>
            </div>

            <div>
              <button type="submit" class="button-submit w-full m-0">
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";

export default {
  name: "ConfirmResetPassword",
  mixins: [formMixin],
  components: {
    Form,
    Field,
  },

  methods: {
    async confirmResetPassword(values, actions) {
      const response = await this.handleSubmissionErrors(
        () =>
          this.$alfredService.confirmResetPassword({
            token: this.$route.query.token,
            ...values,
          }),
        { formActions: actions }
      );

      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Password updated. You can now login using new credentials.",
            type: "success",
          },
        });
        await this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>
