<template>
  <a
    class="inline-flex hover:text-gray-900 cursor-pointer"
    @click="switchOrder"
  >
    <span class="my-auto">{{ title }}</span>
    <span class="my-auto" v-if="!ordering">
      <SelectorIcon class="h-4" />
    </span>
    <span class="my-auto" v-else-if="ordering === `-${param}`">
      <ChevronDownIcon class="h-4" />
    </span>
    <span class="my-auto" v-else-if="ordering === param">
      <ChevronUpIcon class="h-4" />
    </span>
  </a>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SelectorIcon,
} from "@heroicons/vue/solid";

export default {
  name: "FilterableHeaderCell",
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    SelectorIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    param: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const ordering = ref(null);
    return { ordering };
  },
  methods: {
    setOrdering(ordering) {
      this.ordering = ordering;
    },
    switchOrder() {
      if (!this.ordering) {
        this.ordering = this.$props.param;
      } else if (this.ordering === this.$props.param) {
        this.ordering = `-${this.$props.param}`;
      } else {
        this.ordering = null;
      }
      this.$emit("switchOrder", this.ordering);
    },
  },
};
</script>
