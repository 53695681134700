<template>
  <div
    class="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <router-link to="/">
      <img
        @click="toLoginPage"
        class="mx-auto h-12 w-auto cursor-pointer"
        :src="require('@/assets/images/logo.png')"
        alt="Alfred Knows"
      />
    </router-link>
    <p class="mt-10 text-center">{{ displayMessage }}</p>
    <button @click="toLoginPage" class="button-submit mt-5 sm:max-w-md w-full">
      Login
    </button>
  </div>
</template>

<script>
import apiMixin from "@/mixins/apiMixin";
import { ref } from "vue";

export default {
  name: "ConfirmChangeEmail",
  mixins: [apiMixin],

  setup() {
    return {
      displayMessage: ref(""),
    };
  },

  methods: {
    async toLoginPage() {
      await this.$router.push({ name: "Login" });
    },
  },

  async mounted() {
    try {
      await this.$store.dispatch("fetching/FETCH", {
        apiCall: () =>
          this.$alfredService.confirmChangeEmail({
            token: this.$route.query.token,
          }),
        handleErrors: false,
      });
      this.displayMessage = "Email successfully changed.";
    } catch (e) {
      this.displayMessage = "This confirmation link is no longer valid.";
    }
  },
};
</script>
