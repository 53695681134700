import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import eventBus from "@/utils/eventBus";
import Login from "@/views/common/Login.vue";
import Registration from "@/views/common/Registration.vue";
import PartnerRegistration from "@/views/common/PartnerRegistration.vue";
import ResetPassword from "@/views/common/ResetPassword.vue";
import VerifyEmail from "@/views/common/VerifyEmail";
import ConfirmResetPassword from "@/views/common/ConfirmResetPassword";
import ConfirmChangeEmail from "@/views/common/ConfirmChangeEmail";
import Usage from "@/views/common/Usage";
import GlobalDefaults from "@/views/admin/GlobalDefaults";
import DiscountCodes from "@/views/admin/DiscountCodes";
import UserStatistics from "@/views/admin/UserStatistics";
import Bouncer from "@/views/admin/Bouncer";
import UsersAdminView from "@/views/admin/UsersAdminView";
import UserDetails from "@/views/admin/UserDetails";
import ApiKeys from "@/views/basic/ApiKeys";
import AddUser from "@/views/basic/AddUser";
import Billing from "@/views/basic/Billing";
import Contact from "@/views/basic/Contact";
import Credits from "@/views/basic/Credits";
import Dashboard from "@/views/basic/Dashboard";
import FileDetails from "@/views/basic/FileDetails";
import EmailCheckDetails from "@/views/basic/EmailCheckDetails";
import Glossary from "@/views/basic/Glossary";
import ListHealthCheck from "@/views/basic/ListHealthCheck";
import Profile from "@/views/basic/Profile";
import UsageViewDetails from "../components/common/usage-page/UsageViewDetails";
import RegistrationConfirmation from "@/views/common/RegistrationConfirmation.vue"
import YotpoIntegration from "@/views/common/YotpoIntegration.vue"
import YotpoIntegrationInstall from "@/views/common/YotpoIntegrationInstall.vue"

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      hideMenu: true,
      navClass: "bg-gray-50 absolute",
    },
  },

  {
    path: "/integrations/yotpo",
    name: "YotpoSetup",
    component: YotpoIntegration,
    meta: {
      navClass: "bg-gray-50 absolute",
    },
  },

  {
    path: "/integrations/yotpo/auth",
    name: "Yotpo",
    component: YotpoIntegrationInstall,
    meta: {
      hideNav: true,
      navClass: "bg-gray-50 absolute",
    },
  },
  
  {
    path: "/register",
    name: "Registration",
    component: Registration,
    meta: {
      hideMenu: true,
      navClass: "bg-gray-50 absolute",
    },
  },

  {
    path: "/partner-register",
    name: "PartnerRegistration",
    component: PartnerRegistration,
    meta: {
      hideMenu: true,
      navClass: "bg-gray-50 absolute",
    },
  },

  {
    path: "/confirmation",
    name: "RegistrationConfirmation",
    component: RegistrationConfirmation,
    meta: {
      hideMenu: true,
      navClass: "bg-gray-50 absolute",
    },
  },
  {
    path: "/confirm-account",
    name: "ConfirmAccount",
    component: VerifyEmail,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      hideMenu: true,
      navClass: "bg-gray-50 absolute",
    },
  },
  {
    path: "/confirm-reset-password",
    name: "ConfirmResetPassword",
    component: ConfirmResetPassword,
    meta: {
      hideMenu: true,
      navClass: "bg-gray-50 absolute",
    },
  },
  {
    path: "/list/:id/health-check",
    name: "ListHealthCheck",
    component: ListHealthCheck,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/list/:id/summary",
    name: "FileDetails",
    component: FileDetails,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/email-check/:id/summary",
    name: "EmailCheckDetails",
    component: EmailCheckDetails,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/account/billing",
    name: "Billing",
    component: Billing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/usage",
    name: "Usage",
    component: Usage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/credits",
    name: "Credits",
    component: Credits,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/glossary",
    name: "Glossary",
    component: Glossary,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/api-keys",
    name: "API",
    component: ApiKeys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/users",
    name: "AddUser",
    component: AddUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/confirm-change-email",
    name: "ConfirmChangeEmail",
    component: ConfirmChangeEmail,
    meta: {
      hideMenu: true,
      navClass: "bg-gray-50 absolute",
    },
  },
  {
    path: "/admin",
    name: "UsageAdmin",
    component: Usage,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
  {
    path: "/admin/global-defaults",
    name: "GlobalDefaults",
    component: GlobalDefaults,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
  {
    path: "/admin/discount-codes",
    name: "DiscountCodes",
    component: DiscountCodes,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
  {
    path: "/admin/user-statistics",
    name: "UserStatistics",
    component: UserStatistics,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
  {
    path: "/admin/bouncer",
    name: "Bouncer",
    component: Bouncer  ,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
  {
    path: "/admin/users",
    name: "UsersAdminView",
    component: UsersAdminView,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
  {
    path: "/admin/users/:userId",
    name: "UserDetails",
    component: UserDetails,
    props: true,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
  {
    path: "/admin/:id/UsageViewDetail",
    name: "UsageViewDetails",
    component: UsageViewDetails,
    props: true,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      footerComponent: null,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresStaff = to.matched.some((record) => record.meta.requiresStaff);
  const isStaff = store.getters["auth/isStaff"];
  const redirectToAdmin = !requiresStaff && isStaff;
  if (redirectToAdmin) {
    next({
      name: "UsageAdmin",
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  const shouldLogin = requiresAuth && !isLoggedIn;
  if (shouldLogin) {
    next({
      name: "Login",
      query: { nextUrl: to.fullPath },
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const requiresStaff = to.matched.some((record) => record.meta.requiresStaff);
  const isStaff = store.getters["auth/isStaff"];
  const isParent = store.getters["auth/isParent"];
  const userData = store.getters["auth/userData"];
  let allowBilling = false;
  if(userData){
    allowBilling = userData.allow_billing;
  }
  const denyAccess = requiresStaff && !isStaff;
  const denyCreditsAccess = ["Credits", "Billing", "Usage"].includes(to.name) && !isParent;
  if (denyAccess && !allowBilling) {
    next({
      name: "Dashboard",
    });
    eventBus.trigger("notify", {
      notification: {
        group: "top-right",
        type: "error",
        text: "Access denied!",
      },
    });
  }else if (denyCreditsAccess && !allowBilling) {
    next({
      name: "Dashboard",
    });
    eventBus.trigger("notify", {
      notification: {
        group: "top-right",
        type: "error",
        text: "Access denied!",
      },
    });
  } else {
    next();
  }
});

export default router;
