<template>
  <div class="mt-4">
    <form @submit.prevent="onSubmit" class="flex flex-col items-start">
      <label for="email">
        Set number of days for auto-deleting after Verification
      </label>
      <div class="mt-1 w-full">
        <NumberInput
          v-model="days"
          @input="formActions.clearFormErrors"
          required
        />
        <p class="text-red-500 text-sm mt-1">{{ errors.value }}</p>
      </div>
      <button
        type="submit"
        :disabled="Object.keys(errors).length > 0"
        class="button-submit"
      >
        Confirm
      </button>
    </form>
  </div>
</template>
<script>
import { ref } from "vue";
import NumberInput from "@/components/common/NumberInput";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import useConfirmationModal from "@/api/confirmationModal";

export default {
  name: "AutoDeleteAdminForm",
  mixins: [formMixin],
  props: {
    userId: Number,
    default: null,
  },
  components: {
    NumberInput,
  },
  setup() {
    const { errors, formActions } = useFormErrors();
    const days = ref(14);

    return {
      days,
      errors,
      formActions,
    };
  },
  async beforeMount() {
    this.days = await this.getAutoDeleteDays();
  },
  methods: {
    async onSubmit() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        message: "This action will overwrite all settings in the system",
        onSubmit: () => this.handleChangeSetting(),
      });
    },
    async handleChangeSetting() {
      const response = await this.handleSubmissionErrors(
        () =>
          this.$alfredService.changeSetting({
            key: "AUTO_DELETE_DAYS",
            value: this.days,
            user: this.userId,
          }),
        { formActions: this.formActions, throwExceptions: true }
      );
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Auto-delete setting successfully updated!",
          },
        });
      }
    },
    async getAutoDeleteDays() {
      const response = await this.getSettingRequest();
      return response?.value || 14;
    },
    async getSettingRequest() {
      return await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.getSetting("AUTO_DELETE_DAYS", {
            user: this.userId || null,
          }),
        handleErrors: false,
      });
    },
  },
};
</script>
