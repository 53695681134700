<template>
    <Container>
        <template v-slot:header>Email Check Detail</template>
        <template v-slot:content>
            <EmailCheckTabContent :id="id" />
            <EmailCheckList :id="id" />
        </template>
    </Container>
</template>
  
<script>
import Container from "@/components/common/Container";
import EmailCheckTabContent from "@/components/basic/email-check-detail/EmailCheckTabContent"
import EmailCheckList from "@/components/basic/email-check-detail/EmailCheckList"
export default {
    name: "EmailCheckDetails",
    components: {
        Container,
        EmailCheckList,
        EmailCheckTabContent
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    }

};
</script>