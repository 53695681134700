<template>
  <div class="min-h-screen bg-gray-50 p-2">
    <div class="flex justify-center sm:justify-start px-3 py-3">
      <router-link to="/">
        <img
          class="h-10"
          :src="require('@/assets/images/logo.png')"
          alt="Alfred Knows"
        />
      </router-link>
    </div>
    <div
      class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form @submit.prevent="resetCall" class="space-y-6" action="#">
            <div>
              <label for="email"> Email address </label>
              <div class="mt-1">
                <input
                  id="email"
                  v-model="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="form-field-no-icon w-full"
                />
              </div>
            </div>

            <div>
              <button type="submit" class="button-submit w-full m-0">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";

export default {
  name: "ResetPassword",
  mixins: [formMixin],

  setup() {
    return {
      email: "",
    };
  },

  methods: {
    async resetCall() {
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.resetPassword({ email: this.email }),
        {}
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Request has been sent. Check your email to confirm the process.",
            type: "success",
          },
        });
      }
    },
  },
};
</script>
