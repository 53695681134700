export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/v1/"
    : "/api/v1/";
export const API_V2_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/v2/"
    : "/api/v2/";
export const KLAVIYO_URL = "https://a.klaviyo.com/api/v2/";
export const JWT_EXPIRATION_SEC = 30;
export const STRIPE_SECRET =
  window.STRIPE_SECRET ||
  process.env.STRIPE_PUBLISHABLE_API_KEY ||
  "pk_test_51IniB6FU5E3IFUVmmr7eBGtj0Kd9FUoNaZPkqUHNilwgQ4HC8596SmiIOfSiLi46KWCW2iqkYV1BwSneFpskfr0O00k01NH94G";
