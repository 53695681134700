<template>
  <div>
    <Container>
      <template v-slot:header>Dashboard</template>
      <template v-slot:content>
        <UploadListModal ref="uploadListModal" />
        <RealTimeEmailCheck ref="RealTimeEmailCheck"/>
        <div class="pb-6">
          <div class="flex justify-center">
            <div class="m-3">
              <div class="justify-center mb-2">
                <!-- <span class="text-xl text-gray-900 mb-2 mr-2">Upload List</span>
                <span class="text-xs text-gray-400">
                  Upload your email list .CSV format
                </span> -->
              </div>
              <button @click="openUploadListModal" class="button-submit sm:mt-0 w-full">
                <PlusCircleIcon class="h-6"></PlusCircleIcon>
                Upload or Import Email List
              </button>
            </div>

            <div class="m-3 flex items-end pb-3">
              <span class="text-sm text-black-500 mr-3">or</span>
              <a class="underline link-text cursor-pointer" @click="openRealTimeEmailCheck">Check an individual email address</a>
            </div>

          </div>
        </div>
        <!-- <span>or</span>
          <a class="link">Check an individual email address</a>  -->

        <!-- <div
            class="flex flex-grow w-3/6 p-9 pl-5 mb-6 sm:flex-none sm:justify-between sm:flex-nowrap justify-center items-center flex-wrap bg-white shadow rounded-md"
          >
            <div>
              <div class="text-center sm:text-left p-1">
                <div class="text-xl text-gray-900 mb-2">List Evaluation</div>
                <div class="text-xs text-gray-400">
                  Evaluate a list to see if it needs to be hygiened
                </div>
              </div>
            </div>
            <button @click="openUploadListModal" class="button-submit sm:mt-0">
              Upload List
            </button>
          </div> -->
        <!-- <RealTimeEmailCheck /> -->

        <EmailCheckDetails />
      </template>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/common/Container";
import UploadListModal from "@/components/basic/dashboard-page/modals/UploadModal";
import EmailCheckDetails from "@/components/basic/common/EmailCheckDetails";
import { ref, reactive } from "vue";
import RealTimeEmailCheck from "@/components/basic/common/RealTimeEmailCheck";
import apiMixin from "@/mixins/apiMixin";
import { PlusCircleIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

export default {
  name: "NewUserDashboard",
  mixins: [apiMixin],
  components: {
    Container,
    RealTimeEmailCheck,
    UploadListModal,
    EmailCheckDetails,
    PlusCircleIcon,
  },
  setup() {
    return {
      uploadListModal: ref(),
      RealTimeEmailCheck: ref(),
      data: ref([]),
      paginationData: reactive({}),
      urlParams: reactive({ limit: 10, ordering: "-created", offset: 0 }),
      showClass: false
    };
  },
  computed: {
    ...mapGetters({showUploadModal: "settings/showUploadModal"})
  },
  mounted() {
    if(this.showUploadModal){
      this.uploadListModal.openModal(true);
      this.$store.dispatch('settings/SET_SHOW_UPLOAD_MODAL', false);
    }
  },
  methods: {
    openUploadListModal() {
      this.uploadListModal.openModal();
    },
    openRealTimeEmailCheck() {
      this.RealTimeEmailCheck.openModal();
    },
    async fetchJobs(urlParams = {}) {
      this.urlParams = { ...this.urlParams, ...urlParams };
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.fetchDashboard(this.urlParams),
      });
      if (response) {
        this.data = response.results || [];
        delete response.results;
        this.paginationData = response;
      }
    },
  },
};
</script>
<style>
.link-text {
  color: var(--main-color);
  font-weight: 500;
}
</style>