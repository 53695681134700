export function SET_SETTINGS(state, payload) {
  state.settings = payload.reduce((previous, current) => {
    return {
      ...previous,
      [current.key]: current.value,
    };
  }, {});
}

export function SET_SHOW_UPLOAD_MODAL(state, value) {
  state.showUploadModal = value;
}

export function SET_ADMIN_STATS_DATE(state, value) {
  state.admin_stats_date = value;
}
