<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="onClose"
      :open="isOpen"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            :class="`
              inline-block
              align-bottom
              bg-white
              text-left
              shadow-xl
              transform
              transition-all
              sm:my-8
              sm:align-middle
              sm:max-w-${maxWidth}
              sm:max-h-${maxHeight}
              w-full
              rounded-lg            
             
            `"
          >
            <div class="bg-white px-4 py-5 sm:p-6 md:pb-2 rounded-lg">
              

              <div class="mt-3 sm:mt-0 sm:text-left">
                <div
                  v-if="hasCloseBtn"
                  class="absolute top-0 right-0 pt-4 pr-4"
                >
                  <button
                    type="button"
                    @click="onClose"
                    class="button-icon text-gray-400 hover:text-gray-500"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div class="flex">
                <div
                v-if="isConfirmation"
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 sm:mr-4"
              >
                <ExclamationIcon
                  class="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
                <DialogTitle
                  as="h3"
                  class="flex items-center text-lg leading-6 font-medium text-gray-900"
                >
                  <slot name="title" />
                </DialogTitle>
              </div>
                <slot name="content" />
              </div>
            </div>
            <div
              v-if="showButtonSection"
              :class="`${
                isConfirmation ? 'bg-white' : 'bg-gray-50'
              } px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b`"
            >
              <slot name="buttons" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { ExclamationIcon } from "@heroicons/vue/outline";

export default {
  name: "DialogContainer",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ExclamationIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      defualt: false,
    },
    onClose: {
      type: Function,
      required: true,
    },
    hasCloseBtn: {
      type: Boolean,
      default: true,
    },
    isConfirmation: {
      type: Boolean,
      default: false,
    },
    isUpload: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "md",
    },
    maxHeight: {
      type: String,
      default: "md",
    },
  },
  computed: {
    showButtonSection() {
      return !!this.$slots.buttons;
    },
  },
};
</script>
