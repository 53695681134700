<template>
  <div class="hidden-print">
    <nav class="navbar">
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="-ml-2 mr-2 flex items-center md:hidden">
              <!-- Mobile menu button -->
              <button
                @click="toggleMenu"
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                <!--Icon when menu is closed.-->
                <svg
                  class="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <!--Icon when menu is open.-->
                <svg
                  class="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="flex-shrink-0 flex items-center">
              <router-link to="/" @click="resetComponent('Dashboard')">
                <img
                  class="mx-auto h-10 w-auto"
                  :src="require('@/assets/images/logo-white.png')"
                  alt="Alfred Knows"
                />
              </router-link>
            </div>
            <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              <template v-for="(item, index) in menuItems" :key="item.label">
                <router-link
                  v-if="item.routerLink"
                  :to="item?.routerLink?.to"
                  class="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-white hover:bg-opacity-40"
                  @click="resetComponent(item.label)"
                >
                  {{ item.label }}
                </router-link>
                <template v-if="item.expansion">
                  <NavExpansion
                    v-if="index !== menuItems.length - 1"
                    :item="item"
                    :buttonClasses="`
                    inline-flex
                    items-center
                    justify-center text-white
                    px-3
                    py-2
                    rounded-md
                    text-sm
                    font-medium
                    hover:bg-white hover:bg-opacity-25`"
                    :expandedClasses="`left-0 w-96`"
                  />
                </template>
              </template>
            </div>
          </div>
          <div class="flex">
            <div class="hidden md:ml-6 md:flex md:items-center md:space-x-2 p-5" v-if="!this.isStaff">
            <span class="text-white text-xs">Available Credits</span>
            <router-link
      to="/account/billing"
    >
            <button class="button-cancel m-1 ml-0 font-bold" title="Buy credits">
              {{ userCredits }}
            </button>
</router-link>
          </div>
            <NavExpansion
              :item="menuItems[menuItems.length - 1]"
              :buttonClasses="`text-md sm:text-sm mr-2`"
            />
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div class="md:hidden" v-show="isOpened" id="mobile-menu">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <template v-for="item in menuItems" :key="item.label">
            <router-link
              v-if="item.routerLink"
              :to="item?.routerLink?.to"
              class="text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              {{ item.label }}
            </router-link>
            <NavExpansion
              v-if="item.expansion && item.label !== 'Account'"
              :item="item"
              :expandedClasses="`left-0 w-96`"
            />
          </template>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import {
  adminMenuItems,
  userMenuItems,
  subuserMenuItems,
  BilleduserMenuItems
} from "@/components/common/navigation/navigation";
import NavExpansion from "@/components/common/navigation/NavExpansion";
import humanize from "humanize";
import eventBus from "@/utils/eventBus";
export default {
  components: {
    NavExpansion,
  },
  setup() {
    return {
      isOpened: ref(false),
    };
  },
  computed: {
    ...mapGetters("auth", ["isStaff", "userData", "is", "isParent"]),
    menuItems() {
      if(this.isStaff){
        return adminMenuItems;
      }else if(this.isParent){
      return userMenuItems
      }else if(!this.isParent && !this.userData.allow_billing){
          return subuserMenuItems
      }else{
      return BilleduserMenuItems;
      }
      // return this.isStaff ? adminMenuItems : userMenuItems;
    },
    expandable() {
      return this.menuItems.filter(({ expansion }) => expansion);
    },
    userCredits() {
      let userCredits = 0;
      if(this.userData?.credits == 0){
        userCredits = this.userData?.credits;
      }else{
        userCredits = this.userData?.credits || this.userData?.organization?.credits;
      }
      return humanize.numberFormat(userCredits, 0);
    },
  },
  methods: {
    resetComponent(label) {
      if (label === "Dashboard") {
        eventBus.trigger("hideRealTimeEmailDetails");
      }
    },
    toggleMenu() {
      this.isOpened = !this.isOpened;
    },
  },
};
</script>
<style scoped>
.navbar {
  min-height: 60px;
  background-color: var(--main-color);
}
</style>
