<template>
  <div style="
    height: 28rem;
">
    <div class="P-5 pl-1" v-if="!showList">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Connect to Iterable
      </h3>
      <div class="text-sm text-gray-600 list-inside list-decimal pt-3">
        Before importing your list, you'll need to create a new Iterable API
        key and share it with us.
      </div>
      <div class="text-sm text-gray-600 list-inside list-decimal">
        <br />
        <div class="text-black pb-3 font-medium">Instructions</div>
        <div class="pl-1">
          1. In Iterable, go to
          <span><a class="link-text cursor-pointer" target="_blank"
              href="https://app.iterable.com/settings/apiKeys">Integrations>API Keys</a></span>
          <br /><br />
          2. Click <span class="font-bold">New API Key</span><br /><br />
          3. Label it as Alfred (optional)<br /><br />
          4. Choose type as <span class="font-bold">Server-side</span> and click Create API key<br /><br />
          5. Copy and paste API key below to get started.<br /><br />
        </div>
        <div>
          <div class="text-black pb-1 font-medium">API Key</div>
          <div class="pt-2 pb-1">
            <input class="form-field-no-icon pr-5 w-full" id="apikey" v-model="IterableAPIkey" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
    <div v-else style="
    min-height: 25rem;">
      <h2 class="text-lg leading-6 font-medium text-gray-900 pb-2">
        Choose list to import emails from Iterable
      </h2>

      <div class="flex sm:w-30 items-start justify-items-start mt-2">
        <SearchInput v-model="searchKeyword" @input="searchList" placeholder="Search by List" />
      </div>
      <div class="h-64 mt-7 overflow-auto overflow-x-hidden scroll-style w-full">
        <table class="w-full">
          <tbody>
            <tr class="border-b" v-for="(list, list_id) in IterableList" :key="list_id">
              <td class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 max-w-sm truncate">
                <input type="radio" :checked="selection" :id="list.list_id" :name="list.name"
                  @change="onSelectList($event)" :value="list.list_id" />
                <span class="text-sm text-black px-6 py-3 text-center max-w-sm truncate" :for="list.list_id">{{
                  list.name }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <teleport to="body">
        <transition name="fade">
          <div v-if="showPopups" class="popup-container">
            <div>

              <div class="popup inline-block align-bottom bg-white text-left transform transition-all p-4 w-full">
                <h3 id="headlessui-dialog-title-12" class="text-lg leading-6 font-medium text-gray-900">
                  <div class="pb-3">
                    <span class="font-bold">Hygiene</span> {{ IterableImportListName }} ({{
                      humanizeNumber(IterableImportListCount) }} emails)
                  </div>
                </h3>
                <span class="pl-5 text-sm font-bold">Select Service:</span>
                <div class="flex">
                  <!-- First Box with Highlighted Top Border and Text -->
                  <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-1/4">
                    <span class="bg-red-100 flex justify-center text-red-800 px-2 py-1 rounded-t-lg block">Most
                      Popular</span>
                    <div @click="selectBox(1)"
                      :class="{ 'selected-box': selectedBox === 1, 'unselected-box': selectedBox !== 1 }"
                      class="box-item best-value rounded-b-lg">
                      <p class="font-bold">Deliverability + Threat Detection</p>
                      <ul class="ml-3 pl-3 list-disc">
                        <li class="text-sm text-gray-500 text-left">Recommended for most lists</li>
                        <li class="text-sm text-gray-500 text-left">Best value</li>
                      </ul>
                      <div class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
                        <p class="pt-3 absolute bottom-2">{{
                          humanizeNumber(Math.ceil(parseInt(IterableImportListCount))) }} credits</p>
                      </div>

                    </div>
                  </div>

                  <!-- Second Box -->
                  <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-1/4">
                    <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
                    <div @click="selectBox(2)"
                      :class="{ 'selected-box': selectedBox === 2, 'unselected-box': selectedBox !== 2 }"
                      class="box-item rounded-lg">
                      <p class="font-bold">Deliverability Check</p>
                      <ul class="ml-3 pl-3 list-disc">
                        <li class="text-sm text-gray-500 text-left">Validates email addresses</li>
                        <li class="text-sm text-gray-500 text-left">Excludes threat detection</li>
                      </ul>
                      <div class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
                        <p class="pt-3 absolute bottom-2">{{ humanizeNumber(Math.ceil(parseInt(IterableImportListCount)
                          * 0.9)) }} credits</p>
                      </div>
                    </div>
                  </div>
                  <!-- Third Box -->
                  <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-1/4">
                    <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
                    <div @click="selectBox(3)"
                      :class="{ 'selected-box': selectedBox === 3, 'unselected-box': selectedBox !== 3 }"
                      class="box-item rounded-lg">
                      <p class="font-bold">Threat Detection</p>
                      <ul class="ml-3 pl-3 list-disc">
                        <li class="text-sm text-gray-500 text-left">Identifies toxic addresses</li>
                        <li class="text-sm text-gray-500 text-left">Excludes deliverability check</li>
                      </ul>
                      <div class="bg-gray-300 p-5 w-full absolute left-0 bottom-0 rounded-b-lg">
                        <p class="pt-3 absolute bottom-2">{{ humanizeNumber(Math.ceil(parseInt(IterableImportListCount)
                          * 0.4)) }} credits</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right bg-gray-50 rounded-b-lg p-4 shadow-xl">
                <button type="button" @click="back()" class="button-cancel">
                  Cancel
                </button>
                <button type="button" @click="proceedJob(selectedBox)" class="button-submit ml-3">
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </transition>
      </teleport>
    </div>

    <div class="absolute right-5 bottom-5 flex p-3" v-if="!showList">
      <button @click="closeUpload()" class="button-cancel sm:ml-3">
        Cancel
      </button>
      <button @click="submitApiKey()" class="button-submit sm:ml-3">Submit</button>
    </div>
    <div v-else class="absolute right-5 bottom-5 flex p-3">
      <button @click="removeIterable" class="button-cancel sm:ml-3">
        Remove Iterable
      </button>
      <button type="button" class="button-submit ml-3 rounded-md bg-red-100 text-red-800"
        @click="importIterableJob('evaluation', 0)">
        Evaluate List &nbsp;<span class="font-bold">FREE</span>
      </button>
      <button @click="showPopup()" class="button-submit sm:ml-3">
        <span v-if="isLoading">
          <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
            </circle>
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
        </span>
        Hygiene List</button>
    </div>
  </div>

</template>

<script>
import { ref } from "vue";
import eventBus from "@/utils/eventBus";
import SearchInput from "@/components/common/SearchInput";
import useConfirmationModal from "@/api/confirmationModal";
import _sortBy from "lodash/sortBy";
import humanize from "humanize";

export default {
  name: "IterableIntegrationCenter",
  components: {
    SearchInput
  },
  setup() {
    const radioButton = ref(false);
    const radioButtons = [];
    const isIterableAccess = ref(false);
    const IterableAPIkey = ref("");
    const showList = ref(false);
    const IterableList = ref("");
    const temoIterableList = ref("");
    const showPopups = ref(false);
    const IterableIdImport = ref("");
    const IterableImportListName = ref("");
    const IterableImportListCount = ref("");
    const searchKeyword = ref("");
    const selectedBox = ref(1);
    const selectBox = (boxNumber) => {
      selectedBox.value = boxNumber;
    };
    const isLoading = ref(false);
    return {
      radioButtons,
      radioButton,
      isIterableAccess,
      IterableAPIkey,
      IterableList,
      temoIterableList,
      showList,
      showPopups,
      IterableIdImport,
      IterableImportListName,
      searchKeyword,
      IterableImportListCount,
      selectedBox,
      selectBox,
      isLoading
    };
  },
  mounted() {
    this.getIterableList()
  },
  methods: {
    async getIterableList() {
      try {
        const response = await this.$alfredService.getIterableList();
        if (response) {
          this.IterableList = response;
          this.tempIterableList = this.IterableList;
          this.showList = true;
        }
      } catch (e) {
        await this.$alfredService.iterableAPIKeyDelete();
        this.isIterableAccess = false;
      }
    },
    onSelectList(event, count) {
      this.radioButton = document.getElementById(event.target.id);
      this.radioButtons.push(this.radioButton);
      if (this.radioButtons.length > 0) {
        this.radioButtons.forEach((item) => {
          if (item.checked) {
            item.checked = false;
          }
        });
      }
      this.radioButton.checked = true;
      this.IterableIdImport = event.target.id;
      this.IterableImportListName = event.target.name;
      this.IterableImportListCount = count;
    },
    humanizeNumber(count) {
      return humanize.numberFormat(count, 0);
    },
    async searchList() {
      this.IterableIdImport = "";
      if (this.radioButton) {
        this.radioButton.checked = false;
      }
      if (this.searchKeyword) {
        this.IterableList = this.tempIterableList.filter((x) =>
          x.name.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
      } else {
        this.IterableList = this.tempIterableList;
      }
      this.IterableList = _sortBy(this.IterableList, "name");
    },
    showPopup() {
      if (!this.IterableIdImport) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please select a list to import",
          },
        });
        return false;
      }
      this.isLoading = true;
      const resp = this.$alfredService.getIterableListSize(this.IterableIdImport).then((resp) => {
        this.IterableImportListCount = resp.list_size[0];
        this.showPopups = true;
        this.isLoading = false;
        this.$emit('iterable-clicked', true);
      })
      this.IterableImportListCount = resp.list_size[0];
    },
    closeUpload() {
      this.$emit('iterableImport', true);
      this.$emit('iterable-clicked', false);
    },
    async importIterableJob(action, price) {
      if (!this.IterableIdImport) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please select a list to import",
          },
        });
        return false;
      }
      this.showPopups = false;
      let action_map = {
        evaluation: "evaluate",
        threat_assessment: "threat assess",
        deliverability: "check deliverability of",
        verification: "hygiene"
      }
      const action_ = action_map[action]
      const modal = useConfirmationModal();
      await modal.openModal({
        title: `Are you sure you want to ${action_} this list?`,
        message: `It is going to cost <strong>${price} Credits</strong>.`,
        onSubmit: () => this.importIterable(action),
        onClose: () => this.$emit('iterable-clicked', false)
      });
    },
    async importIterable(action) {
      let payload = {
        list_id: this.IterableIdImport,
        list_name: this.IterableImportListName,
        count: this.IterableImportListCount,
        actions: [action]
      }
      try {
        const response = await this.$alfredService.iterableImport(payload)
        if (response) {
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "Iterable list is uploading!",
            },
          });
          this.$emit('iterableImport', true);
        }
      } catch (e) {
        console.error(e);
      }
    },
    back() {
      this.$emit('iterable-clicked', false);
      this.showPopups = false;
      this.selectedBox = 1;
    },
    async removeIterable() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to permanently remove Iterable?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.removeIterableFunc();
        },
      });
    },
    async removeIterableFunc() {
      try {
        await this.$alfredService.iterableAPIKeyDelete();
        this.isIterableAccess = false;
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Successfully removed Iterable",
          },
        });
      } catch (e) {
        console.log("error", e);
      }
    },
    proceedJob(selectedBox) {
      if (selectedBox == 1) {
        this.importIterableJob('verification', this.humanizeNumber(Math.ceil(parseInt(this.IterableImportListCount))))
      } else if (selectedBox == 2) {
        this.importIterableJob('deliverability', this.humanizeNumber(Math.ceil(parseInt(this.IterableImportListCount) * 0.9)))
      } else if (selectedBox == 3) {
        this.importIterableJob('threat_assessment', this.humanizeNumber(Math.ceil(parseInt(this.IterableImportListCount) * 0.4)))
      }
    },
    async submitApiKey() {
      if (!this.IterableAPIkey) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please provide a valid Iterable API Key.",
          },
        });
        return false;
      }
      try {
        const response = await this.$alfredService.postIterableAPIKey({
          api_key: this.IterableAPIkey,
        });
        if (response) {
          this.getIterableList();
        } else {
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "warning",
              text: "Your Iterable API Key is not valid",
            },
          });
        }
      } catch (e) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Your Iterable API Key is not valid",
          },
        });
        return false;
      }
    }

  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  box-shadow: None !important;
}

.box-item {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  position: relative;
  height: 10rem;
}

.box-item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.selected-box {
  background-color: #fee2e2;
  border: 1px solid #fd7e7e !important;
}

.box-color {
  background-color: #c9c9c9;
}

.selected-color {
  background-color: #ffc4c4 !important;
}

.used-box {
  opacity: 0.5;
  cursor: not-allowed;
}

.unselected-box {
  opacity: 0.8;
}

.checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
}

.checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #ff0000;
  background-color: #ff0000;
}

.checkbox:checked {
  background-color: #ff0000;
}

[type='checkbox'],
[type='radio'] {
  color: #ff0000;
}
</style>