<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 w-72 mx-8 my-4 h-48 flex flex-col"
  >
    <div class="px-4 py-6 sm:p-6">
      <div class="flex flex-row justify-between items-center">
        <span>Credit Card</span>
        <button
          @click="onDelete"
          :disabled="pendingUpdate"
          class="text-red-600 hover:text-red-500 button-icon"
        >
          <XIcon class="h-5" />
        </button>
      </div>
      <div class="mt-10 flex flex-row flex-grow justify-between">
        <div>**** **** **** {{ data.card.last4 }}</div>
        <div>
          {{
            data.card.exp_month > 9
              ? data.card.exp_month
              : `0${data.card.exp_month}`
          }}/{{ data.card.exp_year }}
        </div>
      </div>
    </div>
    <button @click="onUpdate" class="button-submit py-4 mt-0 rounded-none">
      <a class="text-base">Update</a>
    </button>
  </div>
</template>
<script>
import { ref } from "vue";
import useConfirmationModal from "@/api/confirmationModal";
import { XIcon } from "@heroicons/vue/solid";
import formMixin from "@/mixins/formMixin";

export default {
  name: "PaymentMethodCard",
  mixins: [formMixin],
  components: {
    XIcon,
  },
  inject: ["editCreditCardModal"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    update: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      pendingUpdate: ref(false),
    };
  },
  methods: {
    async onUpdate() {
      this.editCreditCardModal.value.openModal(this.data);
    },
    async onDelete() {
      if (this.pendingUpdate) {
        return;
      }
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to delete this credit card?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          this.pendingUpdate = true;
          try {
            await this.handleSubmissionErrors(
              () => this.$alfredService.deletePaymentMethod(this.data.id),
              {}
            );
            await this.update();
          } finally {
            window.setTimeout(() => {
              this.pendingUpdate = false;
            }, 1000);
          }
        },
      });
    },
  },
};
</script>
