<template>
  <Container>
    <template v-slot:header>Contact</template>
    <template v-slot:content>
      <div class="mb-8 bg-white text-left">
        <div class="grid grid-cols-1 lg:grid-cols-3">
          <!-- Contact information -->
          <div
            class="relative z-0 overflow-hidden py-10 px-6 bg-main sm:px-10 xl:p-12"
          >
            <div
              class="absolute inset-0 pointer-events-none sm:hidden"
              aria-hidden="true"
            >
              <svg
                class="absolute inset-0 w-full h-full"
                width="343"
                height="388"
                viewBox="0 0 343 388"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                  fill="url(#linear1)"
                  fill-opacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear1"
                    x1="254.553"
                    y1="107.554"
                    x2="961.66"
                    y2="814.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff"></stop>
                    <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <h3 class="text-lg font-medium text-white">Contact information</h3>
            <dl class="mt-8 space-y-6">
              <dt><span class="sr-only">Phone number</span></dt>
              <dd class="flex text-base text-gray-50">
                <!-- Heroicon name: outline/phone -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span class="ml-3">+1 (706) 426-1970 (M-F, 9-5, EST)</span>
              </dd>
              <dt><span class="sr-only">Email</span></dt>
              <dd class="flex text-base text-gray-50">
                <!-- Heroicon name: outline/mail -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <a
                  href="mailto:alfred@emailindustries.com"
                  class="ml-3 underline hover:opacity-70"
                >
                alfred@emailindustries.com
                </a>
              </dd>
            </dl>
          </div>

          <!-- Contact form -->
          <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <h3 class="text-lg font-medium text-gray-900">Send us a message</h3>
            <form
              @submit.prevent="onSubmit"
              class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <label for="first_name">First name</label>
                <div class="mt-1">
                  <input
                    type="text"
                    v-model="formData.first_name"
                    @input="formActions.clearFieldError('first_name')"
                    name="first_name"
                    id="first_name"
                    autocomplete="given-name"
                    class="form-field-lg form-field-no-icon w-full"
                  />
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.first_name }}
                </p>
              </div>
              <div>
                <label for="last_name">Last name</label>
                <div class="mt-1">
                  <input
                    type="text"
                    v-model="formData.last_name"
                    @input="formActions.clearFieldError('last_name')"
                    name="last_name"
                    id="last_name"
                    autocomplete="family-name"
                    class="form-field-lg form-field-no-icon w-full"
                  />
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.last_name }}
                </p>
              </div>
              <div>
                <label for="email">Email</label>
                <div class="mt-1">
                  <input
                    id="email"
                    v-model="formData.email"
                    @input="formActions.clearFieldError('email')"
                    name="email"
                    type="email"
                    autocomplete="email"
                    class="form-field-lg form-field-no-icon w-full"
                  />
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.email }}
                </p>
              </div>
              <div>
                <div class="flex justify-between">
                  <label for="phone">Phone</label>
                  <span id="phone-optional" class="text-sm text-gray-500"
                    >Optional</span
                  >
                </div>
                <div class="mt-1">
                  <input
                    type="text"
                    v-model="formData.phone"
                    @input="formActions.clearFieldError('phone')"
                    name="phone"
                    id="phone"
                    autocomplete="tel"
                    class="form-field-lg form-field-no-icon w-full"
                    aria-describedby="phone-optional"
                  />
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.phone }}
                </p>
              </div>
              <div class="sm:col-span-2">
                <label for="subject">Subject</label>
                <div class="mt-1">
                  <input
                    type="text"
                    v-model="formData.subject"
                    @input="formActions.clearFieldError('subject')"
                    name="subject"
                    id="subject"
                    class="form-field-lg form-field-no-icon w-full"
                  />
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.subject }}
                </p>
              </div>
              <div class="sm:col-span-2">
                <div class="flex justify-between">
                  <label for="message">Message</label>
                  <span id="message-max" class="text-sm text-gray-500"
                    >Max. 500 characters</span
                  >
                </div>
                <div class="mt-1">
                  <textarea
                    id="message"
                    v-model="formData.message"
                    @input="formActions.clearFieldError('message')"
                    name="message"
                    rows="4"
                    class="form-field-lg form-field-no-icon w-full"
                    aria-describedby="message-max"
                  ></textarea>
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.message }}
                </p>
              </div>
              <div class="sm:col-span-2 sm:flex sm:justify-end">
                <button type="submit" class="button-submit button-lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/common/Container";
import formMixin from "@/mixins/formMixin";
import { reactive } from "vue";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";

export default {
  name: "Contact",
  mixins: [formMixin],
  components: {
    Container,
  },
  setup() {
    const formData = reactive({
      first_name: "",
      last_name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
    const { errors, formActions } = useFormErrors();
    return { formData, errors, formActions };
  },
  computed: {
    ...mapGetters("auth", ["userData"]),
  },
  mounted() {
    Object.assign(this.formData, {
      email: this.userData?.email || "",
    });
  },
  methods: {
    async onSubmit() {
      const response = await this.handleSubmissionErrors(
        async () => await this.$alfredService.sendMessage(this.formData),
        { formActions: this.formActions }
      );
      if (response) {
        Object.assign(this.formData, {
          first_name: "",
          last_name: "",
          email: this.userData?.email || "",
          subject: "",
          phone: "",
          message: "",
        });
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Your message has been successfully sent!",
          },
        });
      }
    },
  },
};
</script>
