<template>
  <span
    class="flex form-field sm:w-80 w-full px-3 py-2 mt-1 sm:mt-0 bg-white shadow"
  >
    <input
      name="search"
      class="focus:outline-none flex-grow"
      v-model="searchKeyword"
      @input="onSearchInput"
      :placeholder="getPlaceholder"
    />
    <SearchIcon class="h-6 inline text-gray-500 flex-none" />
  </span>
</template>

<script>
import { ref } from "@vue/reactivity";
import { SearchIcon } from "@heroicons/vue/solid";
import { debounce } from "lodash";

export default {
  name: "SearchInput",
  inheritAttrs: false,
  components: {
    SearchIcon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      require: false,
    },
  },
  setup() {
    return { searchKeyword: ref("") };
  },
  computed: {
    getPlaceholder() {
      return this.placeholder || "Search by Email";
    },
  },
  methods: {
    onSearchInput: debounce(function () {
      this.$emit("update:modelValue", this.searchKeyword);
      this.$emit("input", this.searchKeyword);
    }, 300),
  },
};
</script>
