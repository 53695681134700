<template>
  <div class="inline-flex tooltip">
    <div class="relative tooltip-inner">
      <div
        class="bg-gray-600 opacity-90 tooltip-inside text-white text-xs rounded py-1 px-1 mt-1 right-0 bottom-full whitespace-pre-wrap"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
};
</script>
<style scoped>
.tooltip-inner {
  transform: translateX(-50%);
}
.tooltip {
  @apply invisible absolute;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  -ms-transition: opacity 0.1s ease-in-out;
  -o-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}
</style>
