<template>
  <div class="pb-4">
    <ExpertDownloadModal />
    <ExportKlaviyoList />
    <KlaviyoDownload ref="KlaviyoDownload" />

    <div
      class="flex sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8"
    >
      <div class="flex flex-col text-sm text-left sm:w-1/4 w-full">
        <div class="grid grid-cols-2 mb-2" id="name">
          <span class="font-medium text-gray-600 uppercase">Name</span>
          <span class="text-gray-500 whitespace-nowrap">{{ fileName }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase">Created</span>
          <span class="text-gray-500 whitespace-nowrap">{{ createdAt }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase">Quantity</span>
          <span class="text-gray-500 whitespace-nowrap">{{
            totalEmails(emailsCount)
          }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase">Status</span>
          <span class="text-gray-500 whitespace-nowrap">Complete</span>
        </div>
      </div>
      <div class="flex items-center m-2" v-if="!isDownload">
        <div class="pr-3">
          <button
            @click="showDashboard()"
            type="submit"
            class="button-submit border px-4 py-2.5 border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-100 focus:outline-none"
          >
            View Dashboard
          </button>
        </div>
        <button
          v-if="delivOnly"
          @click="DownloadFileDeliv(job)"
          :disabled="!job.result_file"
          type="submit"
          class="button-submit"
        >
          Export List
        </button>
        <button
        v-if="!delivOnly"
          @click="showDownloadFileModal(job)"
          :disabled="!job.result_file"
          type="submit"
          class="button-submit"
        >
          Export List
        </button>
        <button
          @click="onDelete()"
          class="ml-5 text-gray-500 focus:outline-none"
        >
          <TrashIcon class="h-6" />
        </button>
        <button
          class="ml-5 text-red-500 focus:outline-none text-sm"
          @click="fileDetails"
          v-if="!isGenerating"
        >
          <img
            class="mx-auto h-6 w-auto"
            :src="require('@/assets/images/pdf.png')"
            alt="Alfred Knows"
          />PDF
        </button>
      </div>
    </div>

    <TabContent
      :data="charts"
      :count="determineEmailCount"
      v-if="!isDownload"
    />

    <!-- <div class="flex flex-col md:flex-row md:justify-between">
    <Chart
      v-for="chart in charts"
      :key="chart.title"
      :title="chart.title"
      :labels="chart.labels"
      :data="chart.data"
    />
  </div> -->
    <div class="sm:flex sm:items-start justify-center" v-if="isDownload">
      <PulseLoader color="#ff5a5f" />
    </div>
    <EmailList v-if="!delivOnly" class="mt-6 text-left" />
    <DelivEmailList v-if="delivOnly" class="mt-6 text-left" />

    <div
      class="sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8 canvas_div_pdf"
      id="content"
      hidden
    >
      <div class="flex flex-col text-sm text-left sm:w-1/4 w-full pb-6">
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase"
            >Name: &nbsp;&nbsp;</span
          >
          <span class="text-gray-500 whitespace-nowrap">{{ fileName }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase"
            >Created: &nbsp;&nbsp;</span
          >
          <span class="text-gray-500 whitespace-nowrap">{{ createdAt }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase"
            >Quantity: &nbsp;&nbsp;</span
          >
          <span class="text-gray-500 whitespace-nowrap">{{
            totalEmails(emailsCount)
          }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase"
            >Status: &nbsp;&nbsp;</span
          >
          <span class="text-gray-500 whitespace-nowrap">Complete</span>
        </div>
      </div>

      <div class="py-5 p-4">
        <div
          v-for="(menuitem, indexi) in charts"
          :key="indexi"
          class="text-gray-600 text-xl align-left pb-10"
        >
          <h3 class="text-lg leading-6 font-bold text-gray-600">
            {{ menuitem.title }}
          </h3>
          <dl class="mt-5 grid gap-1 sm:grid-cols-3">
            <div
              v-for="(item, index) in charts[indexi].data"
              :key="index"
              class="px-4 py-5 bg-white shadow rounded-lg sm:p-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                {{ charts[indexi].labels[index].text }}
              </dt>
              <div></div>
              <dd
                :class="`mt-1 text-2xl font-semibold ${charts[indexi].labels[index].color_class}`"
                v-if="indexi !== 0"
              >
                {{ formatValue(item, indexi) }}
                <span class="mt-1 text-xs font-medium text-gray-700"
                  >{{ formatNumber(item) }}
                </span>
              </dd>
              <dd
                :class="`mt-1 text-2xl font-semibold ${charts[indexi].labels[index].color_class}`"
                v-if="indexi == 0"
              >
                {{ formatValueObjective(item) }}
                <span class="mt-1 text-xs font-medium text-gray-700"
                  >{{ formatNumber(item) }}
                </span>
              </dd>   
            </div>
          </dl>
        </div>
        <br />
      </div>
    </div>
  </div>

  <teleport to="body">
    <transition name="fade">
      <div v-if="showPopup" class="popup-container">
        <div class="popup inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:max-h-md w-full rounded-lg">
            <h3 id="headlessui-dialog-title-12" class="text-lg leading-6 font-medium text-gray-900"><div class="pb-1"> Download by deliverability status of email address </div></h3>
            <fieldset class="flex">
          <!-- <legend class="text-sm font-bold text-gray-600">Score</legend> -->
          <div
            v-for="(label, labelIdx) in deliv_values"
            :key="labelIdx"
            class="relative flex items-start py-2 space-x-3"
          >
            <div class="ml-3 flex items-center h-5">
              <input
                :id="`label-${label.name}`"
                :name="`label-${label.name}`"
                type="checkbox"
                @click="clickDeliv(label.id, label.name)"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                checked
                />
            </div>
            <div class="min-w-0 flex-1 text-sm">
              <label
                :for="`label-${label.id}`"
                class="font-medium text-gray-400 select-none"
                >{{ humanize(label.name) }}</label
              >
            </div>
          </div>
        </fieldset>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b">
          <button
        type="button"
        class="button-submit sm:ml-3"
        @click="DownloadFile()"
      >
        Download
      </button>
      <button class="button-cancel" @click="showPopup = false">
            Cancel</button>
    </div>
        </div>
      </div>
    </transition>
    </teleport>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import EmailList from "@/components/basic/file-details-page/EmailList";
import DelivEmailList from "@/components/basic/file-details-page/DelivEmailList";
import TabContent from "@/components/basic/file-details-page/TabContent";
import apiMixin from "@/mixins/apiMixin";
import ExpertDownloadModal from "@/components/basic/common/modals/ExpertDownloadModal";
import ExportKlaviyoList from "@/components/basic/common/modals/ExportKlaviyoList";
import KlaviyoDownload from "@/components/basic/common/modals/KlaviyoDownload";
import modalMixin from "@/mixins/modalMixin";
import useConfirmationModal from "@/api/confirmationModal";
import { TrashIcon } from "@heroicons/vue/outline";
import PulseLoader from "vue-spinner/src/PulseLoader";
import humanize from "humanize";
import jsPDF from "jspdf";
import $ from "jquery";
import html2canvas from "html2canvas";

export default {
  name: "CompleteDetails",

  mixins: [modalMixin, apiMixin],
  components: {
    EmailList,
    DelivEmailList,
    TabContent,
    ExpertDownloadModal,
    ExportKlaviyoList,
    TrashIcon,
    PulseLoader,
    KlaviyoDownload,
  },

  setup() {
    return {
      job: reactive({}),
      jobResult: reactive({}),
      createdAt: ref(""),
      emailsCount: ref(0),
      fileName: ref(""),
      charts: reactive({}),
      determineEmailCount: ref(0),
      KlaviyoDownload: ref(),
      jobid: ref(0),
      isDownload: ref(false),
      isGenerating: ref(false),
      delivOnly: ref(false),
      showPopup: ref(false),
      deliv_values: [   
            { id: 3, name: "Unknown" },
            { id: 2, name: "Undeliverable" },
            { id: 1, name: "Possibly Deliverable" },
            { id: 0, name: "Deliverable" },
        ]
    };
  },

  async beforeMount() {
    const jobDetails = await this.$store.dispatch("fetching/FETCH", {
      apiCall: async () =>
        await this.$alfredService.getJob(this.$route.params.id),
    });
    this.jobid = this.$route.params.id;
    this.emailsCount = jobDetails.emails_count;
    if (jobDetails.actions[0] === "deliverability"){
      this.delivOnly = true;
      this.jobResult = {
      deliverabilityStats: jobDetails.result?.deliverability_job?.deliverability,
      providers: jobDetails.result?.deliverability_job?.providers,
      reasons: jobDetails.result?.deliverability_job?.reasons,
    };
    this.charts = [
      this.determineDeliverabilityStatusChart(
        this.jobResult.deliverabilityStats
      ),
      this.determineReasonChart(),
      this.determineProviderChart(),
    ];
    }
    else{
      this.jobResult = {
      deliverabilityStats: jobDetails.result?.verification_job?.deliverability,
      qualityStats: jobDetails.result?.verification_job?.scores,
      threatStats: jobDetails.result?.verification_job?.threat?.threat_scores,
      scoreStatus: jobDetails.result?.verification_job?.scores,
      providers: jobDetails.result?.verification_job?.providers,
      emailStatus: jobDetails.result?.verification_job?.scores,
      reasons: jobDetails.result?.verification_job?.reasons,
    };
    this.charts = [
      this.determineObjectiveChart(),
      this.determineRatingScoreChart(),
      this.determineProviderChart(),
      this.determineRecommendationChart(),
      this.determineDeliverabilityStatusChart(
        this.jobResult.deliverabilityStats
      ),
      this.determineThreatLevelChart(),
      this.determineReasonChart(),
    ];
    }

    this.job = jobDetails;
    this.createdAt = new Date(jobDetails.input_file.created_at).toLocaleString(
      "en-US"
    );
    this.fileName = jobDetails.input_file.name;
    this.emailsCount = jobDetails.emails_count;
    
    this.determineEmailCount = this.emailsCount;
    this.createdAt = new Date(jobDetails.input_file.created_at).toLocaleString(
      "en-US"
    );
    this.fileName = jobDetails.input_file.name;
  },

  methods: {
    saveDownloadFileDeliv(fileDownload) {
      const url = window.URL.createObjectURL(new Blob([fileDownload]));
      const link = document.createElement("a");
      link.href = url;
      const namePos = this.job.input_file.name.indexOf(".");
      const name = this.job.input_file.name.substr(0, namePos);
      const datePos = this.job.input_file.created_at.indexOf("T");
      const createdDate = this.job.input_file.created_at.substr(0, datePos);
      const fileName = name + "_results_" + createdDate + ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    },
    async DownloadFileDeliv() {
        const fileDownload = await this.$alfredService.expertDownload(
          this.job.id,
        );
        if (fileDownload) {
          this.saveDownloadFileDeliv(fileDownload);
        }
      },
    fileDetails() {
      this.isDownload = true;
      this.isGenerating = true;
      $("#content").removeAttr("hidden");
      const namePos = this.fileName.indexOf(".");
      let fileName1 = this.fileName.toString();
      const name = fileName1.substring(0, namePos);
      setTimeout(() => {
        // parentdiv is the html element which has to be converted to PDF
        html2canvas($(".canvas_div_pdf")[0], {
          allowTaint: true,
          scale: 2,
        }).then((canvas) => {
          var pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);

          var imgData = canvas.toDataURL("image/jpeg", 1.0);
          pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
          pdf.save(name + ".pdf");
        });
        $("#content").attr("hidden", "true");
        this.isDownload = false;
        this.isGenerating = false;
      }, "3000");
    },
    formatNumber(count) {
      return ` (${humanize.numberFormat(count, 0)})`;
    },
    formatValue(value, index) {
      const percentage =
        Math.round((value * 100) / this.totalValue(index) || 0) + "%";
      return percentage;
    },
    formatValueObjective(value) {
      const percentage = Math.round((value * 100) / this.emailsCount || 0) + "%";
      return percentage;
    },
    totalValue(index) {
      return (
        this.charts &&
        this.charts[index]?.data?.reduce((acc, curr) => acc + curr, 0)
      );
    },
    async onDelete() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to delete this file?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.$alfredService.deleteJob(this.job.id);
          await this.$router.push({
            name: "Dashboard",
          });
        },
      });
    },
    async showDashboard() {
      await this.$router.push({
        name: "Dashboard",
      });
    },
    saveDownloadFile(fileDownload) {
      const url = window.URL.createObjectURL(new Blob([fileDownload]));
      const link = document.createElement("a");
      link.href = url;
      const namePos = this.job.input_file.name.indexOf(".");
      const name = this.job.input_file.name.substr(0, namePos);
      const datePos = this.job.input_file.created_at.indexOf("T");
      const createdDate = this.job.input_file.created_at.substr(0, datePos);
      const fileName = name + "_results_" + createdDate + ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    },
    showDownloadFileModal(job) {
      // eventBus.trigger("show-expert", { job });
      this.KlaviyoDownload.openModal({ job });
    },
    humanize(words) {
      const separateWords = words.toLowerCase().split("_");
      for (let i = 0; i < separateWords.length; i++) {
        separateWords[i] =
          separateWords[i].charAt(0).toUpperCase() +
          separateWords[i].substring(1);
      }
      return separateWords
        .join(" ")
        .replaceAll("Smtp", "SMTP")
        .replaceAll("Dns", "DNS");
    },
    totalEmails(count) {
      return humanize.numberFormat(count, 0);
    },

    determineObjectiveChart() {
      const qualityStats = this.jobResult.qualityStats.reduce(
        (accumulator, currentValue) => {
          if (currentValue.score >= 7) {
            accumulator[0] += currentValue.email_count;
          }
          if (currentValue.score >= 6) {
            accumulator[1] += currentValue.email_count;
          }
          return accumulator;
        },
        [0, 0]
      );

      return {
        title: "Objective",
        visible: true,
        labels: [
          { text: "Best Deliverability", color_class: "text-green-600" },
          { text: "Maximum Reach", color_class: "text-yellow-400" },
        ],
        data: qualityStats,
      };
    },

    determineRecommendationChart() {
      const qualityStats = this.jobResult.qualityStats.reduce(
        (accumulator, currentValue) => {
          if (currentValue.score >= 7) {
            accumulator[0] += currentValue.email_count;
          } else if (currentValue.score >= 4) {
            accumulator[1] += currentValue.email_count;
          } else {
            accumulator[2] += currentValue.email_count;
          }
          return accumulator;
        },
        [0, 0, 0]
      );

      return {
        title: "Recommendation",
        visible: true,
        labels: [
          { text: "Safe to Send", color_class: "text-green-600" },
          { text: "Send with Caution", color_class: "text-yellow-400" },
          { text: "Do not Send", color_class: "text-red-600" },
        ],
        data: qualityStats,
      };
    },

    determineRatingScoreChart() {
      const qualityStats = this.jobResult.scoreStatus.reduce(
        (accumulator, currentValue) => {
          if (currentValue.score >= 9) {
            accumulator[0] += currentValue.email_count;
          } else if (currentValue.score >= 7) {
            accumulator[1] += currentValue.email_count;
          } else if (currentValue.score == 6) {
            accumulator[2] += currentValue.email_count;
          } else if (currentValue.score >= 4) {
            accumulator[3] += currentValue.email_count;
          } else {
            accumulator[4] += currentValue.email_count;
          }

          return accumulator;
        },
        [0, 0, 0, 0, 0]
      );

      return {
        title: "Score",
        visible: true,
        labels: [
          { text: "10-9 (out of 10)", color_class: "text-green-600" },
          { text: "7-8 (out of 10)", color_class: "text-green-400" },
          { text: "6 (out of 10)", color_class: "text-yellow-400" },
          { text: "4-5 (out of 10)", color_class: " text-red-400" },
          { text: "1-3 (out of 10)", color_class: " text-red-600" },
        ],
        data: qualityStats,
      };
    },

    determineDeliverabilityStatusChart({
      deliverable,
      possibly_deliverable,
      undeliverable,
      unknown_deliverability,
    }) {
      return {
        title: "Deliverability Status",
        visible: true,
        labels: [
          { text: "Deliverable", color_class: "text-green-600" },
          { text: "Possibly Deliverable", color_class: "text-yellow-400" },
          { text: "Undeliverable", color_class: "text-red-600" },
          { text: "Unknown", color_class: "text-gray-400" },
        ],
        data: [
          deliverable,
          possibly_deliverable,
          undeliverable,
          unknown_deliverability,
        ],
      };
    },
    determineThreatLevelChart() {
      let tempThreatStats = this.jobResult.threatStats;
      if (this.jobResult.threatStats.length > 3) {
        tempThreatStats = [
          { score: 1, email_count: 0 },
          { score: 2, email_count: 0 },
          { score: 3, email_count: 0 },
        ];
        //reduce to low mid high
        this.jobResult.threatStats.forEach((element) => {
          if (element.score == 1) {
            tempThreatStats[0].email_count += element.email_count;
          } else if (element.score <= 3) {
            tempThreatStats[1].email_count += element.email_count;
          } else if (element.score <= 5) {
            tempThreatStats[2].email_count += element.email_count;
          }
        });
      }
      const threatStats = tempThreatStats.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.email_count);
          return accumulator;
        },
        []
      );
      const totalKnown = threatStats.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const totalUnknown =  this.emailsCount - totalKnown;
      //new respose with low,mid,high
      return {
        title: "Threat Level",
        visible: true,
        labels: [
          { text: "Low", color_class: "text-green-400" },
          { text: "Moderate", color_class: "text-yellow-400" },
          { text: "High", color_class: "text-red-600" },
          { text: "Undetermined/Unknown", color_class: "text-gray-400" },
        ],
        data: [...threatStats, totalUnknown],
      };
    },

    determineReasonChart() {
      let labels = [];
      let data = [];
      const color_class = {
        accepted_email: "text-green-600",
        catch_all: "text-yellow-400",
        disposable: "text-yellow-400",
        invalid_email: "text-red-600",
        invalid_domain: "text-red-600",
        rejected_email: "text-red-600",
        dns_error: "text-yellow-400",
        unavailable_smtp: "text-yellow-400",
        unknown: "text-gray-400",
        role_based: "text-yellow-400",
        mailbox_disabled: "text-gray-400",
        mailbox_full: "text-gray-400",
        suspicious: "text-yellow-400",
        dangerous: "text-red-600",
        malicious: "text-red-600",
      };
      const acceptemails = Object.keys(this.jobResult.reasons)
        .filter((key) => key.includes("accepted_email") && this.jobResult.reasons[key] !== 0)
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.reasons[key],
          });
        }, {});

      const acceptemailbox = Object.keys(this.jobResult.reasons)
        .filter((key) => key.includes("mailbox_full") && this.jobResult.reasons[key] !== 0)
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.reasons[key],
          });
        }, {});

      const selectedyello = [
        "catch_all",
        "disposable",
        "dns_error",
        "role_based",
        "unavailable_smtp",
        "suspicious",
      ];

      const filteredyellow = Object.keys(this.jobResult.reasons) 
        .filter((key) => selectedyello.includes(key) && this.jobResult.reasons[key] !== 0)
        .reduce((obj, key) => {
          obj[key] = this.jobResult.reasons[key];
          return obj;
        }, {});

      const sortableYellow = Object.fromEntries(
        Object.entries(filteredyellow).sort((a, b) => {
          if (b[1] > a[1]) return 1;
          else if (b[1] < a[1]) return -1;
        })
      );

      const selectedred = [
        "malicious",
        "dangerous",
        "rejected_email",
        "invalid_domain",
        "invalid_email",
      ];

      const filteredred = Object.keys(this.jobResult.reasons)
        .filter((key) => selectedred.includes(key) && this.jobResult.reasons[key] !== 0)
        .reduce((obj, key) => {
          obj[key] = this.jobResult.reasons[key];
          return obj;
        }, {});

      const sortablered = Object.fromEntries(
        Object.entries(filteredred).sort((a, b) => {
          if (b[1] > a[1]) return 1;
          else if (b[1] < a[1]) return -1;
        })
      );
      const object = {
        ...acceptemails,
        ...sortableYellow,
        ...sortablered,
        ...acceptemailbox,
      };
      const finalObject = object;

      this.jobResult?.reasons &&
        Object.keys(finalObject).forEach((element) => {
          labels.push({
            text: this.humanize(element),
            color_class: color_class[element] || "text-gray-400",
          });
          data.push(finalObject[element]);
        });

      return {
        title: "Reason",
        visible: this.jobResult?.reasons ? true : false,
        labels: labels,
        data: data,
      };
    },
    determineProviderChart() {
      let labels = [];
      let data = [];
      const excluded = Object.keys(this.jobResult.providers)
        .filter((key) => !key.includes("Other"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.providers[key],
          });
        }, {});
      const sortableexcluded = Object.fromEntries(
        Object.entries(excluded).sort((a, b) => {
          if (b[1] > a[1]) return 1;
          else if (b[1] < a[1]) return -1;
        })
      );

      const othersmails = Object.keys(this.jobResult.providers)
        .filter((key) => key.includes("Other"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.providers[key],
          });
        }, {});
      const providerdata = { ...sortableexcluded, ...othersmails };

      this.jobResult?.providers &&
        Object.keys(providerdata).forEach((element) => {
          labels.push({
            text: element,
            color_class: "text-yellow-400",
          });
          data.push(providerdata[element]);
        });

      return {
        title: "Providers",
        visible: this.jobResult?.providers ? true : false,
        labels: labels,
        data: data,
      };
    },
  },
};
</script>

<style scoped></style>
