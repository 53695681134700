import { createApp } from "vue";
import Notifications from "vue3-vt-notifications";
import Maska from "maska";
import VueCookies from "vue3-cookies";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/utils/veeValidators";
import { API_URL, API_V2_URL, STRIPE_SECRET } from "@/config";
import AlfredService from "@/services/alfred";
import AlfredServiceV2 from "@/services/alfredV2";
import { loadStripe } from "@stripe/stripe-js";
import vClickOutside from "click-outside-vue3";

const app = createApp(App).use(VueClipboard);

const alfredService = new AlfredService({
  apiUrl: API_URL,
});

const alfredServiceV2 = new AlfredServiceV2({
  apiUrl: API_V2_URL,
  alfredService: alfredService,
});

store.$alfredService = alfredService;
store.$alfredServiceV2 = alfredServiceV2;
app.config.globalProperties.$alfredService = alfredService;
app.config.globalProperties.$alfredServiceV2 = alfredServiceV2;

let stripeService = loadStripe(STRIPE_SECRET);

Object.defineProperty(app.config.globalProperties, "$stripeService", {
  get: function () {
    return stripeService;
  },
});

app.use(store);
app.use(router);
app.use(Notifications);
app.use(Maska);
app.use(VueCookies);
app.use(vClickOutside);
app.mount("#app");

export default app;
