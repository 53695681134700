<template>
  <div class="min-h-screen bg-gray-50 p-2">
    <div class="flex justify-center sm:justify-start px-3 py-3">
      <router-link to="/">
        <img
          class="h-10"
          :src="require('@/assets/images/logo.png')"
          alt="Alfred Knows"
        />
      </router-link>
    </div>
    <div
      class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Login
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form class="space-y-6" @submit="onSubmit">
            <div>
              <label for="email-address"> Email address </label>
              <div class="mt-1">
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required=""
                  v-model="email"
                  class="form-field-no-icon w-full"
                  placeholder="Email address"
                />
              </div>
            </div>
            <div>
              <label for="password"> Password </label>
              <div class="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  v-model="password"
                  class="form-field-no-icon w-full"
                  placeholder="Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                class="group relative button-submit w-full m-0"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    class="h-5 w-5 text-red-700 group-hover:text-red-300"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button>
            </div>

            <div class="flex items-center justify-between flex-wrap">
              <div class="text-sm mr-2">
                <router-link :to="{ name: 'ResetPassword' }" class="link-text">
                  Forgot your password?
                </router-link>
              </div>
              <div class="text-sm">
                <router-link :to="{ name: 'Registration' }" class="link-text">
                  Don’t have an account?
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    LockClosedIcon,
  },
  setup() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push(this.nextUrl);
    }
  },
  computed: {
    nextUrl() {
      return this.$route.query.nextUrl ? this.$route.query.nextUrl : "/";
    },
    ...mapGetters("auth", ["isLoggedIn"]),
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      const response = await this.$store.dispatch("auth/LOGIN", {
        email: this.email,
        password: this.password,
      });
      if (response) {
        await this.$store.dispatch("auth/FETCH_USER_DATA");
        await this.$router.push(this.nextUrl);
        await this.$router.go();
      }
    },
  },
};
</script>
