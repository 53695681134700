<template>
  <Container>
    <template v-slot:header>Credits</template>
    <template v-slot:content>

      <div class="bg-white shadow rounded-lg p-8 mb-6 px-4 sm:px-6 w-auto">
        <span class="flex text-lg justify-left font-semibold mb-5">Credit Calculator</span>
        <span class="flex justify-left font-bold">Number of Emails</span>
        <input v-model="inputValue" @input="handleInput" class="mt-2 mb-5 text-3xl sm:text-3xl
                      font-extrabold form-field-no-icon pr-12 w-full" autocomplete="off" />
        <p class="flex justify-left text-red-500 text-sm mt-1" v-if="errorMessage">{{ errorMessage }} </p>
        <span class="flex justify-left font-bold mt-7">Type of Service</span>
        <div class="flex">
          <!-- First Box -->
          <div class="item-center pt-2 justify-center px-5 py-5 p-2 flex-auto md:w-60">
            <span class="bg-red-100 flex justify-center text-red-800 px-2 py-1 rounded-t-lg block">Most Popular</span>
            <div @click="selectBox(1)"
              :class="{ 'selected-box': selectedBox === 1, 'unselected-box': selectedBox !== 1 }"
              class="box-item best-value rounded-b-lg">
              <p class="font-bold">Deliverability + Threat Detection</p>
              <ul class="ml-3 pl-3 list-disc">
                <li class="text-sm text-gray-500 text-left">Recommended for most lists</li>
                <li class="text-sm text-gray-500 text-left">Best value</li>
              </ul>
              <div class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
                <p class="pt-3 absolute bottom-2">{{ deliv_threat }} credits</p>
              </div>
            </div>
          </div>

          <!-- Second Box -->
          <div class="item-center pt-2 justify-center px-5 py-5 p-2 flex-auto md:w-56">
            <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
            <div @click="selectBox(2)"
              :class="{ 'selected-box': selectedBox === 2, 'unselected-box': selectedBox !== 2 }"
              class="box-item rounded-lg">
              <p class="font-bold">Deliverability Check</p>
              <ul class="ml-3 pl-3 list-disc">
                <li class="text-sm text-gray-500 text-left">Validates email addresses</li>
                <li class="text-sm text-gray-500 text-left">Excludes threat detection</li>
              </ul>
              <div class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
                <p class="pt-3 absolute bottom-2">{{ deliv }} credits</p>
              </div>
            </div>
          </div>

          <!-- Third Box -->
          <div class="item-center pt-2 justify-center px-5 py-5 p-2 flex-auto md:w-56">
            <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
            <div @click="selectBox(3)"
              :class="{ 'selected-box': selectedBox === 3, 'unselected-box': selectedBox !== 3 }"
              class="box-item rounded-lg">
              <p class="font-bold">Threat Detection</p>
              <ul class="ml-3 pl-3 list-disc">
                <li class="text-sm text-gray-500 text-left">Identifies toxic addresses</li>
                <li class="text-sm text-gray-500 text-left">Excludes deliverability check</li>
              </ul>
              <div class="bg-gray-300 p-5 w-full absolute left-0 bottom-0 rounded-b-lg">
                <p class="pt-3 absolute bottom-2">{{ threat }} credits</p>
              </div>
            </div>
          </div>

        </div>
        <span class="flex justify-left font-bold mt-3">Price</span>
        <NumberInput v-model="price" @input="onPriceTyped" :isMoney="true" decimals="2" disabled classes="
                      form-field-no-icon
                      self-center
                      my-2
                      mb-3
                      text-3xl sm:text-3xl
                      font-extrabold
                      text-gray-900 w-full
                    " />

        <div>
          <p v-if="isExceeded" class="flex justify-left pb-5 text-red-500 text-center text-sm mt-1">
            Credits quantity must be between 100 and
            {{ maxSliderValue }}.
          </p>
          <span class="flex justify-left text-sm">Price Per Credit: &nbsp; <p class="font-bold"> ${{ receivedPrice }}
            </p>
          </span>

        </div>
        <a class="text-red-400 visited:text-purple-600 flex justify-left underline cursor-pointer"
          @click="showPopup = true;">
          See Pricelist</a>
        <button @click="onBuyCredits" :disabled="isExceeded"
          class="button-submit mt-5 whitespace-nowrap m-1 px-6 py-3 text-base">
          Buy Credits
        </button>
      </div>

    </template>

  </Container>

  <teleport to="body">
    <transition name="fade">
      <div v-if="showPopup" class="element-with-scrollbar overflow-auto popup-container">


        <button type="button" @click="onClose" class="button-icon text-gray-400 hover:text-gray-500">
          <span class="sr-only">Close</span>
          <XIcon class="h-5 w-5" aria-hidden="true" />
        </button>

        <div
          class="popup inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:max-h-md w-full rounded-lg">
          <fieldset>
            <div class="flex flex-col">
              <div class="-my-2 element-with-scrollbar overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="h-96 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="mt-5">
                    <div class="border-b border-gray-200 sm:rounded-lg">
                      <table class="divide-gray-300 table-fixed w-full">
                        <thead class="border-b-2">
                          <tr>
                            <th scope="col"
                              class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Credits
                            </th>
                            <th scope="col"
                              class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Cost Per 1 Credit
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(pricingRange, rangeIdx) in schema" :key="pricingRange[0]"
                            class="border-b-2 bg-white">
                            <td :class="`
                    px-6
                    py-4
                    whitespace-nowrap
                    text-sm
                    font-medium
                    ${highlightClass(rangeIdx)}
                    `" v-if="pricingRange.to">
                              {{ pricingRange.from }} - {{ pricingRange.to }}
                            </td>
                            <td :class="`
                    px-6
                    py-4
                    whitespace-nowrap
                    text-sm
                    font-medium
                    ${highlightClass(rangeIdx)}
                  `" v-else>
                              More than {{ pricingRange.from }}
                            </td>
                            <td v-if="rangeIdx === schema.length - 1" :class="`px-6 py-4 whitespace-nowrap text-sm ${highlightClass(
                              rangeIdx
                            )}`">
                              <router-link to="/contact" class="whitespace-nowrap underline hover:text-gray-400">
                                Contact Us
                              </router-link>
                            </td>
                            <td v-else :class="`px-6 py-4 whitespace-nowrap text-sm ${highlightClass(
                              rangeIdx, pricingRange.price
                            )}`">
                              {{
                                '$' + pricingRange.price
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="bg-gray-50 px-2 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b">

            <button class="button-cancel" @click="showPopup = false">
              Close</button>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
  <div class="hidden">
    <fieldset>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="mt-5">
              <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="divide-gray-300 table-fixed w-full">
                  <thead class="border-b-2">
                    <tr>
                      <th scope="col"
                        class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Quantity Range
                      </th>
                      <th scope="col"
                        class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Cost Per 1 Credit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(pricingRange, rangeIdx) in schema" :key="pricingRange[0]" class="border-b-2 bg-white">
                      <td :class="`
                    px-6
                    py-4
                    whitespace-nowrap
                    text-sm
                    font-medium
                    ${highlightClass(rangeIdx)}
                    `" v-if="pricingRange.to">
                        {{ pricingRange.from }} - {{ pricingRange.to }}
                      </td>
                      <td :class="`
                    px-6
                    py-4
                    whitespace-nowrap
                    text-sm
                    font-medium
                    ${highlightClass(rangeIdx)}
                  `" v-else>
                        More than {{ pricingRange.from }}
                      </td>
                      <td v-if="rangeIdx === schema.length - 1" :class="`px-6 py-4 whitespace-nowrap text-sm ${highlightClass(
                        rangeIdx
                      )}`">
                        <router-link to="/contact" class="whitespace-nowrap underline hover:text-gray-400">
                          Contact Us
                        </router-link>
                      </td>
                      <td v-else :class="`px-6 py-4 whitespace-nowrap text-sm ${highlightClass(
                        rangeIdx, pricingRange.price
                      )}`">
                        {{
                          tab === 'Threat Detection' ? '$' + formatPrice(pricingRange.price * 0.4) :
                            tab === 'Deliverability' ? '$' + formatPrice(pricingRange.price * 0.9) :
                              '$' + pricingRange.price
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Container from "@/components/common/Container";
import { ref } from "vue";
import apiMixin from "@/mixins/apiMixin";
import "vue-slider-component/theme/default.css";
import NumberInput from "@/components/common/NumberInput";
import eventBus from "@/utils/eventBus";
import humanize from "humanize";

export default {
  name: "Credits",
  components: {
    Container,
    NumberInput,
  },
  mixins: [apiMixin],
  setup() {
    const minSliderValue = 1000;
    const maxSliderValue = ref(1000000);

    const pricingSchema = ref([]);
    const value = ref(1000);
    const price = ref(0);
    const currentThreshold = ref(null);

    const deliv_threat = ref(1000);
    const deliv = ref(900);
    const threat = ref(400);
    const inputValue = ref('1,000');
    const selectedBox = ref(1);
    const showPopup = ref(false);
    const receivedPrice = ref(null)
    return {
      pricingSchema,
      value,
      price,
      currentThreshold,
      minSliderValue,
      maxSliderValue,
      deliv_threat,
      deliv,
      threat,
      inputValue,
      selectedBox,
      showPopup,
      receivedPrice
    };
  },
  beforeMount() {
    this.getPricing()
      .then(() => {
        this.determinePrice(this.value);
        this.determineCurrentThresholdByValue(this.value);
      });
  },
  computed: {
    schema() {
      return this.pricingSchema.reduce(
        (previousValue, currentValue, index, array) => {
          const nextIndex = index + 1;
          previousValue.push({
            from: humanize.numberFormat(currentValue["from"] + 1, 0),
            to: array[nextIndex]
              ? humanize.numberFormat(array[nextIndex]["from"], 0)
              : null,
            price: currentValue["price"],
          });
          return previousValue;
        },
        []
      );
    },
    currentThresholdIdx() {
      return this.pricingSchema.indexOf(this.currentThreshold);
    },
    isExceeded() {
      return (
        this.value > this.maxSliderValue || this.value < 100
      );
    },
  },
  watch: {
    pricingSchema() {
      if (this.value <= 100) {
        this.determineCurrentThresholdByValue(this.value);
      }
      this.determineMaxSliderValue();
    },
  },
  methods: {
    highlightClass(idx, price) {
      if (idx === this.currentThresholdIdx) {
        this.receivedPrice = price;
        return "font-extrabold text-main";
      } else {
        return "text-gray-500";
      }
    },
    showPrice(price) {
      return price
    },
    formatPrice(price) {
      const parts = price.toString().split('.');
      const decimalPartLength = parts[1] ? parts[1].length : 0;
      return decimalPartLength > 6 ? price.toFixed(6) : price;
    },
    handlePrice(price) {
      this.receivedPrice = price;
    },
    selectBox(boxNumber) {
      this.selectedBox = boxNumber;
      if (this.selectedBox == 1) {
        this.value = this.deliv_threat
      } else if (this.selectedBox == 2) {
        this.value = this.deliv
      } else if (this.selectedBox == 3) {
        this.value = this.threat
      }
      this.value = String(this.value);
      if (this.value.includes(',')) {
        this.value = this.value.replace(/,/g, '');
      }
      this.handleProceed(parseInt(this.value))
    },
    handleInput(event) {
      let value = event.target.value.replace(/\D/g, '');
      let formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.inputValue = formattedValue;
      value = value.replace(/,/g, '');
      if (!isNaN(value) && value !== "" && value >= 0) {
        this.errorMessage = "";
        this.deliv_threat = this.formatNumber(Math.ceil(parseInt(value) * 1));
        this.deliv = this.formatNumber(Math.ceil(parseInt(value) * 0.9));
        this.threat = this.formatNumber(Math.ceil(parseInt(value) * 0.4));
        this.selectBox(this.selectedBox);
      } else {
        this.deliv_threat = 0;
        this.deliv = 0;
        this.threat = 0;
        this.errorMessage = "Please enter a valid number.";
      }
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleProceed(value) {
      this.value = value;
      this.determinePrice(value);
      this.onValueTyped(value);

    },
    toTheList() {
      this.PricingModal.openModal({});
    },
    onBuyCredits() {
      if (this.selectedBox == 1) {
        this.value = this.deliv_threat;
      } else if (this.selectedBox == 2) {
        this.value = this.deliv;
      } else if (this.selectedBox == 3) {
        this.value = this.threat;
      }
      if (typeof this.value === 'string') {
        this.value = this.value.split(",").join("");
      }
      eventBus.trigger("buy-credits", {
        initialValue: this.value,
        onSubmit: this.onCheckout,
      });
    },
    onCheckout(data) {
      eventBus.trigger("checkout", { data });
    },
    determineMaxSliderValue() {
      this.maxSliderValue =
        this.pricingSchema[this.pricingSchema.length - 1]["from"];
    },
    determineCurrentThresholdByValue(value) {
      for (let threshold of this.pricingSchema) {
        if (value > threshold.from) {
          this.currentThreshold = threshold;
        } else {
          break;
        }
      }
    },
    determineCurrentThresholdByPrice(price) {
      for (let threshold of this.pricingSchema) {
        if (price >= threshold.priceFrom) {
          this.currentThreshold = threshold;
        } else {
          break;
        }
      }
    },
    determineValue(price) {
      this.determineCurrentThresholdByPrice(price);
      this.value = Math.round(price / this.currentThreshold.price);
    },
    determinePrice(value) {
      let price;
      if (!this.pricingSchema.length) {
        price = 0.01 * value;
      } else {
        price = this.currentThreshold.price * value;
      }
      this.price = Math.round(price * 100) / 100;
    },
    onPriceTyped(value) {
      this.determineCurrentThresholdByPrice(value);
      this.determineValue(value);
    },
    onValueTyped(value) {
      this.determineCurrentThresholdByValue(value);
      this.determinePrice(value);
    },
    onSliderChange(value) {
      this.determineCurrentThresholdByValue(value);
      this.determinePrice(value);
    },
    tooltipFormatter() {
      return this.value;
    },
    async getPricing() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: () => this.$alfredService.getPricing(),
      });
      if (response) {
        this.pricingSchema = response.pricing_table;
        for (let threshold of this.pricingSchema) {
          threshold.priceFrom =
            Math.floor((threshold.from + 1) * threshold.price * 100) / 100;
        }
        this.currentThreshold = this.pricingSchema[0];
      }
    },
  },
};
</script>
<style scoped>
.vue-slider-dot-tooltip-inner {
  @apply bg-gray-600;
  @apply border-gray-600;
}

.vue-slider-process {
  background-color: var(--main-color);
}
</style>
<style scoped>
.credits-component {
  @apply flex-grow-0 !important;
}


.box-item {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  position: relative;
  height: 10rem;
}

.box-item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.selected-box {
  background-color: #fee2e2;
  border: 1px solid #fd7e7e !important;
}

.box-color {
  background-color: #c9c9c9;
}

.selected-color {
  background-color: #ffc4c4 !important;
}

.used-box {
  opacity: 0.5;
  cursor: not-allowed;
}

.unselected-box {
  opacity: 0.8;
}

.form-field-no-icon:disabled {
  opacity: initial;
}


.element-with-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.element-with-scrollbar::-webkit-scrollbar-track {
  background-color: #ff5a5f;
  border-radius: 5px;
}

.element-with-scrollbar::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 10px;
}

.element-with-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
</style>
