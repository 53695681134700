const apiMixin = {
  beforeMount() {
    this.$store.dispatch("fetching/RESET_STATE");
  },
  beforeUnmount() {
    this.$store.dispatch("fetching/RESET_STATE");
  },
};

export default apiMixin;
