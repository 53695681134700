<template>
  <div class="min-h-screen bg-gray-50 p-2">
    <div class="flex justify-center sm:justify-start px-3 py-3">
      <router-link to="/">
        <img
          class="h-10"
          :src="require('@/assets/images/logo.png')"
          alt="Alfred Knows"
        />
      </router-link>
    </div>
    <div
      class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create Free Account
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form @submit="onSubmit" v-slot="{ errors }" class="space-y-6">

            <div>
              <label for="name"> Name </label>
              <div class="mt-1">
                <Field
                  id="name"
                  name="name"
                  type="text"
                  v-model="name"
                  class="form-field-no-icon w-full"
                />
              </div>
            </div>

            <div>
              <label for="email"> Email address </label>
              <div class="mt-1">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  v-model="email"
                  autocomplete="email"
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">{{ errors.email }}</p>
              </div>
            </div>

            <div>
              <label for="password"> Password </label>
              <div class="mt-1">
                <Field
                  id="password"
                  name="password"
                  type="password"
                  v-model="password"
                  autocomplete="current-password"
                  required=""
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
              </div>
            </div>

            <div>
              <label for="password"> Confirm Password </label>
              <div class="mt-1">
                <Field
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  v-model="confirmPassword"
                  autocomplete="current-password"
                  required=""
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.confirm_password }}
                </p>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <Field
                  name="agree_to_terms"
                  type="checkbox"
                  v-model="agreeToTerms"
                >
                  <input
                    ref="agreeRef"
                    id="agree_to_terms"
                    name="agree_to_terms"
                    type="checkbox"
                    v-model="agreeToTerms"
                    class="h-4 w-4 text-red-900 border-gray-300 rounded"
                  />
                  <label
                    for="agree"
                    class="ml-2"
                    v-bind:class="
                      errors.agree_to_terms
                        ? 'text-red-500 font-medium'
                        : 'text-gray-900'
                    "
                  >
                    <span @click="() => $refs.agreeRef.click()">
                      I agree and accept the
                    </span>
                    <a
                      href="https://alfredknows.com/company/legal/"
                      class="text-gray-500 hover:text-gray-900 cursor-pointer"
                    >
                      Terms of Use and Privacy Policy
                    </a>
                  </label>
                </Field>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <Field
                  name="subscribed_to_newsletter"
                  type="checkbox"
                  v-model="subscribedToNewsletter"
                >
                  <input
                    ref="agreeSub"
                    id="subscribed_to_newsletter"
                    name="subscribed_to_newsletter"
                    type="checkbox"
                    v-model="subscribedToNewsletter"
                    class="h-4 w-4 text-red-900 border-gray-300 rounded"
                  />
                    <label
                        class="ml-2"
                        v-bind:class="text-gray-900"
                    >
                        <span @click="() => $refs.agreeSub.click()">
                          I would like to subscribe to your email newsletter!
                        </span>
                    </label>
                </Field>
              </div>
            </div>

            <div>
              <button type="submit" :disabled="isLoading" class="button-submit w-full m-0" @click="submit">
                <span v-if="isLoading">
                        <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>                        
                    </span>
                    Create Free Account
              </button>
            </div>

            <div class="flex items-center justify-between">
              <div class="text-sm">
                <router-link :to="{ name: 'Login' }" class="link-text">
                  Already registered?
                </router-link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import { ref } from "vue";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import { mapGetters } from "vuex";

export default {
  name: "Registration",
  components: {
    Field,
    Form,
  },
  mixins: [formMixin],
  setup() {
    return {
      agreeToTerms: ref(false),
      subscribedToNewsletter: ref(false),
      success: ref(false),
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ settings: "settings/settings" }),
  },
  methods: {
    async onSubmit(values, actions) {
      const urlParams = new URLSearchParams(window.location.search);
      const referralCode = urlParams.get("ref");
      if (referralCode) {
        values.tapfiliate_referral_code = referralCode;
      }
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.register(values),
        { formActions: actions }
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "You have successfully registered! Now you need to confirm your email address.",
            type: "success",
          },
        });
        this.success = true;
        this.name = "";
        this.email = "";
        this.password = "";
        this.confirmPassword = "";
        this.tapfiliate_referral_code = "";
        this.agreeToTerms = false;
        this.subscribedToNewsletter = true;
        this.isLoading = false;
        await this.$router.push({ name: "RegistrationConfirmation" });
      } else {
        this.isLoading = false;
        this.success = false;
      }
    },
    async submit(){
      this.isLoading = true;
    },
    showLegalModal() {
      eventBus.trigger("show-legal");
    },
  },
};
</script>
<style scoped>
input:focus {
  --tw-ring-color: var(--main-color);
  border-color: var(--main-color);
}
</style>
