<template>
  <Container>
    <template v-slot:header>Dashboard</template>
    <template v-slot:content>
      <div class="flex justify-between flex-wrap sm:flex-nowrap items-start">
        <div class="shadow w-full sm:w-4/6 mb-6">
          <HealthDetails :jobData="jobData" />
        </div>
        <AvailableCredits class="hidden-print" />
      </div>
      <JobList
        v-if="data?.length"
        :data="data"
        :paginationData="paginationData"
      />
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import { reactive } from "vue";
import { ref } from "@vue/reactivity";
import apiMixin from "@/mixins/apiMixin";
import JobList from "@/components/basic/common/JobList";
import HealthDetails from "@/components/basic/common/HealthDetails";
import eventBus from "@/utils/eventBus";
import AvailableCredits from "@/components/basic/common/AvailableCredits";

export default {
  name: "ListHealthCheck",
  mixins: [apiMixin],
  components: {
    Container,
    JobList,
    AvailableCredits,
    HealthDetails,
  },
  setup() {
    const jobData = reactive({});
    return {
      data: ref([]),
      paginationData: reactive({}),
      jobData,
      timeout: ref(null),
      updateCount: ref(0),
    };
  },
  async beforeMount() {
    this.interval = window.setInterval(this.onUpdate, 3000);
    await this.getJob();
    await this.fetchJobs();
    eventBus.on("update", this.fetchJobs);
  },
  beforeUnmount() {
    window.clearInterval(this.interval);
    this.interval = null;
    eventBus.off("update", this.fetchJobs);
  },
  computed: {
    isCompleted() {
      return this.jobData?.status === "COMPLETED";
    },
  },
  watch: {
    isCompleted(value) {
      if (value) {
        this.fetchJobs();
      }
    },
  },
  methods: {
    async fetchJobs(urlParams = {}) {
      this.urlParams = { ...this.urlParams, ...urlParams };
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.fetchDashboard(this.urlParams),
      });
      if (response) {
        this.data = response.results || [];
        delete response.results;
        this.paginationData = response;
      }
    },
    async onUpdate() {
      if (!this.isCompleted) {
        await this.getJob();
        this.updateCount += 1;
      }
    },
    async getJob() {
      const jobData = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.getJob(this.$route.params.id),
      });
      if (jobData) {
        Object.assign(this.jobData, jobData);
      }
    },
  },
};
</script>
