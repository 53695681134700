import moment from "moment";

export function accessToken(state) {
  return state.accessToken;
}

export function isLoggedIn(state) {
  return !!(state.accessToken && state.refreshToken);
}

export function userData(state) {
  return state.userData;
}

export function isStaff(state) {
  return state.userData?.is_staff || false;
}

export function isParent(state) {
  return state.userData?.is_parent || false;
}

export function useApiV2(state) {
  return state.userData?.use_api_v2 || false;
}

export function hasToRefreshToken(state, getters) {
  if (!getters.isLoggedIn) {
    return false;
  }
  if (!state.expirationTime) {
    return false;
  }
  return moment() > state.expirationTime;
}
