import eventBus from "@/utils/eventBus";
import { flattenDict } from "@/utils/dicts";

function displayErrors(e, actions) {
  let isErrorHandled = false;
  if (e?.response?.data) {
    const nonFieldErrors = e.response.data["non_field_errors"] || [];
    for (let error of nonFieldErrors) {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          title: "Error",
          text: error,
          type: "error",
        },
      });
      isErrorHandled = true;
    }
    const detail = e.response.data["detail"] || e.response.data.error?.message;
    if (e.response.data["code"] === "token_not_valid") {
      isErrorHandled = true;
    } else if (detail) {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          title: "Error",
          text: detail,
          type: "error",
        },
      });
      isErrorHandled = true;
    } else if (e.response.status === 400 && actions !== null) {
      const data = flattenDict(e.response.data);
      for (const [key, value] of Object.entries(data)) {
        actions.setFieldError(key, value[0]);
      }
      isErrorHandled = true;
    }
  }
  if (!isErrorHandled) {
    eventBus.trigger("notify", {
      notification: {
        group: "top-right",
        text: "Unexpected error has occurred!",
        type: "error",
      },
    });
  }
}

const formMixin = {
  methods: {
    async handleSubmissionErrors(
      apiCall,
      { formActions = null, throwExceptions = false }
    ) {
      try {
        const response = await apiCall();
        return response;
      } catch (e) {
        displayErrors(e, formActions);
        if (throwExceptions) {
          throw e;
        }
      }
    },
  },
};

export default formMixin;
