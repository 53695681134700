<template>
  <Container>
    <template v-slot:header>List Detail</template>
    <template v-slot:content>
    <CompleteDetails v-if="fileName && !isThreat" />
      <ThreatDetails v-else-if="fileName && isThreat" />     
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import { ref } from "@vue/reactivity";
import CompleteDetails from "@/components/basic/file-details-page/CompleteDetails";
import ThreatDetails from "@/components/basic/file-details-page/ThreatDetails";
import apiMixin from "@/mixins/apiMixin";

export default {
  name: "FileDetails",
  components: {
    Container,
    CompleteDetails,
    ThreatDetails
  },
  mixins: [apiMixin],
  setup() {
    return { 
      fileName: ref(""),
      isThreat: ref(false)
   };
  },
  async beforeMount() {
    const jobDetails = await this.$store.dispatch("fetching/FETCH", {
      apiCall: async () =>
        await this.$alfredService.getJob(this.$route.params.id),
    });
    this.fileName = jobDetails.input_file.name;
    if(jobDetails.result.threat_assessment_job){
      this.isThreat = true;
    }
  },
};
</script>
