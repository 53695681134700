const intercomMixin = {
  methods: {
    setupIntercom(userData, isLoggedIn) {
      let args = {
        app_id: "x297kb0e",
        action_color: "#ff5a5f",
        background_color: "#fcd9da",
        hide_default_launcher: true,
      };
      if (isLoggedIn) {
        args = { ...args, email: userData.email, user_id: userData.id };
      }
      window.Intercom("boot", args);
    },
  },
};

export default intercomMixin;
