<template>
  <Container>
    <template v-slot:header>Profile</template>
    <template v-slot:content>
      <div class="lg:w-2/3 xl:w-1/2">
        <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
          <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
            Profile Details
          </h2>
          <form @submit.prevent="submitProfileChange">
            <div class="flex flex-col items-start pb-8">
              <div class="flex flex-col items-start w-full sm:w-full">
                <label>Name</label>
                <div class="flex flex-col w-full items-start">
                  <div
                    class="inline-flex flex-col items-start w-full sm:w-min-w-xs sm:flex-grow mb-2 sm:mb-0"
                  >
                    <input
                      id="name"
                      name="name"
                      type="text"
                      v-model="userData.name"
                      class="form-field-no-icon w-full my-1 mb-2"
                    />

                  </div>
                </div>
              </div>
              <div class="flex items-start w-full sm:w-full mt-2 mb-2">
                <input
                    id="subscribed_to_newsletter"
                    name="subscribed_to_newsletter"
                    type="checkbox"
                    v-model="userData.subscribed_to_newsletter"
                    class="h-4 w-4 text-red-900 border-gray-300 rounded"
                  />
                <label
                        class="ml-2"
                        v-bind:class="text-gray-900"
                    >
                        <span @click="() => userData.subscribed_to_newsletter=true">
                          I would like to subscribe to your email newsletter!
                        </span>
                    </label>
              </div>
              <button type="submit" :disabled="isLoading" class="button-submit">
                    <span v-if="isLoading">
                        <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                    </span>
                    Confirm</button>
            </div>
          </form>
        </section>
        <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
          <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
            Change Email Address
          </h2>
          <form @submit.prevent="submitEmailChange">
            <div class="flex flex-col items-start pb-8">
              <div class="flex flex-col items-start w-full sm:w-full">
                <label> Current Email Address </label>
                <div class="flex flex-col w-full items-start">
                  <div
                    class="inline-flex flex-col items-start w-full sm:w-min-w-xs sm:flex-grow mb-2 sm:mb-0"
                  >
                    <input
                      id="email"
                      name="email"
                      type="email"
                      disabled
                      :value="userData.email"
                      class="form-field-no-icon w-full my-1 mb-2"
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-start w-full sm:w-full mt-2">
                <label> New Email Address </label>
                <div class="flex flex-col w-full items-start">
                  <div
                    class="inline-flex flex-col items-start w-full sm:w-min-w-xs sm:flex-grow mb-2 sm:mb-0"
                  >
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      v-model="emailForm.email"
                      @change="clearError"
                      class="form-field-no-icon w-full mt-1 mb-2"
                    />
                    <p v-if="!isLoading" class="text-red-500 text-sm mt-1">
                      {{ emailFormErrors["email"] }}
                    </p>
                  </div>
                  <button type="submit" :disabled="isLoading" class="button-submit">
                    <span v-if="isLoading">
                        <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>                        
                    </span>
                    Confirm</button>
                </div>
              </div>
            </div>
          </form>
        </section>
        <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
          <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
            Change Password
          </h2>
          <form @submit.prevent="submitPasswordChange">
            <div class="flex flex-col items-start pb-8">
              <div class="flex flex-col items-start w-full sm:w-full">
                <label for="email"> Current Password </label>
                <input
                  id="current_password"
                  name="current_password"
                  type="password"
                  autofill="current-password"
                  required
                  v-model="passwordForm.current_password"
                  @input="
                    passwordFormActions.clearFieldError('current_password')
                  "
                  class="form-field-no-icon w-full mt-1 mb-1"
                />
                <p class="text-red-500 text-sm mt-1">
                  {{ passwordFormErrors["current_password"] }}
                </p>
              </div>
              <div class="flex flex-col items-start w-full sm:w-full mt-2">
                <label for="password"> New Password </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autofill="new-password"
                  required
                  v-model="passwordForm.password"
                  @input="passwordFormActions.clearFieldError('password')"
                  class="form-field-no-icon w-full mt-1 mb-1"
                />
                <p class="text-red-500 text-sm mt-1">
                  {{ passwordFormErrors["password"] }}
                </p>
              </div>
              <div class="flex flex-col items-start w-full sm:w-full mt-2">
                <label for="confirm_password"> Confirm New Password </label>
                <input
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  autofill="new-password"
                  required
                  v-model="passwordForm.confirm_password"
                  @input="
                    passwordFormActions.clearFieldError('confirm_password')
                  "
                  class="form-field-no-icon w-full mt-1 mb-2"
                />
                <p class="text-red-500 text-sm mt-1">
                  {{ passwordFormErrors["confirm_password"] }}
                </p>
              </div>
              <button type="submit" class="button-submit">Confirm</button>
            </div>
          </form>
        </section>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import { reactive } from "vue";
import { mapGetters } from "vuex";
import useFormErrors from "@/api/formErrors";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";

export default {
  name: "Profile",
  mixins: [formMixin],
  components: {
    Container,
  },
  setup() {
    const profileForm = reactive({});
    const emailForm = reactive({});
    const passwordForm = reactive({});

    const emailFormHelper = useFormErrors();
    const passwordFormHelper = useFormErrors();
    const profileFormHelper = useFormErrors();

    return {
      profileForm,
      emailForm,
      passwordForm,
      profileFormActions: profileFormHelper.formActions,
      emailFormErrors: emailFormHelper.errors,
      emailFormActions: emailFormHelper.formActions,
      passwordFormErrors: passwordFormHelper.errors,
      passwordFormActions: passwordFormHelper.formActions,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["userData"]),
  },
  methods: {
    async submitProfileChange() {
      this.isLoading = true;
      this.profileForm.name = this.userData.name;
      this.profileForm.subscribed_to_newsletter = this.userData.subscribed_to_newsletter;
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.updateUser(this.profileForm),
        {
          formActions: this.profileFormActions,
        }
      );
      this.isLoading = false;
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Profile details saved!",
          },
        });
      }
    },
    async submitEmailChange() {
      this.isLoading = true;
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.changeEmail(this.emailForm),
        {
          formActions: this.emailFormActions,
        }
      );
      this.isLoading = false;
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Confirmation email has been sent!",
          },
        });
      }
    },
    clearError() {
      this.emailFormErrors['email']=""
    },
    async submitPasswordChange() {
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.changePassword(this.passwordForm),
        {
          formActions: this.passwordFormActions,
        }
      );
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Password changed successfully!",
          },
        });
      }
    },
  },
};
</script>
