<template>
  <div>
    <div class="mb-6">
      <SearchInput v-model="searchKeyword" @input="onSearchInput" />
    </div>
    <div class="overflow-x-auto bg-white shadow rounded-md">
      <div class="align-middle inline-block w-full">
        <div class="sm:rounded-lg">
          <table class="divide-gray-300 w-full text-left table-auto">
            <thead class="border-b-2">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                  <FilterableHeaderCell
                    title="Email"
                    param="email"
                    @switchOrder="onSwitchOrder"
                  />
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                <FilterableHeaderCell
                    title="Deliverability Status"
                    param="deliverability_status"
                    @switchOrder="onSwitchOrder"
                  />
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                <FilterableHeaderCell
                    title="Reason"
                    param="deliverability_reason"
                    @switchOrder="onSwitchOrder"
                  />
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                <FilterableHeaderCell
                    title="Provider"
                    param="provider"
                    @switchOrder="onSwitchOrder"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="verificationResult in verificationResults"
                :key="verificationResult.email"
                class="row"
              >
                <td class="px-6 py-5 text-sm text-gray-500 w-1/4">
                  {{ verificationResult.email }}
                </td>
                <td
                  class="px-6 py-5 whitespace-nowrap text-sm text-gray-500 w-1/6"
                >
                  {{ verificationResult.status }}
                </td>
                <td
                  class="px-6 py-5 whitespace-nowrap text-sm text-gray-500 w-1/6"
                >
                  {{ verificationResult.reason }}
                </td>
                <td
                  class="px-6 py-5 whitespace-nowrap text-sm text-gray-500 w-1/6"
                >
                  {{ verificationResult.provider }}
                </td>
              </tr>
            </tbody>
          </table>
          <Paginator
            ref="paginator"
            :onPageChange="onPageChange"
            :data="paginationData"
          >
            <template v-slot:info="slotProps">
              <div class="w-full mr-4">
                <div
                  class="flex flex-row-reverse items-center flex-shrink justify-between align-center"
                >
                  <div
                    class="text-sm text-gray-700 whitespace-nowrap hidden sm:block"
                  >
                    Showing page
                    {{ " " }}
                    <span class="font-medium">{{ slotProps.page }}</span>
                    {{ " " }}
                    of
                    {{ " " }}
                    <span class="font-medium">{{ slotProps.pageCount }}</span>
                    {{ " " }}
                  </div>
                  <div class="flex-1 py-4">
                    <div class="text-sm text-left text-gray-500 mr-4 italic">
                      <span
                        >Your lists will be automatically deleted in
                        {{ settings?.AUTO_DELETE_DAYS }} days by default.
                      </span>
                      <router-link
                        to="/contact"
                        class="whitespace-nowrap underline hover:text-gray-400"
                        >Contact us</router-link
                      >
                      to request an extension or reduction.
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Paginator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import Paginator from "@/components/common/Paginator";
import apiMixin from "@/mixins/apiMixin";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import SearchInput from "@/components/common/SearchInput";
import { mapGetters } from "vuex";

export default {
  name: "DelivEmailList",
  props: ["resultFile"],
  mixins: [apiMixin],
  components: {
    SearchInput,
    Paginator,
    FilterableHeaderCell,
  },
  computed: {
    ...mapGetters({ settings: "settings/settings" }),
  },
  setup() {
    const paginationData = reactive({});
    const verificationResults = ref([]);
    const urlParams = reactive({});
    const paginator = ref();
    const searchKeyword = ref("");
    return {
      verificationResults,
      paginationData,
      urlParams,
      paginator,
      searchKeyword,
    };
  },
  beforeMount() {
    this.fetchEmailList();
  },
  methods: {
    async fetchEmailList() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.getJobPreview(
            this.$route.params.id,
            this.urlParams
          ),
      });
      if (response) {
        this.verificationResults = response["results"].map((value) => {
          return {
            ...value,
            reason: this.humanize(value["deliverability_reason"]),
            status: this.humanize(value["deliverability_status"]),
          };
        });
        delete response["results"];
        Object.assign(this.paginationData, response);
      }
    },
    async onPageChange(page) {
      this.urlParams["search"] = this.searchKeyword;
      this.urlParams["page"] = page;
      await this.fetchEmailList();
    },
    async onSearchInput() {
      this.urlParams["search"] = this.searchKeyword;
      await this.$refs.paginator.setPage(1);
    },
    humanize(words) {
      const separateWords = words.toLowerCase().split("_");
      for (let i = 0; i < separateWords.length; i++) {
        separateWords[i] =
          separateWords[i].charAt(0).toUpperCase() +
          separateWords[i].substring(1);
      }
      return separateWords
        .join(" ")
        .replaceAll("Smtp", "SMTP")
        .replaceAll("Dns", "DNS");
    },
    onSwitchOrder(ordering) {
      this.urlParams["ordering"] = ordering;
      this.$refs.paginator.setPage(1);
    },
  },
};
</script>
<style scoped>
.row {
  flex-grow: 1;
  flex-basis: 0;
}
</style>
