<template>
  <div v-if="data?.length" class="overflow-x-auto rounded-lg">
    <div class="align-middle inline-block w-full">
      <table class="divide-gray-300 w-full text-left table-auto">
        <thead class="border-b-2">
          <tr data-test="header-row">
            <th
              v-for="columnName in columnNames"
              :key="columnName"
              scope="col"
              class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left"
            >
              {{ columnName }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, index) in data" :key="index">
            <td class="px-6 py-5 text-sm text-gray-500 text-left">
              {{ row.url }}
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left">
              {{ new Date(row.created_at).toLocaleString("en-US") }}
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left trash-cell">
              <button @click="onDeleteWebhook(row.id)" class="button-icon">
                <TrashIcon class="h-6" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { TrashIcon } from "@heroicons/vue/outline";
import apiMixin from "@/mixins/apiMixin";
import formMixin from "@/mixins/formMixin";
import useConfirmationModal from "@/api/confirmationModal";
import eventBus from "@/utils/eventBus";

export default {
  name: "WebhooksTable",
  mixins: [apiMixin, formMixin],
  components: {
    TrashIcon,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    eventBus.trigger("update-webhooks");
  },
  setup() {
    const columnNames = ref(["Url", "Created", ""]);

    return { columnNames };
  },
  methods: {
    async onDeleteWebhook(id) {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to permanently delete this Webhook?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.handleSubmissionErrors(
            () => this.$alfredService.deleteWebhook(id),
            { throwExceptions: true }
          );
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "Webhook successfully deleted!",
            },
          });
          eventBus.trigger("update-webhooks");
        },
      });
    },
  },
};
</script>
