<template>
  <Container>
    <template v-slot:header>Discount Codes</template>
    <template v-slot:content>
      <div class="mb-8 bg-white shadow rounded-md">
        <div class="p-6 flex flex-col items-start">
          <span class="block text-sm font-medium text-gray-700 mb-4">
            Add a new discount code available to all users
          </span>
          <button
            type="button"
            @click="openCreationModal"
            class="button-submit"
          >
            New Discount Code
          </button>
        </div>
        <DiscountTable />
      </div>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import apiMixin from "@/mixins/apiMixin";
import eventBus from "@/utils/eventBus";
import DiscountTable from "@/components/admin/discount-codes-page/DiscountTable";

export default {
  name: "DiscountCodes",
  mixins: [apiMixin],
  components: {
    Container,
    DiscountTable,
  },
  methods: {
    openCreationModal() {
      eventBus.trigger("create-discount-code");
    },
  },
};
</script>
