<template>
  <nav
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg"
    aria-label="Pagination"
  >
    <slot name="info" v-bind:page="page" v-bind:pageCount="pageCount">
      <div class="hidden sm:block w-full mr-4">
        <p class="text-sm text-right text-gray-700">
          Showing page
          {{ " " }}
          <span class="font-medium">{{ page }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ pageCount }}</span>
          {{ " " }}
        </p>
      </div>
    </slot>
    <div class="flex justify-between sm:justify-end">
      <button
        :disabled="!hasPreviousPage"
        @click="previousPage"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
      >
        Previous
      </button>
      <button
        :disabled="!hasNextPage"
        @click="nextPage"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
      >
        Next
      </button>
    </div>
  </nav>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Paginator",
  props: {
    data: {
      type: Object,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    onPageChange: {
      type: Function,
    },
  },
  setup() {
    const page = ref(1);
    const isUpdating = ref(false);
    return {
      page,
      isUpdating,
    };
  },
  computed: {
    hasNextPage() {
      return (
        !!this.data?.next && !this.isUpdating && this.page < this.pageCount
      );
    },
    hasPreviousPage() {
      return !!this.data?.previous && !this.isUpdating && this.page > 1;
    },
    pageCount() {
      const pages = Math.ceil(
        (this.data?.count || this.pageSize) / this.pageSize
      );
      return isNaN(pages) ? 1 : pages;
    },
  },
  methods: {
    async setPage(page) {
      this.page = page;
      await this.onPageChange(this.page);
    },
    async nextPage() {
      if (!this.hasNextPage || this.isUpdating) {
        return;
      }
      this.isUpdating = true;
      this.page += 1;
      await this.onPageChange(this.page);
      await this.$nextTick(() => {
        this.isUpdating = false;
      });
    },
    async previousPage() {
      if (!this.hasPreviousPage || this.isUpdating) {
        return;
      }
      this.isUpdating = true;
      this.page -= 1;
      await this.onPageChange(this.page);
      await this.$nextTick(() => {
        this.isUpdating = false;
      });
    },
  },
};
</script>
