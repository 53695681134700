<template>
  <div v-if="data?.length" class="overflow-x-auto rounded-lg">
    <div class="align-middle inline-block w-full">
      <table class="divide-gray-300 w-full text-left table-auto">
        <thead class="border-b-2">
          <tr data-test="header-row">
            <th
              v-for="columnName in columnNames"
              :key="columnName"
              scope="col"
              class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left"
            >
              {{ columnName }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, index) in data" :key="index">
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <input
                :class="
                  (row.isUpdating
                    ? 'ring-1 ring-red-300 border-red-300 '
                    : '') + 'form-field-no-icon font-bold text-left w-full'
                "
                v-model="row.name"
                @change="editApiKeyName(row)"
              />
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left">
              {{ new Date(row.created_at).toLocaleString("en-US") }}
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left">
              {{ "Api-Key " + row.encrypted_key }}
              <button
                v-clipboard="'Api-Key ' + row.encrypted_key"
                class="button-icon"
              >
                <DocumentDuplicateIcon class="h-6 inline" />
              </button>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left trash-cell">
              <button @click="onDeleteApiKey(row.id)" class="button-icon">
                <TrashIcon class="h-6" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { TrashIcon, DocumentDuplicateIcon } from "@heroicons/vue/outline";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import formMixin from "@/mixins/formMixin";

export default {
  name: "ApiKeysTable",
  components: {
    DocumentDuplicateIcon,
    TrashIcon,
  },
  mixins: [formMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    eventBus.trigger("update-api-keys");
  },
  setup() {
    const columnNames = ref(["Name", "Created", "Key", ""]);

    return { columnNames };
  },
  methods: {
    async editApiKeyName(apiKey) {
      apiKey.isUpdating = true;
      try {
        await this.$alfredService.changeApiKey(apiKey.id, {
          scope: apiKey.scope,
          name: apiKey.name,
        });
      } finally {
        window.setTimeout(() => {
          apiKey.isUpdating = false;
          eventBus.trigger("update-api-keys");
        }, 100);
      }
    },
    async onDeleteApiKey(id) {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to permanently delete this API Key?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.handleSubmissionErrors(
            () => this.$alfredService.deleteApiKey(id),
            { throwExceptions: true }
          );
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "API Key successfully deleted!",
            },
          });
          eventBus.trigger("update-api-keys");
        },
      });
    },
  },
};
</script>
<style scoped>
.input-cell {
  min-width: 200px;
}
</style>
