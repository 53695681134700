<template>
  <div
    class="fixed left-0 top-0 mt-16 mx-auto w-screen px-4 z-50 pointer-events-none"
  >
    <notificationGroup group="top-right">
      <notification v-slot="{ notifications, close }" class="mr-2">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="mb-1"
          role="alert"
        >
          <div
            aria-live="assertive"
            class="flex items-end px-4 py-6 sm:p-6 sm:items-start w-screen"
          >
            <div
              class="w-full flex flex-col items-center space-y-4 sm:items-end"
            >
              <div
                class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mr-4"
              >
                <div class="p-4">
                  <div class="flex items-start">
                    <div class="flex-shrink-0">
                      <CheckCircleIcon
                        class="h-6 w-6 text-green-400"
                        v-if="notification.type === 'success'"
                        aria-hidden="true"
                      />
                      <ExclamationCircleIcon
                        class="h-6 w-6 text-yellow-400"
                        v-if="notification.type === 'warning'"
                        aria-hidden="true"
                      />
                      <InformationCircleIcon
                        class="h-6 w-6 text-blue-400"
                        v-if="notification.type === 'info'"
                        aria-hidden="true"
                      />
                      <ExclamationIcon
                        class="h-6 w-6 text-red-400"
                        v-if="notification.type === 'error'"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                      <p
                        class="text-sm font-medium text-gray-900"
                        v-if="notification.title"
                      >
                        {{ notification.title }}
                      </p>
                      <p
                        class="text-sm font-medium text-gray-900"
                        v-else-if="notification.type === 'success'"
                      >
                        Success!
                      </p>
                      <p
                        class="text-sm font-medium text-gray-900"
                        v-else-if="notification.type === 'warning'"
                      >
                        Warning!
                      </p>
                      <p
                        class="text-sm font-medium text-gray-900"
                        v-else-if="notification.type === 'info'"
                      >
                        Information!
                      </p>
                      <p
                        class="text-sm font-medium text-gray-900"
                        v-else-if="notification.type === 'error'"
                      >
                        Error!
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                        {{ notification.text }}
                      </p>
                    </div>
                    <div class="ml-4 flex-shrink-0 flex">
                      <button
                        @click="close(notification.id)"
                        class="button-icon text-gray-400 hover:text-gray-500"
                      >
                        <span class="sr-only">Close</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </notification>
    </notificationGroup>
  </div>
</template>
<script>
import {
  CheckCircleIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/vue/outline";

export default {
  name: "Notifications",
  components: {
    ExclamationIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XIcon,
  },
};
</script>
