<template>
  <div class="bg-white shadow rounded-b-md pt-8 mb-6 hidden-print">
    <div class="flex flex-col">
      <ExpertDownloadModal />
      <ExportKlaviyoList />
      <KlaviyoDownload ref="KlaviyoDownload" />
      <ExportYotpo />
      <ExportIterable />
      <JobsModal ref="JobsModal" />
      <div class="overflow-x-auto rounded-lg">
        <div v-if="!!jobs" class="align-middle inline-block w-full">
          <table class="relative divide-gray-300 w-full text-left table-auto">
            <thead class="border-b-2">
              <tr>
                <th
                  v-for="(column, index) in columns"
                  :key="column.name"
                  scope="col"
                  :class="`
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    tracking-wider
                    uppercase
                    ${index === columns.length - 1 ? ' w-56' : ''}
                  `"
                >
                  <FilterableHeaderCell
                    :ref="setItemRef"
                    v-if="column.param"
                    :title="column.name"
                    :param="column.param"
                    @switchOrder="onSwitchOrder"
                  />
                  <template v-else>
                    {{ column.name }}
                  </template>
                </th>
              </tr>
            </thead>
            <tbody v-for="job in jobs" :key="job.id">
              <tr
                :class="`border-b-2 ${
                  job.statusData.status === 'DELETED'
                    ? 'bg-gray-50'
                    : 'bg-white'
                }`"
              >
                <td
                  class="px-6 py-3 whitespace-nowrap text-sm font-medium max-w-xs lg:max-w-md xl:max-w-lg"
                >
                  <div
                    @click="job.toFileDetails()"
                    :class="`truncate ${job.toFileDetails ? 'file-name' : ''} font-semibold`"
                    :title="job.name"                   
                  >
                 {{ job.name }}               
                  </div>                     
                </td>
                <td
                  v-for="value in Object.values(job.basicData)"
                  :key="value"
                  class="px-6 py-3 text-sm text-gray-500"
                >
                  {{ value }}
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                  <span class="inline-flex flex-wrap">
                    <span
                      :class="`
                        px-2
                        text-${job.statusData.color}-800
                        bg-${job.statusData.color}-100
                        rounded-full
                        border-gray-200
                      `"
                    >
                      {{ job.statusData.displayName }}
                    </span>
                    <span
                      v-if="job.statusData.tooltipMessage"
                      class="my-auto ml-1 text-left has-tooltip"
                    >
                      <QuestionMarkCircleIcon class="h-4" />
                      <Tooltip>
                        {{ job.statusData.tooltipMessage }}
                      </Tooltip>
                    </span>
                  </span>
                </td>
                <td class="px-1 py-3 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="flex flex-row flex-shrink items-center justify-between"
                  >
                    <div
                      class="flex flex-row flex-wrap flex-shrink items-center px-3"
                    >
                      <div class="flex">
                        <div class="p-2">
                          <button
                            type="button"
                            class="rounded-md text-gray-700 bg-white border border-gray-300 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium opacity-25 m-0 w-40 justify-center cursor-not-allowed"
                            v-if="
                              job.statusData.displayName === 'Processing' ||
                              job.statusData.displayName === 'Evaluating' ||
                              job.statusData.displayName === 'Error' ||
                              job.statusData.displayName === 'Uploading'
                            "
                            disabled
                          >
                            View Details
                          </button>
                          <button
                            type="button"
                            @click="evaluation(job)"
                            class="rounded-md bg-red-100 text-red-800 bg-white border border-gray-300 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium m-0 w-40 justify-center"
                            v-else-if="
                              job.statusData.displayName === 'Uploaded'
                            "
                          >
                            Evaluate List &nbsp;<span class="font-bold">FREE</span>
                          </button>
                          <button
                            type="button"
                            @click="job.toFileDetails()"
                            :disabled="isCleanJobLoading[job.id] || false"
                            class="rounded-md text-gray-700 bg-white border border-gray-300 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium m-0 w-40 justify-center"
                            v-else
                          >
                            View Details
                          </button>
                        </div>

                        <div class="p-2">
                          <template v-if="job.isToBeCleaned || job.isThreatAssessed || job.isDeliv || job.isUploaded">
                            <button
                              type="button"
                              class="button-submit m-0 w-auto cursor-not-allowed"
                              v-if="
                                isCleanJobLoading[job.id] ||
                                job.statusData.displayName === 'Error' ||
                                job.statusData.displayName === 'Evaluating' ||
                                job.statusData.displayName === 'Uploading' ||
                                job.statusData.displayName === 'Processing'
                              "
                              disabled
                            >
                              Hygiene List
                            </button>

                            <!-- <button
                              type="button"
                              @click="onCleanTheList(job)"
                              :disabled="isCleanJobLoading[job.id] || false"
                              class="button-submit m-0 w-auto"
                              v-else
                            >
                              Hygiene List
                            </button> -->
                            <button
                              type="button"
                              @click="toTheList(job)"
                              :disabled="isCleanJobLoading[job.id] || false"
                              class="button-submit m-0 w-auto"
                              v-else
                            >
                            Hygiene List
                            </button>
                            <!-- <button
                              type="button"
                              @click="threat_assessment(job)"
                              v-if="!job.isThreatAssessed ||
                                !job.statusData.displayName === 'Evaluating' ||
                                !job.statusData.displayName === 'Error'
                              "
                              class="button-submit ml-3 m-0 w-auto"                             
                            >
                              Threat Assessment
                            </button> -->
                          </template>
                          <template v-else>
                            <button
                              v-show="job.hasResultFile"
                              @click="showDownloadFileModal(job)"
                              style="width: 120px"
                              type="button"
                              class="button-submit m-0"
                            >
                              Export List
                            </button>
                          </template>
                        </div>
                      </div>
                    </div>

                    <button
                      @click="handleDelete(job.job_id)"
                      v-show="job.isToBeRemoved && job.statusData.displayName != 'Uploading'"
                      class="button-icon m-3"
                    >
                      <TrashIcon class="h-6" />
                    </button>
                    <button
                      @click="handleDeleteUpload(job.id)"
                      v-show="job.isUploaded && job.statusData.displayName != 'Uploading'"
                      class="button-icon m-3"
                    >
                      <TrashIcon class="h-6" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav
          class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg"
          aria-label="Pagination"
        >
          <div class="flex-1 py-4">
            <div class="text-sm text-left text-gray-500 mr-4 italic">
              <span
                >Your lists will be automatically deleted in
                {{ settings?.AUTO_DELETE_DAYS || 14 }} days by default.
              </span>
              <router-link
                to="/contact"
                class="whitespace-nowrap underline hover:text-gray-400"
                >Contact us</router-link
              >
              to request an extension or reduction.
            </div>
          </div>
          <div class="flex items-center pr-3">
                <label for="dropdown">Items:</label>
                <select class="form-field-no-icon ml-2 text-center sm:w-full" id="dropdown" v-model="selectedPage" @change="updatePage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
          <div class="hidden sm:block mr-4">
            <p class="text-sm text-right text-gray-700">
              {{ " " }}
              <span class="font-medium">{{ currentPage }}</span>
              {{ " " }}
              of
              {{ " " }}
              <span class="font-medium">{{ totalPages }}</span>
              {{ " " }}
            </p>
          </div>
          <div class="flex justify-between sm:justify-end">
            <button
             @click="prevPage"
              :disabled="currentPage === 1"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <button
              @click="nextPage"
              :disabled="currentPage === totalPages"
              class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </nav>       
      </div>
    </div>
  </div>
</template>

<script>
import useConfirmationModal from "@/api/confirmationModal";
import formMixin from "@/mixins/formMixin";
import sortingTableMixin from "@/mixins/sortingTableMixin";
import Tooltip from "@/components/basic/common/Tooltip";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import { ref } from "vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import { TrashIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";
import eventBus from "@/utils/eventBus";

import ExpertDownloadModal from "@/components/basic/common/modals/ExpertDownloadModal";
import ExportKlaviyoList from "@/components/basic/common/modals/ExportKlaviyoList";
import ExportYotpo from "@/components/basic/common/modals/ExportYotpo";
import ExportIterable from "@/components/basic/common/modals/ExportIterable";
import JobsModal from "@/components/basic/common/modals/JobsModal";
import KlaviyoDownload from "@/components/basic/common/modals/KlaviyoDownload";
import humanize from "humanize";

export default {
  name: "JobList",
  components: {
    Tooltip,
    QuestionMarkCircleIcon,
    TrashIcon,
    FilterableHeaderCell,
    ExpertDownloadModal,
    ExportKlaviyoList,
    ExportYotpo,
    ExportIterable,
    KlaviyoDownload,
    JobsModal,
  },
  mixins: [formMixin, sortingTableMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
    totalPages: {
      type: Number,
    },
    currentPage: {
      type: Number,
    }
  },
  setup() {
    const columns = ref([
      { name: "Name", param: "name" },
      { name: "Created", param: "created" },
      { name: "Qty.", param: "emails_count" },
      { name: "Status", param: "status" },
      { name: "Actions" },
    ]);
    const urlParams = ref({});
    const isCleanJobLoading = ref({});
    const price = ref("")
    const selectedPage = ref("10")
    return {
      columns,
      urlParams,
      itemRefs: [],
      isCleanJobLoading,
      KlaviyoDownload: ref(),
      JobsModal: ref(),
      price,
      selectedPage
    };
  },
  computed: {
    ...mapGetters({ settings: "settings/settings" }),
    jobs() {
      return this.processJobs(this.$props.data);
    },
  },
  beforeMount() {
    this.$store.dispatch("settings/FETCH_SETTINGS");
    eventBus.on("reset-page", this.resetPaginator);
  },
  beforeUnmount() {
    eventBus.off("reset-page", this.resetPaginator);
  },
  mounted() {
    this.handleQueryParams();
    this.itemRefs[1].setOrdering("-created");
  },
  methods: {
    async evaluation(job) {    
      this.price = 0;
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to evaluate this list?",
        message: `It is going to cost <strong>${this.price} Credits</strong>.`,
        onSubmit: () => this.evaluation_func(job),
      });
    },
    async evaluation_func(job){
    const response = await this.handleSubmissionErrors(
        async () =>
          this.$alfredServiceV2.createJob({
            input_file:{
              id: job.id
            },
            actions: ["evaluation"],
          }),
        { throwExceptions: true }
      );
      eventBus.trigger("update");
      return response;
  },
    nextPage() {
      this.$emit('next-page');
    },
    prevPage(){
      this.$emit('prev-page');
    }, 
    async onCleanTheList(job) {
      this.isCleanJobLoading[job.id] = true;
      eventBus.trigger("clean-the-list", {
        job,
        callback: () => (this.isCleanJobLoading[job.upload_id] = false),
      });
    },
    async onPageChange(page) {
      eventBus.trigger("update", { page });
    },
    applyOrdering(ordering) {
      eventBus.trigger("update", { ordering });
    },
    async handleDelete(jobId) {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to permanently delete this file?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          if (jobId == this.$route.params?.id) {
            this.$router.push({
              name: "Dashboard",
            });
          }
          await this.handleSubmissionErrors(
            () => this.$alfredService.deleteJob(jobId),
            {}
          );
          if (this.jobs.length === 1) {
            this.$refs.paginator.previousPage();
          } else {
            eventBus.trigger("update");
          }
        },
      });
    },
    async handleDeleteUpload(uploadId) {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to permanently delete this file?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          if (uploadId == this.$route.params?.id) {
            this.$router.push({
              name: "Dashboard",
            });
          }
          await this.handleSubmissionErrors(
            () => this.$alfredService.deleteUpload(uploadId),
            {}
          );
          if (this.jobs.length === 1) {
            this.$refs.paginator.previousPage();
          } else {
            eventBus.trigger("update");
          }
        },
      });
    },
    showDownloadFileModal(job) {
      this.KlaviyoDownload.openModal({ job });
    },
    toTheList(job) {
      this.JobsModal.openModal({ job });
    },
    totalEmails(count) {
      return humanize.numberFormat(count, 0);
    },
    processJobs(jobs) {
      return jobs.map((job) => {
        const actions = job.actions;
        const isEvaluation = actions && actions.includes("evaluation");
        const isThreat = actions && actions.includes("threat_assessment");
        const isDeliv = actions && actions.includes("deliverability");
        const isUploaded = job.job_id === null ? true : false;
        return {
          id: job.upload_id,
          job_id: job.job_id,
          upload_id:job.upload_id,
          basicData: {
            createdAt: this.formattedDate(job.created),
            emailsCount: this.totalEmails(job.emails_count),
          },
          statusData: this.determineStatusData(job, isEvaluation, isThreat, isDeliv, isUploaded),
          hasResultFile: !!job.result_file,
          isToBeCleaned: isEvaluation,
          isThreatAssessed: isThreat,
          isUploaded: isUploaded,
          isToBeRemoved: ["COMPLETED", "ERROR", "DELETED"].includes(job.status),
          toFileDetails: this.determineDetailsFn(job, isEvaluation),
          result: job.result,
          isDeliv: isDeliv,
          name: job.name
        };
      });
    },
    formattedDate(timezone) {
      return new Date(timezone).toLocaleString("en-US");
    },
    determineStatusData({ status }, isEvaluation, isThreat, isDeliv, isUploaded) {
      let data;
      if(isUploaded){
        switch (status) {
        case "UPLOADING":
        data =
             {
                displayName: "Uploading",
                color: "blue",
              };
              break;
        default:
        data =
             {
                displayName: "Uploaded",
                color: "blue",
              };
              break;
        }

      }
      else if (isThreat) {
        switch (status) {
        case "COMPLETED":
          data = isEvaluation
            ? {
                displayName: "Evaluated",
                color: "red",
                tooltipMessage: "The list was checked but not yet cleaned.",
              }
            : {
                displayName: "Threat Only",
                color: "green",
              };
          break;
        case "ERROR":
          data = {
            displayName: "Error",
            color: "red",
          };
          break;
        default:
          data = isEvaluation
            ? {
                displayName: "Evaluating",
                color: "blue",
              }
            : {
                displayName: "Processing",
                color: "blue",
                tooltipMessage:
                  "We’ll send you an email when your list has been processed. Depending on the size of your list this could take a few minutes to a few hours.",
              };
          break;
      }
      }else if(isDeliv){
        switch (status) {
        case "COMPLETED":
          data = isEvaluation
            ? {
                displayName: "Evaluated",
                color: "red",
                tooltipMessage: "The list was checked but not yet cleaned.",
              }
            : {
                displayName: "Deliverability Only",
                color: "green",
              };
          break;
        case "ERROR":
          data = {
            displayName: "Error",
            color: "red",
          };
          break;
        default:
          data = isEvaluation
            ? {
                displayName: "Evaluating",
                color: "blue",
              }
            : {
                displayName: "Processing",
                color: "blue",
                tooltipMessage:
                  "We’ll send you an email when your list has been processed. Depending on the size of your list this could take a few minutes to a few hours.",
              };
          break;
      }
      }else{
        switch (status) {
        case "COMPLETED":
          data = isEvaluation
            ? {
                displayName: "Evaluated",
                color: "red",
                tooltipMessage: "The list was checked but not yet cleaned.",
              }
            : {
                displayName: "Full Hygiene",
                color: "green",
              };
          break;
        case "ERROR":
          data = {
            displayName: "Error",
            color: "red",
          };
          break;
        default:
          data = isEvaluation
            ? {
                displayName: "Evaluating",
                color: "blue",
              }
            : {
                displayName: "Processing",
                color: "blue",
                tooltipMessage:
                  "We’ll send you an email when your list has been processed. Depending on the size of your list this could take a few minutes to a few hours.",
              };
          break;
      }
      }
     
      return { status, ...data };
    },
    determineDetailsFn(job, isEvaluation) {
      if (job.status !== "COMPLETED") {
        return null;
      }
      if (isEvaluation) {
        return () => {
          eventBus.trigger("open-details", job);
        };
      }
      return async () => {
        await this.$router.push({
          name: "FileDetails",
          params: { id: job.job_id },
        });
      };
    },
    determineDisplayStats(job, isEvaluation) {
      const totalCount = job.emails_count;

      if (isEvaluation || !totalCount) {
        return {
          goodCount: "-",
          badCount: "-",
          riskyCount: "-",
        };
      }

      const scores = job.result?.verification_job?.scores || [];
      const qualityStats = this.countStats(scores);

      Object.keys(qualityStats).map((key) => {
        const percentage = Number(
          (qualityStats[key] * 100) / totalCount
        ).toFixed(0);
        qualityStats[key] = `${qualityStats[key]} (${percentage}%)`;
      });

      return qualityStats;
    },
    countStats(scores) {
      return scores.reduce(
        (accumulator, currentValue) => {
          if (currentValue.score >= 1 && currentValue.score <= 3) {
            accumulator.badCount += currentValue.email_count;
          } else if (currentValue.score > 3 && currentValue.score < 8) {
            accumulator.riskyCount += currentValue.email_count;
          } else {
            accumulator.goodCount += currentValue.email_count;
          }
          return accumulator;
        },
        {
          goodCount: 0,
          badCount: 0,
          riskyCount: 0,
        }
      );
    },
    async handleQueryParams() {
      if (this.$route.query.download) {
        const job = await this.getJobById(this.$route.query.download);
        if (job) {
          this.showDownloadFileModal(job);
        }
      }
    },
    async getJobById(jobId) {
      for (let currentJob of this.jobs) {
        if (currentJob.id === Number(jobId)) {
          return currentJob;
        }
      }
      const response = await this.handleSubmissionErrors(
        async () => this.$alfredService.getJob(jobId),
        {}
      );
      return response;
    },
    resetPaginator() {
      this.$refs.paginator.setPage(1);
    },
    async threat_assessment(job) {      
      this.price = Math.ceil(parseInt(job.basicData.emailsCount) * 0.4);
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to threat assess this list?",
        message: `It is going to cost <strong>${this.price} Credits</strong>.`,
        onSubmit: () => this.threat_assessment_func(job),
      });
    },
  async threat_assessment_func(job){
    const response = await this.handleSubmissionErrors(
        async () =>
          this.$alfredServiceV2.createJob({
            input_file:{
              id: job.id.replace(/-/g, '')
            },
            actions: ["threat_assessment"],
          }),
        { throwExceptions: true }
      );
      eventBus.trigger("update");
      return response;
  }, 
  updatePage() {
      this.$emit('update', this.selectedPage);
    }

  },
};
</script>
<style scoped>
.file-name {
  @apply text-main;
  @apply hover:text-main-hover;
  @apply cursor-pointer;
}
</style>
