<template>
  <DialogContainer :isOpen="isOpen" :onClose="onClose" :maxWidth="'lg'">
    <template v-slot:title>Legal</template>
    <template v-slot:content>
      <div class="modal-content" v-html="modalMessage"></div>
    </template>
  </DialogContainer>
</template>

<script>
import DialogContainer from "@/components/common/DialogContainer";
import modalMixin from "@/mixins/modalMixin";
import { content } from "@/constants/legal.js";
import { ref } from "vue";
export default {
  name: "LegalModal",
  mixins: [modalMixin],
  components: {
    DialogContainer,
  },
  setup() {
    const modalMessage = content;
    const modalEventName = "show-legal";
    const isOpen = ref(false);
    return {
      isOpen,
      modalMessage,
      modalEventName,
    };
  },
  beforeUnmount() {
    this.isOpen = false;
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
    },
  },
};
</script>
<style scoped>
.modal-content {
  height: 70vh;
  max-width: 95vw;
  @apply overflow-y-auto;
}
.modal-content >>> p,
.modal-content >>> h3,
.modal-content >>> ul {
  @apply p-2;
}
.modal-content >>> h3 {
  font-size: 1.125rem;
}
.modal-content >>> ul > li,
.modal-content >>> ol > li,
.modal-content >>> p {
  font-size: 0.875rem;
}
.modal-content >>> ul {
  list-style: inside;
  @apply my-2;
}
.modal-content >>> ol {
  list-style-type: decimal;
}
.modal-content >>> a {
  @apply text-gray-500;
  @apply hover:text-gray-900;
}
</style>
