<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :hasCloseBtn="false">
    <template v-slot:title>Add Credit Card</template>
    <template v-slot:content>
      <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="cc-name" class="block text-sm font-medium text-gray-700">
            Name on Card
          </label>
          <div class="mt-1">
            <input
              ref="ccName"
              id="cc-name"
              name="cc-name"
              autocomplete="cc-name"
              v-model="data.billing_details.name"
              type="text"
              class="form-field form-field-no-icon sm:w-full"
              @click="blurInput('ccName')"
              @input="formActions.clearFieldError('billing_details.name')"
            />
            <p class="text-red-500 text-sm mt-1">
              {{ errors["billing_details.name"] }}
            </p>
          </div>
        </div>
        <div class="sm:col-span-6">
          <label
            for="cc-number"
            class="block text-sm font-medium text-gray-700"
          >
            Card Number
          </label>
          <div ref="cardNumber" :class="wrapperStyles?.cardNumber">
            <div class="input-placeholder"></div>
          </div>
          <p class="text-red-500 text-sm mt-1">
            {{ errors.cardNumber }}
          </p>
        </div>

        <div class="sm:col-span-3">
          <label for="cc-exp"> Card Expiration </label>
          <div ref="cardExpiry" :class="wrapperStyles?.cardExpiry">
            <div class="input-placeholder"></div>
          </div>
          <p class="text-red-500 text-sm mt-1">
            {{ errors.cardExpiry }}
          </p>
        </div>

        <div class="sm:col-span-3">
          <label for="cc-exp"> Security Code </label>
          <div ref="cardCvc" :class="wrapperStyles?.cardCvc">
            <div class="input-placeholder"></div>
          </div>
          <p class="text-red-500 text-sm mt-1">
            {{ errors.cardCvc }}
          </p>
        </div>

        <div class="sm:col-span-6">
          <label for="street-address"> Street Address </label>
          <div class="mt-1">
            <input
              ref="streetAddress"
              id="street-address"
              name="street-address"
              autocomplete="street-address"
              v-model="data.billing_details.address.line1"
              type="text"
              class="form-field-no-icon sm:w-full"
              @click="blurInput('streetAddress')"
              @input="
                formActions.clearFieldError('billing_details_address.line1')
              "
            />
            <p class="text-red-500 text-sm mt-1">
              {{ errors["billing_details_address.line1"] }}
            </p>
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="postal-code"> Postal Code </label>
          <div class="mt-1">
            <input
              ref="postalCode"
              id="postal-code"
              name="postal-code"
              autocomplete="postal-code"
              v-model="data.billing_details.address.postal_code"
              type="text"
              class="form-field-no-icon sm:w-full"
              @click="blurInput('postalCode')"
              @input="
                formActions.clearFieldError(
                  'billing_details_address.postal_code'
                )
              "
            />
            <p class="text-red-500 text-sm mt-1">
              {{ errors["billing_details_address.postal_code"] }}
            </p>
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="country"> Country </label>
          <div class="mt-1">
            <select
              ref="country"
              id="country"
              name="country"
              autocomplete="country"
              v-model="data.billing_details.address.country"
              @click="blurInput('country')"
              class="form-field-no-icon sm:w-full"
            >
              <option
                v-for="country in countryCodes"
                :key="country.code"
                :value="country.code"
              >
                {{ country.country }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="button-submit"
        @click="onSubmit"
        :disabled="pendingUpdate"
      >
        Submit
      </button>
      <button
        type="button"
        class="button-cancel mr-2"
        @click="closeModal"
        ref="cancelButtonRef"
      >
        Cancel
      </button>
    </template>
  </DialogContainer>
</template>
<script>
import { reactive, ref } from "vue";
import DialogContainer from "@/components/common/DialogContainer";
import formMixin from "@/mixins/formMixin";
import stripeElementsMixin from "@/mixins/stripeElementsMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import { required } from "@/utils/validators";
import countryCodes from "@/constants/countryCodes.json";

export default {
  name: "EditCreditCardModal",
  mixins: [formMixin, stripeElementsMixin],
  components: {
    DialogContainer,
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.data = {
          billing_details: {
            address: {
              country: "US",
            },
          },
        };
        this.formActions.clearFormErrors();
        this.initialize();
      }
    },
  },
  setup() {
    const wrapperStyles = reactive({});
    const stripeElements = reactive({});

    const { errors, formActions } = useFormErrors();
    const isOpen = ref(false);
    const pendingUpdate = ref(false);
    const data = reactive({
      billing_details: {
        address: {
          country: "US",
        },
      },
    });

    return {
      isOpen,
      data,
      countryCodes,
      errors,
      formActions,
      wrapperStyles,
      stripeElements,
      pendingUpdate,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
      this.formActions.clearFormErrors();
    },
    closeModal() {
      this.isOpen = false;
    },
    validate() {
      const validators = {
        "billing_details.name": [required],
        "billing_details_address.line1": [required],
        "billing_details_address.postal_code": [required],
      };
      this.formActions.validateForm(this.data, validators);
      for (let key of Object.keys(this.stripeElements)) {
        const stripeElement = this.stripeElements[key];
        this.validateStripeField(key, stripeElement);
      }
      return !Object.keys(this.errors).length;
    },
    validateStripeField(name, value) {
      if (value._empty) {
        this.formActions.setFieldError(name, "This field is required.");
        return false;
      }
      if (value._invalid) {
        this.formActions.setFieldError(name, "This value is invalid.");
        return false;
      }
      return true;
    },
    async createCreditCard() {
      const stripe = await this.$stripeService;
      const cardElement = this.stripeElements?.cardNumber;
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (paymentMethod?.error) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "error",
            text: paymentMethod.error.message,
          },
        });
      }
      const response = await this.handleSubmissionErrors(
        async () =>
          await this.$alfredService.createPaymentMethod({
            payment_method_id: paymentMethod.id,
            ...this.data,
          }),
        { formActions: this.formActions }
      );
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Your credit card has been successfully updated!",
          },
        });
        this.isOpen = false;
        eventBus.trigger("update-cards", {});
      }
    },
    async onSubmit() {
      if (this.pendingUpdate) {
        return;
      }
      this.pendingUpdate = true;
      try {
        if (!this.validate()) {
          return;
        }
        await this.createCreditCard();
      } finally {
        window.setTimeout(() => {
          this.pendingUpdate = false;
        }, 1000);
      }
    },
  },
};
</script>
