import humanize from "humanize";

const formatNumberMixin = {
  methods: {
    formatNumber(num, decimals, maxLength) {
      let { realPart, decimalPart } = this.getDecimalAndRealParts(
        num,
        decimals,
        maxLength
      );
      const prefix = this.isMoney ? "$" : "";
      return `${prefix}${humanize.numberFormat(realPart, 0)}${decimalPart}`;
    },
    getDecimalAndRealParts(num, decimals, maxLength) {
      num = String(num);
      let realPart = num;
      let decimalPart = "";
      const parts = num.split(".");
      if (parts.length > 1) {
        realPart = parts[0];
        decimalPart = `.${parts[1].substr(0, decimals)}`;
      }
      return {
        realPart: realPart.replaceAll(",", "").substr(0, maxLength),
        decimalPart: decimalPart,
      };
    },
  },
};

export default formatNumberMixin;
