<template>
  <Container>
    <template v-slot:header>Glossary</template>
    <template v-slot:content>
      <section class="bg-white shadow rounded-lg pt-8 mb-6">
        <h2
          class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0 sm:mx-6"
        >
          Verification Glossary
        </h2>
        <GlossaryTable
          class="mt-6"
          :data="verificationGlossary.recommendation.data"
          :column-names="['Recommendation']"
        />
        <GlossaryTable
          class="mt-6"
          :data="verificationGlossary.rating.data"
          :column-names="['Rating']"
        />
        <GlossaryTable
          class="mt-6"
          :data="verificationGlossary.score.data"
          :column-names="['Score']"
        >
          <tr>
            <td class="px-6 py-4 text-sm font-medium text-gray-500" colspan="2">
              {{ verificationGlossary.score.info }}
            </td>
          </tr>
        </GlossaryTable>
        <GlossaryTable
          class="mt-6"
          :data="verificationGlossary.status.data"
          :column-names="['Status']"
        />
        <GlossaryTable
          class="mt-6"
          :data="verificationGlossary.reason.data"
          :column-names="['Reason']"
        />
        <GlossaryTable
          class="mt-6"
          :data="verificationGlossary.threatLevel.data"
          :column-names="['Threat Level']"
        />
      </section>
      <section class="bg-white shadow rounded-lg pt-8 mb-6">
        <h2
          class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0 sm:mx-6"
        >
          Verification Example Results
        </h2>
        <GlossaryTable
          :data="verificationResults.data"
          :column-names="verificationResults.columns"
        />
      </section>
      <section class="bg-white shadow rounded-lg pt-8 mb-6">
        <h2
          class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0 sm:mx-6"
        >
          Evaluation Glossary
        </h2>
        <GlossaryTable
          :data="evaluationGlossary.deliverability.data"
          :column-names="['Deliverability']"
        />
        <GlossaryTable
          class="mt-6"
          :data="evaluationGlossary.risk.data"
          :column-names="['Threat']"
        />
      </section>
      <div class="flex align-left">
        <section class="bg-white shadow rounded-lg pt-8 mb-6 inline-block">
          <h2
            class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0 sm:mx-6"
          >
            Evaluation Example Results
          </h2>
          <img
            class="block py-2 px-4 sm:px-6 lg:px-8 my-5"
            :src="require('@/assets/images/evaluation-example-results.png')"
            alt="Example Results"
          />
        </section>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import GlossaryTable from "@/components/basic/glossary-page/GlossaryTable";
import evaluationGlossary from "@/constants/glossary/evaluationGlossary.json";
import verificationGlossary from "@/constants/glossary/verificationGlossary.json";
import verificationResults from "@/constants/glossary/verificationResults.json";

export default {
  name: "Glossary",
  components: {
    Container,
    GlossaryTable,
  },
  setup() {
    return { evaluationGlossary, verificationGlossary, verificationResults };
  },
};
</script>
<style scoped>
.section-header {
  @apply text-xl;
  @apply sm:text-2xl;
}
</style>
