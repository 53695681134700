<template>
  <section class="flex flex-col mt-6 mb-3">
    <span class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center">
      <span class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center">

        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto pl-4">Start Date</span>
          <input type="date" class="ml-2 flex-grow focus:outline-none placeholder-gray-400 sm:text-sm date_inp"
            v-model="payload.start_date" @change="changeFilter()" />
        </span>

        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto pl-4">End Date</span>
          <input type="date" class="ml-2 flex-grow focus:outline-none placeholder-gray-400 sm:text-sm date_inp"
            v-model="payload.end_date" @change="changeFilter()" />
        </span>

      </span>
    </span>
    <span class="flex pl-4 mt-6 mb-6 text-gray-600 items-center">
     <p class="pr-5">TOTAL: {{ formatN(count) }}</p>
     <p class="pl-5 pr-5">TOTAL AMOUNT: {{ formatN(total_sum.total_amount_sum) }} </p>
      <p class="pl-5">TOTAL CREDITS: {{ formatN(total_sum.total_credits_sum) }}</p></span>
  </section>
  <div class="align-middle p-5 bg-white inline-block w-full">
    <table class="divide-gray-300 w-full text-left table-auto">
      <thead class="border-b-2">
        <tr>
          <th v-for="columnName in columnNames" :key="columnName.name" scope="col" :class="`
                px-6
                py-5
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              `">
            <FilterableHeaderCell :title="columnName.name" :param="columnName.param"
              @switchOrder="onSwitchOrders(columnName.param)" />
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="data?.length">
          <tr v-for="(row, index) in data" :key="index">

            <td class="px-6 py-5 text-sm text-gray-500">
              {{ row.created_by__email }}
            </td>
            <td class="px-6 py-5 text-sm text-gray-500">
              {{ formatN(row.total_credits) }}
            </td>
            <td class="px-6 py-5 text-sm text-gray-500">
              {{ formatN(row.total_amount) }}
            </td>
            <td class="px-6 py-5 text-sm text-gray-500">
              {{ formatN(row.balance_credits) }}
            </td>
          </tr>
        </template>
        <template v-else>
          <div class="px-6 py-5 text-sm text-gray-500 text-left">
            No data
          </div>
        </template>
      </tbody>
    </table>
  </div>
  <Paginator ref="paginator" :onPageChange="onPageChange" :pageSize="15" :data="paginationData" />
</template>

<script>
import { ref, reactive } from "vue";
import apiMixin from "@/mixins/apiMixin";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import Paginator from "@/components/common/Paginator";
import humanize from "humanize";
import { mapGetters } from "vuex";

export default {
  name: "AdminCreditPurchases",
  mixins: [apiMixin],
  components: {
    Paginator,
    FilterableHeaderCell,
  },
  data() {
    return {
      data: ref([]),
      columnNames: ref([
        { name: "Created by", param: "created_by__email" },
        { name: "Credits", param: "total_credits" },
        { name: "Amount", param: "total_amount" },
        { name: "Credit Balance", param: "balance_credits" },
      ]),
      paginationData: ref(""),
      payload: ref({
        start_date: "",
        end_date: "",
        page: 1,
        ordering: reactive(""),
      }),
      total_sum: ref(""),
      count: ref("")
    };
  },
  computed: {
    ...mapGetters({adminStatsDate: "settings/adminStatsDate"})
  },
  mounted() {
    this.payload.end_date = this.adminStatsDate.end_date;
    this.payload.start_date = this.adminStatsDate.start_date;
    this.fetchCreditPurchase()
  },
  methods: {
    async fetchCreditPurchase() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.StatsCreditPurchase(this.payload),
      });
      if (response) {
        this.data = response.results;
        delete response.results;
        this.total_sum = response.total_sum;
        delete response.total_sum;
        this.count = response.count;
        this.paginationData = response;
      }
    },
    changeFilter() {
      let admin_stats_date = {
      start_date: this.payload.start_date,
      end_date: this.payload.end_date,
    }
    this.$emit('getDate', admin_stats_date);
    this.$store.dispatch('settings/SET_ADMIN_STATS_DATE', admin_stats_date);
      this.fetchCreditPurchase();
    },
    async onPageChange(page) {
      this.payload.page = page;
      await this.fetchCreditPurchase();
    },
    async onSwitchOrders(name) {
      this.payload.ordering = this.payload.ordering == name ? `-${name}` : name;
      await this.fetchCreditPurchase()
      await this.$refs.paginator.setPage(1);
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style scoped>
select {
  background-image: none;
  border-color: #ffffff00;
}

.date_inp {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-radius: 0.375rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>