import { BaseJWTService } from "@/services/base";
import store from "@/store";

export class AlfredServiceV2 extends BaseJWTService {
  constructor({ apiUrl, alfredService }) {
    super({ apiUrl });
    this.alfredService = alfredService;
  }

  useApiV2() {
    return store.getters["auth/useApiV2"];
  }

  async uploadFile(payload) {
    if (this.useApiV2()) {
      const response = await this.api.post("/upload", payload);
      return response.data;
    }

    return this.alfredService.uploadFile(payload);
  }

  async createJob(payload) {
    if (this.useApiV2()) {
      const response = await this.api.post("/jobs", payload);
      return response.data;
    }

    return this.alfredService.createJob(payload);
  }
}

export default AlfredServiceV2;
