<template>
  <section class="flex flex-col mb-8">
    <span
      class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center"
    >
      <span
        class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center"
      >
        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto">EMAIL JOB</span>
          <select
            class="form-field-no-icon sm:ml-2 w-full sm:w-48 mb-2 sm:mb-0 uppercase"
            v-model="currentEmailJob"
            @change="onSelectEmailJob($event.target.value)"
            name="emailJobdrp"
          >
            <option v-for="jobItem in emailJob" :key="jobItem" :value="jobItem">
              {{ jobItem }}
            </option>
          </select>
        </span>
        <span
          class="flex text-gray-600 items-center"
          v-if="filters.emailJob != 'Real-time'"
        >
          <span class="w-full sm:w-auto sm:ml-16">SERVICE</span>
          <select
            class="form-field-no-icon sm:ml-2 w-full sm:w-48 mb-2 sm:mb-0 uppercase"
            v-model="filters.service"
          >
            <option :value="''">ANY</option>
            <option v-for="service in services" :key="service" :value="service">
              {{ serviceMappings[service] }}
            </option>
          </select>
        </span>
        <span class="w-full sm:w-auto sm:ml-16">TYPE</span>

        <select
          class="form-field-no-icon sm:ml-2 w-full sm:w-48 mb-2 sm:mb-0 uppercase"
          v-model="filters.type"
        >
          <option :value="''">ANY</option>
          <option v-for="type in types" :key="type" :value="type">
            {{ type }}
          </option>
        </select>
      </span>
      <SearchInput v-if="!singleUser" v-model="filters.search" />
    </span>
  </section>
</template>

<script>
import { ref, reactive } from "vue";
import formMixin from "@/mixins/formMixin";
import { mapGetters } from "vuex";
import SearchInput from "@/components/common/SearchInput";

export default {
  name: "UsageFilters",
  mixins: [formMixin],
  components: { SearchInput },
  props: {
    singleUser: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const services = ref(["evaluation", "threat_evaluation", "verification", "deliverability", "threat_assessment"]);
    const types = ref(["UI", "API"]);
    const emailJob = ref(["Batch", "Real-time"]);
    const currentEmailJob = "Batch";
    const filters = reactive({
      service: "",
      type: "",
      search: "",
      emailJob: "",
    });
    const serviceMappings = {
      evaluation: "Evaluation",
      threat_evaluation: "Threat Evaluation",
      verification: "Deliverability + Threat Detection",
      deliverability: "Deliverability Check",
      threat_assessment: "Threat Detection",
    };
    return { services, types, filters, emailJob, currentEmailJob, serviceMappings };
  },
  computed: {
    ...mapGetters("auth", ["isStaff"]),
  },
  beforeMount(){
    if(this.$props.singleUser){
      this.services.splice(1,1);
      delete this.serviceMappings.threat_evaluation;
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.onFilter();
      },
    },
  },
  methods: {
    onFilter() {
      this.$emit("onFilter", this.filters);
    },
    onSelectEmailJob(job) {
      this.currentEmailJob = job;
      this.filters.emailJob = job;
      this.filters.type = "";
    },
  },
};
</script>
<style>
input:focus,
input[type="radio"]:focus {
  --tw-ring-color: #ff5a5f;
  border-color: #ff5a5f;
}

input[type="radio"] {
  color: var(--hover-color);
}
</style>
