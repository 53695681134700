function loadFreshchatScript(callback) {
    const existingScript = document.getElementById("freshchat-js-sdk");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://wchat.freshchat.com/js/widget.js";
      script.id = "freshchat-js-sdk";
      script.onload = callback;
      document.body.appendChild(script);
    } else {
      callback();
    }
  }

  const freshchatMixin = {
    methods: {
      setupFreshchat(userData, isLoggedIn) {
        loadFreshchatScript(() => {
          window.fcWidget.init({
            token: process.env.FRESHCHAT_TOKEN,
            host: "https://wchat.freshchat.com"
          });

          window.fcWidget.on("widget:loaded", () => {
            if (isLoggedIn) {
              window.fcWidget.setExternalId(userData.id);
              window.fcWidget.user.setFirstName(userData.name || "Alfred User");
              window.fcWidget.user.setEmail(userData.email);
              window.fcWidget.user.setProperties({
                cf_plan: "Pro",
                cf_status: "Active"
              });
            }
          });
        });
      }
    }
  };

  export default freshchatMixin;
