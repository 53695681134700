export const content = `
<p>Thanks for taking the time to learn about our legal policies. This is where you'll find information about how we protect your privacy, what you can and can't do with Email Industries, and how we handle user accounts.&nbsp;</p>
<ul>
<li class="list-item"><a href="#terms_of_use">Terms of Use</a> - The main agreement you're making in order to use Email Industries</li>
<li><a href="#privacy_policy">Privacy Policy</a> - What we do (and don't do!) with the information you give us</li>
<li><a href="#additional_terms">Additional Terms</a> - Some features are subject to additional usage terms and restrictions</li>
<li><a href="#acceptable_use">Acceptable Use</a> - A set of rules about the types of information we are willing and able to accept and analyze&nbsp;&nbsp;</li>
<li><a href="#api_use_policy">API Use Policy</a> - Rules for developers who use our API</li>
<li><a href="#cookie_statement">Cookie Statement</a> - How we use cookies and similar technologies</li>
<li><a href="#data_processing">Data Processing Addendum</a> - Our agreement that governs data processing activities for personal data</li>
<li><a href="#partner_agreement">Partner Agreement</a> - Terms that govern participation in our partner programs</li>
</ul>
<p>If you're looking for something not listed here, or have any questions, feel free to reach out to us at <a href="mailto:privacy@emailindustries.com">privacy@emailindustries.com</a>.</p>
<p>Last Updated: 5/18/2021</p>
<h1 id="terms_of_use">Terms of Use</h1>
<p>Please read these Terms carefully. By using Email Industries or signing up for an account, you&rsquo;re agreeing to these Terms, which will result in a legal agreement between you and Email Industries (&ldquo;Agreement&rdquo;).&nbsp;</p>
<p>We&rsquo;ll start with the basics, including a few definitions that should help you understand these Terms. Email Industries (&ldquo;Email Industries&rdquo;, &ldquo;AlfredKnows&rdquo;, &ldquo;BlackBox&rdquo;, &ldquo;we,&rdquo; or &ldquo;us&rdquo;) is an online data analysis platform (the &ldquo;App&rdquo;) that allows you to analyze Contacts (as defined in our <a href="#privacy_policy">Privacy Policy</a>), a (&ldquo;Job,&rdquo; and collectively, &ldquo;Jobs&rdquo;), including, without limitation, the quality of email addresses.</p>
<p>The App is offered through our websites, including https://www.alfredknows.com, https://www.blackbox.email, and https://www.emailindustries.com, and any other website or mobile application owned, operated or controlled by us (we&rsquo;ll collectively refer to these as the &ldquo;Email Industries Site(s),&rdquo; and together with the App, the &ldquo;Service&rdquo;).&nbsp;</p>
<p>Email Industries is a South Carolina limited liability company whose legal name is Indiemark LLC d/b/a Email Industries. Email Industries has employees, independent contractors, and representatives (&ldquo;our Team&rdquo;). As a customer of the Service or a representative of an entity that&rsquo;s a customer of the Service, you&rsquo;re a &ldquo;Member&rdquo; according to this Agreement (or &ldquo;you&rdquo;).</p>
<p>These Standard Terms of Use (&ldquo;Terms,&rdquo; including our <a href="#acceptable_use">Acceptable Use Policy</a>, <a href="#api_use_policy">API Use Policy</a>, and <a href="#data_processing"#data_processing> Data Processing Addendum</a>, define the terms and conditions under which you&rsquo;re allowed to use the Service in accordance with the Agreement, which in addition to our <a href="#privacy_policy">Privacy Policy</a> and <a href="#cookie_statement">Cookie Statement</a>, describe how we&rsquo;ll treat your account and the data we collect and process about you, your end users, and your contacts while you&rsquo;re a Member.&nbsp;</p>
<p>If you don&rsquo;t agree to these Terms, you must immediately discontinue your use of the Service.</p>
<p>Some features of the Service are offered as add-ons to your Email Industries account (&ldquo;Add-ons&rdquo;). Unless expressly stated otherwise, these Terms apply to the use of Add-ons. Some Add-ons are intended for particular use cases and may also have additional terms or restrictions (&ldquo;Additional Terms&rdquo;). Members agree to the applicable <a href="#additional_terms">Additional Terms</a> at the time they choose to add the corresponding Add-on. Also note that a Member&rsquo;s account may have access to unique features of the Service based on their historic usage or status.</p>
<p><strong>1. Eligibility</strong></p>
<p>In order to use the Service, you must be (a) at least eighteen years old and able to enter into contracts; (b) complete the account registration process; (c) agree to these Terms; (d) provide true, complete, and up-to-date contact and billing information; (e) not be based in Cuba, Iran, North Korea, Syria, or any other territory that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a &ldquo;terrorist-supporting&rdquo; country; and (f) not be listed on any U.S. government list of prohibited or restricted persons.</p>
<p>By using the Service, you represent and warrant that you meet all the requirements listed above, and that you won&rsquo;t use the Service in a way that violates any laws or regulations. Note that by representing and warranting, you are making a legally enforceable promise.</p>
<p>Email Industries may refuse service, close accounts of any Members, and change eligibility requirements at any time.</p>
<p><strong>2. Term</strong></p>
<p>When you sign up for an account and agree to these Terms, the Agreement between you and Email Industries is formed, and the term of the Agreement (the &ldquo;Term&rdquo;) will begin. The Term will continue for as long as you have a Email Industries account or until you or we terminate the Agreement in accordance with these Terms, whichever happens first. If you sign up for an account on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms and enter into the Agreement on its behalf.</p>
<p><strong>3. Closing Your Account</strong></p>
<p>You or Email Industries may terminate the Agreement at any time and for any reason by terminating your Email Industries account or giving notice to the other party. We may suspend the Service to you at any time, with or without cause. If we terminate your account without cause, and your account is a paid account, we&rsquo;ll refund a prorated portion of your monthly prepayment for a Monthly Plan or reimburse you for unused Pay as You Go Credits. We won&rsquo;t refund or reimburse you in any other situation, including if your account is suspended or terminated for cause, such as for a breach or violation of the Agreement. Once your account is terminated, you acknowledge and agree that we may permanently delete your account and all the data associated with it, including your Jobs.&nbsp;</p>
<p><strong>4. Changes</strong></p>
<p>We may change any of the Terms by posting revised Terms on our Email Industries Sites(s)(s). Unless you terminate your account, the new Terms will be effective immediately upon posting and apply to any continued or new use of the Service. We may change the Service, Add-ons, or any features of the Service at any time, and we may discontinue the Service, Add-ons, or any features of the Service at any time.</p>
<p><strong>5. Account and Password</strong></p>
<p>You&rsquo;re responsible for keeping your account name and password confidential. You&rsquo;re also responsible for any account that you have access to and any activity occurring in that account (other than activity that Email Industries is directly responsible for that isn&rsquo;t performed in accordance with your instructions), whether or not you authorized that activity. You&rsquo;ll immediately notify us of any unauthorized access or use of your accounts. We&rsquo;re not responsible for any losses due to stolen or hacked passwords. We don&rsquo;t have access to your current password, and for security reasons, we may only provide you with instructions on how to reset your password. We have the right to update any of your contact information in your account for billing purposes. In addition, you represent and warrant that all information you provide to us when you establish an account, and when you access and use the Service, is and will remain complete and accurate. We may contact you, or any seat, authorized user, or login added to your account, based on the information provided in your account.</p>
<p><strong>6. Account Disputes</strong></p>
<p>We don&rsquo;t know the inner workings of your organization or the nature of your personal relationships. You won&rsquo;t request access to or information about an account that&rsquo;s not yours, and you&rsquo;ll resolve any account-related disputes directly with the other party. We decide who owns an account based on a number of factors, including the Jobs in that account, and the contact and profile information listed for that account. In cases where differing contact and profile information is present or we are unable to reasonably determine ownership, we&rsquo;ll require you to resolve the matter through proper channels outside of Email Industries.</p>
<p>When a dispute is identified, we may suspend any account associated with the dispute, including disabling login and sending capabilities, to protect the security and privacy of the data held within the account until the dispute is properly resolved.</p>
<p><strong>7. Paid Monthly Plans</strong></p>
<p>When you sign up for a paid monthly plan, you are required to self-select both your package, and fee, based on your anticipated use of the Service. Each Monthly Plan offers different pricing and feature options. Once you select your Monthly Plan, Email Industries will never automatically upgrade or downgrade your Monthly Plan. So, give yourself room to grow if you&rsquo;ll need it. If you exceed your Monthly Plan usage limits (by exceeding your daily or monthly limits, or both), you will incur additional charges for the prior billing cycle based on your current Monthly Plan, which will be detailed on your bill and in your account. Before paying for your Monthly Plan for the first time, you will have an opportunity to preview the additional charges which would apply to your Monthly Plan should you incur them by exceeding your self-selected usage limits. If you decide to proceed, and you incur additional charges, you agree to pay them on or before the next Pay Date, as described below. You will always have the option to upgrade to a higher tier at any time. If you upgrade during a billing cycle, you may elect to upgrade your package, your audience limit, or both. If you elect to upgrade both, the upgrade amount for the package upgrade is calculated first, and the upgrade amount for the audience limit upgrade is calculated second. In any event, your monthly charge for that billing cycle will be calculated based on the number of days in that billing cycle during which you were at each of the lower tier and the higher tier (including any applicable additional charges for the higher tier). You will also have the option to downgrade to a lower tier at any time, but if you elect to downgrade during a billing cycle, your downgrade will not be effective until the end of that billing cycle, and you will still be charged for that entire billing cycle at the higher tier (including any applicable additional charges for the higher tier).</p>
<p>When you sign up for a Monthly Plan, you agree to monthly recurring billing, starting on the date you sign up. Billing occurs on the same day each month, based on the date that you started your Monthly Plan. Payments are due for any month on the same or closest date to the day you made your first monthly payment (the &ldquo;Pay Date&rdquo;). If any part of a month is included in the Term, then payment is required for the full month. If you exceed your self-selected usage limits for your Monthly Plan, then you&rsquo;ll have to pay additional charges (as described above) for the prior billing cycle on or before the next Pay Date, even if the Term ends or you downgrade to a Free Plan before that payment is otherwise due. Billing for Monthly Plans may be paused, and you may choose to close your account permanently at any time.</p>
<p><strong>8. Pay as You Go Credits</strong></p>
<p>You may also buy email credits to use the Service (&ldquo;Pay as You Go Credits&rdquo;), as explained on our Email Industries Sites(s). You will have an opportunity to review current rates for Pay as You Go Credits in your account prior to purchase. Pay as You Go Credits have no cash value, cannot be refunded or redeemed for cash, and represent a limited license to use the Service for the specified volume and type of service. Credits do not expire.</p>
<p><strong>9. Debit and Credit Cards</strong></p>
<p>As long as you&rsquo;re a Member with a Monthly Plan or otherwise have an outstanding balance with us, you&rsquo;ll provide us with valid debit or credit card (&ldquo;card&rdquo;) information and authorize us to deduct your charges against that card. You&rsquo;ll replace the information for any card that expires with information for a valid one. If your card is automatically replaced with a new card by a payment processor, you acknowledge and agree that we&rsquo;re authorized to deduct any charges on your account against the new card. Anyone using a card represents and warrants that they are authorized to use that card, and that any and all charges may be billed to that card and won&rsquo;t be rejected. If we&rsquo;re unable to process your card order, we&rsquo;ll try to contact you by email and may suspend your account until your payment can be processed.</p>
<p><strong>10. Refunds</strong></p>
<p>We&rsquo;ll give you a refund for a prepaid month or reimburse you for unused Pay as You Go Credits if we stop providing the Service and terminate your account without cause. You won&rsquo;t be entitled to a refund or credit from us under any other circumstances. We may, at our sole discretion, offer a refund, discount or credit.</p>
<p><strong>11. Charges for Add-Ons</strong></p>
<p>If you use an Add-on that has a charge, then you&rsquo;ll be billed that additional amount with each billing cycle for as long as the Add-on is active. Your billing cycle for an Add-on may differ from your billing cycle for your Monthly Plan or other features of the Service, and certain Add-ons may require upfront payment for their entire billing cycle. If you use Mandrill to send bulk emails in violation of our Acceptable Use Policy, then we may charge you at the comparable Email Industries plan or terminate your account.</p>
<p><strong>12. Billing Changes</strong></p>
<p>We may change our fees, including our charges for Monthly Plans and Pay as You Go Credits, at any time by posting a new pricing structure to our Email Industries Sites(s) or in your account and/or sending you a notification by email. Quoted fees don&rsquo;t include sales or other transaction-based taxes of any kind.</p>
<p><strong>13. Feedback and Proprietary Rights</strong></p>
<p>We own all proprietary rights in the Service, including, but not limited to, patents, trademarks, service marks, trade secrets, copyrights, and other intellectual property rights. You will respect our proprietary rights in the Service.&nbsp;</p>
<p>You shall retain all right, title, and interest in and to the data and information (including your personal information and the personal information of others) you submit to Email Industries in the course of using the Service or which Email Industries otherwise retrieves or accesses at your direction or with your permission (collectively, your &ldquo;Content&rdquo;). Subject to these Terms, you grant us permission to use or disclose your Content (including any personal information therein) only as necessary to provide the Service to you and/or as otherwise permitted by these Terms. You represent and warrant that: (i) you own or have otherwise obtained all necessary rights, releases, and permissions to submit all your Content to the Service and to grant the rights granted to us in these Terms and (ii) your Content and its submission and use as you authorize in these Terms will not violate (1) any applicable law, (2) any third-party intellectual property, privacy, publicity, or other rights, or (3) any of your or third-party policies or terms governing your Content.</p>
<p>You may provide or we may ask you to provide suggestions, comments, input or other feedback (&ldquo;Feedback&rdquo;) regarding the Service. If you provide us with any Feedback, then you grant us a royalty-free, non-exclusive, worldwide, sublicensable, and transferable license to use, reproduce, publicly display, distribute, modify, and publicly perform the Feedback as we see fit. Any Feedback you choose to provide is given entirely voluntarily. You understand that you will not receive any compensation for your Feedback, and that we may use any Feedback you provide to improve the Service or to develop new features and services.</p>
<p><strong>14. Privacy Policy</strong></p>
<p>Your privacy is important to us. Please read our <a href="#privacy_policy">Privacy Policy</a> and <a href="cookie_statement">Cookie Statement</a> for information regarding how we collect, use, and disclose your personal information and the privacy rights available to you when you use and interact with the Service.</p>
<p>For the purposes of European privacy laws, for much of the personal information we collect and process through the Service, we act as a processor; however, there are certain instances, as described in our Privacy Policy, where we process personal information, including Content and other data from Member accounts, as a controller for important business purposes (e.g., for billing, administrative, security, and product improvement purposes).</p>
<p>When we, as a controller, process data that originates from the European Economic Area, UK, or Switzerland in a country that has not been found to provide an adequate level of protection under applicable data protection law, we shall process such data in compliance with the European Commission decision standard contractual clauses for controllers (as updated, amended, or replaced from time to time), the terms of which are incorporated herein by reference. For the purposes of the descriptions in the standard contractual clauses: (a) Email Industries shall be the "data importer" and you shall be the "data exporter"; (b) the data importer selects option (iii) for the purposes of Clause 2(h) of the Model Clauses; and (c) the optional clauses are expressly not included. The details of the transfer are as follows: (i) the purposes of transfer, categories of data and data subjects are described in our Privacy Policy; (ii) sensitive data: n/a; and (iii) contact points for data protection enquiries are as set forth in these Terms.</p>
<p><strong>15. Right to Review&nbsp;</strong></p>
<p>We may view, copy, and internally distribute your Jobs and account to create algorithms and programs (&ldquo;Tools&rdquo;) that help us spot problem accounts and improve the Service. We use these Tools to find Members who violate these Terms or laws and to study data internally to make the Service smarter and create better experiences for Members and their contacts. We may aggregate and anonymize data, including your Jobs, to create statistical information. Aggregated anonymized statistical information may be shared externally for research, marketing, or other lawful purposes.</p>
<p><strong>16. General Rules</strong></p>
<p>By agreeing to these Terms, you promise to follow these rules:</p>
<ul>
<li>You&rsquo;ll comply with our <a href="#acceptable_use">Acceptable Use Policy</a>, which forms part of these Terms.</li>
<li>If you use our API, you&rsquo;ll comply with our <a href="#api_use_policy">API Use Policy</a>, which forms part of these Terms.</li>
</ul>
<p>If you violate any of these rules, then we may issue a warning to, suspend, or terminate your account.</p>
<p><strong>17. Reporting Abuse</strong></p>
<p>If you think anyone is violating any of these Terms, please notify us immediately.&nbsp;</p>
<p><strong>18. Compliance with Laws</strong></p>
<p>You represent and warrant that your use of the Service will comply with all applicable laws and regulations. You&rsquo;re responsible for determining whether the Service is suitable for you to use in light of your obligations under any regulations like HIPAA, GLB, Data Protection Laws (as defined in the <a href="#data_processing">Data Processing Addendum</a>), United States export control laws and regulations and economic sanctions laws and regulations (&ldquo;U.S. Export Control Laws and Regulations&rdquo;), or other applicable laws. If you&rsquo;re subject to regulations (like HIPAA) and you use the Service, then we won&rsquo;t be liable if the Service doesn&rsquo;t meet those requirements. You may not use the Service for any unlawful or discriminatory activities, including acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit Opportunity Act, Children's Online Privacy Protection Act, or any other applicable laws.</p>
<p>If you collect any personal information pertaining to a minor and store such information within your Email Industries account, you represent and warrant that you have obtained valid consent for such activities according to the applicable laws of the jurisdiction in which the minor lives.</p>
<p>You agree, represent, and warrant to Email Industries that:</p>
<ul>
<li>You will clearly post, maintain, and abide by a publicly accessible privacy notice on the digital properties from which the underlying data is collected that (a) satisfies the requirements of applicable data protection laws, (b) describes your use of the Service, and (c) includes a link to Email Industries&rsquo; Privacy Policy.</li>
<li>You will get and maintain all necessary permissions and valid consents required to lawfully transfer data to Email Industries and to enable such data to be lawfully collected, processed, and shared by Email Industries for the purposes of providing the Service or as otherwise directed by you.</li>
<li>You will comply with all laws and regulations applicable to the Jobs sent through the Service, including those relating to (a) acquiring consents (where required) to lawfully send Jobs, (b) the Content of Jobs, and (c) your Job deployment practices.</li>
<li>You will provide and obtain all notices and obtain all necessary consents required by applicable data protection laws to enable Email Industries to deploy cookies and similar tracking technologies (like web beacons or pixels) lawfully on and collect data from the devices of contacts and end users in accordance with and as described in the Cookie Statement.</li>
</ul>
<p>To the extent Email Industries processes your Content protected by Data Protection Laws as a processor on your behalf (all as defined in the DPA), you and Email Industries shall be subject to and comply with the Email Industries <a href="#data_processing">Data Processing Addendum</a> ("DPA"), which is incorporated into and forms an integral part of these Terms. The DPA sets out our obligations with respect to data protection and security when processing your Content on your behalf in connection with the Service.</p>
<p>In addition, if you are subject to EU Data Protection Law (as defined in the Data Processing Addendum), you acknowledge and agree that we have your prior written authorization to respond, at our discretion, to any data subject access requests we receive from your contacts made under EU Data Protection Law, or, alternatively, we may direct any such contacts to you so that you can respond to the request accordingly.</p>
<p>You agree to indemnify and hold us harmless from any losses, including all legal fees and expenses, that result from your breach of this Section 20.</p>
<p><strong>19. Export Controls</strong></p>
<p>The software that supports the App (the &ldquo;Software&rdquo;) is subject to U.S. Export Control Laws and Regulations. Export laws are set up by the U.S. government to keep certain goods and services from reaching other countries, usually because of security concerns or trade agreements. None of the Software may be downloaded or otherwise exported or re-exported in violation of U.S. Export Control Laws and Regulations and any other applicable export laws and regulations (collectively, &ldquo;Export Control Laws&rdquo;). Therefore, you agree that you won&rsquo;t, directly or indirectly through a third party, allow the Software or your Jobs to be accessed or generated from within, or distributed or sent to, any prohibited or embargoed country as mentioned in any Export Control Laws. In addition, you certify that neither you nor any principals, officers, directors, or any person or entity you know to be directly involved with the use of the Service is designated on any U.S. government list of prohibited or restricted persons.</p>
<p>It&rsquo;s important to note that this Section 21 isn&rsquo;t meant to provide a comprehensive summary of the Export Control Laws that govern Email Industries, the Service, or the Software. You&rsquo;re downloading and using the Software at your own risk, and it&rsquo;s your responsibility to consult with a legal advisor to make sure your use of the Service and the Software complies with applicable laws.</p>
<p><strong>20. Limitation of Liability</strong></p>
<p>To the maximum extent permitted by law, you acknowledge and agree that (i) you assume full responsibility for any loss that results from your use of the Service, including any downloads from the Email Industries Sites(s); (ii) we and our Team won&rsquo;t be liable for any indirect, punitive, special, or consequential damages under any circumstances, even if they&rsquo;re based on negligence or we&rsquo;ve been advised of the possibility of those damages; and (iii) in any calendar month, our total liability to you arising under or in connection with the Agreement&mdash;whether in contract, tort (including negligence), breach of statutory duty, or otherwise&mdash;will be no more than what you paid us for the Service the preceding month.</p>
<p>For the avoidance of doubt, in no instance will we or our Team be liable for any losses or damages you suffer if you use the Service in violation of these Terms, regardless of whether we terminate or suspend your account due to such violation.</p>
<p><strong>21. No Warranties</strong></p>
<p>To the maximum extent permitted by law, we provide the Service as-is. This means that, except as expressly stated in these Terms, we don&rsquo;t provide warranties, conditions, or undertakings of any kind in relation to the Service, either express or implied. This includes, but isn&rsquo;t limited to, warranties of merchantability and fitness for a particular purpose, which are, to the fullest extent permitted by law, excluded from the Agreement. Since Members use the Service for a variety of reasons, we can&rsquo;t guarantee that it&rsquo;ll meet your specific needs.</p>
<p><strong>22. Indemnity</strong></p>
<p>You agree to indemnify and hold us and our Team harmless from any losses, including legal fees and expenses that directly or indirectly result from any claims you make that aren&rsquo;t allowed under these Terms due to a &ldquo;Limitation of Liability&rdquo; or other provision. (Indemnity is an agreement to compensate someone for a loss.) You also agree to indemnify and hold us harmless from any losses, including legal fees and expenses, that directly or indirectly result from (i) your Content, (ii) your use of the Service, (iii) your violation of any laws or regulations, (iv) third-party claims that you or someone using your password did something that, if true, would violate any of these Terms, (v) any misrepresentations made by you, or (vi) a breach of any representations or warranties you&rsquo;ve made to us.</p>
<p><strong>23. Equitable Relief</strong></p>
<p>Your violation of these Terms may cause irreparable harm to us and our Team. Therefore, we have the right to seek injunctive relief or other equitable relief if you violate these Terms (meaning we may request a court order to stop you).</p>
<p><strong>24. Subpoena Fees</strong></p>
<p>If we have to provide information in response to a subpoena, court order, or other legal, governmental, or regulatory inquiry related to your account, then we may charge you for our costs. These fees may include attorney and employee time spent retrieving the records, preparing documents, and participating in a deposition.</p>
<p><strong>25. Disclaimers</strong></p>
<p>We and our Team aren&rsquo;t responsible for the behavior of any third parties, agencies, linked websites, or other Members, including third-party applications, products, or services for use in connection with the Service (each, a &ldquo;Third-Party Integration&rdquo;). Your use of any Third-Party Integration and rights with respect to such Third-Party Integration are solely between you and the applicable third party. We are not responsible for the privacy, security or integrity of any Third-Party Integration or the practices and policies of any Third-Party Integration. We make no warranties of any kind and assume no liability of any kind for your use of any Third-Party Integration.</p>
<p><strong>26. Notice to U.S. Government End Users</strong></p>
<p>The Software and the Email Industries Sites(s), including all documentation, are &ldquo;Commercial Items,&rdquo; as that term is defined at 48 C.F.R. &sect;2.101, and consist of &ldquo;Commercial Computer Software&rdquo; and &ldquo;Commercial Computer Software Documentation.&rdquo; The Commercial Computer Software and Commercial Computer Software Documentation are licensed to U.S. government end users only as Commercial Items with the same rights as all other end users, and</p>
<p>according to the Terms.</p>
<p><strong>27. Assignments</strong></p>
<p>You may not assign any of your rights under this Agreement to anyone else. We may assign our rights to any other individual or entity at our discretion.</p>
<p><strong>28. Choice of Law</strong></p>
<p>The State of South Carolina&rsquo;s laws, except for conflict of laws rules, will apply to any dispute related to the Agreement or the Service. You acknowledge and agree that any dispute related to the Agreement or the Service itself will be decided by the state or federal courts in Aiken County, South Carolina, and each party consents to personal jurisdiction in those courts.</p>
<p><strong>29. Force Majeure</strong></p>
<p>We won&rsquo;t be held liable for any delays or failure in performance of any part of the Service, from any cause beyond our control. This includes, but is not limited to, acts of god, changes to law or regulations, embargoes, war, terrorist acts, riots, fires, earthquakes, nuclear accidents, zombie apocalypse, floods, strikes, power blackouts, volcanic action, unusually severe weather conditions, and acts of hackers, or third-party internet service providers.</p>
<p><strong>30. Survivability</strong></p>
<p>Even if this Agreement is terminated, the following sections will continue to apply: Feedback and Proprietary Rights, Compliance with Laws, Limitation of Liability, No Warranties, Indemnity, Choice of Law, Severability, and Entire Agreement.</p>
<p><strong>31. Severability</strong></p>
<p>If it turns out that a section of these Terms isn&rsquo;t enforceable, then that section will be removed or edited as little as required, and the rest of the Agreement will still be valid.</p>
<p><strong>32. Interpretation</strong></p>
<p>The headers and table of content text are provided only to make these Terms easier to read and understand and won&rsquo;t affect the way the Agreement is interpreted.</p>
<p><strong>33. Waiver</strong></p>
<p>If we don&rsquo;t immediately take action on a violation of these Terms, we&rsquo;re not giving up any rights under the Terms, and we may still take action at some point.</p>
<p><strong>34. No Changes in Terms at Request of Member</strong></p>
<p>Because we have many Members, we can&rsquo;t change these Terms for any one Member.</p>
<p><strong>35. Further Actions</strong></p>
<p>You&rsquo;ll provide all documents and take any actions necessary to meet your obligations under these Terms.</p>
<p><strong>36. Notification of Security Incident</strong></p>
<p>If we become aware of a security incident related to our systems or databases that contain personal information of you or your contacts, we&rsquo;ll notify you if required by law. In that event, we&rsquo;ll also provide you with information about that incident so that you can evaluate the consequences to you and any legal or regulatory requirements that may apply to you, unless we&rsquo;re prevented from doing so by legal, security or confidentiality obligations. Notifying you of a security incident or cooperating with you to respond to one will not be deemed an acknowledgement or assumption of any liability or fault of Email Industries for such incident.</p>
<p><strong>37. Beta Services</strong></p>
<p>From time to time, (i) we may invite you to try products or features related to our Service that are not generally available to all of our Members or the public or (ii) we may release products or features related to our Service that are identified in the App as beta, preview, pilot, limited release, or by a similar designation (collectively, &ldquo;Beta Services&rdquo;). Beta Services may be provided for evaluation or testing purposes only, and, therefore, may contain bugs or errors, and may not be as reliable as other features of the Service. We may discontinue Beta Services at any time in our sole discretion, and we may decide not to make Beta Services generally available.</p>
<p><strong>38. Notices</strong></p>
<p>Any notice to you will be effective when we send it to the last email or physical address you gave us or when posted on our Email Industries Sites(s). Any notice to us will be effective when delivered to us along with a copy to our legal counsel: Attn. Email Industries, 801 Broad Street, Suite 603, Augusta GA 30901, or any addresses as we may later post on the Email Industries Sites(s).</p>
<p><strong>39. Entire Agreement</strong></p>
<p>These Terms and any Additional Terms you&rsquo;ve agreed to by enabling any Add-ons make up the entire agreement between us in relation to its subject matter and supersede all prior agreements, representations, and understandings. Any Additional Terms will be considered incorporated into the Agreement when you activate the corresponding Add-on.</p>
<p>Where there&rsquo;s a conflict between these Terms and the Additional Terms, the Additional Terms will control the extent of the conflict.</p>
<h1 id="acceptable_use">Acceptable Use</h1>
<p>We&rsquo;re in this together. The actions individual Members take can have a huge impact on our system as a whole. That&rsquo;s why all Email Industries&rsquo; Members must follow this Acceptable Use Policy in their use of Email Industries and any other Email Industries Add-ons or products we may offer, like AlfredKnow and BlackBox.&nbsp;</p>
<p>If you violate this policy, we may suspend or terminate your account. We may also suspend or terminate accounts according to our Standard Terms of Use where we see behavior, content, or other factors that pose a threat to our platform. If a term is capitalized in this Acceptable Use Policy but isn&rsquo;t defined, it has the meaning given to it in our Standard Terms of Use.</p>
<p>We work hard to maintain the positive reputation of our platform, but we count on our Members to pitch in too. You may not:</p>
<ul>
<li>Use the Service to enable the sending of unsolicited commercial messages.</li>
<li>Share your password.</li>
<li>Decipher, decompile, disassemble, or reverse engineer any of the Software.</li>
<li>Use any of the Software to create a competing product.</li>
<li>Set up multiple accounts for any person or entity.</li>
<li>Upload Social Security numbers, security credentials, or sensitive personal information.</li>
<li>Take any action intended to avoid our billing or usage thresholds.</li>
</ul>
<p>Last Updated: 5/18/2021</p>
<h1 id="additional_terms">Additional Terms</h1>
<p>Certain features within Email Industries are offered as add-ons to your Email Industries account. Some add-ons have additional terms or restrictions (&ldquo;Additional Terms&rdquo;) associated with them. Additional Terms for each of the add-ons can be found below, and Email Industries Members agree to the applicable Additional Terms at the time they choose to add the corresponding add-on feature.</p>
<p>Last Updated: 5/18/2021</p>
<h1 id="privacy_policy">Privacy Policy</h1>
<p>We recommend that you read this privacy policy in full to ensure you are fully informed. If you have questions, please contact us using the contact details provided at the end of this Privacy Policy.</p>
<p><strong>1. The Basics</strong></p>
<p>Email Industries (&ldquo;Email Industries&rdquo;, &ldquo;AlfredKnows&rdquo;, &ldquo;BlackBox&rdquo;, &ldquo;we,&rdquo; or &ldquo;us&rdquo;) is an online data analysis platform (the &ldquo;App&rdquo;) that allows you to analyze Contacts (as defined below).&nbsp;</p>
<p>The App is offered through our websites, including https://www.alfredknows.com, https://www.blackbox.email, and https://www.emailindustries.com, and any other website or mobile application owned, operated or controlled by us (we&rsquo;ll collectively refer to these as the &ldquo;</p>
<p><strong>2. Key Terms</strong></p>
<p>In this privacy policy, these terms have the following meanings:</p>
<p>"Affiliate" means an entity that directly or indirectly Controls, is Controlled by or is under common Control with an entity.</p>
<p>"Contact" is an email address that a Member may import into our Service. In other words, a Contact is anyone on a Member's Distribution List about whom a Member has given us information via the Service. For example, if you are a Member, a subscriber to your email marketing list would be considered a Contact.</p>
<p>"Control" means an ownership, voting or similar interest representing fifty percent (50%) or more of the total interests then outstanding of the entity in question. The term "Controlled" shall be construed accordingly.</p>
<p>"Distribution List" is a list of Contacts a Member may upload or manage on our platform and all associated information related to those Contacts (for example, email addresses).</p>
<p>"Email Industries Site(s)" has the meaning given to it in our Standard Terms of Use.</p>
<p>"Member" means any person or entity that is registered with us to use the Service.</p>
<p>"Personal Information" means any information that identifies or can be used to identify an individual directly or indirectly. Examples of Personal Information include, but are not limited to, first and last name, date of birth, email address, gender, occupation, or other demographic information.</p>
<p>&ldquo;Service&rdquo; has the meaning given to it in our Standard Terms of Use.</p>
<p>"Visitor" means, depending on the context, any person who visits any of our Email Industries Sites, offices, or otherwise engages with us at our events or in connection with our marketing or recruitment activities.</p>
<p>"you" and "your" means, depending on the context, either a Member, a Contact, or a Visitor.</p>
<p><strong>3. Privacy for Members</strong></p>
<p>This section applies to the Personal Information we collect and process from a Member or potential Member through the provision of the Service. If you are not a Member, the Visitors or Contacts section of this policy may be more applicable to you and your data. In this section, "you" and "your" refer to Members and potential Members.</p>
<p><strong>3A. Information We Collect</strong></p>
<p>The Personal Information that we collect depends on the context of your interactions with Email Industries, your Email Industries account settings, the products and features you use, your location, and applicable law. However, the Personal Information we collect broadly falls into the following categories:</p>
<p>(i) Information you provide to us: You (or your organization) may provide certain Personal Information to us when you sign up for a Email Industries account and use the Service, consult with our customer service team, send us an email, integrate the Service with another website or service (for example, when you choose to connect your email service provider with Email Industries), or communicate with us in any other way. This information may include:</p>
<ul>
<li>Business contact information (such as your name, job title, organization, location, phone number, email address, and country);</li>
<li>Marketing information (such as your contact preferences);</li>
<li>Account log-in credentials (such as your email address or username and password when you sign up for an account with us);</li>
<li>Troubleshooting and support data (which is data you provide or we otherwise collect in connection with support queries we receive from you. This may include contact or authentication data, the content of your chats and other communications with us, and the product or service you are using related to your help inquiry); and</li>
<li>Payment information (including your credit card numbers and associated identifiers and billing address).</li>
</ul>
<p>(ii) Information we collect automatically: When you use the Service, we and our third-party partners may automatically collect or receive certain information about your device and usage of the Service (collectively &ldquo;Service Usage Data&rdquo;). In some (but not all) countries, including countries in the European Economic Area (&ldquo;EEA&rdquo;), this information is considered Personal Information under applicable data protection laws. We and our third-party partners use cookies and other tracking technologies to collect some of this information.Service Usage Data may include:</p>
<ul>
<li>Device information: We collect information about the device and applications you use to access the Service, such as your IP address, your operating system, your browser ID, viewfinder size, and other information about your system and connection. If you are using our Mobile App, we may also collect information about the cellular network associated with your mobile device, your mobile device&rsquo;s operating system or platform, the type of mobile device you use, your mobile device&rsquo;s name and unique device ID, and information about the features of our Mobile App that you accessed.</li>
<li>Log data: Our web servers keep log files that record data each time a device accesses those servers and the nature of each access, including originating IP addresses and your activity in the Service (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take (for example, which features you used)), device event information (such as system activity, error reports (sometimes called &lsquo;crash dumps&rsquo;)), and hardware settings. We may also access metadata and other information associated with files that you upload into our Service.</li>
<li>Usage data: We collect usage data about you whenever you interact with our Service, which may include the dates and times you access the Service and your browsing activities (such as what portions of the Service you used, session duration, links clicked, non-sensitive text entered, and mouse movements). We also collect information regarding the performance of the Service, including metrics related to the deliverability of emails and other communications you send through the Service. If you are using our Mobile App, we may collect information about how often you use the Mobile App and other performance data. This information allows us to improve the content and operation of the Service, and facilitate research and analysis of the Service.</li>
</ul>
<p>(iii) Information we collect from other sources: From time to time, we may obtain information about you from third-party sources, such as public databases, social media platforms, third-party data providers, and our joint marketing partners. Examples of the information we receive from other sources include demographic information (such as age and gender), device information (such as IP addresses), location (such as city and state), and online behavioral data (such as information about your use of social media websites, page view information and search results and links). We use this information, alone or in combination with other Personal Information we collect, to enhance our ability to provide relevant marketing and content to you and to develop and provide you with more relevant products, features, and service.</p>
<p><strong>3B. Use of Personal Information</strong></p>
<p>We may use the Personal Information we collect or receive through the Service (alone or in combination with other data we source) for the purposes and on the legal bases identified below:</p>
<ul>
<li>To bill and collect money owed to us by you to perform our contract with you for the use of the Service or where we have not entered into a contract with you, in accordance with our legitimate interests to operate and administer our Service. This includes sending you emails, invoices, receipts, notices of delinquency, and alerting you if we need a different credit card number. We use third parties for secure credit card transaction processing, and those third parties collect billing information to process your orders and credit card payments. To learn more about the steps we take to safeguard that data, see the "Our Security" section of this privacy policy.</li>
<li>To send you system alert messages in reliance on our legitimate interests in administering the Service and providing certain features. For example, we may inform you about temporary or permanent changes to our Service, such as planned outages, or send you account, security or compliance notifications, such as new features, version updates, releases, abuse warnings, and changes to this privacy policy.</li>
<li>To communicate with you about your account and provide customer support to perform our contract with you for the use of the Service or where we have not entered into a contract with you, in reliance on our legitimate interests in administering and supporting our Service. For example, if you use our Mobile Apps, we may ask you if you want to receive push notifications about activity in your account. If you have opted in to these push notifications and no longer want to receive them, you may turn them off through your operating system.</li>
<li>To enforce compliance with our Standard Terms of Use and applicable law, and to protect the rights and safety of our Members in reliance on our legitimate interest to protect against misuse or abuse of our Service and to pursue remedies available. This may include developing tools and algorithms that help us prevent violations. For example, sometimes we review the content our Members send or display to ensure it complies with our Standard Terms of Use. To improve that process, we have software that helps us find content that may violate our Standard Terms of Use. We may or our third-party service provider may also review content that our Members send or display. This benefits all Members who comply with our Standard Terms of Use because it reduces abuse and helps us maintain a reliable platform. Please do not use Email Industries to send or display confidential information.</li>
<li>To meet legal requirements, including complying with court orders, valid discovery requests, valid subpoenas, and other appropriate legal mechanisms.</li>
<li>To provide information to representatives and advisors, including attorneys and accountants, to help us comply with legal, accounting, or security requirements in reliance on our legitimate interests.</li>
<li>To prosecute and defend a court, arbitration, or similar legal proceeding.</li>
<li>To respond to lawful requests by public authorities, including to meet national security or law enforcement requirements.</li>
<li>To provide, support and improve the Service to perform our contract with you for the use of the Service or where we have not entered into a contract with you, in reliance on our legitimate interests in administering and improving the Service and providing certain features. For example, this may include improving the navigation and content of the Service and sharing your information with third parties in order to provide and support our Service or to make certain features of the Service available to you. When we share your Personal Information with third parties, we take steps to protect your information in a manner that is consistent with our obligations under applicable privacy laws. For further information about how we share your information, refer to Section 5 below.</li>
<li>To provide suggestions to you and to provide tailored features within our Service that optimize and personalize your experience in reliance on our legitimate interests in administering the Service and providing certain features. This includes adding features that compare Members&rsquo; email campaigns, using data to suggest other publishers your Contacts may be interested in, or using data to recommend products or services that you may be interested in or that may be relevant to you or your Contacts. Some of these suggestions are generated through analysis of the data used in our data analytics projects, as described below.</li>
<li>To perform data analytics projects in reliance on our legitimate business interests in improving and enhancing our products and services for our Members. Our data analytics projects use data from Email Industries accounts, including Personal Information of Contacts, to provide and improve the Service. We use information like your sending habits and your Contacts&rsquo; purchase history, so we can make more informed predictions, decisions, and products for our Members. For example, we use data from Email Industries accounts to enable product recommendation, audience segmentation, and predicted demographics features for our Members. If you or your Contact prefers not to have their data used for this purpose, you can alter the settings on your account (as described here) to opt out of data analytics projects, or your Contact can opt out of data analytics projects at any time by visiting this page or emailing us at privacy@emailindustries.com. As always, we take the privacy of Personal Information seriously, and will continue to implement appropriate safeguards to protect this Personal Information from misuse or unauthorized disclosure.</li>
<li>To combine and anonymize data about our Members and our Member's use of the Service in order to create aggregate, anonymized statistics which we may use to provide certain features within the Service and for promoting and improving the Service in reliance on our legitimate interests.</li>
<li>To personalize the Service, content, and advertisements we serve to you in reliance on our legitimate interests in supporting our marketing activities and providing certain features within the Service. We and our third-party marketing and advertising partners may use your Personal Information to serve you specifically, such as to deliver gifts and promotional materials, marketing information, product recommendations, non-transactional communications (e.g., email, telemarketing calls, SMS, or push notifications), and personalized advertising about us, in accordance with your applicable marketing preferences and this privacy policy.&nbsp;</li>
</ul>
<p><strong>3C. Third-Party Integrations</strong></p>
<p>We may use the Personal Information we collect or receive through the Service, as a processor and as otherwise stated in this privacy policy, to enable your use of the integrations and plugins you choose to connect to your Email Industries account. For instance, if you choose to connect your email service provider to your Email Industries account, we&rsquo;ll ask you to grant us permission to view and/or download your Contacts. This allows us to configure your email service providers integration(s) in accordance with your preferences.&nbsp;</p>
<p><strong>3D. Cookies and Tracking Technologies</strong></p>
<p>We and our third-party partners may use various technologies to collect and store Service Usage Data when you use our Service (as discussed above), and this may include using cookies and similar tracking technologies, such as pixels.&nbsp; Our use of cookies and other tracking technologies is discussed in more detail in our <a href="#cookie_statement">Cookie Statement</a>.</p>
<p><strong>3E. Member Distribution Lists</strong></p>
<p>In order to use the Services, you need to upload a Distribution List that provides us information about your Contacts, such as their email addresses. We use and process this information to provide the Service in accordance with our contract with you or your organization and this privacy policy.</p>
<p>A Distribution List can be created in a number of ways, including by importing Contacts, such as through a CSV or directly from your email service providers. We do not, under any circumstances, sell your Distribution Lists. If someone on your Distribution List complains or contacts us, we might then contact that person. You may export (download) your Distribution Lists from Email Industries by accessing your account.</p>
<p>If we detect abusive or illegal behavior related to your Distribution List, we may use your Distribution List or portions of to improve our Service to the extent permitted or required by applicable law.</p>
<p><strong>3F. Your Data Protection Rights</strong></p>
<p>Depending on the country in which you reside, you may have the following data protection rights:</p>
<ul>
<li>To access; correct; update; port; delete; restrict; or object to our processing of your Personal Information.</li>
<li>You can manage your individual account and profile settings within the dashboard provided through the Email Industries platform, or you may contact us directly by emailing us at privacy@emailindustries.com. You can also manage information about your Contacts within the dashboard provided through the Email Industries platform to assist you with responding to requests to delete information that you receive from your Contacts. Note, if any of your Contacts wish to exercise any of these rights, they should contact you directly, or contact us as described in the &ldquo;Privacy for Contacts&rdquo; section below. You can also contact us at any time to update your own marketing preferences. Email Industries takes reasonable steps to ensure that the data we collect is reliable for its intended use, accurate, complete and up to date.</li>
<li>The right to complain to a data protection authority about the collection and use of Personal Information. For more information, please contact your local data protection authority.&nbsp;</li>
<li>Similarly, if Personal Information is collected or processed on the basis of consent, the data subject can withdraw their consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your Personal Information conducted in reliance on lawful processing grounds other than consent. If you receive these requests from Contacts, you can segment your lists within the Email Industries platform to ensure that you only market to Contacts who have not opted out of receiving such marketing.</li>
</ul>
<p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection law. We may ask you to verify your identity in order to help us respond efficiently to your request. If we receive a request from one of your Contacts, we will either direct the Contact to reach out to you, or, if appropriate, we may respond directly to their request.</p>
<p><strong>4. Privacy for Contacts</strong></p>
<p>This section applies to the information we process about our Members&rsquo; Contacts as a data controller. Our Service is intended for use by our Members. As a result, for much of the Personal Information we collect and process about Contacts through the Service, we act as a processor on behalf of our Members. Email Industries is not responsible for the privacy or security practices of our Members, which may differ from those set forth in this privacy policy. Please check with individual Members about the policies they have in place. For purposes of this section, "you" and "your" refer to Contacts.</p>
<p><strong>4A. Information We Collect</strong></p>
<p>The Personal Information that we may collect or receive about you broadly falls into the following categories:</p>
<p>(i) Information we receive about Contacts from our Members: A Member may provide Personal Information about you to us through the Service. When a Member uploads their Distribution List or integrates the Service with another website or service (for example, when a Member chooses to connect their e-commerce account with Email Industries), or when you sign up for a Member&rsquo;s Distribution List on a Email Industries or other signup form, the Member may provide us with certain contact information or other Personal Information about you such as your name, email address, address, or telephone number. You may have the opportunity to update some of this information by electing to update or manage your preferences via an email you receive from a Member.</p>
<p>(ii) Information we collect automatically: When you interact with an email campaign that you receive from a Member or browse or purchase from a Member&rsquo;s connected store, we may collect information about your device and interaction with an email. We use cookies and other tracking technologies to collect some of this information. Our use of cookies and other tracking technologies is discussed more below and in more detail in our Cookie Statement.</p>
<ul>
<li>Device information: We collect information about the device and applications you use to access emails sent through our Service, such as your IP address, your operating system, your browser ID, and other information about your system and connection.</li>
<li>Usage data: It is important for us to ensure the security and reliability of the Service we provide. Therefore, we also collect usage data about your interactions with campaigns (and/or emails) sent through the Service, which may include dates and times you access campaigns (and/or emails) and your browsing activities (such as what pages are viewed and which emails are opened). This information also allows us to ensure compliance with our Standard Terms of Use and Acceptable Use Policy, to monitor and prevent service abuse, and to ensure we attain certain usage standards and metrics in relation to our Service. We also collect information regarding the performance of the Service, including metrics related to the deliverability of emails and other electronic communications that our Members send through the Service. This information allows us to improve the content and operation of the Service and facilitate research and perform analysis into the use and performance of the Service.</li>
</ul>
<p>(iii) Information we collect from other sources: From time to time, we may obtain information about you from third-party sources, such as social media platforms, and third-party data providers.</p>
<p><strong>4B. Use of Personal Information</strong></p>
<p>We may use the Personal Information we collect or receive about you in reliance on our (and where applicable, our Members&rsquo;) legitimate interests for the following purposes:</p>
<ul>
<li>To enforce compliance with our Standard Terms of Use and applicable law. This may include utilizing usage data and developing tools and algorithms that help us prevent violations.</li>
<li>To protect the rights and safety of Members, third parties, or Email Industries. For example, sometimes we review the content of our Members&rsquo; email campaigns to make sure they comply with our Standard Terms of Use. To improve that process, we have software that helps us find email campaigns that may violate our Standard Terms of Use. We, or our third-party service provider, may review those particular email campaigns, which may include your contact information. This reduces the amount of spam being sent through our servers and helps us maintain high deliverability.</li>
<li>To meet legal requirements, including complying with court orders, valid discovery requests, valid subpoenas, and other appropriate legal mechanisms.</li>
<li>To provide information to representatives and advisors, including attorneys and accountants, to help us comply with legal, accounting, or security requirements.</li>
<li>To prosecute and defend a court, arbitration, or similar legal proceeding.</li>
<li>To respond to lawful requests by public authorities, including to meet national security or law enforcement requirements.</li>
<li>To provide, support and improve the Service. For example, this may include sharing your information with third parties in order to provide and support our Service or to make certain features of the Service available to our Members. When we share Personal Information with third parties, we take steps to protect your information in a manner that is consistent with applicable privacy laws. For further information about how we share information, refer to Section 5 below.</li>privacy@emailindustries.com</a>.</li>
<li>To carry out other business purposes. To carry out other legitimate business purposes, as well as other lawful purposes about which we will notify you.</li>
</ul>
<p><strong>4C. Cookies and Tracking Technologies</strong></p>
<p>We and our third-party partners may use various technologies to automatically collect and store certain device and usage information (as discussed above) when you interact with a Member&rsquo;s email campaign or connected store, and this may include using cookies and similar tracking technologies, such as pixels and web beacons or if a Member is using our Mobile App, we may collect this information through our SDKs deployed on our Members mobile device. For example, we use web beacons in the emails we send on behalf of our Members. When you receive and engage with a Member&rsquo;s campaign, web beacons track certain behavior such as whether the email sent through the Email Industries platform was delivered and opened and whether links within the email were clicked. Both web beacons and SDKs allow us to collect information such as your IP address, browser, email client type, and other similar data as further described above. We use this information to measure the performance of our Members&rsquo; email campaigns, and to provide analytics information and enhance the effectiveness of our Service, and for the other purposes described above.</p>
<p><strong>4D. Your Data Protection Rights</strong></p>
<p>Depending on the country in which you reside, you may have the following data protection rights:</p>
<ul>
<li>To access; correct; update; port; delete; restrict or object to our processing of your Personal Information.</li>
<li>For more information about how you can exercise these rights, please see our Privacy Rights Requests form. You also have the right to complain to a data protection authority about our collection and use of your Personal Information. For more information, please contact your local data protection authority. Contact details for data protection authorities in the EEA are available here.</li>
</ul>
<p>As described above, for much of the Personal Information we collect and process about Contacts through the Service, we act as a processor on behalf of our Members. In such cases, if you are a Contact and want to exercise any data protection rights that may be available to you under applicable law or have questions or concerns about how your Personal Information is handled by Email Industries as a processor on behalf of our individual Members, you should contact the relevant Member that is using the Email Industries Service, and refer to their separate privacy policies.</p>
<p>If you no longer want to be contacted by one of our Members through our Service, please unsubscribe directly from that Member&rsquo;s newsletter or contact the Member directly to update or delete your data. If you contact us directly, we may either forward your request to the relevant Member or provide you with the identity of the Member to enable you to contact them directly.</p>
<p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. We may ask you to verify your identity in order to help us respond efficiently to your request.</p>
<p><strong>5. Privacy for Visitors</strong></p>
<p>This section applies to Personal Information that we collect and process when you visit the Email Industries Sites, and in the usual course of our business, such as in connection with our recruitment, events, sales and marketing activities or when you visit our offices. In this section, "you" and "your" refer to Visitors.</p>
<p><strong>5A. Information We Collect</strong></p>
<p>(i) Information you provide to us on the Email Industries Sites or otherwise: Our Email Industries Sites offer various ways to contact us, such as through form submissions, email or phone, to inquire about our company and Service. For example, we may ask you to provide certain Personal Information when you express an interest in obtaining information about us or our Service, take part in surveys, subscribe to marketing, apply for a role with Email Industries, or otherwise contact us. We may also collect Personal Information from you in person when you attend our events or trade shows, if you visit our offices (where you will be required to register as a visitor and provide us with certain information that may also be shared with our service providers) or via a phone call with one of our sales representatives. You may choose to provide additional information when you communicate with us or otherwise interact with us, and we may keep copies of any such communications for our records. The Personal Information we collect may include:</p>
<ul>
<li>Business contact information (such as your name, phone number, email address and country);</li>
<li>Professional information (such as your job title, institution or company);</li>
<li>Nature of your communication;</li>
<li>Marketing information (such as your contact preferences); and</li>
<li>Any information you choose to provide to us when completing any &lsquo;free text&rsquo; boxes in our forms.</li>
</ul>
<p>(ii) Information we collect automatically through the Email Industries Sites: When you visit our Email Industries Sites or interact with our emails, we and our third-party partners use cookies and similar technologies such as pixels or web beacons, alone or in conjunction with cookies, to collect certain information automatically from your browser or device. In some countries, including countries in the EEA, this information may be considered Personal Information under applicable data protection laws. Our use of cookies and other tracking technologies is discussed more below, and in more detail in our Cookie Statement available here. The information we collect automatically includes:</p>
<ul>
<li>Device information: such as your IP address, your browser, operating system, device information, unique device identifiers, mobile network information, request information (speed, frequency, the site from which you linked to us (&ldquo;referring page&rdquo;), the name of the website you choose to visit immediately after ours (called &ldquo;exit page&rdquo;), information about other websites you have recently visited, the web browser you used (software used to browse the internet) including its type and language), and viewfinder size and scripts errors.</li>
<li>Usage data: such as information about how you interact with our emails, Email Industries Sites, and other websites (such as the pages and files viewed, session duration, links clicked, searches, non-sensitive text entered, mouse movements, operating system and system configuration information and date/time stamps associated with your usage).</li>
</ul>
<p><strong>5B. Use of Personal Information</strong></p>
<p>We may use the information we collect through our Email Industries Sites and in connection with our events and marketing activities (alone or in combination with other data we collect) for a range of reasons in reliance on our legitimate interests, including:</p>
<ul>
<li>To provide, operate, optimize, and maintain the Email Industries Sites.</li>
<li>To send you marketing information, product recommendations and non-transactional communications (e.g., email, telemarketing calls, SMS, or push notifications) about us, in accordance with your marketing preferences, including information about our products, services, promotions or events as necessary for our legitimate interest in conducting direct marketing or to the extent you have provided your prior consent.</li>
<li>For recruitment purposes if you have applied for a role with Email Industries.</li>
<li>To respond to your online inquiries and requests, and to provide you with information and access to resources or services that you have requested from us.</li>
<li>To manage the Email Industries Sites and system administration and security.</li>
<li>To manage event registrations and attendance, including sending related communications to you.</li>
<li>To register visitors to our offices for security reasons and to manage non-disclosure agreements that visitors may be required to sign.</li>
<li>To improve the navigation and content of the Email Industries Sites.</li>
<li>To identify any server problems or other IT or network issues.</li>
<li>To process transactions and to set up online accounts.</li>
<li>To compile aggregated statistics about site usage and to better understand the preferences of our Visitors.</li>
<li>To help us provide, improve and personalize our marketing activities.</li>
<li>To facilitate the security and continued proper functioning of the Email Industries Sites.</li>
<li>To carry out research and development to improve our Email Industries Sites, products and services.</li>
<li>To conduct marketing research, advertise to you, provide personalized information about us on and off our Email Industries Sites, and to provide other personalized content based on your activities and interests to the extent necessary for our legitimate interests in supporting our marketing activities or advertising our Service or instances where we seek your consent.</li>
<li>To carry out other legitimate business purposes, as well as other lawful purposes, such as data analysis, fraud monitoring and prevention, identifying usage trends and expanding our business activities in reliance on our legitimate interests.</li>
<li>To cooperate with public and government authorities, courts or regulators in accordance with our legal obligations under applicable laws to the extent this requires the processing or disclosure of Personal Information to protect our rights or is necessary for our legitimate interest in protecting against misuse or abuse of our Email Industries Sites and Service, protecting personal property or safety, pursuing remedies available to us and limiting our damages, complying with judicial proceedings, court orders or legal processes, or responding to lawful requests.</li>
</ul>
<p><strong>5C. Public Information and Third-Party Websites</strong></p>
<p><strong>Blog.</strong> We have public blogs on the Email Industries Sites. Any information you include in a comment on our blog may be read, collected, and used by anyone. If your Personal Information appears on our blogs and you want it removed, contact us at privacy@emailindustries.com. If we are unable to remove your information, we will tell you why.</p>
<p><strong>Social media platforms.</strong> We maintain presences on social media platforms, including Facebook, Twitter, and Instagram. Any information, communications, or materials you submit to us via a social media platform is done at your own risk without any expectation of privacy. We cannot control the actions of other users of these platforms or the actions of the platforms themselves. Your interactions with those features and platforms are governed by the privacy policies of the companies that provide them</p>
<p><strong>Links to third-party websites.</strong> The Email Industries Sites include links to other websites, whose privacy practices may be different from ours. If you submit Personal Information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.</p>
<p><strong>Contests and sweepstakes. </strong>We may, from time to time, offer surveys, contests, sweepstakes, or other promotions on the Email Industries Sites or through social media (collectively, "Promotions"). Participation in our Promotions is completely voluntary. Information requested for entry may include Personal Information such as your name, address, date of birth, phone number, email address, username, and similar details. We use the information you provide to administer our Promotions. We may also, unless prohibited by the Promotion&rsquo;s rules or law, use the information provided to communicate with you, or other people you select, about our Service. We may share this information with our subsidiaries or Affiliates and other organizations or service providers in line with this privacy policy and the rules posted for our Promotions.</p>
<p><strong>5D. Cookies and Tracking Technologies</strong></p>
<p>We and our third-party partners use cookies and similar tracking technologies to collect and use Personal Information about you, including to serve interest-based advertising about Email Industries and its Affiliates. For further information about the types of cookies and tracking technologies we use, why, and how you can control them, please see our Cookie Statement.</p>
<p><strong>5E. Other Data Protection Rights</strong></p>
<p>Depending on the country in which you reside, you may have the following data protection rights:</p>
<ul>
<li>To access; correct; update; port; delete; restrict or object to our processing of your Personal Information. You can exercise these rights by visiting this page or by emailing <a href="mailto:privacy@emailindustries.com">privacy@emailindustries.com</a>.</li>
<li>You may also have the right to complain to a data protection authority about our collection and use of your Personal Information. For more information, please contact your local data protection authority. Contact details for data protection authorities in the EEA are available here.</li>
</ul>
<p>Similarly, if we have collected and processed your Personal Information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your Personal Information conducted in reliance on lawful processing grounds other than consent. You can also contact us at any time to update your marketing preferences (see Section 5. General Information, C. Your Choices and Opt-Outs below).</p>
<p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. We may ask you to verify your identity in order to help us respond efficiently to your request.</p>
<p><strong>6. General Information</strong></p>
<p><strong>6A. How We Share Information</strong></p>
<p>We may share and disclose your Personal Information with our subsidiaries or Affiliates and to the following types of third parties for the purposes described in this privacy policy (for purposes of this section, "you" and "your" refer to Members, Contacts, and Visitors unless otherwise indicated). Our subsidiaries and Affiliates include Courier Holdings Ltd. located in the United Kingdom. You can learn more about Courier and its privacy practices here.</p>
<p>(i) Our service providers: Sometimes, we share your information with our third-party service providers working on our behalf for the purposes described in this privacy policy. For example, companies we&rsquo;ve hired to help us provide and support our Service or assist in protecting and securing our systems and services and other business-related functions.</p>
<p>Other examples include analyzing data, hosting data, engaging technical support for our Service, processing payments, and delivering content.</p>
<p>We use YouTube's API services in connection with our Service to provide certain features. As such, you acknowledge and agree that by signing up for an account and using the Service, you are also bound by Google's Privacy Policy. In addition to the rights set forth in Section 2, you may manage your YouTube API data by visiting Google's security settings page at https://security.google.com/settings/security/permissions</p>
<p>In connection with our Service, we also use a third-party service provider, Twilio, Inc. We use Twilio's API, which allows us to build features into our Email Industries application to enable us to communicate with our Members through texting and calling, and their "Authy" product, which we use for two-factor authentication for our application. If you are a Member, Twilio may need to collect and process certain Personal Information about you as a controller to provide such services. To learn more about Twilio's privacy practices, please visit https://www.twilio.com/legal/privacy</p>
<p>(ii) Advertising partners: We may partner with third-party advertising networks, exchanges, and social media platforms (like Google and LiveRamp) to display advertising on the Email Industries Sites or to manage and serve our advertising on other sites. We and our third-party partners may use cookies and other similar tracking technologies, such as pixels and web beacons, to gather information about your activities on the Email Industries Sites and other sites in order to provide you with targeted advertising based on your browsing activities and interests. For more information about how we and our third-party partners use cookies, please see our Cookie Statement available here. In certain cases, we may also share limited Personal Information about our Members with our third-party advertising partners. If you prefer us not to share your Personal Information, you can alter the settings on your account as described here.</p>
<p>(iii) Any competent law enforcement body, regulatory body, government agency, court or other third party where we believe disclosure is necessary (a) as a matter of applicable law or regulation, (b) to exercise, establish, or defend our legal rights, or (c) to protect your vital interests or those of any other person.</p>
<p>(iv) A potential buyer (and its agents and advisors) in the case of a sale, merger, consolidation, liquidation, reorganization, or acquisition. In that event, any acquirer will be subject to our obligations under this privacy policy, including your rights to access and choice. We will notify you of the change either by sending you an email or posting a notice on our Email Industries Site.</p>
<p>(v) Any other person with your consent.</p>
<p>We may also share anonymized, aggregated information with selected third parties for statistical purposes.</p>
<p><strong>6B. Legal Basis for Processing Personal Information (EEA and UK Persons Only)</strong></p>
<p>If you are located in the EEA or UK, our legal basis for collecting and using the Personal Information described above will depend on the Personal Information concerned and the specific context in which we collect it.</p>
<p>However, we will normally collect and use Personal Information from you where the processing is in our legitimate interests and not overridden by your data-protection interests or fundamental rights and freedoms. Our legitimate interests are described in more detail in this privacy policy in the sections above titled &ldquo;Use of Personal Information&rdquo;, but they typically include improving, maintaining, providing, and enhancing our technology, products, and services; ensuring the security of the Service and our Email Industries Sites; and supporting our marketing activities.</p>
<p>If you are a Member, we may need the Personal Information to perform a contract with you. In some limited cases, we may also have a legal obligation to collect Personal Information from you. If we ask you to provide Personal Information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your Personal Information is mandatory or not, as well as of the possible consequences if you do not provide your Personal Information.</p>
<p>Where required by law, we will collect Personal Information only where we have your consent to do so.</p>
<p><strong>6C. Your Choices and Opt-Outs</strong></p>
<p>(i) Marketing: Members and Visitors who have opted in to our marketing emails can opt out of receiving marketing emails from us at any time by clicking the "unsubscribe" link at the bottom of our marketing messages.</p>
<p>Also, all opt-out requests can be made by emailing us using the contact details provided in the "Questions and Concerns" section below. Please note that some communications (such as service messages, account notifications, and billing information) are considered transactional and necessary for account management, and Members cannot opt out of these messages unless you cancel your Email Industries account.</p>
<p>(ii) Data Analytics: Our data analytics projects use data from Email Industries accounts, including Personal Information of Members and Contacts, to provide and improve the Service. If you are a Member and prefer not to have your data used for this purpose, you can alter the settings on your account (as described here). If you are a Contact, you can also opt out of data analytics projects at any time by completing this form or emailing us at privacy@emailindustries.com. For further information about our data analytics projects, refer to Section 2, B (for Members) or Section 3, B (for Contacts) above.</p>
<p>(iii) Personalized Advertising: We may share limited Personal Information of our Members with third-party advertising networks, exchanges, and social media platforms (like Google and LiveRamp) to display advertising on the Email Industries Sites or to manage and serve our advertising on other sites. If you are a Member and prefer us not to share your Personal Information, you can alter the settings on your account (as described here). For further information about how we may share your information, refer to Section 5, A above.</p>
<p><strong>6D. Our Security</strong></p>
<p>We take appropriate and reasonable technical and organizational measures designed to protect Personal Information from loss, misuse, unauthorized access, disclosure, alteration, and destruction, taking into account the risks involved in the processing and the nature of the Personal Information. For further information about our security practices, please see our Security page available here. If you have any questions about the security of your Personal Information, you may contact us at privacy@emailindustries.com.</p>
<p>Email Industries accounts require a username and password to log in. Members must keep their username and password secure, and never disclose it to a third party. Because the information in a Member&rsquo;s Email Industries account is private, account passwords are hashed, which means we cannot see a Member&rsquo;s password. We cannot resend forgotten passwords either. We will only provide Members with instructions on how to reset them.</p>
<p><strong>6E. International Transfers</strong></p>
<p>(i) We operate in the United States</p>
<p>Our servers and offices are located in the United States, so your information may be transferred to, stored, or processed in the United States. While the data protection, privacy, and other laws of the United States might not be as comprehensive as those in your country, we take many steps to protect your privacy, including offering our Members a <a href="#data_processing">Data Processing Agreement</a>.</p>
<p>(ii) Data transfers from Switzerland, United Kingdom, or the EEA to the United States</p>
<p>Email Industries participates in the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework. We are committed to subjecting all Personal Information received from EEA member countries, United Kingdom, and Switzerland, respectively.</p>
<p>With respect to Personal Information received or transferred pursuant to the Privacy Shield Frameworks, in certain situations, we may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
<p>If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third-party dispute resolution provider (free of charge to you) at <a href="https://feedback-form.truste.com/watchdog/request" target="_blank" rel="noopener">https://feedback-form.truste.com/watchdog/request</a>.</p>
<p>Members located in Switzerland, United Kingdom, and the EEA are subject to our <a href="#data_processing">Data Processing Addendum</a>, as described in our Standard Terms of Use.</p>
<p>(iii) Members, Contacts and Visitors located in Australia&nbsp;</p>
<p>If you are a Member, Contact or Visitor who accesses our Service in Australia, this section applies to you. We are subject to the operation of the Privacy Act 1988 ("Australian Privacy Act"). Here are the specific points you should be aware of:</p>
<ul>
<li>As stated in our Acceptable Use Policy available here, sensitive personal information is not permitted on Email Industries&rsquo; platform and Members are prohibited from importing or incorporating any sensitive personal information into their Email Industries accounts or uploading any sensitive personal information to Email Industries&rsquo; servers.</li>
<li>Please note that if you do not provide us with your Personal Information or if you withdraw your consent for us to collect, use and disclose your Personal Information, we may be unable to provide the Service to you.</li>
<li>Where we collect Personal Information of our Visitors, the Personal Information we ask you to provide will be information that is reasonably necessary for, or directly related to, one or more of our functions or activities. Please see Section 4, of this privacy policy for examples of the types of Personal Information we may ask Visitors to provide.</li>
<li>Where we say we assume an obligation about Personal Information, we will also require our contractors and subcontractors to undertake a similar obligation.</li>
</ul>
<p>We will not use or disclose Personal Information for the purpose of our direct marketing to you unless:</p>
<ul>
<li>you have consented to receive direct marketing;</li>
<li>you would reasonably expect us to use your personal details for marketing; or</li>
<li>we believe you may be interested in the material but it is impractical for us to obtain your consent.</li>
</ul>
<p>You may opt out of any marketing materials we send to you through an unsubscribe mechanism. If you have requested not to receive further direct marketing messages, we may continue to provide you with messages that are not regarded as "direct marketing" under the Australian Privacy Act, including changes to our terms, system alerts, and other information related to your account as permitted under the Australian Privacy Act and the Spam Act 2003.</p>
<p>Our servers are located in the United States. In addition, we or our subcontractors may use cloud technology to store or process Personal Information, which may result in storage of data outside Australia. It is not practicable for us to specify in advance which country will have jurisdiction over this type of offshore activity. All of our subcontractors, however, are required to comply with the Australian Privacy Act in relation to the transfer or storage of Personal Information overseas.</p>
<p>We may also share your Personal Information outside of Australia to our business operations in other countries. While it is not practicable for us to specify in advance each country where your Personal Information may be disclosed, typically we may disclose your Personal Information to the United States, Canada and the European Union.</p>
<p>You may access the Personal Information we hold about you. If you wish to access your Personal Information, you may do so by visiting this page, or by emailing us at privacy@emailindustries.com. We will respond to all requests for access within a reasonable time.</p>
<p>If you think the information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, we will take reasonable steps, consistent with our obligations under the Australian Privacy Act, to correct that information upon your request. If you find that the information we have is not up to date or is inaccurate or incomplete, please contact us in writing at privacy@emailindustries.com, so we can update our records. We will respond to all requests for correction within a reasonable time.</p>
<p>If you are unsatisfied with our response to a privacy matter, you may consult either an independent advisor or contact the Office of the Australian Information Commissioner for additional help. We will provide our full cooperation if you pursue this course of action.</p>
<p><strong>6F. Retention of Data</strong></p>
<p>We retain Personal Information where we have an ongoing legitimate business or legal need to do so. Our retention periods will vary depending on the type of data involved, but, generally, we'll refer to these criteria in order to determine retention period:</p>
<ul>
<li>Whether we have a legal or contractual need to retain the data.</li>
<li>Whether the data is necessary to provide our Service.</li>
<li>Whether our Members have the ability to access and delete the data within their Email Industries accounts.</li>
<li>Whether our Members would reasonably expect that we would retain the data until they remove it or until their Email Industries accounts are closed or terminated.</li>
<li>When we have no ongoing legitimate business need to process your Personal Information, we will either delete or anonymize it or, if this is not possible (for example, because your Personal Information has been stored in backup archives), then we will securely store your Personal Information and isolate it from any further processing until deletion is possible.</li>
</ul>
<p><strong>6G. California Privacy</strong></p>
<p>The California Consumer Privacy Act (&ldquo;CCPA&rdquo;) provides consumers with specific rights regarding their Personal Information. You have the right to request that businesses subject to the CCPA (which may include our Members with whom you have a relationship) disclose certain information to you about their collection and use of your Personal Information over the past 12 months. In addition, you have the right to ask such businesses to delete Personal Information collected from you, subject to certain exceptions. If the business sells Personal Information, you have a right to opt-out of that sale. Finally, a business cannot discriminate against you for exercising a CCPA right.</p>
<p>When offering services to its Members, Email Industries acts as a &ldquo;service provider&rdquo; under the CCPA and our receipt and collection of any consumer Personal Information is completed on behalf of our Members in order for us to provide the Service. Please direct any requests for access or deletion of your Personal Information under the CCPA to the Member with whom you have a direct relationship.</p>
<p>Consistent with California law, if you choose to exercise your applicable CCPA rights, we won&rsquo;t charge you different prices or provide you a different quality of services. If we ever offer a financial incentive or product enhancement that is contingent upon you providing your Personal Information, we will not do so unless the benefits to you are reasonably related to the value of the Personal Information that you provide to us.</p>
<p><strong>6H. Do not Track</strong></p>
<p>Certain state laws require us to indicate whether we honor &ldquo;Do Not Track&rdquo; settings in your browser. Email Industries adheres to the standards set out in this Privacy Policy and does not monitor or follow any Do Not Track browser requests.</p>
<p><strong>6I. Changes to this Policy</strong></p>
<p>We may change this privacy policy at any time and from time to time. The most recent version of the privacy policy is reflected by the version date located at the top of this privacy policy. All updates and amendments are effective immediately upon notice, which we may give by any means, including, but not limited to, by posting a revised version of this privacy policy or other notice on the Email Industries Sites. We encourage you to review this privacy policy often to stay informed of changes that may affect you. Our electronically or otherwise properly stored copies of this privacy policy are each deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this privacy policy that was in effect on each respective date you visited the Email Industries Site.</p>
<p><strong>6J. Questions &amp; Concerns</strong></p>
<p>If you have any questions or comments, or if you have a concern about the way in which we have handled any privacy matter, please send an email to privacy@emailindustries.com. You may also contact us by postal mail or email at:</p>
<p>For EEA, Swiss and UK Residents:</p>
<p>For the purposes of EU data protection legislation, Indiemark LLC d/b/a Email Industries is the controller of your Personal Information. Our Data Protection Officer can be contacted at privacy@emailindustries.com.</p>
<p>For any other Residents:</p>
<p>Indiemark LLC d/b/a Email Industries</p>
<p>Attn. Privacy Officer</p>
<p>privacy@emailindustries.com</p>
<p>801 Broad Street</p>
<p>Suite 603</p>
<p>Augusta GA 30901&nbsp;</p>
<p>Last Updated: 5/18/2021</p>
<h1 id="api_use_policy">API Use Policy</h1>
<p>Thank you for using the Email Industries application program interfaces (&ldquo;APIs&rdquo;). By accessing the APIs, you agree to this API Use Policy (the &ldquo;Policy&rdquo;) and our Terms. We provide these APIs to allow people to build on and benefit from our Service by creating software, services, or modules that connect to our platform or have access to the data within our platform via our APIs (an &ldquo;Integration&rdquo;). This Policy is and will be treated as part of our Terms. Capitalized terms used and not otherwise defined in this Policy have the meanings given to them in our Terms.</p>
<p><strong>1. Login</strong></p>
<p>You&rsquo;ll only access the API using OAuth or an API key. If you have an Integration, then users must have the option to log in via OAuth or their API key. You won&rsquo;t prompt users to provide their Email Industries username, password, or security questions.</p>
<p><strong>2. Permitted Use</strong></p>
<p>You won&rsquo;t use the API to take any actions that violate our <a href="#acceptable_use">Acceptable Use Policy</a> and our <a href="#terms_of_use">Standard Terms of Use</a>. You will comply with all applicable laws (including privacy laws and United States export control laws and regulations and economic sanctions laws and regulations). You&rsquo;ll follow all documentation we provide for the APIs. You won&rsquo;t attempt to hack or change the way the Service functions. We may throttle your use of the APIs at any time. We may monitor your use of the APIs for compliance with these rules, and we may deny you access to the API if you violate this Policy.</p>
<p><strong>3. Privacy</strong></p>
<p>You&rsquo;ll respect the privacy of our users. You must obtain express permission from each user before you access their Email Industries account. Your Integration must display a privacy policy for users detailing the information you&rsquo;ll collect from them when they use the Integration. You will only access a user&rsquo;s data to the extent permitted by the user and explained in your privacy policy. You must immediately delete a user&rsquo;s data if the user requests deletion or terminates their account with you. Any user data which is collected by Email Industries via your Integration will be treated in accordance with our Privacy Policy.</p>
<p>4. Security</p>
<p>You will implement and maintain appropriate technical and organizational security measures to protect and to preserve the security, integrity and confidentiality of the data of your users. These security measures shall prevent the unauthorized access or disclosure of personal or confidential data that you process. You must promptly report any security deficiencies or security incidents that may impact or compromise our users, APIs, or Service in writing to legal@emailindustries.com. And, if there is a security incident, you will work with us to immediately correct the incident or deficiency.</p>
<p>We reserve the right to review or audit your books, records, agreements, access logs, third-party audit and examination reports, systems, networks, facilities (including physical and remote access to data centers and cloud facilities), controls, policies and procedures related to your Integration to ensure compliance with this Policy. You will promptly correct any security flaws or deficiencies. Upon remediation, you will certify in writing that you are now in compliance.</p>
<p><strong>5. License</strong></p>
<p>We grant you a non-exclusive, non-transferable, non-sublicensable, revocable, limited right to access and use the APIs to develop, test and support your Integration and to distribute or allow access to your Integration to users of your service, provided that you comply with this Policy and all applicable laws. You have no right to distribute or allow access to our stand-alone APIs. We may terminate your access to our APIs at any time for any reason.</p>
<p><strong>6. Ownership</strong></p>
<p>We own all rights, titles, and interest in the Service and the APIs, including all intellectual property rights, marks, code, and features. You won&rsquo;t infringe, reverse engineer, or copy our code, design, or content. You will not access our APIs in order to compete with our Service. Any rights not expressly granted by this Policy are withheld, so if you don&rsquo;t see it here, then it&rsquo;s not a right we&rsquo;re giving you. You own all rights, titles, and interest in the Integration, except for the APIs, our marks, and the Service. If you provide feedback about the APIs or the Service, we may copy, modify, create derivative works, display, disclose, distribute, or use that feedback without any obligation to you.</p>
<p><strong>7. Use of Marks</strong></p>
<p>You may not alter or remove any proprietary notices in our marks. You won&rsquo;t use our name or marks in your Integration name or logo, or in any way that implies an endorsement by us. If you use our marks to create your logo or name, you&rsquo;ll immediately assign those rights to us at no expense.</p>
<p><strong>8. Disclaimer</strong></p>
<p>To the maximum extent permitted by law, we provide the APIs as-is. That means we don&rsquo;t provide warranties of any kind, either express or implied, including but not limited to merchantability and fitness for a particular purpose.</p>
<p><strong>9. Updates</strong></p>
<p>We may update or modify the APIs and this Policy from time to time by posting the changes on this site or notifying you via email. These changes may affect your use of the APIs or the way your Integration interacts with the API. If we make a change that&rsquo;s unacceptable to you, you should stop using the APIs.</p>
<p><strong>10. Confidentiality</strong></p>
<p>You may have access to confidential, proprietary, and non-public information specific to the APIs (&ldquo;Confidential Information&rdquo;). You may use this information only to build with the APIs. You won&rsquo;t disclose the Confidential Information to anyone without our written consent, and you&rsquo;ll protect the Confidential Information from unauthorized use and disclosure in the same way you&rsquo;d protect your own confidential information.</p>
<p><strong>11. Indemnification</strong></p>
<p>You&rsquo;ll indemnify and hold us and our Team harmless from any losses (including attorney fees) that result from third-party claims that relate to your use of the API.</p>
<p><strong>12. The Details</strong></p>
<p>This Policy doesn&rsquo;t create or imply any partnership, agency, or joint venture. This Policy will apply for as long as you use the APIs or until terminated in accordance with our Terms. In the event of a conflict between this Policy and the Standard Terms of Use, the Standard Terms of Use shall control.</p>
<p>Last Updated: 5/18/2021</p>
<h1 id="cookie_statement">Cookie Statement</h1>
<p>Email Industries is owned and operated by Indiemark LLC (&ldquo;Email Industries&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, and &ldquo;our&rdquo;). This Cookie Statement explains how we use cookies and similar technologies in the course of our business, including through our websites that link to this Cookie Statement, such as http://www.alfredknows.com, http://www.blackbox.email, and http://www.emailindustries.com, or any website or mobile application owned, operated or controlled by us (collectively, &ldquo;Email Industries Site(s)&rdquo;), and when our Members&rsquo; end users visit websites or e-commerce stores operated by our Members or open or interact with emails delivered through our Service. It explains what these technologies are and why we use them, as well as your rights to control our use of them. Capitalized terms used and not otherwise defined in this Cookie Statement have the meanings given to those terms in our Privacy Policy or Additional Terms.</p>
<p>In some cases we may use cookies and other tracking technologies described in this Cookie Statement to collect Personal Information, or to collect information that becomes Personal Information if we combine it with other information. For more information about how we process your Personal Information, please consult our Privacy Policy.</p>
<p><strong>1. What are cookies?</strong></p>
<p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by online service providers in order to (for example) make their websites or services work, or to work more efficiently, as well as to provide reporting information.</p>
<p>Cookies set by the website owner or service provider (in this case, Email Industries) are called &ldquo;first-party cookies&rdquo;. Cookies set by parties other than the website owner are called &ldquo;third-party cookies&rdquo;. Third-party cookies enable third-party features or functionality to be provided on or through the website or service you are using (such as advertising, interactive content and analytics). The third parties that set these third-party cookies can recognize your computer both when it visits the website or service in question and also when it visits certain other websites or services.</p>
<p><strong>2. Why do we use cookies and other tracking technologies?</strong></p>
<p>We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Email Industries Sites and Service to operate, and we refer to these as &ldquo;essential&rdquo; or &ldquo;strictly necessary&rdquo; cookies. Other cookies enable us and the third parties we work with to track and target the interests of Visitors to our Email Industries Sites, and we refer to these as &ldquo;performance&rdquo; or &ldquo;functionality&rdquo; cookies. For example, we use cookies to tailor content and information that we may send or display to you and otherwise personalize your experience while interacting with our Email Industries Sites and to otherwise improve the functionality of the Service we provide. We also enable our Members to employ cookies and similar tracking technologies in connection with their use of our Service in order to allow us and our Members to track their Contacts or end users. Finally, third parties serve cookies through our Email Industries Sites and Service for advertising, analytics, and other purposes. This is described in more detail below.</p>
<p><strong>3. Cookies served through our Email Industries Sites</strong></p>
<p>The specific types of first and third-party cookies served through our Email Industries Sites and the purposes they perform are described in further detail below:</p>
<p><strong>4. Essential Website Cookies</strong></p>
<p>These cookies are strictly necessary to provide you with services available through our Email Industries Sites and to use some of its features, such as access to secure areas. Because these cookies are strictly necessary to deliver the Email Industries Sites, you cannot refuse them without impacting how our Email Industries Sites function. You can block or delete them by changing your browser settings, as described under the heading "How can I control cookies?" in this Cookie Statement.</p>
<p><strong>5. Performance and Functionality Cookies</strong></p>
<p>These cookies are used to enhance the performance and functionality of our Email Industries Site. They help us to know which pages are the most and least popular and to see how visitors move around the Email Industries Site. Although important to us, these cookies are non-essential to the use of the Email Industries Site. However, without these cookies, certain functionality may become unavailable.</p>
<p><strong>6. Analytics and Customization Cookies</strong></p>
<p>These cookies collect information that is used either to help us understand how our Email Industries Sites are being used or how effective our marketing campaigns are, or to help us customize our Email Industries Sites and application for you in order to enhance your experience. These cookies may be set by us or by third-party providers whose services we have added to our pages.</p>
<p><strong>7. Advertising (Targeting) Cookies</strong></p>
<p>These cookies are used to make advertising messages more relevant to you and your interests. They also perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting advertisements that are based on your interests. For further information, see the section of the Cookie Statement entitled "Targeted online advertising".</p>
<p><strong>8. Other tracking technologies</strong></p>
<p>We and our third-party partners may use other, similar technologies from time to time, like web beacons, SDKs, pixels (or &ldquo;clear gifs&rdquo;) and other tracking technologies. Pixels are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Email Industries Sites or, in the case of web beacons, opened an email that we have sent them. This allows us, for example, to monitor the traffic patterns of Visitors from one page within our Email Industries Sites to another, to deliver or communicate with cookies, to understand whether you have come to our Email Industries Sites from an online advertisement displayed on a third-party website, to serve targeted advertisements to you and others like you, to improve site performance, and to measure the success of marketing campaigns. While you may not have the ability to specifically reject or disable these tracking technologies, in many instances, these technologies are reliant on cookies to function properly; accordingly, in those instances, declining cookies will impair the functionality of these technologies.</p>
<p><strong>9. Targeted online advertising</strong></p>
<p>We have engaged one or more third-party service providers to track and analyze both individualized usage and volume statistical information from interactions with our Email Industries Sites. We also partner with one or more third parties (such as advertisers and ad networks) to display advertising on our Email Industries Sites and/or to manage and serve advertising on other sites.</p>
<p>These third parties use cookies, web beacons, pixel tags and similar tracking technologies to collect and use certain information about your online activities, either on our Email Industries Sites and/or other sites or mobile apps, to infer your interests and deliver you targeted advertisements that are more tailored to you based on your browsing activities and inferred interests (&ldquo;Interest Based Advertising&rdquo;). For more information about this practice, click here: <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">http://www.aboutads.info/choices/</a>.</p>
<p>Our third-party service providers may also use cookies or web beacons to collect information about your visits to our Email Industries Sites and/or other sites to measure and track the effectiveness of advertisements and our online marketing activities (for example, by collecting data on how many times you click on one of our ads).</p>
<p>Facebook: We also use the pixel function of Facebook Custom Audiences to identify users who have landed on certain pages for the purposes of target group-orientated remarketing and advertising. The Facebook pixel stores a cookie, which means that information about your visit to our Email Industries Site will be shared with Facebook. Also, as part of the "advanced matching" undertaken by Facebook, other personal information (such as telephone number, email address, Facebook ID) may be shared with Facebook in a protected (hashed) form. These cookies and the information collected are used to create target groups to help Facebook deliver advertising on our behalf to users who have previously visited our Email Industries Site when they are on Facebook or a digital platform powered by Facebook Advertising, and to track advertising success. You can modify your Facebook Ad settings to restrict these advertisements. Navigate to Settings/Adverts in your Facebook account to adjust your preferences. Learn more about the different types of Facebook cookies here: <a href="https://www.facebook.com/policies/cookies/" target="_blank" rel="noopener">https://www.facebook.com/policies/cookies/</a></p>
<p><strong>10. How can I control cookies?</strong></p>
<p>You have the right to decide whether to accept or reject cookies.</p>
<p>Browser Controls: You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our Email Industries Sites though your access to some functionality and areas of our Email Industries Sites may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser&rsquo;s help menu for more information.</p>
<p>Disabling Most Interest Based Advertising: Most advertising networks offer you a way to opt out of Interest Based Advertising. If you would like to find out more information, please visit http://www.aboutads.info/choices/ or http://www.youronlinechoices.com.</p>
<p>Mobile Advertising: You can opt out of having your mobile advertising identifiers used for certain types of Interest Based Advertising, including those performed by us, by accessing the settings in your Apple or Android mobile device and following the most recent published instructions. If you opt out, we will remove all data about you and no further data collection or tracking will occur. The random ID we (or our third-party partners) had previously assigned to you will also be removed. This means that if at a later stage, you decide to opt-in, we will not be able to continue and track you using the same ID as before, and you will for all practical purposes be a new end user to our system.</p>
<p>Do Not Track: Some Internet browsers - like Internet Explorer, Firefox, and Safari - include the ability to transmit &ldquo;Do Not Track&rdquo; or &ldquo;DNT&rdquo; signals. Since uniform standards for &ldquo;DNT&rdquo; signals have not been adopted, our Email Industries Sites, Connected Sites, Landing Pages, and Websites do not currently process or respond to &ldquo;DNT&rdquo; signals. Email Industries takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard. To learn more about &ldquo;DNT&rdquo;, please visit All About Do Not Track.</p>
<p><strong>11. How often will you update this Cookie Statement?</strong></p>
<p>We may update this Cookie Statement from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Statement regularly to stay informed about our use of cookies and related technologies.</p>
<p><strong>12. Where can I get further information?</strong></p>
<p>If you have any questions about our use of cookies or other technologies, please email us at <a href="mailto:privacy@emailindustries.com">privacy@emailindustries.com</a>.</p>
<p>Last Updated: 5/18/2021</p>
<h1 id="data_processing">Data Processing Addendum</h1>
<p>This Data Processing Addendum ("DPA") is incorporated into, and is subject to the terms and conditions of, the Agreement between Indiemark LLC d/b/a Email Industries (together with its Affiliates, &ldquo;Email Industries&rdquo;) and the customer entity that is a party to the Agreement ("Customer" or "you").</p>
<p>All capitalized terms not defined in this DPA shall have the meanings set forth in the Agreement. For the avoidance of doubt, all references to the "Agreement" shall include this DPA (including the SCCs (where applicable), as defined herein).</p>
<p><strong>1. Definitions</strong></p>
<p>"Affiliate" means an entity that directly or indirectly Controls, is Controlled by or is under common Control with an entity.</p>
<p>"Agreement" means Email Industries&rsquo; Standard Terms of Use, or other written or electronic agreement, which govern the provision of the Service to Customer, as such terms or agreement may be updated from time to time.</p>
<p>"Control" means an ownership, voting or similar interest representing fifty percent (50%) or more of the total interests then outstanding of the entity in question. The term "Controlled" shall be construed accordingly.</p>
<p>"Customer Data" means any personal data that Email Industries processes on behalf of Customer via the Service, as more particularly described in this DPA.</p>
<p>"Data Protection Laws" means all data protection laws and regulations applicable to a party's processing of Customer Data under the Agreement, including, where applicable, EU Data Protection Law and Non-EU Data Protection Laws.</p>
<p>"EU Data Protection Law" means all data protection laws and regulations applicable to Europe, including (i) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation) ("GDPR"); (ii) Directive 2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications sector; (iii) applicable national implementations of (i) and (ii); and (iii) in respect of the United Kingdom ("UK") any applicable national legislation that replaces or converts in domestic law the GDPR or any other law relating to data and privacy as a consequence of the UK leaving the European Union).</p>
<p>"Europe" means, for the purposes of this DPA, the European Union, the European Economic Area and/or their member states, Switzerland and the United Kingdom.</p>
<p>&ldquo;Non-EU Data Protection Laws&rdquo; means the California Consumer Privacy Act (&ldquo;CCPA&rdquo;); the Canadian Personal Information Protection and Electronic Documents Act (&ldquo;PIPEDA&rdquo;); the Brazilian General Data Protection Law ("LGPD"), Federal Law no. 13,709/2018; and the Privacy Act 1988 (Cth) of Australia, as amended ("Australian Privacy Law").</p>
<p>"Privacy Shield" means the EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield Framework self-certification program operated by the U.S. Department of Commerce.</p>
<p>"Privacy Shield Principles" means the Privacy Shield Principles (as supplemented by the Supplemental Principles).</p>
<p>"SCCs" means the standard contractual clauses for processors as approved by the European Commission or Swiss Federal Data Protection Authority (as applicable).</p>
<p>"Security Incident" means any unauthorized or unlawful breach of security that leads to the accidental or unlawful destruction, loss, or alteration of, or unauthorized disclosure of or access to, Customer Data on systems managed or otherwise controlled by Email Industries.</p>
<p>"Sensitive Data" means (a) social security number, tax file number, passport number, driver's license number, or similar identifier (or any portion thereof); (b) credit or debit card number (other than the truncated (last four digits) of a credit or debit card); (c) employment, financial, credit, genetic, biometric or health information; (d) racial, ethnic, political or religious affiliation, trade union membership, information about sexual life or sexual orientation, or criminal record; (e) account passwords; or (f) other information that falls within the definition of "special categories of data" under applicable Data Protection Laws.</p>
<p>"Sub-processor" means any processor engaged by Email Industries or its Affiliates to assist in fulfilling its obligations with respect to providing the Service pursuant to the Agreement or this DPA. Sub-processors may include third parties or Affiliates of Email Industries but shall exclude Email Industries employees, contractors, or consultants.</p>
<p>The terms "personal data", "controller", "data subject", "processor" and "processing" shall have the meaning given to them under applicable Data Protection Laws or if not defined thereunder, the GDPR, and "process", "processes" and "processed", with respect to any Customer data, shall be interpreted accordingly.</p>
<p><strong>2. Roles and Responsibilities</strong></p>
<p><strong>2.1 Parties&rsquo; roles. </strong>If EU Data Protection Law or the LGPD applies to either party's processing of Customer Data, the parties acknowledge and agree that with regard to the processing of Customer Data, Customer is the controller and Email Industries is a processor acting on behalf of Customer, as further described in Annex A (Details of Data Processing) of this DPA. For the avoidance of doubt, this DPA shall not apply to instances where Email Industries is the controller (as defined by EU Data Protection Law) unless otherwise described in Annex D hereto.</p>
<p><strong>2.2 Purpose limitation.</strong> Email Industries shall process Customer Data only in accordance with Customer&rsquo;s documented lawful instructions as set forth in this DPA, as necessary to comply with applicable law, or as otherwise agreed in writing ("Permitted Purposes"). The parties agree that the Agreement sets out Customer&rsquo;s complete and final instructions to Email Industries in relation to the processing of Customer Data, and processing outside the scope of these instructions (if any) shall require prior written agreement between the parties.</p>
<p><strong>2.3 Prohibited data. </strong>Customer will not provide (or cause to be provided) any Sensitive Data to Email Industries for processing under the Agreement, and Email Industries will have no liability whatsoever for Sensitive Data, whether in connection with a Security Incident or otherwise. For the avoidance of doubt, this DPA will not apply to Sensitive Data.</p>
<p><strong>2.4 Customer compliance.</strong> Customer represents and warrants that (i) it has complied, and will continue to comply, with all applicable laws, including Data Protection Laws, in respect of its processing of Customer Data and any processing instructions it issues to Email Industries; and (ii) it has provided, and will continue to provide, all notice and has obtained, and will continue to obtain, all consents and rights necessary under Data Protection Laws for Email Industries to process Customer Data for the purposes described in the Agreement. Customer shall have sole responsibility for the accuracy, quality, and legality of Customer Data and the means by which Customer acquired Customer Data. Without prejudice to the generality of the foregoing, Customer agrees that it shall be responsible for complying with all laws (including Data Protection Laws) applicable to any Campaigns (as defined in the Agreement) or other content created, sent or managed through the Service, including those relating to obtaining consents (where required) to send emails, the content of the emails and its email deployment practices.</p>
<p><strong>2.5 Lawfulness of Customer's instructions. </strong>Customer will ensure that Email Industries's processing of the Customer Data in accordance with Customer&rsquo;s instructions will not cause Email Industries to violate any applicable law, regulation, or rule, including, without limitation, Data Protection Laws. Email Industries shall promptly notify Customer in writing, unless prohibited from doing so under EU Data Protection Laws, if it becomes aware or believes that any data processing instruction from Customer violates the GDPR or any UK implementation of the GDPR.</p>
<p><strong>3. Sub-processing</strong></p>
<p><strong>3.1 Authorized Sub-processors</strong>. Customer agrees that Email Industries may engage Sub-processors to process Customer Data on Customer's behalf. The Sub-processors currently engaged by Email Industries and authorized by Customer are available <a href="https://mailchimp.com/legal/subprocessors/" target="_blank" rel="noopener">here</a>. Email Industries shall notify Customer if it adds or removes Sub-processors at least 10 days prior to any such changes if Customer opts in to receive such notifications.</p>
<p><strong>3.2 Sub-processor obligations. </strong>Email Industries shall: (i) enter into a written agreement with each Sub-processor containing data protection obligations that provide at least the same level of protection for Customer Data as those in this DPA, to the extent applicable to the nature of the service provided by such Sub-processor; and (ii) remain responsible for such Sub-processor&rsquo;s compliance with the obligations of this DPA and for any acts or omissions of such Sub-processor that cause Email Industries to breach any of its obligations under this DPA.</p>
<p><strong>4. Security</strong></p>
<p><strong>4.1 Security Measures.</strong> Email Industries shall implement and maintain appropriate technical and organizational security measures that are designed to protect Customer Data from Security Incidents and designed to preserve the security and confidentiality of Customer Data in accordance with Email Industries's security standards described in Annex B ("Security Measures").</p>
<p><strong>4.2 Confidentiality of processing. </strong>Email Industries shall ensure that any person who is authorized by Email Industries to process Customer Data (including its staff, agents and subcontractors) shall be under an appropriate obligation of confidentiality (whether a contractual or statutory duty).</p>
<p><strong>4.3 Updates to Security Measures.</strong> Customer is responsible for reviewing the information made available by Email Industries relating to data security and making an independent determination as to whether the Service meets Customer&rsquo;s requirements and legal obligations under Data Protection Laws. Customer acknowledges that the Security Measures are subject to technical progress and development and that Email Industries may update or modify the Security Measures from time to time, provided that such updates and modifications do not result in the degradation of the overall security of the Service provided to Customer.</p>
<p><strong>4.4 Security Incident response.</strong> Upon becoming aware of a Security Incident, Email Industries shall: (i) notify Customer without undue delay, and where feasible, in any event no later than 48 hours from becoming aware of the Security Incident; (ii) provide timely information relating to the Security Incident as it becomes known or as is reasonably requested by Customer; and (iii) promptly take reasonable steps to contain and investigate any Security Incident. Email Industries's notification of or response to a Security Incident under this Section 4.4 shall not be construed as an acknowledgment by Email Industries of any fault or liability with respect to the Security Incident.</p>
<p><strong>4.5 Customer responsibilities.</strong> Notwithstanding the above, Customer agrees that except as provided by this DPA, Customer is responsible for its secure use of the Service, including securing its account authentication credentials, protecting the security of Customer Data when in transit to and from the Service, and taking any appropriate steps to securely encrypt or backup any Customer Data uploaded to the Service.</p>
<p><strong>5. Security Reports and Audits</strong></p>
<p><strong>5.1 Audit rights.</strong> Email Industries shall make available to Customer all information reasonably necessary to demonstrate compliance with this DPA and allow for and contribute to audits, including inspections by Customer in order to assess compliance with this DPA. Customer acknowledges and agrees that it shall exercise its audit rights under this DPA (including this Section 5.1 and where applicable, the SCCs) and any audit rights granted by Data Protection Laws, by instructing Email Industries to comply with the audit measures described in Sections 5.2 and 5.3 below.</p>
<p><strong>5.2 Security reports</strong>. Customer acknowledges that Email Industries is regularly audited against SSAE 16 and PCI standards by independent third party auditors and internal auditors respectively. Upon written request, Email Industries shall supply (on a confidential basis) a summary copy of its most current audit report(s) ("Report") to Customer, so that Customer can verify Email Industries' compliance with the audit standards against which it has been assessed and this DPA.</p>
<p><strong>5.3 Security due diligence. </strong>In addition to the Report, Email Industries shall respond to all reasonable requests for information made by Customer to confirm Email Industries' compliance with this DPA, including responses to information security, due diligence, and audit questionnaires, by making additional information available regarding its information security program upon Customer&rsquo;s written request to privacy@emailindustries.com, provided that Customer shall not exercise this right more than once per calendar year.</p>
<p><strong>6. International Transfers</strong></p>
<p><strong>6.1 Data center locations.</strong> Subject to Section 6.2, Customer acknowledges that Email Industries may transfer and process Customer Data to and in the United States and anywhere else in the world where Email Industries, its Affiliates or its Sub-processors maintain data processing operations. Email Industries shall at all times ensure that such transfers are made in compliance with the requirements of Data Protection Laws and this DPA.</p>
<p><strong>6.2 Australian data.</strong> To the extent that Email Industries is a recipient of Customer Data protected by the Australian Privacy Law, the parties acknowledge and agree that Email Industries may transfer such Customer Data outside of Australia as permitted by the terms agreed upon by the parties and subject to Email Industries complying with this DPA and the Australian Privacy Law.</p>
<p><strong>6.3 European Data transfers. </strong>To the extent that Email Industries is a recipient of Customer Data protected by EU Data Protection Laws ("EU Data") in a country outside of Europe that is not recognized as providing an adequate level of protection for personal data (as described in applicable EU Data Protection Law), the parties agree to the following:</p>
<ul>
<li>(a) SCCs: Email Industries agrees to abide by and process EU Data in compliance with the SCCs in the form set out in Annex C. For the purposes of the descriptions in the SCCs, Email Industries agrees that it is the "data importer" and Customer is the "data exporter" (notwithstanding that Customer may itself be an entity located outside Europe).</li>
<li>(b) Privacy Shield: Although Email Industries does not rely on the EU-US Privacy Shield as a legal basis for transfers of Customer Data in light of the judgement of the Court of Justice of the EU in Case C-311/18, for as long as Email Industries is self-certified to the Privacy Shield: (i) Email Industries agrees to process EU Data in compliance with the Privacy Shield Principles and (ii) if Email Industries is unable to comply with this requirement, Email Industries shall inform Customer.</li>
</ul>
<p><strong>6.4 Alternative transfer mechanism. </strong>To the extent Email Industries adopts an alternative data export mechanism (including any new version of or successor to the SCCs or Privacy Shield) for the transfer of EU Data not described in this DPA ("Alternative Transfer Mechanism"), the Alternative Transfer Mechanism shall apply instead of the transfer mechanisms described in this DPA (but only to the extent such Alternative Transfer Mechanism complies with applicable EU Data Protection Law and extends to the countries to which EU Data is transferred). In addition, if and to the extent that a court of competent jurisdiction or supervisory authority orders (for whatever reason) that the measures described in this DPA cannot be relied on to lawfully transfer EU Data (within the meaning of applicable EU Data Protection Law), Email Industries may implement any additional measures or safeguards that may be reasonably required to enable the lawful transfer of EU Data.</p>
<p><strong>7. Return or Deletion of Data</strong></p>
<p>Deletion or return on termination. Upon termination or expiration of the Agreement, Email Industries shall (at Customer's election) delete or return to Customer all Customer Data (including copies) in its possession or control, except that this requirement shall not apply to the extent Email Industries is required by applicable law to retain some or all of the Customer Data, or to Customer Data it has archived on back-up systems, which Customer Data Email Industries shall securely isolate, protect from any further processing and eventually delete in accordance with Email Industries' deletion policies, except to the extent required by applicable law.</p>
<p><strong>8. Data Subject Rights and Cooperation</strong></p>
<p><strong>8.1 Data subject requests.</strong> As part of the Service, Email Industries provides Customer with a number of self-service features, that Customer may use to retrieve, correct, delete or restrict the use of Customer Data, which Customer may use to assist it in connection with its obligations under the Data Protection Laws with respect to responding to requests from data subjects via Customer's account at no additional cost. In addition, Email Industries shall, taking into account the nature of the processing, provide reasonable additional assistance to Customer to the extent possible to enable Customer to comply with its data protection obligations with respect to data subject rights under Data Protection Laws. In the event that any such request is made to Email Industries directly, Email Industries shall not respond to such communication directly except as appropriate (for example, to direct the data subject to contact Customer) or legally required, without Customer's prior authorization. If Email Industries is required to respond to such a request, Email Industries shall promptly notify Customer and provide Customer with a copy of the request unless Email Industries is legally prohibited from doing so. For the avoidance of doubt, nothing in the Agreement (including this DPA) shall restrict or prevent Email Industries from responding to any data subject or data protection authority requests in relation to personal data for which Email Industries is a controller.</p>
<p><strong>8.2 Data protection impact assessment.</strong> To the extent required under applicable Data Protection Laws, Email Industries shall (taking into account the nature of the processing and the information available to Email Industries) provide all reasonably requested information regarding the Service to enable Customer to carry out data protection impact assessments or prior consultations with data protection authorities as required by Data Protection Laws. Email Industries shall comply with the foregoing by: (i) complying with Section 5 (Security Reports and Audits); (ii) providing the information contained in the Agreement, including this DPA; and (iii) if the foregoing sub-sections (i) and (ii) are insufficient for Customer to comply with such obligations, upon request, providing additional reasonable assistance (at Customer's expense).</p>
<p><strong>9. Jurisdiction-Specific Terms</strong></p>
<p>To the extent Email Industries processes Customer Data originating from and protected by Data Protection Laws in one of the jurisdictions listed in Annex D, then the terms specified in Annex D with respect to the applicable jurisdiction(s) (&ldquo;Jurisdiction-Specific Terms&rdquo;) apply in addition to the terms of this DPA. In the event of any conflict or ambiguity between the Jurisdiction-Specific Terms and any other terms of this DPA, the applicable Jurisdiction-Specific Terms will take precedence, but only to the extent of the Jurisdiction-Specific Terms&rsquo; applicability to Email Industries.</p>
<p><strong>10. Limitation of Liability</strong></p>
<p><strong>10.1</strong> Each party&rsquo;s and all of its Affiliates&rsquo; liability taken together in the aggregate arising out of or related to this DPA (including the SCCs) shall be subject to the exclusions and limitations of liability set forth in the Agreement.</p>
<p><strong>10.2 </strong>Any claims made against Email Industries or its Affiliates under or in connection with this DPA (including, where applicable, the SCCs) shall be brought solely by the Customer entity that is a party to the Agreement.</p>
<p><strong>10.3</strong> In no event shall any party limit its liability with respect to any individual's data protection rights under this DPA or otherwise.</p>
<p><strong>11. Relationship with the Agreement</strong></p>
<p><strong>11.1 </strong>This DPA shall remain in effect for as long as Email Industries carries out Customer Data processing operations on behalf of Customer or until termination of the Agreement (and all Customer Data has been returned or deleted in accordance with Section 7.1 above).</p>
<p><strong>11.2 </strong>The parties agree that this DPA shall replace any existing data processing agreement or similar document that the parties may have previously entered into in connection with the Service.</p>
<p><strong>11.3</strong> In the event of any conflict or inconsistency between this DPA and the Email Industries Standard Terms of Use, the provisions of the following documents (in order of precedence) shall prevail: (i) SCCs; then (ii) this DPA; and then (iii) the Email Industries Standard Terms of Use.</p>
<p><strong>11.4</strong> Except for any changes made by this DPA, the Agreement remains unchanged and in full force and effect.</p>
<p><strong>11.5 </strong>No one other than a party to this DPA, its successors and permitted assigns shall have any right to enforce any of its terms.</p>
<p><strong>11.6 </strong>This DPA shall be governed by and construed in accordance with the governing law and jurisdiction provisions in the Agreement, unless required otherwise by applicable Data Protection Laws.</p>
<p><strong>Annex A &ndash; Details of Data Processing</strong></p>
<p>(a) Controller (data exporter): Customer, being a Email Industries Member (as defined in the Email Industries Privacy Policy) that has engaged Email Industries to provide the Service under the Agreement.&nbsp;</p>
<p>(b) Processor (data importer): Email Industries, a South Carolina limited liability company, whose legal name is Indiemark LLC d/b/a Email Industries.</p>
<p>(c) Subject matter: The subject matter of the data processing under this DPA is the Customer Data.</p>
<p>(d) Duration of processing: Email Industries will process Customer Data as outlined in Section 7 (Return or Deletion of Data) of this DPA.</p>
<p>(e) Purpose of processing: Email Industries shall only process Customer Data for the Permitted Purposes, which shall include: (i) processing as necessary to provide the Service in accordance with the Agreement; (ii) processing initiated by Customer in its use of the Service; and (iii) processing to comply with any other reasonable instructions provided by Customer (e.g., via email or support tickets) that are consistent with the terms of the Agreement.</p>
<p>(f) Nature of the processing: Email Industries provides an email service, automation and marketing platform and other related services, as more particularly described in the Agreement.</p>
<p>(g) Categories of data subjects: (i) Members and (ii) Contacts, each as defined in the Email Industries Privacy Policy.</p>
<p>(h) Types of Customer Data: Customer may upload, submit or otherwise provide certain personal data to the Service, the extent of which is typically determined and controlled by Customer in its sole discretion, and may include the following types of personal data:</p>
<ul>
<li>Members: Identification and contact data (name, address, title, contact details, username); financial information (credit card details, account details, payment information); employment details (employer, job title, geographic location, area of responsibility);</li>
<li>Contacts: Identification and contact data (name, date of birth, gender, general, occupation or other demographic information, address, title, contact details, including email address); personal interests or preferences (including purchase history, marketing preferences and publicly available social media profile information); IT information (IP addresses, usage data, cookies data, online navigation data, location data, browser data); financial information (credit card details, account details, payment information).</li>
</ul>
<p>(i) Sensitive Data: Email Industries does not want to, nor does it intentionally, collect or process any Sensitive Data in connection with the provision of the Service.</p>
<p>(j) Processing Operations: Customer Data will be processed in accordance with the Agreement (including this DPA) and may be subject to the following processing activities:</p>
<ul>
<li>Storage and other processing necessary to provide, maintain and improve the Service provided to Customer pursuant to the Agreement; and/or</li>
<li>Disclosures in accordance with the Agreement and/or as compelled by applicable law.</li>
</ul>
<p><strong>Annex B &ndash; Security Measures</strong></p>
<p>The Security Measures applicable to the Service are described here (as updated from time to time in accordance with Section 4.3 of this DPA).</p>
<p><strong>Annex C &ndash; Standard Contractual Clauses</strong></p>
<p><strong>Standard contractual clauses. </strong>2010 Standard contractual clauses for the transfer of personal data from the Community to third countries (controller to processor transfers)</p>
<p>For the purposes of Article 26(2) of Directive 95/46/EC for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection, Email Industries a South Carolina limited liability company whose legal name is Indiemark LLC d/b/a Email Industries (hereinafter the "data importer") and Customer (hereinafter the "data exporter") each a &ldquo;party&rdquo;; together &ldquo;the parties&rdquo;, HAVE AGREED on the following Contractual Clauses (the Clauses) in order to adduce adequate safeguards with respect to the protection of privacy and fundamental rights and freedoms of individuals for the transfer by the data exporter to the data importer of the personal data specified in Annex 1.</p>
<p><strong>Clause 1</strong></p>
<p><strong>Definitions. </strong>For the purposes of the Clauses:</p>
<ul>
<li>'personal data', 'special categories of data', 'process/processing', 'controller', 'processor', 'data subject' and 'supervisory authority' shall have the same meaning as in Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 on the protection of individuals with regard to the processing of personal data and on the free movement of such data;</li>
<li>'the data exporter' means the controller who transfers the personal data;</li>
<li>'the data importer' means the processor who agrees to receive from the data exporter personal data intended for processing on his behalf after the transfer in accordance with his instructions and the terms of the Clauses and who is not subject to a third country's system ensuring adequate protection within the meaning of Article 25(1) of Directive 95/46/EC;</li>
<li>'the subprocessor' means any processor engaged by the data importer or by any other subprocessor of the data importer who agrees to receive from the data importer or from any other subprocessor of the data importer personal data exclusively intended for processing activities to be carried out on behalf of the data exporter after the transfer in accordance with his instructions, the terms of the Clauses and the terms of the written subcontract;</li>
<li>'the Data Protection Law' means the legislation protecting the fundamental rights and freedoms of individuals and, in particular, their right to privacy with respect to the processing of personal data applicable to a data controller in the Member State in which the data exporter is established;</li>
<li>'technical and organisational security measures' means those measures aimed at protecting personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing.</li>
</ul>
<p><strong>Clause 2</strong></p>
<p><strong>Details of the transfer. </strong>The details of the transfer and in particular the special categories of personal data where applicable are specified in Appendix 1 which forms an integral part of the Clauses.</p>
<p><strong>Clause 3</strong></p>
<p>Third-party beneficiary clause.</p>
<ol>
<li>The data subject can enforce against the data exporter this Clause, Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j), Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as third-party beneficiary.</li>
<li>The data subject can enforce against the data importer this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where the data exporter has factually disappeared or has ceased to exist in law unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law, as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity.</li>
<li>The data subject can enforce against the subprocessor this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.</li>
<li>The parties do not object to a data subject being represented by an association or other body if the data subject so expressly wishes and if permitted by national law.</li>
</ol>
<p><strong>Clause 4</strong></p>
<p><strong>Obligations of the data exporter. </strong>The data exporter agrees and warrants:</p>
<ul>
<li>that the processing, including the transfer itself, of the personal data has been and will continue to be carried out in accordance with the relevant provisions of the Data Protection Law (and, where applicable, has been notified to the relevant authorities of the Member State where the data exporter is established) and does not violate the relevant provisions of that State;</li>
<li>that it has instructed and throughout the duration of the personal data processing services will instruct the data importer to process the personal data transferred only on the data exporter's behalf and in accordance with the Data Protection Law and the Clauses;</li>
<li>that the data importer will provide sufficient guarantees in respect of the technical and organisational security measures specified in Appendix 2 to this contract;</li>
<li>that after assessment of the requirements of the Data Protection Law, the security measures are appropriate to protect personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing, and that these measures ensure a level of security appropriate to the risks presented by the processing and the nature of the data to be protected having regard to the state of the art and the cost of their implementation;</li>
<li>that it will ensure compliance with the security measures; that, if the transfer involves special categories of data, the data subject has been informed or will be informed before, or as soon as possible after, the transfer that its data could be transmitted to a third country not providing adequate protection within the meaning of Directive 95/46/EC;</li>
<li>to forward any notification received from the data importer or any subprocessor pursuant to Clause 5(b) and Clause 8(3) to the data protection supervisory authority if the data exporter decides to continue the transfer or to lift the suspension;</li>
<li>to make available to the data subjects upon request a copy of the Clauses, with the exception of Appendix 2, and a summary description of the security measures, as well as a copy of any contract for subprocessing services which has to be made in accordance with the Clauses, unless the Clauses or the contract contain commercial information, in which case it may remove such commercial information;</li>
<li>that, in the event of subprocessing, the processing activity is carried out in accordance with Clause 11 by a subprocessor providing at least the same level of protection for the personal data and the rights of data subject as the data importer under the Clauses; and</li>
<li>that it will ensure compliance with Clause 4(a) to (i).</li>
</ul>
<p><strong>Clause 5</strong></p>
<p><strong>Obligations of the data importer. </strong>The data importer agrees and warrants:</p>
<ul>
<li>to process the personal data only on behalf of the data exporter and in compliance with its instructions and the Clauses; if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;</li>
<li>that it has no reason to believe that the legislation applicable to it prevents it from fulfilling the instructions received from the data exporter and its obligations under the contract and that in the event of a change in this legislation which is likely to have a substantial adverse effect on the warranties and obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as it is aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;</li>
<li>that it has implemented the technical and organisational security measures specified in Appendix 2 before processing the personal data transferred;</li>
<li>that it will promptly notify the data exporter about:</li>
<ul>
<li>any legally binding request for disclosure of the personal data by a law enforcement authority unless otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation,</li>
<li>any accidental or unauthorised access, and</li>
<li>any request received directly from the data subjects without responding to that request, unless it has been otherwise authorised to do so;</li>
</ul>
<li>to deal promptly and properly with all inquiries from the data exporter relating to its processing of the personal data subject to the transfer and to abide by the advice of the supervisory authority with regard to the processing of the data transferred;</li>
<li>at the request of the data exporter to submit its data processing facilities for audit of the processing activities covered by the Clauses which shall be carried out by the data exporter or an inspection body composed of independent members and in possession of the required professional qualifications bound by a duty of confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;</li>
<li>to make available to the data subject upon request a copy of the Clauses, or any existing contract for subprocessing, unless the Clauses or contract contain commercial information, in which case it may remove such commercial information, with the exception of Appendix 2 which shall be replaced by a summary description of the security measures in those cases where the data subject is unable to obtain a copy from the data exporter;</li>
<li>that, in the event of subprocessing, it has previously informed the data exporter and obtained its prior written consent;</li>
<li>that the processing services by the subprocessor will be carried out in accordance with Clause 11;</li>
<li>to send promptly a copy of any subprocessor agreement it concludes under the Clauses to the data exporter.</li>
</ul>
<p><strong>Clause 6</strong></p>
<p><strong>Liability</strong></p>
<ol>
<li>The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations referred to in Clause 3 or in Clause 11 by any party or subprocessor is entitled to receive compensation from the data exporter for the damage suffered.</li>
<li>If a data subject is not able to bring a claim for compensation in accordance with paragraph 1 against the data exporter, arising out of a breach by the data importer or his subprocessor of any of their obligations referred to in Clause 3 or in Clause 11, because the data exporter has factually disappeared or ceased to exist in law or has become insolvent, the data importer agrees that the data subject may issue a claim against the data importer as if it were the data exporter, unless any successor entity has assumed the entire legal obligations of the data exporter by contract of by operation of law, in which case the data subject can enforce its rights against such entity. The data importer may not rely on a breach by a subprocessor of its obligations in order to avoid its own liabilities.</li>
<li>If a data subject is not able to bring a claim against the data exporter or the data importer referred to in paragraphs 1 and 2, arising out of a breach by the subprocessor of any of their obligations referred to in Clause 3 or in Clause 11 because both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, the subprocessor agrees that the data subject may issue a claim against the data subprocessor with regard to its own processing operations under the Clauses as if it were the data exporter or the data importer, unless any successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law, in which case the data subject can enforce its rights against such entity. The liability of the subprocessor shall be limited to its own processing operations under the Clauses.</li>
</ol>
<p><strong>Clause 7</strong></p>
<p><strong>Mediation and jurisdiction.</strong></p>
<ol>
<li>The data importer agrees that if the data subject invokes against it third-party beneficiary rights and/or claims compensation for damages under the Clauses, the data importer will accept the decision of the data subject:</li>
<ul>
<li>to refer the dispute to mediation, by an independent person or, where applicable, by the supervisory authority;</li>
<li>to refer the dispute to the courts in the Member State in which the data exporter is established.</li>
</ul>
<li>The parties agree that the choice made by the data subject will not prejudice its substantive or procedural rights to seek remedies in accordance with other provisions of national or international law.</li>
</ol>
<p><strong>Clause 8</strong></p>
<p><strong>Cooperation with supervisory authorities.</strong></p>
<ol>
<li>The data exporter agrees to deposit a copy of this contract with the supervisory authority if it so requests or if such deposit is required under the Data Protection Law.</li>
<li>The parties agree that the supervisory authority has the right to conduct an audit of the data importer, and of any subprocessor, which has the same scope and is subject to the same conditions as would apply to an audit of the data exporter under the Data Protection Law.</li>
<li>The data importer shall promptly inform the data exporter about the existence of legislation applicable to it or any subprocessor preventing the conduct of an audit of the data importer, or any subprocessor, pursuant to paragraph 2. In such a case the data exporter shall be entitled to take the measures foreseen in Clause 5 (b).</li>
</ol>
<p><strong>Clause 9</strong></p>
<p><strong>Governing law. </strong>The Clauses shall be governed by the law of the Member State in which the data exporter is established.</p>
<p><strong>Clause 10</strong></p>
<p><strong>Variation of the contract. </strong>The parties undertake not to vary or modify the Clauses. This does not preclude the parties from adding clauses on business related issues where required as long as they do not contradict the Clause.</p>
<p><strong>Clause 11</strong></p>
<p><strong>Subprocessing.</strong></p>
<ol>
<li>The data importer shall not subcontract any of its processing operations performed on behalf of the data exporter under the Clauses without the prior written consent of the data exporter. Where the data importer subcontracts its obligations under the Clauses, with the consent of the data exporter, it shall do so only by way of a written agreement with the subprocessor which imposes the same obligations on the subprocessor as are imposed on the data importer under the Clauses. Where the subprocessor fails to fulfil its data protection obligations under such written agreement the data importer shall remain fully liable to the data exporter for the performance of the subprocessor's obligations under such agreement.</li>
<li>The prior written contract between the data importer and the subprocessor shall also provide for a third-party beneficiary clause as laid down in Clause 3 for cases where the data subject is not able to bring the claim for compensation referred to in paragraph 1 of Clause 6 against the data exporter or the data importer because they have factually disappeared or have ceased to exist in law or have become insolvent and no successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.</li>
<li>The provisions relating to data protection aspects for subprocessing of the contract referred to in paragraph 1 shall be governed by the law of the Member State in which the data exporter is established.</li>
<li>The data exporter shall keep a list of subprocessing agreements concluded under the Clauses and notified by the data importer pursuant to Clause 5 (j), which shall be updated at least once a year. The list shall be available to the data exporter's data protection supervisory authority.</li>
</ol>
<p><strong>Clause 12</strong></p>
<p><strong>Obligation after the termination of personal data processing services.</strong></p>
<ol>
<li>The parties agree that on the termination of the provision of data processing services, the data importer and the subprocessor shall, at the choice of the data exporter, return all the personal data transferred and the copies thereof to the data exporter or shall destroy all the personal data and certify to the data exporter that it has done so, unless legislation imposed upon the data importer prevents it from returning or destroying all or part of the personal data transferred. In that case, the data importer warrants that it will guarantee the confidentiality of the personal data transferred and will not actively process the personal data transferred anymore.</li>
<li>The data importer and the subprocessor warrant that upon request of the data exporter and/or of the supervisory authority, it will submit its data processing facilities for an audit of the measures referred to in paragraph 1.</li>
</ol>
<p><strong>APPENDIX 1 TO THE STANDARD CONTRACTUAL CLAUSES</strong></p>
<p>Details of the transfer: Please see the details set forth in Annex A to the Data Processing Addendum ("DPA") to which these Clauses are appended.</p>
<p><strong>APPENDIX 2 TO THE STANDARD CONTRACTUAL CLAUSES</strong></p>
<p>Description of the technical and organisational security measures implemented by the data importer in accordance with Clauses 4(d) and 5(c) (or document/legislation attached): Please see Annex B &ndash; Security Measures</p>
<p><strong>APPENDIX 3 TO STANDARD CONTRACTUAL CLAUSES</strong></p>
<p>The parties acknowledge that Clause 10 of the Clauses permits them to include additional business-related terms provided they do not contradict with the Clauses. Accordingly, this Appendix sets out the parties' interpretation of their respective obligations under specific Clauses identified below. Where a party complies with the interpretations set out in this Appendix, that party shall be deemed by the other party to have complied with its commitments under the Clauses.</p>
<p>Clauses 4(h) and 8: Disclosure of these Clauses</p>
<ol>
<li>Data exporter agrees that these Clauses constitute data importer's Confidential Information (as that term is defined in the Agreement) and may not be disclosed by data exporter to any third party without data importer's prior written consent unless permitted pursuant to the Agreement. This shall not prevent disclosure of these Clauses to a data subject pursuant to Clause 4(h) or a supervisory authority pursuant to Clause 8.</li>
</ol>
<p>Clause 5(a) and Clause 5(b): Suspension of data transfers and termination</p>
<ol>
<li>The parties acknowledge that for the purposes of Clause 5(a), data importer may process the personal data only on behalf of the data exporter and in compliance with its documented instructions as set out in the DPA and that pursuant to the DPA, these instructions shall be the data exporter&rsquo;s complete and final instructions.</li>
<li>The parties acknowledge that if data importer cannot provide compliance in accordance with Clause 5(a) and/or Clause 5(b), the data importer agrees to promptly inform the data exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the affected parts of the Service in accordance with the terms of the Agreement.</li>
<li>If the data exporter intends to suspend the transfer of personal data and/or terminate the affected parts of the Service, it shall first provide notice to the data importer and provide data importer with a reasonable period of time to cure the non-compliance (&ldquo;Cure Period&rdquo;).</li>
<li>In addition, the data exporter and data importer shall reasonably cooperate with each other during the Cure Period to agree what additional safeguards or other measures, if any, may be reasonably required to ensure the data importer's compliance with the Clauses and applicable data protection law.</li>
<li>If, after the Cure Period, the data importer has not or cannot cure the non-compliance in accordance with the paragraphs 3 and 4 above, then the data exporter may suspend and/or terminate the affected part of the Service in accordance with the provisions of the Agreement without liability to either party (but without prejudice to any fees incurred by the data exporter prior to suspension or termination).</li>
</ol>
<p>Clause 5(f): Audit</p>
<ol>
<li>Data exporter acknowledges and agrees that it exercises its audit right under Clause 5(f) by instructing data importer to comply with the audit measures described in Section 5 (Security Reports and Audits) of the DPA.</li>
</ol>
<p>Clause 5(j): Disclosure of subprocessor agreements</p>
<ol>
<li>The parties acknowledge the obligation of the data importer to send promptly a copy of any onward subprocessor agreement it concludes under the Clauses to the data exporter.</li>
<li>The parties further acknowledge that, pursuant to subprocessor confidentiality restrictions, data importer may be restricted from disclosing onward subprocessor agreements to data exporter. Notwithstanding this, data importer shall use reasonable efforts to require any subprocessor it appoints to permit it to disclose the subprocessor agreement to data exporter.</li>
<li>Even where data importer cannot disclose a subprocessor agreement to data exporter, the parties agree that, upon the request of data exporter, data importer shall (on a confidential basis) provide all information it reasonably can in connection with such subprocessing agreement to data exporter.</li>
</ol>
<p>Clause 6: Liability</p>
<ol>
<li>Any claims brought under the Clauses shall be subject to the terms and conditions, including but not limited to, the exclusions and limitations set forth in the Agreement. In no event, shall any party limit its liability with respect to any data subject rights under these Clauses.</li>
</ol>
<p>Clause 11: Onward subprocessing</p>
<ol>
<li>The parties acknowledge that, pursuant to FAQ II.1 in Article 29 Working Party Paper WP 176 entitled "FAQs in order to address some issues raised by the entry into force of the EU Commission Decision 2010/87/EU of 5 February 2010 on standard contractual clauses for the transfer of personal data to processors established in third countries under Directive 95/46/EC" the data exporter may provide a general consent to onward subprocessing by the data importer.</li>
<li>Accordingly, data exporter provides a general consent to data importer, pursuant to Clause 11 of these Clauses, to engage onward subprocessors. Such consent is conditional on data importer&rsquo;s compliance with the requirements set out in Section 3 (Sub-processing) of the DPA.</li>
</ol>
<p><strong>Annex D &ndash; Jurisdiction-Specific Terms</strong></p>
<p>Europe:</p>
<ol>
<li><strong>Objection to Sub-processors. </strong>Customer may object in writing to Email Industries&rsquo; appointment of a new Sub-processor within five (5) calendar days of receiving notice in accordance with Section 3.1 of DPA, provided that such objection is based on reasonable grounds relating to data protection. In such an event, the parties shall discuss such concerns in good faith with a view to achieving a commercially reasonable resolution. If no such resolution can be reached, Email Industries will, at its sole discretion, either not appoint such Sub-processor, or permit Customer to suspend or terminate the affected Service in accordance with the termination provisions in the Agreement without liability to either party (but without prejudice to any fees incurred by Customer prior to suspension or termination).</li>
<li><strong>Government data access requests. </strong>As a matter of general practice, Email Industries does not voluntarily provide government agencies or authorities (including law enforcement) with access to or information about Email Industries accounts (including Customer Data). If Email Industries receives a compulsory request (whether through a subpoena, court order, search warrant, or other valid legal process) from any government agency or authority (including law enforcement) for access to or information about a Email Industries account (including Customer Data) belonging to a Customer whose primary contact information indicates the Customer is located in Europe, Email Industries shall: (i) inform the government agency that Email Industries is a processor of the data; (ii) attempt to redirect the agency to request the data directly from Customer; and (iii) notify Customer via email sent to Customer&rsquo;s primary contact email address of the request to allow Customer to seek a protective order or other appropriate remedy. As part of this effort, Email Industries may provide Customer&rsquo;s primary and billing contact information to the agency. Email Industries shall not be required to comply with this paragraph 2 if it is legally prohibited from doing so, or it has a reasonable and good-faith belief that urgent access is necessary to prevent an imminent risk of serious harm to any individual, public safety, or Email Industries&rsquo; property, Sites, or Service.</li>
</ol>
<p>UK:</p>
<ol>
<li>For the avoidance of doubt, when European Union law ceases to apply to the UK upon the UK's withdrawal from the European Union and until such time as the UK is deemed to provide adequate protection for personal data (within the meaning of applicable EU Data Protection Law) then to the extend Email Industries processes (or causes to be processed) any Customer Data protected by EU Data Protection Law applicable to EEA and Switzerland in the United Kingdom, Email Industries shall process such Customer Data in compliance with the SCCs or any applicable Alternative Transfer Mechanism implemented in accordance with Section 6.3 and 6.4 of this DPA.</li>
</ol>
<p>California:</p>
<ol>
<li>Except as described otherwise, the definitions of: &ldquo;controller&rdquo; includes &ldquo;Business&rdquo;; "processor" includes &ldquo;Service Provider&rdquo;; &ldquo;data subject&rdquo; includes &ldquo;Consumer&rdquo;; &ldquo;personal data&rdquo; includes &ldquo;Personal Information&rdquo;; in each case as defined under CCPA.</li>
<li>For this &ldquo;California&rdquo; section of Annex D only, &ldquo;Email Industries Services&rdquo; means the suite of marketing tools and insights available for Email Industries Customers to use, including without limitation, email campaign management, advertisements, and direct mailings and other related digital communications, analytics and tools made available through the Email Industries online marketing platform, as may be further described in the App and/or on the Email Industries Site.</li>
<li>For this &ldquo;California&rdquo; section of Annex D only, &ldquo;Permitted Purposes&rdquo; shall include processing Customer Data only for the purposes described in this DPA and in accordance with Customer&rsquo;s documented lawful instructions as set forth in this DPA, as necessary to comply with applicable law, as otherwise agreed in writing, including, without limitation, in the Agreement, or as otherwise may be permitted for &ldquo;service providers&rdquo; under the CCPA.</li>
<li>Email Industries&rsquo; obligations regarding data subject requests, as described in Section 8 (Data Subject Rights and Cooperation) of this DPA, apply to Consumer&rsquo;s rights under the CCPA.</li>
<li>Notwithstanding any use restriction contained elsewhere in this DPA, Email Industries shall process Customer Data only to perform the Email Industries Services, for the Permitted Purposes and/or in accordance with Customer&rsquo;s documented lawful instructions, except where otherwise required by applicable law.</li>
<li>Email Industries may de-identify or aggregate Customer Data as part of performing the Service specified in this DPA and the Agreement.</li>
<li>Where Sub-processors process the personal data of Customer contacts, Email Industries takes steps to ensure that such Sub-processors are Service Providers under the CCPA with whom Email Industries has entered into a written contract that includes terms substantially similar to this DPA or are otherwise exempt from the CCPA&rsquo;s definition of &ldquo;sale&rdquo;. Email Industries conducts appropriate due diligence on its Sub-processors.</li>
</ol>
<p>Canada:</p>
<ol>
<li>Email Industries takes steps to ensure that Email Industries's Sub-processors, as described in the DPA, are third parties under PIPEDA, with whom Email Industries has entered into a written contract that includes terms substantially similar to this DPA. Email Industries conducts appropriate due diligence on its Sub-processors.</li>
<li>Email Industries will implement technical and organizational measures as set forth in the DPA.</li>
</ol>
<p>Last Updated: 5/18/2021</p>
 <h1 id="partner_agreement">Partner Agreement</h1>
<p>Please read these Terms (&ldquo;Terms&rdquo;) carefully. By joining or participating in Email Industries&rsquo; Partner Programs (the &ldquo;Program&rdquo;), accessing the Email Industries Partner dashboard (the &ldquo;Center&rdquo;), or otherwise accepting these terms within the Email Industries platform, you are agreeing to these Terms, which will result in a legal agreement between you (&ldquo;you&rdquo; or &ldquo;your&rdquo;) and Indiemark LLC d/b/a Email Industries (&ldquo;Email Industries&rdquo;) (collectively, the &ldquo;Parties,&rdquo; and individually, a &ldquo;Party&rdquo;). These Terms govern your participation in the Program. If you accept these Terms on behalf of a company or other entity, you represent and warrant that you have the authority to accept and enter into these Terms on its behalf.</p>
<p><strong>1. Relationship Between the Parties.</strong> The Parties agree that, notwithstanding the existence of the Program and your membership in the Program, there is not and will not be any partnership, joint venture, employer-employee, master-servant or franchisor-franchisee relationship, in a legal sense, between the Parties. Each Party agrees to indemnify and hold the other harmless from any and all claims and losses, including reasonable attorneys&rsquo; fees, that may be incurred resulting from such Party asserting or indicating that there is such a relationship between the Parties.</p>
<p><strong>2. Program Participation. </strong>Your participation in the Program is subject to your agreement to these Terms, including, without limitation, the following:</p>
<p>The Program consists of certain tiers of membership (each, a &ldquo;Tier&rdquo;). Each Tier has distinct qualifiers and benefits, which Email Industries may update from time to time. Your current Tier, and information about the benefits associated with that Tier, will be made available to you. You must at all times meet the minimum requirements for participation in your applicable Tier, and you will not at any time misrepresent your Tier or associated qualifications. If you fail at any time to meet the minimum requirements for participation in your current Tier, your applicable Tier may be altered accordingly or your membership in the Program may be terminated (as applicable).</p>
<p>Some Program features, benefits or activities are subject to certain additional terms, below (&ldquo;Additional Terms&rdquo;), which you must periodically review to ensure your compliance with any applicable Additional Terms. The Additional Terms are incorporated into these Terms by reference, and in the event of a conflict or inconsistency between these Terms and any Additional Terms, the Additional Terms will govern.</p>
<p>Email Industries may elect from time to time to provide you access to certain resources associated with the Program depending on your Tier, which may include a partner badge, certification badges and access to Email Industries Academy (collectively, &ldquo;Program Resources&rdquo;); in the event that Email Industries elects to make any Program Resources available to you, you acknowledge and agree that (i) you will comply with any terms and conditions, codes of conduct and the like which are associated with the use of any such Program Resources, and (ii) Email Industries may withdraw, restrict or remove your access to such Program Resources at any time in Email Industries&rsquo; discretion.</p>
<p>Email Industries may provide a dedicated Slack workspace for Program members in certain Tiers (the &ldquo;Slack Workspace&rdquo;). If your Program Resources include access to the Slack Workspace, you acknowledge and agree that: (a) Slack Workspace access can be revoked and/or individual posts can be deleted or removed at any time without prior notice at Email Industries&rsquo; sole discretion; (b) Slack Workspace participants must provide their name and company; (c) Slack Workspace participants cannot speak on behalf of Email Industries or misrepresent their relationship with Email Industries; (d) Slack Workspace participants will treat other participants with courtesy and respect, and will not communicate with anyone or leverage Slack Workspace features in a discriminatory or harassing manner; (e) posting ads or using the Slack Workspace to sell or promote services or products is not allowed; (f) sharing personal information about anyone other than yourself is absolutely prohibited; (g) Email Industries employees may be participants in the Slack Workspace, but Email Industries is not required to participate in or monitor the Slack Workspace in any manner; and (h) Email Industries will not intervene in or resolve any disputes between Slack Workspace participants.</p>
<p>You will not make any false or misleading statements about Email Industries, the Program, or Email Industries&rsquo; Service (as defined in Email Industries&rsquo; Standard Terms of Use; however, you will not be liable for any statements Email Industries provides to you in the Program Resources.</p>
<p><strong>You will comply with the Email Industries Code of Conduct. </strong>As consideration for Email Industries providing you access to the proprietary features of the Program, the Center and the Program Resources, you will not use the Program, the Center or any Program Resources (i) to sell or promote services or products of any kind to other members of the Program or (ii) to sell or promote services or products which are similar to or compete with any of Email Industries&rsquo; services or products to Email Industries users.</p>
<p>Email Industries may from time to time evaluate your compliance with any Program requirements or qualifications, and you will reasonably cooperate with Email Industries in connection with the same.</p>
<p>You are not eligible for membership in the Program if you are an employee of Email Industries or any of its affiliates.</p>
<p>You are responsible for understanding and complying with all applicable laws, rules, and regulations and determining whether participation in the Program is suitable for you in light of such laws, rules, and regulations.</p>
<p><strong>3. Term and Termination. </strong>This agreement continues until terminated by one of the Parties, in which case such termination of these Terms will be effective immediately; in your case, you may terminate this agreement and your participation in the Program at any time by opting out of the Program in your account. Upon termination, your access to the Program, the Center and any Program Resources may be immediately revoked. For the avoidance of doubt, any termination of these Terms will result in the immediate and automatic termination of any then-applicable Additional Terms.</p>
<p><strong>4. Confidentiality and Data. </strong>Each Party needs to assure protection of its Confidential Information. The term &ldquo;Confidential Information&rdquo; will be information that has value and is held and treated as confidential by the disclosing Party, whether disclosed in oral, written, electronic or other format, and that is marked and/or otherwise identified in advance as confidential by the disclosing Party, or would be understood by a reasonable person as confidential.</p>
<p>Email Industries&rsquo; Confidential Information includes, but is not limited to the following:&nbsp;</p>
<ul>
<li>Financial information relating to Email Industries&rsquo; operations, including, but not limited to, charging methodologies, billings, revenues and expenses;</li>
<li>Email Industries&rsquo; source code;</li>
<li>Concepts contained in Email Industries&rsquo; source code and hardware;</li>
<li>Designs, flowcharts, and algorithms for and relating to Email Industries&rsquo; software and hardware and planned software and hardware;</li>
<li>Email Industries&rsquo; competitive intelligence;</li>
<li>Email Industries&rsquo; marketing information, plans and strategies;</li>
<li>Email Industries&rsquo; business plans and strategies;</li>
<li>Information about Email Industries&rsquo; products and product functionality, including information obtained via alpha and beta releases, webinars, and the like; and</li>
<li>Any non-public information.</li>
</ul>
<p>Confidential Information does not include any information that:</p>
<ul>
<li>Is or becomes publicly known through no wrongful act of the receiving Party;</li>
<li>The receiving Party can demonstrate by written evidence was rightfully in such Party&rsquo;s possession prior to the disclosing Party&rsquo;s disclosure; or</li>
<li>Was or is received from a third party without any restriction regarding confidentiality.</li>
</ul>
<p>Each Party agrees not to use or disclose any of the other Party&rsquo;s Confidential Information, except for the other Party&rsquo;s benefit and for the purpose of furthering the other Party&rsquo;s business. Each Party further agrees that it will exercise best efforts to prevent the disclosure of such Confidential Information except for permitted purposes and then only to such Party&rsquo;s employees or representatives who have signed an agreement agreeing to be bound by the confidentiality provisions of these Terms or another enforceable agreement with the same, or substantially the same, terms.</p>
<p>All Confidential Information will remain the exclusive property of the disclosing Party. Each Party acknowledges that breaching or threatening to breach the confidentiality obligations under these Terms will cause irreparable harm to the disclosing Party. Therefore, in addition to any other rights or remedies available to the disclosing Party, the disclosing Party will also have the right to seek an injunction against the receiving Party without posting any security or proving actual damages. The receiving Party also agrees to be liable for any breaches of these Terms by any party to whom the receiving Party discloses Confidential Information.</p>
<p>Each Party agrees that all Confidential Information that constitutes a trade secret under applicable law will remain protected under these Terms for so long as such information constitutes a trade secret under applicable law. All other Confidential Information will remain protected under these Terms until the earlier of (i) when the information is no longer confidential or (ii) five (5) years from the date the information is first received.</p>
<p>ANY INFORMATION PROVIDED UNDER THESE TERMS IS PROVIDED &ldquo;AS IS.&rdquo; The disclosing Party will have no liability to the receiving Party or any other party relating to the receiving Party&rsquo;s use of Confidential Information or from any errors or omissions in the Confidential Information, or any business decisions made by the receiving Party in reliance on any Confidential Information.</p>
<p>Notwithstanding the foregoing, in the event that either Party receives a subpoena or other validly issued administrative or judicial process requesting any of the disclosing Party&rsquo;s Confidential Information, such Party will promptly notify the disclosing Party and tender to the disclosing Party the defense of such demand. Unless the demand will be timely limited, quashed or extended, the receiving Party will thereafter be entitled to comply with that demand to the extent required by law. If the disclosing Party so requests, the receiving Party will cooperate, at the disclosing Party&rsquo;s expense, in the defense of such demand.</p>
<p>Upon either Party&rsquo;s written request, any information, materials, or documents of the disclosing Party made available to the receiving Party pursuant to these Terms will be promptly returned or destroyed by the receiving Party as per request of the disclosing Party, including all copies of such information, materials, or documentation.</p>
<p>If the receiving Party discovers that any Confidential Information was used or shared in violation of these Terms, the receiving Party will immediately notify the disclosing Party, take all commercially reasonable actions to minimize the impact of the use or sharing, and take all necessary steps to prevent further breach of these Terms.</p>
<p>You acknowledge that in connection with the processing of any personal data undertaken in relation to these Terms, the Program or a Email Industries user or customer (&ldquo;User&rdquo;): (i) Non-European Data Protection Legislation may apply; and/or (ii) European Data Protection Legislation may apply.</p>
<p>You will comply with all applicable laws, including Non-European Data Protection Legislation and European Data Protection Legislation.</p>
<p>If Non-European Data Protection Legislation applies to your processing of any personal data in relation to these Terms or the Program (e.g., User names and contact details) on your own behalf, you acknowledge that it will be subject to any obligations imposed on it under Non-European Data Protection Legislation with respect to such processing.</p>
<p>If European Data Protection Legislation applies to your processing of any personal data in relation to these Terms or the Program (e.g., User names and contact details) as a controller, you acknowledge that it will be subject to the obligations imposed on a controller under European Data Protection Legislation with respect to such processing, including the obligation to provide appropriate privacy notices to data subjects with respect to any processing of their personal data.</p>
<p>If Non-European Data Protection Legislation or European Data Protection Legislation applies to your processing of any personal data on behalf of any User (including where that User is a controller under European Data Protection Legislation), you acknowledge that it will be subject to the obligations imposed on it with respect to such processing under Non-European Data Protection Legislation or European Data Protection Legislation or both, as applicable.</p>
<p>Nothing in these Terms will make either party the processor (within the meaning of European Data Protection Legislation) of the other party.</p>
<p>&ldquo;European Data Protection Legislation&rdquo; means all data protection laws and regulations applicable to Europe, including (i) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation) (&ldquo;GDPR&rdquo;); (ii) Directive 2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications sector; (iii) applicable national implementations of (i) and (ii); and (iv) in respect of the United Kingdom (&ldquo;UK&rdquo;) any applicable national legislation that replaces or converts in domestic law the GDPR or any other law relating to data and privacy as a consequence of the UK leaving the European Union. &ldquo;Europe&rdquo; means the European Union, the European Economic Area and/or their member states, Switzerland and the United Kingdom. The terms &ldquo;processing&rdquo;, &ldquo;personal data&rdquo;, &ldquo;processor&rdquo; and &ldquo;controller&rdquo; as used in these Terms have the meanings given in European Data Protection Legislation.</p>
<p>&ldquo;Non-European Data Protection Legislation&rdquo; means any applicable data protection and privacy legislation, guidelines and industry standards, other than European Data Protection Legislation.</p>
<p><strong>5. Use of Marks. </strong>You hereby grant Email Industries a royalty free, non-exclusive, worldwide license to use your name and marks, including but not limited to all trademarks, on Email Industries&rsquo; websites and all marketing materials for the purpose of discussing or promoting the Program (including, without limitation, your membership in the Program), including any portions of the Program more particularly described in any Additional Terms, during the term.</p>
<p><strong>6. Proprietary Rights.</strong> You agree that as between you and Email Industries, Email Industries owns all right, title and interest in and to the intellectual property rights related to or arising out of the Email Industries API(s) (defined below), Email Industries&rsquo; Service and Email Industries&rsquo; websites and apps, including, without limitation, all copyrights, trademarks, trade secrets and any other proprietary rights inherent in the foregoing. In the event that you access and use one or more of Email Industries&rsquo; open application program interfaces (&ldquo;Email Industries API(s)&rdquo;) which Email Industries may choose to make available from time to time, you acknowledge and agree that such use and access, and any of your permitted development on or related to the Email Industries API(s), (i) is subject to Email Industries&rsquo; API Use Policy, and (ii) will not prohibit or restrict Email Industries&rsquo; use, alteration and/or development of the Email Industries API(s), Email Industries&rsquo; Service, Email Industries&rsquo; websites and apps or any related features and products in any manner whatsoever.</p>
<p>Except as explicitly stated in Section 5 or in any Additional Terms, nothing in these Terms or in any Additional Terms will be construed to grant either Party an ownership right in or license to intellectual property, Confidential Information, or services of the other Party.</p>
<p><strong>7. Notices. </strong>All notices will be in writing, will be emailed and will be deemed given, received and effective when delivered by email transmission, except as otherwise provided in these Terms. The following e-mail addresses will be used for each Party for these purposes: (a) for Email Industries, privacy@emailindustries.com; and (b) for you, the email address of the owner of your Email Industries account.</p>
<p><strong>8. Severability. </strong>The unenforceability or invalidity of any term, provision, section or subsection of these Terms will not affect the validity or enforceability of any remaining terms, provisions, sections or subsections of these Terms, but such remaining terms, provisions, sections or subsections will be interpreted in such a manner as to fully carry out the intention of the Parties.</p>
<p><strong>9. Amendment; Modifications. </strong>Email Industries reserves the right to change these Terms at any time by posting revised Terms here, and changes will be effective upon posting. Your continued participation in the Program constitutes your agreement to, and acceptance of, any updated Terms. If you do not agree to any changes to these Terms, you must terminate these Terms and discontinue your participation in the Program. You must periodically review these Terms(including any applicable Additional Terms) to ensure compliance with any updates. Email Industries may also modify, suspend or discontinue the Program at any time, and Email Industries may limit, suspend, or terminate your access to the Program at any time, with or without cause.</p>
<p><strong>10. Assignment. </strong>These Terms will be binding upon and inure to the benefit of each Party. You will not, directly or indirectly, through any sale, assignment, merger or other transaction, sublicense, assign, or otherwise transfer these Terms, any Additional Terms, or any of your rights or obligations under these Terms or any Additional Terms to anyone else. Email Industries may assign or transfer these Terms, any Additional Terms, or any of Email Industries&rsquo; rights or obligations under these Terms or any Additional Terms to any other individual or entity at Email Industries&rsquo; sole discretion.</p>
<p><strong>11. Waiver. </strong>The waiver by Email Industries of a breach of any term, provision, section or subsection of these Terms will not be construed as a waiver of any subsequent breach of the same, or of a different term, provision, section or subsection.</p>
<p><strong>12. Governing Law and Jurisdiction. </strong>These Terms will be governed by and construed in accordance with the laws of the State of South Carolina. Further, the Parties consent to exclusive jurisdiction and venue of the United States District Court for South Carolina or the state Courts of Aiken County, South Carolina.</p>
<p><strong>13. Entire Agreement. </strong>These Terms, together with any Additional Terms, embody the entire agreement of the Parties and supersede all prior understandings and agreements between the Parties related to the Program or any partnership between Email Industries and you. These Terms do not replace Email Industries&rsquo; Standard Terms of Use and Privacy Policy, and the Standard Terms of Use and Privacy Policy continue to apply to your use, as a Member (as defined in Email Industries&rsquo; Standard Terms of Use), of Email Industries&rsquo; Service.</p>
<p><strong>14. Limitation of Liability. </strong>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, MAILCHIMP MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING MAILCHIMP&rsquo;S SERVICE OR THE PROGRAM, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. Email Industries is not responsible for unintelligible, lost, late, damaged, incomplete submissions for interrupted or unavailable satellite, network, server, ISP, website, or other connections availability, accessibility or traffic congestion, miscommunications, failed computer, network, telephone, satellite or cable hardware or software or lines, or technical failure, or jumbled, scrambled, delayed or misdirected transmissions, computer hardware or software malfunctions, failures or difficulties, or other errors of any kind whether human, mechanical, electronic or network. Except for damages resulting from the breach of any representations or warranties or confidentiality obligations, fraud or fraudulent misrepresentation, infringement of another party&rsquo;s intellectual property rights, or any indemnities contained in these Terms(including any Additional Terms), neither Party will be liable for special, incidental, consequential, indirect, punitive, or other similar damages, even if a Party or any of its employees or representatives has been advised of the possibility of such damages. Email Industries&rsquo; liability to you will be limited to any fees paid to you by Email Industries during the twelve (12) month period immediately preceding the event giving rise to the claim for damages. In no event will Email Industries have any liability to you or any third party with respect to your own products or services.</p>
<p><strong>15. Indemnification. </strong>You hereby agree to indemnify and hold Email Industries and Email Industries&rsquo; members, managers, officers, successors, assigns, employees, partners, agents, licensees and sub-licensees (collectively, the &ldquo;Email Industries Parties&rdquo;) harmless from any losses or damages, including, but not limited to, reasonable attorneys&rsquo; fees, arising from any claim caused by,regarding or related to a breach by you of these Terms (including any Additional Terms).</p>
<p><strong>16. Feedback. </strong>You may from time to time provide Email Industries, or Email Industries may solicit from you, suggestions or comments for enhancements or improvements, new features or functionality or other feedback (&ldquo;Feedback&rdquo;) with respect to Email Industries&rsquo; Service or the Program. Email Industries will have full discretion to determine whether or not to proceed with the development of any requested enhancements, new features or functionality. Email Industries will have the full unencumbered right to use, incorporate and otherwise fully exercise and exploit any such Feedback in connection with Email Industries&rsquo; Service or the Program.</p>
<p><strong>17. Competitive Ideas. </strong>Email Industries is not limited, prohibited or precluded from developing, performing, providing or marketing concepts, systems, techniques, products or services that are similar to or compete with any concepts, systems, techniques, products or services (a) described or contained in the information you submit or make available to Email Industries in connection with the Program (subject to Section 4 above) or (b) otherwise developed, performed, provided or marketed by you.</p>
<p><strong>18. Licenses and Approvals. </strong>You will obtain and maintain all licenses, permits and approvals required to enter into these Terms and perform your obligations hereunder. You represent and warrant that entering into and performing your obligations under these Terms does not breach any agreement to which you are a party or violate the rights of any third party.</p>
<p><strong>19. No Exclusivity. </strong>You have no authority to enter into any agreements, obligations or commitments on Email Industries&rsquo; behalf, or to negotiate the terms of any customer agreements with Email Industries. These Terms will not be construed to be a commitment by either Party to work exclusively with the other Party regarding referrals of potential new business or any other business activities of any kind.</p>
<p><strong>20. Economic Sanctions. </strong>You are not (nor are any of your subsidiaries, affiliates, directors, or officers) the subject of any sanctions administered by the Office of Foreign Assets Control of the U.S. Department of the Treasury, the U.S. State Department, the United Nations Security Council, the European Union, or other relevant sanctions authority; in addition, you are not, and you will not use the Program or any Program Resources to provide services to persons, based in Cuba, Iran, North Korea, Syria, or any other territory that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a &ldquo;terrorist-supporting&rdquo; country. For so long as these Terms are in effect, you will promptly notify Email Industries if any of these circumstances change.</p>
<p><strong>21. Survival. </strong>Any provisions of these Terms or any Additional Terms which by their nature are intended to survive will survive termination or expiration of these Terms.</p>
<p>Last Updated: 5/18/2021</p>
`;
