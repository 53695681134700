<template>
    <DialogContainer
    :isOpen="isOpen"
    :onClose="closeModal"
    :maxWidth="'2xl'"
    :maxHeight="'4xl !important'"
  >
  <template v-slot:content>
    <div :class="classes">
            <div class="text-center sm:text-left p-2">
                <div class="text-xl text-gray-900 mb-2">Check an individual email address</div>
                <div class="flex h-10">
                <input class="form-field-no-icon pr-12 w-full" v-model="email" placeholder="Enter email"
                    autocomplete="off" @keydown="checkEnter"/>
                    <div class="lg:mr-5 lg:mb-2">
                <button type="button" :disabled="isLoading" class="button-submit ml-3 mt-0 relative" @click="submit">
                    <span v-if="isLoading">
                        <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                    </span>
                    Check
                </button>
            </div>
            </div>
            </div>
            <!-- <div class="p-2 2xl:ml-10 ml-0">
                 <div class="flex pt-3">
                    <div v-for="option in options" :key="option.value" class="px-2 whitespace-nowrap">
                        <input type="radio" :id="'list_' + option.value" :name="listType" :value="option.value"
                            v-model="selectedOption" class="mb-1" />
                        <span class="text-sm text-black px-2 pr-4 text-center max-w-sm truncate">{{ option.label }}</span>
                    </div>
                </div> 
            </div> -->
            
        </div>
        <div>
            <RealTimeEmailDetails v-show="showRealTimeEmailDetails" :receivedData="receivedData"></RealTimeEmailDetails>
        </div>
  </template>
 </DialogContainer>
</template>

<script>
import { ref } from "vue";
import eventBus from "@/utils/eventBus";
import RealTimeEmailDetails from "@/components/basic/common/RealTimeEmailDetails";
import { mapGetters } from "vuex";
import DialogContainer from "@/components/common/DialogContainer";
export default {
    name: "RealTimeEmailCheck",
    components: {
        RealTimeEmailDetails,
        DialogContainer
    },
    props: {
        showButton: {
            type: Boolean,
            default: true,
        },
        classes: {
            type: String,
            default:
                "w-full justify-center sm:justify-between items-center",
        },
    },
    data() {
        return {
            isOpen: ref(false),
            email: "",
            selectedOption: "all",
            isLoading: false,
            receivedData: "",
            showRealTimeEmailDetails: false,
            options: [
                { label: "All", value: "all" },
                { label: "Deliverability", value: "deliverability" },
                { label: "Threat", value: "threat" },
            ],
        };
    },
    computed: {
    ...mapGetters("auth", ["userData"]), 
  },
  mounted() {
    eventBus.on("hideRealTimeEmailDetails", () => {
     this.email = "";
     this.showRealTimeEmailDetails = false;
    });
  },
    methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
        this.isOpen = false;
        this.email = "";
        this.showRealTimeEmailDetails = false;
    },
    checkEnter(event) {
      if (event.keyCode === 13) {
        this.submit();
      }
    },
    async submit() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.email || !emailRegex.test(this.email)) {
            eventBus.trigger("notify", {
                notification: {
                    group: "top-right",
                    title: "Error",
                    text: "Enter a valid email",
                    type: "error",
                },
            });
            return;
        }
            let userCredits = 0;
            if(this.userData?.credits == 0){
                userCredits = this.userData?.credits;
            }else{
                userCredits = this.userData?.credits || this.userData?.organization?.credits;
            }
            try {
                if(userCredits == 0 ){
                    eventBus.trigger("notify", {
                        notification: {
                            group: "top-right",
                            title: "Error",
                            text: "Insufficient credits",
                            type: "error",
                        },
                    });
                } else {
                    const requestBody = {
                        email: this.email,
                        created_with: "UI",
                    };

                    if (this.selectedOption === "deliverability") {
                        requestBody.deliverability_status_only = true;
                    } else if (this.selectedOption === "threat") {
                        requestBody.threat_level_only = true;
                    }
                    try {
                        this.isLoading = true;
                        const data = await this.$alfredService.realtimeEmailCheck(requestBody);
                        this.receivedData = data;
                        this.showRealTimeEmailDetails = true;
                    } catch (error) {
                        if (error.response && error.response.data) {
                            this.isLoading = false;
                            const responseData = error.response.data.detail;
                            eventBus.trigger("notify", {
                                notification: {
                                    group: "top-right",
                                    title: "Error",
                                    text: responseData,
                                    type: "error",
                                },
                            });
                        }
                    } finally {
                        this.isLoading = false;
                    }
                }
            }
            catch {
                eventBus.trigger("notify", {
                    notification: {
                        group: "top-right",
                        title: "Error",
                        text: "Try again later",
                        type: "error",
                    },
                });
            }
        },
    },
};

</script>