<template>
    <div class="pb-4">
      <div
        class="flex sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8"
      >
        <div class="flex flex-col text-sm text-left sm:w-1/4 w-full">
          <div class="grid grid-cols-2 mb-2" id="name">
            <span class="font-medium text-gray-600 uppercase">Name</span>
            <span class="text-gray-500 whitespace-nowrap">{{ fileName }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <span class="font-medium text-gray-600 uppercase">Created</span>
            <span class="text-gray-500 whitespace-nowrap">{{ createdAt }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <span class="font-medium text-gray-600 uppercase">Quantity</span>
            <span class="text-gray-500 whitespace-nowrap">{{
              totalEmails(emailsCount)
            }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <span class="font-medium text-gray-600 uppercase">Status</span>
            <span class="text-gray-500 whitespace-nowrap">Complete</span>
          </div>
        </div>
        <div class="flex items-center m-2" v-if="!isDownload">
          <div class="pr-3">
            <button
              @click="showDashboard()"
              type="submit"
              class="button-submit border px-4 py-2.5 border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-100 focus:outline-none"
            >
              View Dashboard
            </button>
          </div>
          <button
            @click="DownloadFile()"
            :disabled="!job.result_file"
            type="submit"
            class="button-submit"
          >
            Export List
          </button>
          <button
            @click="onDelete()"
            class="ml-5 text-gray-500 focus:outline-none"
          >
            <TrashIcon class="h-6" />
          </button>
          <button
            class="ml-5 text-red-500 focus:outline-none text-sm"
            @click="fileDetails"
            v-if="!isGenerating"
          >
            <img
              class="mx-auto h-6 w-auto"
              :src="require('@/assets/images/pdf.png')"
              alt="Alfred Knows"
            />PDF
          </button>
        </div>
      </div>
      <!--Tab content-->
  
      <div class="tab rounded-t-md p-8 mt-10 mb-10 hidden-print">
        <nav class="float-left">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            :class="[
              tab.current
                ? 'current'
                : 'border-transparent hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap mr-5 pb-4 px-1 border-b-2 text-md cursor-pointer',
            ]"
            @click="changeTab(tab.name)"
          >
            {{ tab.name }}
          </a>
        </nav>
      </div>
      <div v-for="tab in tabs" :key="tab.name" v-show="activeTab === tab.name">
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <template v-if="tab.name === 'Threat Scores'">
            <div
              v-for="(item, index) in jobResult['Threat Scores']"
              :key="index"
              class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
            >
              <dt class="text-md font-medium text-gray-500 truncate">
                {{ item.Score }} (Out of 5)
              </dt>
              <dd class="mt-1 text-2xl font-semibold text-green-600">
                <span class="mt-1 text-sm font-medium text-gray-700">
                  <span
                    :class="[
                      'mt-1',
                      'text-2xl',
                      'font-semibold',
                      colorClassMap[item.Score] || 'text-yellow-400',
                    ]"
                    >{{
                      Math.round(
                        (item["Email Count"] / parseInt(emailsCount)) * 100
                      )                      
                    }}%</span
                  >
                  ({{ item["Email Count"] }})</span
                >
              </dd>
            </div>
        </template>
          <template v-if="tab.name === 'Threat Status'">
            <div
              v-for="(item, index) in jobResult['Threat Status']"
              :key="index"
              class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
            >
              <dt class="text-md font-medium text-gray-500 truncate">
                {{ item.Status }}
              </dt>
              <dd class="mt-1 text-2xl font-semibold text-green-600">
                <span class="mt-1 text-sm font-medium text-gray-700">
                  <span
                    :class="[
                      'mt-1',
                      'text-2xl',
                      'font-semibold',
                      colorClassMap[item.Status] || 'text-yellow-400',
                    ]"
                    >{{
                      Math.round((item.Count / parseInt(emailsCount)) * 100)
                    }}%</span
                  >
                  ({{ item.Count }})</span
                >
              </dd>
            </div>
          </template>
        </dl>
      </div>
  
      <div class="sm:flex sm:items-start justify-center" v-if="isDownload">
        <PulseLoader color="#ff5a5f" />
      </div>
      <!--Email list-->
      <div>
        <div class="mb-6 mt-6">
          <SearchInput
            v-model="searchKeyword"
            @input="onSearchInput"
            v-on:keyup="onSearchInput"
          />
        </div>
        <div class="overflow-x-auto bg-white shadow rounded-md mb-6">
          <div class="align-middle inline-block w-full">
            <div class="sm:rounded-lg">
              <table class="divide-gray-300 w-full text-left table-auto">
                <thead class="border-b-2">
                  <tr>
                    <th
                      @click="
                        fetchEmailList(
                          this.$route.params.id,
                          this.urlParams,
                          (name = 'Email')
                        )
                      "
                      scope="col"
                      class="flex px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                    >
                      <a class="inline-flex hover:text-gray-900 cursor-pointer">
                        Email
                        <span class="my-auto"> <SelectorIcon class="h-4" /> </span
                      ></a>
                    </th>
                    <th
                      @click="
                        fetchEmailList(
                          this.$route.params.id,
                          this.urlParams,
                          (name = 'threat_score')
                        )
                      "
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                    >
                      <a class="inline-flex hover:text-gray-900 cursor-pointer">
                        Threat Score (OUT OF 5)
                        <span class="my-auto"> <SelectorIcon class="h-4" /> </span
                      ></a>
                    </th>
                    <th
                      @click="
                        fetchEmailList(
                          this.$route.params.id,
                          this.urlParams,
                          (name = 'threat_status')
                        )
                      "
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                    >
                      <a class="inline-flex hover:text-gray-900 cursor-pointer">
                        Threat Status
                        <span class="my-auto"> <SelectorIcon class="h-4" /> </span
                      ></a>
                    </th>
                  </tr>
                </thead>
  
                <tbody>
                  <tr v-for="item in resultsData" :key="item.id" class="row">
                    <td class="px-6 py-5 text-sm text-gray-500">
                      {{ item.email }}
                    </td>
  
                    <td class="px-6 py-5 text-sm text-gray-500 w-1/4">
                      {{ item.threat_score }}
                    </td>
                    <td
                      class="px-6 py-5 whitespace-nowrap text-sm text-gray-500 w-1/4"
                    >
                      {{ humanize(item.threat_status) }}
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <nav
                class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg"
                aria-label="Pagination"
              >
                <div class="flex-1 py-4">
                  <div class="text-sm text-left text-gray-500 mr-4 italic">
                    <span
                      >Your lists will be automatically deleted in
                      {{ settings?.AUTO_DELETE_DAYS || 14 }} days by default.
                    </span>
                    <router-link
                      to="/contact"
                      class="whitespace-nowrap underline hover:text-gray-400"
                      >Contact us</router-link
                    >
                    to request an extension or reduction.
                  </div>
                </div>
                <div class="hidden sm:block mr-4">
                  <p class="text-sm text-right text-gray-700">
                    {{ " " }}
                    <span class="font-medium">{{ currentPage }}</span>
                    {{ " " }}
                    of
                    {{ " " }}
                    <span class="font-medium">{{ totalPages }}</span>
                    {{ " " }}
                  </p>
                </div>
                <div class="flex justify-between sm:justify-end">
                  <button
                    @click="goToPage(currentPage - 1)"
                    :disabled="currentPage === 1"
                    class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <button
                    @click="goToPage(currentPage + 1)"
                    :disabled="currentPage === totalPages"
                    class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8 canvas_div_pdf"
        id="content"
        hidden
      >
        <div class="flex flex-col text-sm text-left sm:w-1/4 w-full pb-6">
          <div class="grid grid-cols-2 mb-2">
            <span class="font-medium text-gray-600 uppercase"
              >Name: &nbsp;&nbsp;</span
            >
            <span class="text-gray-500 whitespace-nowrap">{{ fileName }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <span class="font-medium text-gray-600 uppercase"
              >Created: &nbsp;&nbsp;</span
            >
            <span class="text-gray-500 whitespace-nowrap">{{ createdAt }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <span class="font-medium text-gray-600 uppercase"
              >Quantity: &nbsp;&nbsp;</span
            >
            <span class="text-gray-500 whitespace-nowrap">{{
              totalEmails(emailsCount)
            }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <span class="font-medium text-gray-600 uppercase"
              >Status: &nbsp;&nbsp;</span
            >
            <span class="text-gray-500 whitespace-nowrap">Complete</span>
          </div>
        </div>
  
        <div class="py-5 p-4 max-w-lg">
          <div class="text-gray-600 text-xl align-left pb-10">
            <h3 class="text-lg leading-6 font-bold text-gray-600">
              Threat Scores
            </h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div
                v-for="(item, index) in jobResult['Threat Scores']"
                :key="index"
                class="px-4 py-5 bg-white shadow rounded-lg"
              >
                <dt class="text-sm font-medium text-gray-500">
                  {{ item.Score }} (out of 5)
                </dt>
                <div></div>
  
                <dd class="mt-1 text-2xl font-semibold">
                  <span class="mt-1 text-sm font-medium text-gray-700">
                    <span
                      :class="[
                        'mt-1',
                        'text-2xl',
                        'font-semibold',
                        colorClassMap[item.Score] || 'text-yellow-400',
                      ]"
                      >{{
                        Math.round(
                          (item["Email Count"] / parseInt(emailsCount)) * 100
                        )
                      }}%</span
                    >
                    ({{ item["Email Count"] }})</span
                  >
                </dd>
              </div>
            </dl>
          </div>
          <br />
          <div class="text-gray-600 text-xl align-left pb-10">
            <h3 class="text-lg leading-6 font-bold text-gray-600">
              Threat Status
            </h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div
                v-for="(item, index) in jobResult['Threat Status']"
                :key="index"
                class="px-4 py-5 bg-white shadow rounded-lg"
              >
                <dt class="text-sm font-medium text-gray-500">
                  {{ item.Status }}
                </dt>
                <div></div>
  
                <dd class="mt-1 text-2xl font-semibold">
                  <span class="mt-1 text-sm font-medium text-gray-700">
                    <span
                      :class="[
                        'mt-1',
                        'text-2xl',
                        'font-semibold',
                        colorClassMap[item.Status] || 'text-yellow-400',
                      ]"
                      >{{
                        Math.round((item.Count / parseInt(emailsCount)) * 100)
                      }}%</span
                    >
                    ({{ item.Count }})</span
                  >
                </dd>
              </div>
            </dl>
          </div>
          <br />
        </div>
      </div>
    </div>

    <!-- <teleport to="body">
    <transition name="fade">
      <div v-if="showPopup" class="popup-container">
        <div class="popup inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:max-h-md w-full rounded-lg">
            <h3 id="headlessui-dialog-title-12" class="text-lg leading-6 font-medium text-gray-900"><div class="pb-1"> Download by threat score of email address </div></h3>
            <fieldset class="flex">
          <legend class="text-sm font-bold text-gray-600">Score</legend>
          <div
            v-for="(label, labelIdx) in score_values"
            :key="labelIdx"
            class="relative flex items-start py-2 space-x-3"
          >
            <div class="ml-3 flex items-center h-5">
              <input
                :id="`label-${label.name}`"
                :name="`label-${label.name}`"
                type="checkbox"
                @click="clickScore(label.id, label.name)"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                checked
                />
            </div>
            <div class="min-w-0 flex-1 text-sm">
              <label
                :for="`label-${label.id}`"
                class="font-medium text-gray-400 select-none"
                >{{ humanize(label.name) }}</label
              >
            </div>
          </div>
        </fieldset>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b">
          <button
        type="button"
        class="button-submit sm:ml-3"
        @click="DownloadFile()"
      >
        Download
      </button>
      <button class="button-cancel" @click="showPopup = false">
            Cancel</button>
    </div>
        </div>
      </div>
    </transition>
    </teleport> -->
  </template>
  
  <script>
  import apiMixin from "@/mixins/apiMixin";
  import useConfirmationModal from "@/api/confirmationModal";
  import { TrashIcon } from "@heroicons/vue/outline";
  import PulseLoader from "vue-spinner/src/PulseLoader";
  import humanize from "humanize";
  import jsPDF from "jspdf";
  import $ from "jquery";
  import html2canvas from "html2canvas";
  import SearchInput from "@/components/common/SearchInput";
  import { SelectorIcon } from "@heroicons/vue/solid";
  
  export default {
    name: "CompleteDetails",
  
    mixins: [apiMixin],
    components: {
      TrashIcon,
      PulseLoader,
      SearchInput,
      SelectorIcon,
    },
  
    data() {
      return {
        job: {},
        jobResult: {},
        createdAt: "",
        emailsCount: 0,
        fileName: "",
        charts: {},
        determineEmailCount: 0,
        KlaviyoDownload: null,
        jobid: 0,
        isDownload: false,
        isGenerating: false,
        urlParams: { page: 1, ordering: "-email", size: 10, search: "" },
        currentPage: 1,
        totalPages: 1,
        searchKeyword: "",
        resultsData: [],
        TabData: [],
        tabs: [
          { name: "Threat Scores", current: true },
          { name: "Threat Status", current: false },
        ],
        activeTab: "Threat Scores",
        showPopup: false,
        score_values: [   
            { id: 5, name: "5" },
            { id: 4, name: "4" },
            { id: 3, name: "3" },
            { id: 2, name: "2" },
            { id: 1, name: "1" },
            { id: 0, name: "0" },
        ],
        threatScores_Checked: ["threat_score=0","threat_score=1","threat_score=2","threat_score=3","threat_score=4","threat_score=5"]
      };
    },
    computed: {
      colorClassMap() {
        return {
          Low: "text-green-400",
          Moderate: "text-yellow-400",
          High: "text-red-600",
          Unknown: "text-green-600",
          0: "text-green-600",
          1: "text-green-400",
          2: "text-yellow-400",
          3: "text-yellow-400",
          4: "text-red-400",
          5: "text-red-600",
        };
      },
    },
    async beforeMount() {
      const jobDetails = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.getJob(this.$route.params.id),
      });
      this.jobid = this.$route.params.id;
  
      this.jobResult = {
        threatStats: jobDetails.result?.threat_assessment_job?.threat_scores,
      };
  
      this.job = jobDetails;
      this.createdAt = new Date(jobDetails.input_file.created_at).toLocaleString(
        "en-US"
      );
      this.fileName = jobDetails.input_file.name;
      this.emailsCount = jobDetails.emails_count;
  
      this.determineEmailCount = this.emailsCount;
      this.fileName = jobDetails.input_file.name;
      this.emailsCount = jobDetails.emails_count;
      const match_count = parseInt(this.jobResult.threatStats.matched_count)
      this.calculateThreatData(this.jobResult.threatStats.threat_scores,this.emailsCount - match_count);
      this.jobResult = this.transform(this.jobResult);
      this.jobResult = this.jobResult.ThreatStats;     
      const newScore = {
            'Score': 0,
            'Email Count': this.emailsCount - match_count
          };
      this.jobResult["Threat Scores"].unshift(newScore);
      this.fetchEmailList(this.$route.params.id, this.urlParams);
    },
  
    methods: {
      onSearchInput() {
        this.urlParams.search = this.searchKeyword;
        this.fetchEmailList(this.$route.params.id, this.urlParams);
      },
      async fetchEmailList(id, urlParams, name) {
        if (name == "Email") {
          this.urlParams.ordering =
            this.urlParams.ordering === "-email" ? "email" : "-email";
        }else if(name == "threat_score"){
          this.urlParams.ordering =
            this.urlParams.ordering === "-threat_score" ? "threat_score" : "-threat_score";
        }else if(name == "threat_status"){
          this.urlParams.ordering =
            this.urlParams.ordering === "-threat_status" ? "threat_status" : "-threat_status";
        }
        const previewDetails = await this.$alfredService.getJobPreview(
          id,
          urlParams
        );
        this.resultsData = previewDetails.results;
        this.totalPages = Math.ceil(previewDetails.count / this.urlParams.size);
      },
      goToPage(pageNumber) {
        if (pageNumber >= 1 && pageNumber <= this.totalPages) {
          this.urlParams.page = pageNumber;
          this.fetchEmailList(this.$route.params.id, this.urlParams);
          this.currentPage = pageNumber;
        }
      },     
      calculateThreatData(threatScoreData, unknown_count) {
        const threatStatusData = [];
        for (const item of threatScoreData) {
          const score = item.score;
          const emailCount = item.email_count;
  
          let threatStatus;
          if (score === 0) {
            threatStatus = "Unknown";
          } else if (score >= 4 && score <= 5) {
            threatStatus = "High";
          } else if (score == 3) {
            threatStatus = "Moderate";
          } else if (score >=1 && score <= 2) {
            threatStatus = "Low";
          } else {
            threatStatus = "Unknown";
          }
          const existingThreatStatus = threatStatusData.find(
            (item) => item.status === threatStatus
          );
          if (existingThreatStatus) {
            existingThreatStatus.count += emailCount;
          } else {
            threatStatusData.push({ status: threatStatus, count: emailCount });
          }
        }
        const unknownStatus = threatStatusData.find(
          (item) => item.status === "Unknown"
        );
        if (!unknownStatus) {
          threatStatusData.push({ status: "Unknown", count: unknown_count });
        }
        this.jobResult.threatStats.threat_status = threatStatusData;        
        threatStatusData.sort((a, b) => {
          const order = { Unknown: 0, Low: 1, Moderate: 2, High: 3 };
          return order[a.status] - order[b.status];
        });
      },
      changeTab(tabName) {
        this.tabs.forEach((tab) => {
          tab.current = tab.name === tabName;
        });
        this.activeTab = tabName;
      },
      transform(data) {
        if (typeof data !== "object" || data === null) {
          return data;
        }
        if (Array.isArray(data)) {
          return data.map(this.transform);
        }
        const transformedObj = {};
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            let transformedKey = key
              .replace(/_/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase());
  
            transformedObj[transformedKey] = this.transform(data[key]);
          }
        }
        return transformedObj;
      },
      fileDetails() {
        this.isDownload = true;
        this.isGenerating = true;
        $("#content").removeAttr("hidden");
        const namePos = this.fileName.indexOf(".");
        let fileName1 = this.fileName.toString();
        const name = fileName1.substring(0, namePos);
        setTimeout(() => {
          // parentdiv is the html element which has to be converted to PDF
          html2canvas($(".canvas_div_pdf")[0], {
            allowTaint: true,
            scale: 2,
          }).then((canvas) => {
            var pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
  
            var imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
            pdf.save(name + ".pdf");
          });
          $("#content").attr("hidden", "true");
          this.isDownload = false;
          this.isGenerating = false;
        }, "3000");
      },
      formatNumber(count) {
        return ` (${humanize.numberFormat(count, 0)})`;
      },
      formatValue(value, index) {
        const percentage =
          Math.round((value * 100) / this.totalValue(index) || 0) + "%";
        return percentage;
      },
      formatValueObjective(value) {
        const percentage =
          Math.round((value * 100) / this.emailsCount || 0) + "%";
        return percentage;
      },
      totalValue(index) {
        return (
          this.charts &&
          this.charts[index]?.data?.reduce((acc, curr) => acc + curr, 0)
        );
      },
      async onDelete() {
        const modal = useConfirmationModal();
        await modal.openModal({
          title: "Are you sure you want to delete this file?",
          message: "This action cannot be undone.",
          onSubmit: async () => {
            await this.$alfredService.deleteJob(this.job.id);
            await this.$router.push({
              name: "Dashboard",
            });
          },
        });
      },
      async showDashboard() {
        await this.$router.push({
          name: "Dashboard",
        });
      },
      clickScore(id, label) {
      let score_id = document.getElementById("label-" + label);
      if (score_id.checked) {
        this.threatScores_Checked.push("threat_score=" + id);
      } else {
        const index = this.threatScores_Checked.findIndex((x) => x.includes("threat_score=" + id));
        if (index != -1) {
          this.threatScores_Checked.splice(index, 1);
        }
      }
    },
    saveDownloadFile(fileDownload) {
      const url = window.URL.createObjectURL(new Blob([fileDownload]));
      const link = document.createElement("a");
      link.href = url;
      const namePos = this.job.input_file.name.indexOf(".");
      const name = this.job.input_file.name.substr(0, namePos);
      const datePos = this.job.input_file.created_at.indexOf("T");
      const createdDate = this.job.input_file.created_at.substr(0, datePos);
      const fileName = name + "_results_" + createdDate + ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    },
    async DownloadFile() {      
        let threatScores_ = this.threatScores_Checked.join("&");
        const fileDownload = await this.$alfredService.threatFileDownload(
            this.$route.params.id, threatScores_
        );
        if (fileDownload) {
          this.saveDownloadFile(fileDownload);
        }
         
      },
      humanize(words) {
        const separateWords = words.toLowerCase().split("_");
        for (let i = 0; i < separateWords.length; i++) {
          separateWords[i] =
            separateWords[i].charAt(0).toUpperCase() +
            separateWords[i].substring(1);
        }
        return separateWords.join(" ");
      },
      totalEmails(count) {
        return humanize.numberFormat(count, 0);
      },
    },
  };
  </script>
  
  <style scoped>
  .current {
    border-color: var(--main-color);
    color: var(--main-color);
  }
  .fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
  </style>
  