<template> 
    <div class="pb-4">
        <div
          class="flex sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8"
        >
          <div class="flex flex-col text-sm text-left sm:w-1/4 w-full">
            <div class="grid grid-cols-2 mb-2" id="name">
              <span class="font-medium text-gray-600 uppercase">Name</span>
              <span class="text-gray-500 whitespace-nowrap">{{ fileName }}</span>
            </div>
            <div class="grid grid-cols-2 mb-2">
              <span class="font-medium text-gray-600 uppercase">Created</span>
              <span class="text-gray-500 whitespace-nowrap">{{ createdAt }}</span>
            </div>
            <div class="grid grid-cols-2 mb-2">
              <span class="font-medium text-gray-600 uppercase">Quantity</span>
              <span class="text-gray-500 whitespace-nowrap">{{
                 totalEmails(emailsCount)
              }}</span>
            </div>
            <div class="grid grid-cols-2 mb-2">
              <span class="font-medium text-gray-600 uppercase">Status</span>
              <span class="text-gray-500 whitespace-nowrap">Complete</span>
            </div>
          </div>         
        </div>

         <!--Tab content-->
  
      <div class="tab rounded-t-md p-8 mt-10 mb-10 hidden-print">
        <nav class="float-left">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            :class="[
              tab.current
                ? 'current'
                : 'border-transparent hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap mr-5 pb-4 px-1 border-b-2 text-md cursor-pointer',
            ]"
            @click="changeTab(tab.name)"
          >
            {{ tab.name }}
          </a>
        </nav>
      </div>
      <div v-for="tab in tabs" :key="tab.name" v-show="activeTab === tab.name">
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <template v-if="tab.name === 'Threat Scores'">
            <div
              v-for="(item, index) in jobResult['Threat Scores']"
              :key="index"
              class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
            >
              <dt class="text-md font-medium text-gray-500 truncate">
                {{ item.Score }} (Out of 5)
              </dt>
              <dd class="mt-1 text-2xl font-semibold text-green-600">
                <span class="mt-1 text-sm font-medium text-gray-700">
                  <span
                    :class="[
                      'mt-1',
                      'text-2xl',
                      'font-semibold',
                      colorClassMap[item.Score] || 'text-yellow-400',
                    ]"
                    >{{
                      Math.round(
                        (item["Email Count"] / parseInt(emailsCount)) * 100
                      )                      
                    }}%</span
                  >
                  ({{ item["Email Count"] }})</span
                >
              </dd>
            </div>
        </template>
          <template v-if="tab.name === 'Threat Status'">
            <div
              v-for="(item, index) in jobResult['Threat Status']"
              :key="index"
              class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
            >
              <dt class="text-md font-medium text-gray-500 truncate">
                {{ item.Status }}
              </dt>
              <dd class="mt-1 text-2xl font-semibold text-green-600">
                <span class="mt-1 text-sm font-medium text-gray-700">
                  <span
                    :class="[
                      'mt-1',
                      'text-2xl',
                      'font-semibold',
                      colorClassMap[item.Status] || 'text-yellow-400',
                    ]"
                    >{{
                      Math.round((item.Count / parseInt(emailsCount)) * 100)
                    }}%</span
                  >
                  ({{ item.Count }})</span
                >
              </dd>
            </div>
          </template>
        </dl>
      </div>
      
    </div>      
      
  </template>
  
  <script>
  import apiMixin from "@/mixins/apiMixin";
  import humanize from "humanize";
  
  export default {
    name: "UsageViewThreatDetails",
    mixins: [apiMixin],
    data() {
      return { 
        job: {},
        jobResult: {},
        fileName: "",
        createdAt: "",
        emailsCount: 0,
        TabData: [],
        tabs: [
            { name: "Threat Scores", current: true },
            { name: "Threat Status", current: false },
          ],
        activeTab: "Threat Scores",
       };
    },
    computed: {
        colorClassMap() {
          return {
            Low: "text-green-400",
            Moderate: "text-yellow-400",
            High: "text-red-600",
            Unknown: "text-gray-400",
            0: "text-gray-400",
            1: "text-red-600",
            2: "text-yellow-400",
            3: "text-yellow-400",
            4: "text-green-400",
            5: "text-green-600",
          };
        },
      },
    async beforeMount() {
      const jobDetails = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.getJob(this.$route.params.id),
      });
      this.fileName = jobDetails.input_file.name;
      this.createdAt = new Date(jobDetails.input_file.created_at).toLocaleString(
        "en-US"
      );
      this.jobResult = {
        threatStats: jobDetails.result?.threat_assessment_job?.threat_scores,
      };
      this.job = jobDetails;
      this.emailsCount = jobDetails.emails_count;
      const match_count = parseInt(this.jobResult.threatStats.matched_count)
      this.calculateThreatData(this.jobResult.threatStats.threat_scores,this.emailsCount - match_count);
      this.jobResult = this.transform(this.jobResult);
      this.jobResult = this.jobResult.ThreatStats;     
      const newScore = {
            'Score': 0,
            'Email Count': this.emailsCount - match_count
          };
      this.jobResult["Threat Scores"].unshift(newScore);
      this.jobResult["Threat Scores"] = this.sortScore(
        this.jobResult["Threat Scores"]
      );
    },
    methods: {
        totalEmails(count) {
        return humanize.numberFormat(count, 0);
      },
      sortScore(arr) {
        const reversedArray = [];
        for (let i = arr.length - 1; i >= 0; i--) {
          reversedArray.push(arr[i]);
        }
        return reversedArray;
      },
      calculateThreatData(threatScoreData, unknown_count) {
        const threatStatusData = [];
        for (const item of threatScoreData) {
          const score = item.score;
          const emailCount = item.email_count;
  
          let threatStatus;
          if (score === 0) {
            threatStatus = "Unknown";
          } else if (score >= 4 && score <= 5) {
            threatStatus = "High";
          } else if (score == 3) {
            threatStatus = "Moderate";
          } else if (score >= 1 && score <= 2) {
            threatStatus = "Low";
          } else {
            threatStatus = "Unknown";
          }
          const existingThreatStatus = threatStatusData.find(
            (item) => item.status === threatStatus
          );
          if (existingThreatStatus) {
            existingThreatStatus.count += emailCount;
          } else {
            threatStatusData.push({ status: threatStatus, count: emailCount });
          }
        }
        threatStatusData.sort((a, b) => {
          const order = { Low: 0, Moderate: 1, High: 2, Unknown: 3 };
          return order[a.status] - order[b.status];
        });
        const unknownStatus = threatStatusData.find(
          (item) => item.status === "Unknown"
        );
        if (!unknownStatus) {
          threatStatusData.push({ status: "Unknown", count: unknown_count });
        }
        this.jobResult.threatStats.threat_status = threatStatusData;
      },
      changeTab(tabName) {
        this.tabs.forEach((tab) => {
          tab.current = tab.name === tabName;
        });
        this.activeTab = tabName;
      },
      transform(data) {
        if (typeof data !== "object" || data === null) {
          return data;
        }
        if (Array.isArray(data)) {
          return data.map(this.transform);
        }
        const transformedObj = {};
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            let transformedKey = key
              .replace(/_/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase());
  
            transformedObj[transformedKey] = this.transform(data[key]);
          }
        }
        return transformedObj;
      },
    }
  };
  </script>
    <style scoped>
    .current {
      border-color: var(--main-color);
      color: var(--main-color);
    }
    </style>