import { reactive } from "vue";
import { flattenDict } from "@/utils/dicts";

export default function useFormErrors() {
  const errors = reactive({});

  // Mimics vee-validate for compatibility
  function setFieldError(field, error) {
    errors[field] = error;
  }

  function clearFieldError(field) {
    delete errors[field];
  }

  function validateField(name, value, validator) {
    const error = validator(name, value);
    if (error) {
      setFieldError(name, error);
      return false;
    }
    return true;
  }

  function validateForm(form, validators) {
    form = flattenDict(form);
    for (let key of Object.keys(validators)) {
      const fieldValidators = validators[key];
      for (let validator of fieldValidators) {
        validateField(key, form[key], validator);
      }
    }
    return !Object.keys(errors).length;
  }

  function clearFormErrors() {
    for (let key of Object.keys(errors)) {
      clearFieldError(key);
    }
  }

  return {
    errors,
    formActions: {
      setFieldError,
      clearFieldError,
      validateForm,
      validateField,
      clearFormErrors,
    },
  };
}
