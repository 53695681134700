<template>
  <DialogContainer :isOpen="isOpen" :onClose="onClose">
    <template v-slot:title>Select Credits</template>
    <template v-slot:content>
      <p class="block text-sm mt-2">
        You have {{ userCreditsDisplay }} credits.
        <template v-if="requiredCreditsDisplay">
          {{ requiredCreditsDisplay }} more credits are needed to clean this
          list{{
            requiredCredits >= minimalPurchase
              ? "."
              : " (minimal purchase is 100 credits)."
          }}
        </template>
      </p>
      <div class="mt-6">
        <ThresholdCheckboxList
          @onChosen="onCheckboxOption"
          :thresholds="thresholds"
          :requiredCredits="{
            credits: requiredCredits,
            price: requiredCreditsPrice,
          }"
          :userCredits="userCredits"
          :minimalPurchase="minimalPurchase"
          :selectionObligatory="true"
          :discountValue="discount?.value"
        />
        <DiscountInput v-model="discount" />
        <div class="mt-6 flex flex-col items-start w-full">
          <button type="button" @click="onSubmit" class="button-submit">
            Proceed to Checkout
          </button>
          <div class="mt-2">
            <div class="italic text-sm">Credits never expire</div>
          </div>
        </div>
      </div>
    </template>
  </DialogContainer>
</template>
<script>
import DialogContainer from "@/components/common/DialogContainer";
import humanize from "humanize";
import { ref } from "vue";
import formMixin from "@/mixins/formMixin";
import useFormErrors from "@/api/formErrors";
import { mapGetters } from "vuex";
import modalMixin from "@/mixins/modalMixin";
import ThresholdCheckboxList from "@/components/basic/common/ThresholdCheckboxList";
import DiscountInput from "@/components/basic/common/DiscountInput";
import thresholdsMixin from "@/mixins/thresholdsMixin";

export default {
  name: "SelectCreditsModal",
  mixins: [formMixin, modalMixin, thresholdsMixin],
  components: {
    DialogContainer,
    ThresholdCheckboxList,
    DiscountInput,
  },
  setup() {
    const isOpen = ref(false);
    const submitCallback = ref(null);
    const closeCallback = ref(null);
    const modalEventName = "select-credits";

    const { errors, formActions } = useFormErrors();

    const credits = ref(0);
    const price = ref(0);
    const primaryPrice = ref(0);
    const requiredCredits = ref(0);
    const requiredCreditsPrice = ref(0);
    const minimalPurchase = 100;

    const discount = ref({});
    const thresholds = ref([]);

    return {
      isOpen,
      submitCallback,
      closeCallback,
      requiredCredits,
      errors,
      formActions,
      credits,
      price,
      primaryPrice,
      requiredCreditsPrice,
      minimalPurchase,
      modalEventName,
      discount,
      thresholds,
    };
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.formActions.clearFormErrors();
        this.discount = {};
      }
    },
    discount: {
      deep: true,
      async handler(value) {
        await this.getRequiredCreditsPrice();
        await this.getPricing(value?.name);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["userData"]),
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    userCredits() {
      let userCredits = 0;
      if(this.userData?.credits == 0){
        userCredits = this.userData?.credits;
      }else{
        userCredits = this.userData?.credits || this.userData?.organization?.credits;
      }
      return humanize.numberFormat(userCredits, 0);
    },
    userCreditsDisplay() {
      return this.userCredits;
    },
    requiredCreditsDisplay() {
      if (this.requiredCredits) {
        return humanize.numberFormat(this.requiredCredits, 0);
      }
      return "";
    },
    creditPack() {
      let creditPack = [25000, 100000, 250000, 500000, 1000000];
      if (this.requiredCredits < this.minimalPurchase) {
        creditPack = [this.minimalPurchase, ...creditPack];
      } else if (
        this.requiredCredits &&
        this.requiredCredits >= this.minimalPurchase
      ) {
        creditPack = [this.requiredCredits, ...creditPack];
      }
      return creditPack;
    },
  },
  methods: {
    openModal({ onSubmit = null, onClose = null, missingCredits }) {
      this.submitCallback = onSubmit;
      this.closeCallback = onClose;
      this.requiredCredits = missingCredits;
      this.isOpen = true;
    },
    onSubmit() {
      this.isOpen = false;
      if (!this.hasErrors && this.submitCallback) {
        this.submitCallback({
          primaryPrice: this.primaryPrice,
          credits: this.credits,
          price: this.price,
          discount: this.discount,
        });
      }
    },
    onClose() {
      if (this.closeCallback) {
        this.closeCallback();
      }
      this.isOpen = false;
    },
    onCheckboxOption(option) {
      this.primaryPrice = option.primaryPrice;
      this.price = option?.price;
      this.credits = option?.credits;
    },
    async getRequiredCreditsPrice() {
      const requiredCredits =
        this.requiredCredits >= this.minimalPurchase
          ? this.requiredCredits
          : this.minimalPurchase;
      const { price } = (await this.getPrice(requiredCredits)) || 0;
      this.requiredCreditsPrice = price;
      this.price = price;
      this.credits = this.requiredCredits;
    },
    async getPricing(discountCode = undefined) {
      let urlParams = this.creditPack.map((credits) => [
        "credit_query",
        credits,
      ]);
      if (discountCode) {
        urlParams.push(["discount_code", discountCode]);
      }
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: () => this.$alfredService.getPricing(urlParams),
      });
      if (response) {
        this.thresholds = this.determineThresholds(response.credit_table);
      }
    },
    async getPrice(credits) {
      const response = await this.handleSubmissionErrors(
        async () =>
          await this.$alfredService.verifyCreditPack({
            credits: credits,
            discount_code: this.discount?.name,
          }),
        {}
      );
      if (response) {
        return response;
      }
    },
  },
};
</script>
