<template>
  <DialogContainer
    :isOpen="isOpen"
    :onClose="onClose"
    :maxWidth="'4xl'"
    :hasCloseBtn="false"
  >
    <template v-slot:content>
      <div class="md:flex flex-row flex-grow w-full">
        <div class="md:flex-1">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Your order
          </h3>
          <div class="mt-6 ml-5 mr-2">
            <ul class="list-disc list-outside mx-2">
              <li>
                <span class="flex justify-between">
                  <span>{{ creditsDisplay }} Credits</span>
                  <span>${{ priceBeforeDisplay }}</span>
                </span>
              </li>
            </ul>
            <template v-if="discount?.value">
              <hr class="-ml-4 my-4" />
              <span class="-ml-5"> Discounts </span>
              <ul class="list-disc list-outside mx-2 mt-2 text-left">
                <li>
                  <span class="flex justify-between">
                    <span>{{ `${discount.name} (-${discount.value}%)` }}</span>
                    <span> -${{ discountPriceDisplay }}</span>
                  </span>
                </li>
              </ul>
            </template>
            <hr class="-ml-4 my-4" />
            <div class="text-right mx-2">
              <p>Total: ${{ priceDisplay }}</p>
            </div>
          </div>
        </div>
        <div class="md:w-1 md:h-full h-1 w-full mx-4 my-4"></div>
        <div class="md:flex-1">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Your billing info
          </h3>
          <div v-show="paymentMethods">
            <div class="sm:col-span-5">
              <div class="mt-5">
                <select
                  class="form-field-no-icon sm:w-full"
                  v-model="paymentMethod"
                >
                  <option :value="null">New Credit Card</option>
                  <option
                    v-for="method in paymentMethods"
                    :key="method.id"
                    :value="method"
                  >
                    Card ending with {{ method.card.last4 }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-10">
              <div class="sm:col-span-10">
                <label for="cc-name"> Name on Card </label>
                <div class="mt-1">
                  <input
                    ref="ccName"
                    id="cc-name"
                    name="cc-name"
                    autocomplete="cc-name"
                    type="text"
                    v-model="formData.name"
                    @input="formActions.clearFieldError('name')"
                    @click="blurInput('ccName')"
                    :disabled="!!paymentMethod"
                    class="form-field-no-icon sm:w-full"
                  />
                  <p class="text-red-500 text-sm mt-1">
                    {{ errors.name }}
                  </p>
                </div>
              </div>
              <div class="sm:col-span-10">
                <label for="cc-number"> Card Number </label>
                <div v-show="paymentMethod" class="mt-1">
                  <input
                    id="cc-number"
                    name="cc-number"
                    autocomplete="cc-number"
                    type="text"
                    :value="`**** **** **** ${paymentMethod?.card?.last4}`"
                    disabled
                    class="form-field-no-icon sm:w-full"
                  />
                </div>
                <div
                  v-show="!paymentMethod"
                  ref="cardNumber"
                  :class="wrapperStyles?.cardNumber"
                >
                  <div class="input-placeholder"></div>
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.cardNumber }}
                </p>
              </div>
              <div class="sm:col-span-5">
                <label for="cc-exp"> Card Expiration </label>
                <div v-show="paymentMethod" class="mt-1">
                  <input
                    id="cc-exp"
                    name="cc-exp"
                    autocomplete="cc-exp"
                    type="text"
                    :value="`${paymentMethod?.card?.exp_month > 9 ? '' : '0'}${
                      paymentMethod?.card?.exp_month
                    }/${paymentMethod?.card?.exp_year}`"
                    disabled
                    class="form-field-no-icon sm:w-full"
                  />
                </div>
                <div
                  v-show="!paymentMethod"
                  ref="cardExpiry"
                  :class="wrapperStyles?.cardExpiry"
                >
                  <div class="input-placeholder"></div>
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.cardExpiry }}
                </p>
              </div>
              <div class="sm:col-span-5">
                <label for="cc-csc"> Security Code </label>
                <div v-show="paymentMethod" class="mt-1">
                  <input
                    id="cc-csc"
                    name="cc-csc"
                    autocomplete="cc-csc"
                    v-show="paymentMethod"
                    type="text"
                    value="***"
                    disabled
                    class="form-field-no-icon sm:w-full"
                  />
                </div>
                <div v-show="!paymentMethod">
                  <div ref="cardCvc" :class="wrapperStyles?.cardCvc">
                    <div class="input-placeholder"></div>
                  </div>
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.cardCvc }}
                </p>
              </div>
              <div class="sm:col-span-10">
                <label for="street-address"> Street Address </label>
                <div v-if="paymentMethod" class="mt-1">
                  <input
                    id="street-address"
                    name="street-address"
                    autocomplete="street-address"
                    type="text"
                    :value="`${
                      paymentMethod.billing_details?.address?.line1 || ''
                    }`"
                    disabled
                    class="form-field-no-icon sm:w-full"
                  />
                </div>
                <div v-else class="mt-1">
                  <input
                    id="street-address"
                    name="street-address"
                    ref="streetAddress"
                    autocomplete="street-address"
                    v-model="formData.address_line1"
                    @input="formActions.clearFieldError('address_line1')"
                    @click="blurInput('streetAddress')"
                    type="text"
                    class="form-field-no-icon sm:w-full"
                  />
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.address_line1 }}
                </p>
              </div>
              <div class="sm:col-span-5">
                <label for="postal-code"> Postal Code </label>
                <div v-if="paymentMethod" class="mt-1">
                  <input
                    id="postal-code"
                    name="postal-code"
                    autocomplete="postal-code"
                    type="text"
                    :value="`${
                      paymentMethod?.billing_details?.address?.postal_code || ''
                    }`"
                    disabled
                    class="form-field-no-icon sm:w-full"
                  />
                </div>
                <div v-else class="mt-1">
                  <input
                    ref="postalCode"
                    id="postal-code"
                    name="postal-code"
                    autocomplete="postal-code"
                    v-model="formData.address_zip"
                    @input="formActions.clearFieldError('address_zip')"
                    @click="blurInput('postalCode')"
                    type="text"
                    maxlength="24"
                    class="form-field-no-icon sm:w-full"
                  />
                </div>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.address_zip }}
                </p>
              </div>
              <div class="sm:col-span-5">
                <label for="country"> Country </label>
                <div v-if="paymentMethod" class="mt-1">
                  <select
                    id="country"
                    name="country"
                    autocomplete="country"
                    class="form-field-no-icon sm:w-full"
                    disabled
                    :value="paymentMethod?.billing_details?.address?.country"
                  >
                    <option
                      v-for="country in countryCodes"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{ country.country }}
                    </option>
                  </select>
                </div>
                <div v-else class="mt-1">
                  <select
                    ref="country"
                    id="country"
                    name="country"
                    autocomplete="country"
                    v-model="formData.address_country"
                    @click="blurInput('country')"
                    class="form-field-no-icon sm:w-full"
                  >
                    <option
                      v-for="country in countryCodes"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{ country.country }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!paymentMethods"
            class="flex items-center justify-center loader-container"
          >
            <PulseLoader color="#ff5a5f" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        :disabled="isProcessing"
        @click="onSubmit"
        class="button-submit sm:ml-3"
      >
        Submit
      </button>
      <button type="button" @click="onClose" class="button-cancel">
        Cancel
      </button>
    </template>
  </DialogContainer>
</template>
<script>
import DialogContainer from "@/components/common/DialogContainer";
import { reactive, ref } from "vue";
import formMixin from "@/mixins/formMixin";
import useFormErrors from "@/api/formErrors";
import eventBus from "@/utils/eventBus";
import { required } from "@/utils/validators";
import humanize from "humanize";
import modalMixin from "@/mixins/modalMixin";
import stripeElementsMixin from "@/mixins/stripeElementsMixin";
import PulseLoader from "vue-spinner/src/PulseLoader";
import useConfirmationModal from "@/api/confirmationModal";
import countryCodes from "@/constants/countryCodes.json";
import tapfiliateMixin from "@/mixins/tapfiliateMixin";
import { mapGetters } from "vuex";

export default {
  name: "CheckoutModal",
  mixins: [formMixin, modalMixin, stripeElementsMixin, tapfiliateMixin],
  components: {
    DialogContainer,
    PulseLoader,
  },
  setup() {
    const isOpen = ref(false);
    const isProcessing = ref(false);
    const submitCallback = ref(null);
    const closeCallback = ref(null);
    const modalEventName = "checkout";
    const credits = ref(null);
    const price = ref(null);
    const primaryPrice = ref(null);
    const discount = ref(null);
    const triggerFocus = ref(false);

    const { errors, formActions } = useFormErrors();

    const stripeElements = reactive({});
    const wrapperStyles = reactive({});

    const paymentMethods = ref(null);
    const paymentMethod = ref(null);
    const formData = reactive({
      name: "",
      address_line1: "",
      address_country: "US",
      address_zip: "",
    });

    return {
      isOpen,
      submitCallback,
      closeCallback,
      credits,
      errors,
      formActions,
      price,
      primaryPrice,
      discount,
      stripeElements,
      wrapperStyles,
      countryCodes,
      paymentMethods,
      paymentMethod,
      formData,
      modalEventName,
      triggerFocus,
      isProcessing,
    };
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.formActions.clearFormErrors();
        this.initialize();
        this.fetchPaymentMethods();
      }
    },
    paymentMethod(value) {
      this.formActions.clearFormErrors();
      if (value) {
        this.formData.name = value.billing_details.name;
      } else {
        this.formData.name = "";
      }
    },
  },
  computed: {
    priceDisplay() {
      return humanize.numberFormat(this.price);
    },
    priceBeforeDisplay() {
      return humanize.numberFormat(this.primaryPrice);
    },
    discountPriceDisplay() {
      if (this.discount?.value) {
        return humanize.numberFormat(this.primaryPrice - this.price);
      }
      return null;
    },
    creditsDisplay() {
      return humanize.numberFormat(this.credits, 0);
    },
    ...mapGetters("auth", ["userData"])
  },
  methods: {
    openModal({ onSubmit = null, onClose = null, data }) {
      this.submitCallback = onSubmit;
      this.closeCallback = onClose;
      this.credits = data.credits;
      this.price = data.price;
      this.primaryPrice = data.primaryPrice;
      this.formData = {
        name: "",
        address_line1: "",
        address_country: "US",
        address_zip: "",
      };
      this.discount = data.discount;
      this.isOpen = true;
      this.paymentMethod = null;
      this.paymentMethods = null;
    },
    onClose() {
      this.isOpen = false;
      if (this.closeCallback) {
        this.closeCallback();
      }
    },
    async fetchPaymentMethods() {
      const paymentMethods = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.fetchPaymentMethods(),
      });
      this.paymentMethods = paymentMethods?.data || [];
      if (this.paymentMethods.length) {
        this.paymentMethod =
          this.paymentMethods[this.paymentMethods.length - 1];
      }
    },
    async onSubmit() {
      try {
        if (this.isProcessing) {
          return;
        }
        this.isProcessing = true;
        await this.executeSubmit();
      } finally {
        this.isProcessing = false;
      }
    },

    async executeSubmit() {
      const { id, isNew } = await this.getPaymentMethodId();
      if (!id) {
        return;
      }
      if (isNew) {
        this.isOpen = false;
        const modal = useConfirmationModal();
        await modal.openModal({
          title: "Would you like to save the card?",
          message: "It will be available on the Billing page.",
          primaryButton: "Yes",
          secondaryButton: "No",
          onSubmit: () => this.handlePayment(id, true),
          onClose: async () => {
            await this.handlePayment(id, false);
            this.isOpen = false;
          },
        });
      } else {
        await this.handlePayment(id);
        this.isOpen = false;
      }
    },
    displayCardCreated() {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          type: "success",
          text: "Your credit card has been successfully added!",
        },
      });
    },
    async handlePayment(paymentMethodId, attachToCustomer) {
      const response = await this.createPayment(
        paymentMethodId,
        attachToCustomer
      );

      if (response && attachToCustomer) {
        this.displayCardCreated();
        eventBus.trigger("update-cards", {});
      }

      if (response?.["requires_action"]) {
        const stripe = await this.$stripeService;
        const { error } = await stripe.handleCardAction(
          response["payment_intent_client_secret"]
        );

        if (error) {
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "error",
              text: error,
            },
          });
        }

        await this.confirmPayment(response["creditpack"]["id"]);
      } else if (response?.["status"] === "success") {
        this.displayCreditsPurchased();
        if (this.submitCallback) {
          this.submitCallback();
        }
      }
      if (response?.["latest_charge"]){
        const chargeId = response?.["latest_charge"]
        this.setuptapfiliatePayment(this.userData, this.price, chargeId);
      }
    },
    async createPayment(paymentMethodId, attachToCustomer = false) {
      return await this.handleSubmissionErrors(
        async () =>
          await this.$alfredService.createPayment({
            credits: this.credits,
            stripe_payment_method_id: paymentMethodId,
            discount_code: this.discount?.name,
            attach_to_customer: attachToCustomer,
          }),
        {}
      );
    },
    async confirmPayment(creditpackId) {
      const response = await this.handleSubmissionErrors(
        async () => await this.$alfredService.confirmPayment({}, creditpackId),
        {}
      );
      if (response?.["status"] === "COMPLETED") {
        this.displayCreditsPurchased();
        if (this.submitCallback) {
          this.submitCallback();
        }
      }
    },
    displayCreditsPurchased() {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          type: "success",
          text: "Credits successfully purchased!",
        },
      });
    },
    async getPaymentMethodId() {
      if (this.paymentMethod) {
        return { id: await this.getExistingCardPaymentMethodId() };
      } else {
        return { id: await this.getNewCardPaymentMethodId(), isNew: true };
      }
    },
    async getExistingCardPaymentMethodId() {
      await this.formActions.clearFormErrors();
      return this.paymentMethod.id;
    },

    async getNewCardPaymentMethodId() {
      await this.formActions.clearFormErrors();
      if (!this.validate()) {
        return;
      }
      const stripe = await this.$stripeService;
      const cardElement = this.stripeElements?.cardNumber;
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (paymentMethod?.error) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "error",
            text: paymentMethod.error.message,
          },
        });
      } else {
        return paymentMethod?.id;
      }
    },
    validate() {
      const validators = {
        name: [required],
        address_line1: [required],
        address_zip: [required],
      };
      this.formActions.validateForm(this.formData, validators);
      for (let key of Object.keys(this.stripeElements)) {
        const stripeElement = this.stripeElements[key];
        this.validateStripeField(key, stripeElement);
      }
      return !Object.keys(this.errors).length;
    },
    validateStripeField(name, value) {
      if (value._empty) {
        this.formActions.setFieldError(name, "This field is required.");
        return false;
      }
      if (value._invalid) {
        this.formActions.setFieldError(name, "This value is invalid.");
        return false;
      }
      return true;
    },
  },
};
</script>
<style>
.input-placeholder {
  min-height: 20px;
}
.loader-container {
  height: 530px;
}
</style>
