<template>
  <div class="mt-4">
    <form @submit.prevent="onSubmit" class="flex flex-col items-start">
      <label
        for="email"
        class="text-sm font-medium text-gray-700 whitespace-nowrap"
      >
        Set the number of additional free credits that will be added to the
        user's account:
      </label>
      <div class="mt-1 w-full">
        <NumberInput
          v-model="amount"
          @input="formActions.clearFormErrors"
          required
        />
        <p class="text-red-500 text-sm mt-1">{{ errors.value }}</p>
      </div>
      <button
        type="submit"
        :disabled="Object.keys(errors).length > 0"
        class="button-submit mt-5"
      >
        Confirm
      </button>
    </form>
  </div>
</template>
<script>
import { ref } from "vue";
import NumberInput from "@/components/common/NumberInput";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import useConfirmationModal from "@/api/confirmationModal";

export default {
  name: "UserCreditsBalanceAdminForm",
  mixins: [formMixin],
  props: {
    userId: Number,
    default: null,
  },
  components: {
    NumberInput,
  },
  setup() {
    const { errors, formActions } = useFormErrors();
    const amount = ref(0);

    return {
      amount,
      errors,
      formActions,
    };
  },
  methods: {
    async onSubmit() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        message: `This action will add ${this.amount} free credits to the user's account`,
        onSubmit: () => this.handleAddUserCreditsBalance(),
      });
    },
    async handleAddUserCreditsBalance() {
      const response = await this.handleSubmissionErrors(
        () =>
          this.$alfredService.addUserCreditsBalance({
            amount: this.amount,
            user: this.userId,
          }),
        { formActions: this.formActions, throwExceptions: false }
      );
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: response["detail"],
          },
        });
        document.getElementsByName(
          "user-balance"
        )[0].innerHTML = `${response.balance} Credits`;
      }
    },
  },
};
</script>
