<template>
    <div class="text-center sm:text-left">

        <div class="flex justify-between xl:flex-row flex-col items-center">
            <div class="mt-4 md:text-left w-full z-10">
                <div v-if="receivedData['did_you_mean']" class="py-2">
                    <h4 class="text-lg font-medium text-gray-900">Did you mean: {{ receivedData['did_you_mean'] }} ?</h4>
                </div>

                <template v-for="(value, key) in transformData(receivedData)">
                    <div v-if="key !== 'credit_balance' && key !== 'did_you_mean' && key !== 'domain'"
                        class="py-2 md:grid md:grid-cols-6" :key="key">
                        <dt class="text-sm font-medium text-red-400 md:col-span-3 uppercase">
                            {{ humanize(key) }}
                        </dt>
                        <dd :class="{'font-bold': key === 'email' || key === 'score' || key === 'status'}" class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-3 md:break-words xl:break-normal">
                            {{ value }}
                        </dd>
                    </div>
                </template>

            </div>
            <img class="result_img" v-if="Number(receivedData['score']) < 4 || receivedData['status'] == 'undeliverable'"
                :src="require('@/assets/images/dashboard/dirty.png')" alt="Dirty" />
            <img class="result_img" v-else :src="require('@/assets/images/dashboard/clean.png')" alt="Clean" />
        </div>

    </div>
</template>

<script>
export default {
    name: "RealTimeEmailDetails",
    transformedData: {},
    props: {
        receivedData: {
            type: String,
            required: true
        }
    },
    methods: {

        transformData(data) {
            const transformed = { ...data };
            const PROVIDER_TRANSFORM_MAP = {
                icloud: "Apple",
                yahoo: "AOL/Yahoo",
                aol: "AOL/Yahoo",
                hotmail: "Hotmail/Outlook",
                outlook: "Hotmail/Outlook",
                live: "Hotmail/Outlook",
                amazonaws: "Amazon",
            };
            const RECOMMENDATION_TRANSFORM_MAP = {
                safe_to_send: "Safe to Send",
                send_with_caution: "Send with Caution",
                do_not_send: "Do not Send"
            };
            if (data.reason) {
                transformed.reason = this.humanize(data.reason);
            }
            if (data.status) {
                transformed.status = this.humanize(data.status);
            }
            if (data.threat_level) {
                transformed.threat_level = this.humanize(data.threat_level);
            }
            if (data.rating) {
                transformed.rating = this.humanize(data.rating);
            }
            if (data.recommendation) {
                transformed.recommendation = RECOMMENDATION_TRANSFORM_MAP[data.recommendation] || this.humanize(data.recommendation);
            }
            if (data.provider) {
                transformed.provider = PROVIDER_TRANSFORM_MAP[data.provider] || this.humanize(data.provider);                
            }
            return transformed;
        },
        humanize(words) {
            const separateWords = words.split("_");
            for (let i = 0; i < separateWords.length; i++) {
                separateWords[i] =
                    separateWords[i].charAt(0).toUpperCase() +
                    separateWords[i].substring(1);
            }
            return separateWords
                .join(" ")
                .replaceAll("Unavailable Smtp", "Unavailable SMTP")
                .replaceAll("Dns Error", "DNS Error")
                .replaceAll("Unknown Deliverability", "Unknown")
                .replaceAll("Undetermined", "Undetermined/Unknown");
        },

    }
};

</script>
<style scoped>
.result_img{
    max-width: 50%;
}
</style>