<template>
  <div class="bg-white shadow rounded-lg my-6">
    <div class="overflow-x-auto rounded-lg">
      <div class="align-middle inline-block w-full">
        <table class="divide-gray-300 w-full text-left table-auto">
          <thead class="border-b-2">
            <tr>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Amount
              </th>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Price
              </th>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Type
              </th>

              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, transactionIdx) in transactions"
              :key="transactionIdx"
            >
              <td
                class="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-500"
              >
                {{ transaction.credits }}
              </td>
              <td class="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                {{
                  transaction.tx_type === "credit_pack"
                    ? `$${formatN(transaction.pack_price)}`
                    : ""
                }}
              </td>
              <td class="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                {{ transaction.txDisplayType }}
              </td>

              <td class="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
                {{ transaction.created_at }}
              </td>
              <td class="px-6 py-5 whitespace-nowrap text-sm text-gray-500">
               <button @click="downloadReceipt(transaction)" class="text-blue-600 underline cursor-pointer">Download Receipt</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Paginator :data="paginationData" :onPageChange="changePage" />
  </div>
</template>

<script>
import apiMixin from "@/mixins/apiMixin";
import { reactive, ref } from "vue";
import humanize from "humanize";
import moment from "moment";
import Paginator from "@/components/common/Paginator";
import eventBus from "@/utils/eventBus";

const ACTION_DISPLAY_NAMES = {
  '["verification"]': "Verification",
};

export default {
  name: "TransactionTable",
  mixins: [apiMixin],
  components: {
    Paginator,
  },
  setup() {
    const transactions = ref([]);
    const paginationData = reactive({});
    const page = ref(1);
    return {
      transactions,
      paginationData,
      page,
    };
  },
  beforeMount() {
    eventBus.on("update-transactions", this.fetchTransactions);
    eventBus.on("update", this.fetchTransactions);
    this.fetchTransactions();
  },
  beforeUnmount() {
    eventBus.off("update-transactions", this.fetchTransactions);
    eventBus.off("update", this.fetchTransactions);
  },
  methods: {
    downloadReceipt(transaction){
      this.$emit("transaction", transaction);
    },
    changePage(page) {
      this.page = page;
      this.fetchTransactions();
    },
    getActionsDisplayName(actions) {
      return ACTION_DISPLAY_NAMES[JSON.stringify(actions)] || "";
    },
    getCreditsDisplayText(tx) {
      const credits = humanize.numberFormat(tx.credits, 0);
      if (tx.tx_type === "credit_pack") {
        return `+${credits} Credits`;
      }
      return `-${credits} Credits`;
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
    async fetchTransactions() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.fetchTransactions({
            page: this.page,
          }),
      });
      Object.assign(this.paginationData, {
        next: response.next,
        previous: response.previous,
        count: response.count,
      });
      let transactions = response?.results || [];
      this.transactions = transactions.map((tx) => {
        return {
          ...tx,
          credits: this.getCreditsDisplayText(tx),
          created_at: moment(tx.created_at).format("M/D/Y"),
          txDisplayType:
            tx.tx_type === "credit_pack"
              ? "Credits"
              : this.getActionsDisplayName(tx.job_actions),
        };
      });
    },
  },
};
</script>
<style></style>
