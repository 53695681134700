<template>
  <Container>
    <template v-slot:header>Users</template>
    <template v-slot:content>
      <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
        <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
          Add New User
        </h2>
        <Form @submit="onSubmit" v-slot="{ errors }" class="space-y-6">
          <tbody class="bg-white divide-y divide-gray-200">
            <tr class="flex">
              <div class="px-6 py-1 text-sm text-gray-500 text-left input-cell">
                <td>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    v-model="name"
                    placeholder="Name"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                  />
                </td>
                <p class="text-red-500 text-sm mt-1">{{ errors.name }}</p>
              </div>
              <div class="px-6 py-1 text-sm text-gray-500 text-left input-cell">
                <td>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    v-model="email"
                    autocomplete="email"
                    placeholder="Email"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                  />
                </td>
                <p class="text-red-500 text-sm mt-1">{{ errors.email }}</p>
              </div>
              <div  class="px-6 py-1 text-sm text-gray-500 text-left">
                <td>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    v-model="password"
                    autocomplete="current-password"
                    required=""
                    placeholder="Password"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                  />
                </td>
                <p class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
              </div>
              <div class="px-6 py-1 text-sm text-gray-500 text-left">
                <td>
                  <Field
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    v-model="confirmPassword"
                    autocomplete="current-password"
                    required=""
                    placeholder="Confirm Password"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                  />
                </td>
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.confirm_password }}
                </p>
              </div>
              <Field
                name="agree_to_terms"
                type="checkbox"
                v-model="agreeToTerms"
              >
                <input
                  ref="agreeRef"
                  id="agree_to_terms"
                  name="agree_to_terms"
                  type="checkbox"
                  v-model="agreeToTerms"
                  class="hidden"
                  checked
                />
              </Field>
              <button
                type="submit"
                class="button-submit mt-1 h-9"
                @click="submit"
                :disabled="onLoad"
              >
                Create
              </button>
            </tr>
          </tbody>
        </Form>
        <AddUserTable :data="userData" />
      </section>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import AddUserTable from "@/components/basic/add-user-page/AddUserTable";
import apiMixin from "@/mixins/apiMixin";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import { ref } from "vue";
import { Field, Form } from "vee-validate";

export default {
  name: "AddUser",
  mixins: [apiMixin, formMixin],
  components: {
    Container,
    AddUserTable,
    Field,
    Form,
  },
  beforeMount() {
    eventBus.on("update-subuser-data", this.fetchApiKeys);
  },
  beforeUnmount() {
    eventBus.off("update-subuser-data", this.fetchApiKeys);
  },
  setup() {
    const userData = ref([]);

    return {
      userData,
      agreeToTerms: ref(true),
      onLoad: ref(false),
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    async onSubmit(values, actions) {
      this.onLoad = true;
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.add_user(values),
        { formActions: actions }
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "You have successfully registered!",
            type: "success",
          },
        });
        this.name = "";
        this.email = "";
        this.password = "";
        this.confirmPassword = "";
        this.onLoad = false;
        this.fetchApiKeys();
      }
    },
    clearError(){
      this.onLoad = false;
    },
    async fetchApiKeys() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.get_user(),
      });
      this.userData = response ? response : [];
    },
  },
};
</script>
<style>
.trash-cell {
  width: 100px;
}
</style>
