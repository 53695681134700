<template>
  <Container>
    <template v-slot:header>List Details</template>
    <template v-slot:content>
      <UsageCompleteDetails v-if="fileName && !isThreat" />
      <UsageViewThreatDetails v-else-if="fileName && isThreat" />
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import { ref } from "@vue/reactivity";
import UsageCompleteDetails from "./UsageCompleteDetails.vue";
import apiMixin from "@/mixins/apiMixin";
import UsageViewThreatDetails from "./UsageViewThreatDetails.vue";

export default {
  name: "UsageViewDetails",
  components: {
    Container,
    //   CompleteDetails,
    UsageCompleteDetails,
    UsageViewThreatDetails
  },
  mixins: [apiMixin],
  setup() {
    return { 
      fileName: ref(""),
      isThreat: ref(false)
   };
  },
  async beforeMount() {
    const jobDetails = await this.$store.dispatch("fetching/FETCH", {
      apiCall: async () =>
        await this.$alfredService.getJob(this.$route.params.id),
    });
    this.fileName = jobDetails.input_file.name;
    if (jobDetails.result.threat_assessment_job){
      this.isThreat = true;
    }
  },
};
</script>
