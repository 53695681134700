<template>
  <div class="relative pr-0 sm:pr-8">
    <div class="overflow-hidden h-2 text-xs flex rounded bg-red-100">
      <div
        :style="`width:${percentage}%`"
        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-main"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
};
</script>
