<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :hasCloseBtn="false">
    <template v-slot:title>Update Credit Card</template>
    <template v-slot:content>
      <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="cc-name" class="block text-sm font-medium text-gray-700">
            Name on Card
          </label>
          <div class="mt-1">
            <input
              id="cc-name"
              name="cc-name"
              autocomplete="cc-name"
              v-model="data.billing_details.name"
              type="text"
              class="form-field form-field-no-icon sm:w-full"
              @input="formActions.clearFieldError('billing_details.name')"
            />
            <p class="text-red-500 text-sm mt-1">
              {{ errors["billing_details.name"] }}
            </p>
          </div>
        </div>
        <div class="sm:col-span-6">
          <label
            for="cc-number"
            class="block text-sm font-medium text-gray-700"
          >
            Card Number
          </label>
          <div class="mt-1">
            <input
              type="text"
              name="cc-number"
              id="cc-number"
              :value="`**** **** **** ${data.card?.last4}`"
              disabled
              class="form-field-no-icon sm:w-full"
            />
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="cc-exp"> Card Expiration </label>
          <div class="mt-1">
            <input
              type="text"
              name="cc-exp"
              id="cc-exp"
              autocomplete="cc-exp"
              v-maska="'##/####'"
              v-model="data.card.expiration_date"
              class="form-field-no-icon sm:w-full"
              @input="formActions.clearFieldError('card.expiration_date')"
            />
          </div>
          <p class="text-red-500 text-sm mt-1">
            {{ errors["card.expiration_date"] }}
          </p>
        </div>

        <div class="sm:col-span-3">
          <label for="cc-number"> Security Code </label>
          <div class="mt-1">
            <input
              type="text"
              name="cc-csc"
              id="cc-csc"
              :value="`***`"
              disabled
              class="form-field-no-icon sm:w-full"
            />
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="street-address"> Street Address </label>
          <div class="mt-1">
            <input
              id="street-address"
              name="street-address"
              autocomplete="street-address"
              v-model="data.billing_details.address.line1"
              type="text"
              class="form-field-no-icon sm:w-full"
              @input="
                formActions.clearFieldError('billing_details_address.line1')
              "
            />
            <p class="text-red-500 text-sm mt-1">
              {{ errors["billing_details_address.line1"] }}
            </p>
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="postal-code"> Postal Code </label>
          <div class="mt-1">
            <input
              id="postal-code"
              name="postal-code"
              autocomplete="postal-code"
              v-model="data.billing_details.address.postal_code"
              type="text"
              class="form-field-no-icon sm:w-full"
              @input="
                formActions.clearFieldError(
                  'billing_details_address.postal_code'
                )
              "
            />
            <p class="text-red-500 text-sm mt-1">
              {{ errors["billing_details_address.postal_code"] }}
            </p>
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="country"> Country </label>
          <div class="mt-1">
            <select
              id="country"
              name="country"
              autocomplete="country"
              v-model="data.billing_details.address.country"
              class="form-field-no-icon sm:w-full"
            >
              <option
                v-for="country in countryCodes"
                :key="country.code"
                :value="country.code"
              >
                {{ country.country }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="button-submit"
        :disabled="pendingUpdate"
        @click="onSubmit"
      >
        Submit
      </button>
      <button
        type="button"
        class="button-cancel mr-2"
        @click="closeModal"
        ref="cancelButtonRef"
      >
        Cancel
      </button>
    </template>
  </DialogContainer>
</template>
<script>
import { reactive, ref } from "vue";
import DialogContainer from "@/components/common/DialogContainer";
import { cloneDeep, padStart } from "lodash";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import countryCodes from "@/constants/countryCodes.json";

export default {
  name: "EditCreditCardModal",
  mixins: [formMixin],
  components: {
    DialogContainer,
  },
  setup() {
    const { errors, formActions } = useFormErrors();
    const isOpen = ref(false);
    const pendingUpdate = ref(false);
    const data = reactive({
      card: {},
      billing_details: {
        address: {},
      },
    });

    return {
      isOpen,
      data,
      countryCodes,
      errors,
      formActions,
      pendingUpdate,
    };
  },
  methods: {
    openModal(data) {
      this.data = cloneDeep(data);
      this.formActions.clearFormErrors();
      this.data.card.expiration_date =
        padStart(data.card.exp_month, 2, "0") + "/" + data.card.exp_year;
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    async onSubmit() {
      if (this.pendingUpdate) {
        return;
      }
      this.pendingUpdate = true;
      try {
        await this.updateCreditCard();
      } finally {
        window.setTimeout(() => {
          this.pendingUpdate = false;
        }, 1000);
      }
    },
    async updateCreditCard() {
      const response = await this.handleSubmissionErrors(
        async () =>
          await this.$alfredService.patchPaymentMethod(this.data.id, this.data),
        { formActions: this.formActions }
      );
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Your credit card has been successfully added!",
          },
        });
        this.isOpen = false;
        eventBus.trigger("update-cards", {});
      }
    },
  },
};
</script>
