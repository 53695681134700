function loadTapfiliateScript(callback) {
    const existingScript = document.getElementById("tapfiliate-js-sdk");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://script.tapfiliate.com/tapfiliate.js";
      script.id = "tapfiliate-js-sdk";
      script.async = true;
      script.onload = callback;
      document.head.appendChild(script);
    } else {
      callback();
    }
  }
  
  export default {
    methods: {
        setuptapfiliateReferral(userData, isLoggedIn) {
        loadTapfiliateScript(() => {
          if (window.tap && isLoggedIn) {
            window.tap("create", "31101-88089f");
            if(userData.tapfiliate_referral_code){
              var trackingParam = {
                referral_code: userData.tapfiliate_referral_code
                };
              window.tap("detect", trackingParam);
            }
            window.tap("customer", userData.email);
          }else if(window.tap && !isLoggedIn){
            const urlParams = new URLSearchParams(window.location.search);
            const tapfiliate_referral_code = urlParams.get("ref");
            if(tapfiliate_referral_code){
            var trackingParams = {
              referral_code: tapfiliate_referral_code
              };
            window.tap("create", "31101-88089f");
            window.tap("detect", trackingParams);
            }
          }
        });
      },
      setuptapfiliatePayment(userData, price, chargeId) {
        loadTapfiliateScript(() => {
          if (window.tap) {
            window.tap("create", "31101-88089f", { integration: "stripe" });
            window.tap("conversion", chargeId, price, { customer_id: userData.stripe_id });
          } 
        });
      },
    },
  };
  