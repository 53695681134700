<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :maxWidth="'xl'">
    <template v-slot:title>
      <div
        v-if="isKlaviyo && !isKlaviyoList && !exportMode"
        class="flex items-center justify-center pb-2"
      >
        Connect to Klaviyo
      </div>
      <div
        v-if="isKlaviyoList && !isKlaviyo && !exportMode"
        class="flex items-center justify-center"
      >
        Choose list to export to Klaviyo
      </div>
      <div
        class="flex items-center justify-center font-bold"
        v-if="exportMode && !isKlaviyoList && !isKlaviyo"
      >
        Which type of emails addresses would you like to keep?
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        v-if="isKlaviyo"
        class="button-submit sm:ml-3"
        @click="postAPIkey"
        :disabled="!isKlavioKey"
      >
        Submit
      </button>
      <button
        type="button"
        v-if="!isKlaviyo && isKlaviyoList"
        class="button-submit sm:ml-3"
        @click="exportSelectedListToKlaviyo"
      >
        Export
      </button>
      <button
        type="button"
        v-if="exportMode && !isKlaviyoList"
        class="button-submit sm:ml-3"
        @click="exportToKlaviyo"
      >
        Export
      </button>
      <button type="button" @click="closeModal" class="button-cancel">
        Cancel
      </button>
    </template>
    <template v-slot:content>
      <div v-if="isKlaviyo">
        <div class="text-sm text-gray-600 list-inside list-decimal">
          Before importing your list, you'll need to create a new Klaviyo API key
          and share it with us.
        </div>
        <div class="text-sm text-gray-600 list-inside list-decimal">
          <br />
          <div class="text-black pb-2 font-medium">Instructions</div>
          <div class="pl-1">
            1. In Klaviyo, go to
            <span
              ><a
                class="text-red-500 link-text cursor-pointer"
                target="_blank"
                href="https://www.klaviyo.com/account#api-keys-tab"
                >Accounts>Settings>API Keys</a
              ></span
            >
            <br /><br />
            2. Click <span class="font-bold">Create Private API Key</span
            ><br /><br />
            3. Label it as Alfred (optional)<br /><br />
            4. Copy and paste API key below to get started.<br /><br />
          </div>
          <div>
            <div class="text-black pb-2 font-medium">API Key</div>
            <br />
            <div class="pr-10">
              <input
                class="form-field-no-icon w-full"
                id="apikey"
                @input="submitApiKey"
                autocomplete="off"
              />
            </div>
            <br />
          </div>
        </div>
      </div>

      <div v-if="!isKlaviyo && isKlaviyoList">
        <div class="flex sm:w-30 items-start justify-items-start mt-1 pt-6">
          <SearchInput
            v-model="searchKeyword"
            @input="searchList"
            placeholder="Search by List"
          />
        </div>
        <div class="invisible">list</div>
        <div class="w-full h-52 overflow-auto overflow-x-hidden scroll-style">
          <table class="w-full">
            <tbody>
              <tr
                class="border-b"
                v-for="(list, list_id) in klaviyoList"
                :key="list_id"
              >
                <td
                  class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 max-w-sm truncate"
                >
                  <input
                    type="radio"
                    :id="list.list_id"
                    :name="list.list_name"
                    @change="onSelectList($event)"
                    :value="2"
                  />
                  <span
                    class="text-sm text-black px-6 py-4 text-center max-w-sm truncate"
                    >{{ list.list_name }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="pt-2.5" v-if="exportMode && !isKlaviyoList">
        <div class="border-none border-t border-inherit invisible"></div>
        <fieldset>
          <legend class="sr-only">Notifications</legend>
          <div class="">
            <div class="relative flex items-start py-4">
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-bestDeliverability"
                  aria-describedby="comments-all"
                  checked="allSelected"
                  name="comments"
                  type="radio"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300"
                />
              </div>
              <div class="min-w-0 flex-1 text-sm px-3">
                <label for="all" class="font-bold text-gray-600"
                  >Best Deliverability</label
                ><br />
                <p
                  id="comments-all"
                  class="text-gray-400 text-sm leading-relaxed text-justify"
                >
                  Unsubscribe everything except for the
                  <span
                    class="font-bold text-black"
                    v-if="bestDeliveryCount > 1"
                    >{{ bestDeliveryCount }} emails</span
                  >
                  <span
                    class="font-bold text-black"
                    v-if="bestDeliveryCount == 1"
                    >{{ bestDeliveryCount }} email</span
                  >
                  that Alfred determined to have the highest deliverability and
                  lowest threat.
                </p>
              </div>
            </div>
            <div class="relative flex items-start py-4">
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-maximumReach"
                  aria-describedby="safetosend-description"
                  name="comments"
                  type="radio"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300"
                />
              </div>
              <div class="min-w-0 flex-1 text-sm px-3">
                <label for="safetosend" class="font-bold text-gray-600"
                  >Maximum Reach
                  <span class="inline-flex font-normal flex-wrap">
                    <span
                      class="text-xs px-2 text-green-800 bg-green-100 rounded-full border-gray-200"
                      >Most Popular</span
                    >
                  </span>
                </label>
                <br />
                <p
                  id="safetosend-description"
                  class="text-gray-400 text-sm leading-relaxed text-justify"
                >
                  Unsubscribe everything except for the
                  <span
                    class="font-bold text-black"
                    v-if="maximumReachCount > 1"
                    >{{ maximumReachCount }} emails</span
                  >
                  <span
                    class="font-bold text-black"
                    v-if="maximumReachCount == 1"
                    >{{ maximumReachCount }} email</span
                  >
                  that Alfred determined to be likely deliverable with
                  low-to-moderate threat levels (this may include unknowns and
                  catch-alls). Undeliverable addresses, disposable domains, and
                  high-risk contacts are excluded.
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import DialogContainer from "@/components/common/DialogContainer";
import { reactive, ref } from "vue";
import { mapGetters } from "vuex";
import modalMixin from "@/mixins/modalMixin";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import SearchInput from "@/components/common/SearchInput";
import _sortBy from "lodash/sortBy";

export default {
  mixins: [modalMixin],
  components: {
    DialogContainer,
    SearchInput,
  },
  setup() {
    const modalEventName = "show-export-klaviyo";
    const isOpen = ref(false);
    const job = reactive({});
    const isKlaviyo = ref(false);
    const isKlaviyoList = ref(false);
    const klaviyoList = ref([]);
    const apikeyValue = ref(0);
    const isKlavioKey = ref(false);
    const klaviyoIdImport = ref(0);
    const emailsCount = ref(0);
    const bestDeliveryCount = ref(0);
    const maximumReachCount = ref(0);
    const klaviyoImportListName = ref("");
    const searchKeyword = ref("");
    const radioButtons = ref([]);
    const exportMode = ref(false);
    const input_bestDeliverability = ref(false);
    const input_maximumReach = ref(false);
    const closeExport = ref(false);
    const tempklaviyoList = ref([]);
    const radioButton = ref(false);
    return {
      job,
      isOpen,
      modalEventName,
      isKlaviyo,
      klaviyoList,
      apikeyValue,
      isKlavioKey,
      klaviyoIdImport,
      klaviyoImportListName,
      radioButtons,
      exportMode,
      input_bestDeliverability,
      input_maximumReach,
      closeExport,
      isKlaviyoList,
      searchKeyword,
      emailsCount,
      bestDeliveryCount,
      maximumReachCount,
      tempklaviyoList,
      radioButton,
    };
  },
  computed: {
    ...mapGetters("auth", ["accessToken"]),
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      this.enabled = false;
      this.allSelected = true;
      this.exportMode = false;
      this.isKlaviyo = false;
      this.klaviyoIdImport = 0;
      this.apikeyValue = "";
      this.isKlaviyoList = false;
      this.isKlavioKey = false;
      this.radioButtons = [];
      this.closeExport = false;
      this.input_bestDeliverability = false;
      this.input_maximumReach = false;
      this.emailsCount = 0;
      this.bestDeliveryCount = 0;
      this.maximumReachCount = 0;
      this.tempklaviyoList = [];
      this.klaviyoImportListName = "";
      this.klaviyoList = [];
    },

    async openModal({ job }) {
      this.job = job;
      this.isOpen = true;
      if (
        this.job.result &&
        this.job.result.verification_job &&
        this.job.result.verification_job.scores &&
        this.job.result.verification_job.scores.length > 0
      ) {
        this.bestDeliveryCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 6)
          .reduce((a, b) => a + b.email_count, 0);
        this.maximumReachCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 5)
          .reduce((a, b) => a + b.email_count, 0);
      } else {
        this.bestDeliveryCount = 0;
        this.maximumReachCount = 0;
      }
      const apikey = await this.$alfredService.klaviyoAPIKey();
      if (apikey != undefined && apikey != "" && apikey != null) {
        this.apikeyValue = apikey.api_key;
        // this.callKlaviyoList();

        this.exportMode = true;
      } else {
        this.isKlaviyo = true;
        this.isKlaviyoList = false;
      }
    },
    submitApiKey() {
      let key = document.getElementById("apikey");
      this.apikeyValue = key.value;
      this.isKlavioKey = true;
    },
    async postAPIkey() {
      let key = document.getElementById("apikey");
      this.apikeyValue = key.value;
      if (!this.apikeyValue) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please provide a valid Klaviyo API Key.",
          },
        });
        return false;
      }
      const success = await this.$alfredService.postKlaviyoAPIKey({
        api_key: key.value,
      });
      if (success) {
        this.isKlaviyo = false;
        this.exportMode = true;
      } else {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Your Klaviyo API Key is not valid",
          },
        });
        return false;
      }
    },
    async callKlaviyoList() {
      const data = await this.$alfredService.getKlaviyoList();
      this.klaviyoList = data;
      this.klaviyoContactList = data;
      this.klaviyoList = _sortBy(this.klaviyoList, "list_name");
      this.tempklaviyoList = this.klaviyoList;
      this.isKlaviyoList = true;
      this.isKlaviyo = false;
    },
    onSelectList(event) {
      this.radioButton = document.getElementById(event.target.id);
      this.radioButtons.push(this.radioButton);
      if (this.radioButtons.length > 0) {
        this.radioButtons.forEach((item) => {
          if (item.checked) {
            item.checked = false;
          }
        });
      }
      this.radioButton.checked = true;
      this.klaviyoIdImport = event.target.id;
      this.klaviyoImportListName = event.target.name;
    },

    async exportSelectedListToKlaviyo() {
      if (!this.klaviyoIdImport) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please select a list to export",
          },
        });
        return false;
      }
      this.exportMode = false;
      this.isKlaviyoList = false;
      this.isOpen = false;
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to update this list?",
        message: `This process will unsubscribe <strong>${this.emailsCount} addresses</strong> from "${this.klaviyoImportListName}".`,
        onSubmit: () => this.runExportingJob(),
        onClose: this.onClose,
      });
    },
    async exportToKlaviyo() {
      let best_deliverability = document.getElementById(
        "cb-bestDeliverability"
      );
      if (best_deliverability.checked) {
        if (this.job.basicData) {
          let emailNumber = this.job.basicData.emailsCount.replace(",", "");
          this.emailsCount = parseInt(emailNumber) - this.bestDeliveryCount;
        } else {
          let emailNumber = this.job.emails_count;
          this.emailsCount = parseInt(emailNumber) - this.bestDeliveryCount;
        }
        this.input_bestDeliverability = true;
      }
      let maximum_reach = document.getElementById("cb-maximumReach");
      if (maximum_reach.checked) {
        if (this.job.basicData) {
          let emailNumber = this.job.basicData.emailsCount.replace(",", "");
          this.emailsCount = parseInt(emailNumber) - this.maximumReachCount;
        } else {
          let emailNumber = this.job.emails_count;
          this.emailsCount = parseInt(emailNumber) - this.maximumReachCount;
        }
        this.input_maximumReach = true;
      }
      if (!this.input_bestDeliverability && !this.input_maximumReach) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Please select atleast one option before Exporting.",
            type: "warning",
          },
        });
        return;
      }
      this.exportMode = false;
      this.callKlaviyoList();
      /* this.isOpen = false;
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to update this list?",
        message: `This process will unsubscribe <strong>${this.emailsCount} addresses</strong> from "${this.klaviyoImportListName}".`,
        onSubmit: () => this.runExportingJob(),
        onClose: this.onClose,
      }); */
    },
    onClose() {
      this.closeModal();
    },
    async searchList() {
      this.klaviyoIdImport = "";
      if (this.radioButton) {
        this.radioButton.checked = false;
      }
      if (this.searchKeyword) {
        this.klaviyoList = this.tempklaviyoList.filter((x) =>
          x.list_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
      } else {
        this.klaviyoList = this.tempklaviyoList;
      }
      this.klaviyoList = _sortBy(this.klaviyoList, "list_name");
    },
    async runExportingJob() {
      let scores = [];

      if (this.input_bestDeliverability && this.input_maximumReach) {
        scores.push(6, 7, 8, 9, 10);
      } else if (this.input_bestDeliverability && !this.input_maximumReach) {
        scores.push(7, 8, 9, 10);
      } else if (!this.input_bestDeliverability && this.input_maximumReach) {
        scores.push(10, 9, 8, 7, 6);
      }

      const fileDownload = await this.$alfredService.klaviyoExport({
        list_id: this.klaviyoIdImport,
        job_id: this.job.id,
        scores,
      });
      if (fileDownload) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "The list is exported successfully!",
            type: "success",
          },
        });
        this.closeModal();
      } else {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Please try again later",
            type: "warning",
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.scroll-style::-webkit-scrollbar {
  width: 8px;
  background-color: gainsboro;
  border-radius: 2px;
}
.scroll-style::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}
</style>
