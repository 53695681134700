<template>
  <Container>
    <template v-slot:header>Usage</template>
    <template v-slot:content>
      <UsageTable
        :data="data"
        :paginationData="paginationData"
        :singleUser="singleUser"
      />
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import { reactive, ref } from "vue";
import UsageTable from "@/components/common/usage-page/UsageTable";
import apiMixin from "@/mixins/apiMixin";
import eventBus from "@/utils/eventBus";
import { mapGetters } from "vuex";
export default {
  name: "DiscountCodes",
  mixins: [apiMixin],
  components: {
    Container,
    UsageTable,
  },
  async beforeMount() {
    if (!this.isStaff) {
      eventBus.on("update-usage", this.fetchJobsForUser);
      await this.fetchJobsForUser({ size: 15, ordering: "-created_at" });
    } else {
      this.singleUser = false;
      eventBus.on("update-usage", this.fetchJobsForAdmin);
      await this.fetchJobsForAdmin({ size: 15, ordering: "-created_at" });
    }
  },
  beforeUnmount() {
    if (!this.isStaff) {
      eventBus.off("update-usage", this.fetchJobsForUser);
    } else {
      eventBus.off("update-usage", this.fetchJobsForAdmin);
    }
  },
  setup() {
    const data = ref([]);
    const singleUser = ref(true);
    const paginationData = reactive({});
    return { data, paginationData, singleUser };
  },
  computed: {
    ...mapGetters({
      isStaff: "auth/isStaff",
      userData: "auth/userData",
    }),
  },
  methods: {
    async fetchJobsForAdmin(urlParams = {}) {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.fetchAdminJobs(urlParams),
      });
      if (response) {
        this.data = response.results || [];
        delete response.results;
        this.paginationData = response;
      }
    },
    async fetchJobsForUser(urlParams = {}) {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.fetchJobs(urlParams),
      });
      if (response) {
        this.data = response.results || [];
        delete response.results;
        this.paginationData = response;
      }
    },
  },
};
</script>
