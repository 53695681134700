<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :maxWidth="'xl'">
    <template v-slot:title>
      <div class="flex items-center justify-center pb-2">
        Export to Yotpo
      </div>
    </template>
    <template v-slot:buttons>
      <button type="button" class="button-submit sm:ml-3" @click="openPopup()">
        Proceed
      </button>
      <button type="button" @click="closeModal" class="button-cancel">
        Cancel
      </button>
    </template>
    <template v-slot:content>
      <div>
        <fieldset>
          <legend class="sr-only">Notifications</legend>
          <div class="">
            <div class="relative flex items-start py-4">
              <div class="ml-3 flex items-center h-5">
                <input id="cb-create_new_list" aria-describedby="comments-all" :checked="exportCompleted" name="comments"
                  type="radio" class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300" :disabled="export_per && is_new_list && !exportCompleted"/>
              </div>
              <div class="min-w-0 flex-1 text-sm px-3">
                <label for="all" class="font-bold text-gray-600">Create a new clean list</label>
                <p id="comments-all" class="text-gray-400 text-sm leading-relaxed text-justify">
                  Create a new list with the cleaned emails in Yotpo
                </p>
                <div v-if="export_per && is_new_list && !exportCompleted">
              <p class="italic pt-3">Adding emails to new list</p>
              <p>{{ export_per }}%</p>
              <ProgressBar :percentage="export_per" />                  
              </div>
              </div>
            </div>
            <div class="relative flex items-start py-4">
              <div class="ml-3 flex items-center h-5">
                <input id="cb-remove_emails" aria-describedby="safetosend-description" :checked="export_per && is_new_list" name="comments" type="radio"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300" :disabled="export_per && !is_new_list && !exportCompleted" />
              </div>
              <div class="min-w-0 flex-1 text-sm px-3">
                <label for="safetosend" class="font-bold text-gray-600">Suppress emails from my current list
                </label>
                <p id="safetosend-description" class="text-gray-400 text-sm leading-relaxed text-justify">
                  Remove unwanted emails from the list but not from the customer database
                </p>
              <div v-if="export_per && !is_new_list && !exportCompleted">
              <p class="italic pt-3">Removing emails from list</p>
              <p>{{ export_per }}%</p>
              <ProgressBar :percentage="export_per" />                  
              </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </template>
  </DialogContainer>
  <teleport to="body">
    <transition name="fade">
      <div v-if="showPopup" class="popup-container">
        <div
          class="popup inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:max-h-md w-full rounded-lg">
          <fieldset>
            <legend class="sr-only">Notifications</legend>
            <div class="">
              <div class="relative flex items-start py-4">
                <div class="ml-3 flex items-center h-5">
                  <input id="cb-bestDeliverability" aria-describedby="comments-all" checked="allSelected"
                    name="comments" type="radio" class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300" />
                </div>
                <div class="min-w-0 flex-1 text-sm px-3">
                  <label for="all" class="font-bold text-gray-600">Best Deliverability</label><br />
                  <p v-if="createnewlist" id="comments-all" class="text-gray-400 text-sm leading-relaxed text-justify">
                    Create new list with
                    <span class="font-bold text-black" v-if="bestDeliveryCount > 1">{{ bestDeliveryCount }}
                      emails</span>
                    <span class="font-bold text-black" v-if="bestDeliveryCount == 1">{{ bestDeliveryCount }}
                      email</span>
                    that Alfred determined to have the highest deliverability and
                    lowest threat.
                  </p>
                  <p v-else id="comments-all" class="text-gray-400 text-sm leading-relaxed text-justify">
                    Remove
                    <span class="font-bold text-black" v-if="bestDeliveryCount > 1">{{ job_emails_count.replace(/,/g, '')-bestDeliveryCount }}
                      emails</span>
                    <span class="font-bold text-black" v-if="bestDeliveryCount == 1">{{ bestDeliveryCount }}
                      email</span>
                    that Alfred determined to have the lowest deliverability and
                    highest threat.
                  </p>
                </div>
              </div>
              <div class="relative flex items-start py-4">
                <div class="ml-3 flex items-center h-5">
                  <input id="cb-maximumReach" aria-describedby="safetosend-description" name="comments" type="radio"
                    class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300" />
                </div>
                <div class="min-w-0 flex-1 text-sm px-3">
                  <label for="safetosend" class="font-bold text-gray-600">Maximum Reach
                    <span class="inline-flex font-normal flex-wrap">
                      <span class="text-xs px-2 text-green-800 bg-green-100 rounded-full border-gray-200">Most
                        Popular</span>
                    </span>
                  </label>
                  <br />
                  <p v-if="createnewlist" id="safetosend-description"
                    class="text-gray-400 text-sm leading-relaxed text-justify">
                    Create new list with
                    <span class="font-bold text-black" v-if="maximumReachCount > 1">{{ maximumReachCount }}
                      emails</span>
                    <span class="font-bold text-black" v-if="maximumReachCount == 1">{{ maximumReachCount }}
                      email</span>
                    that Alfred determined to be likely deliverable with
                    low-to-moderate threat levels (this may include unknowns and
                    catch-alls). Undeliverable addresses, disposable domains, and
                    high-risk contacts are excluded.
                  </p>
                  <p v-else id="safetosend-description" class="text-gray-400 text-sm leading-relaxed text-justify">
                    Remove
                    <span class="font-bold text-black" v-if="maximumReachCount > 1">{{ job_emails_count.replace(/,/g, '')-maximumReachCount }}
                      emails</span>
                    <span class="font-bold text-black" v-if="maximumReachCount == 1">{{ maximumReachCount }}
                      email</span>
                    that Alfred determined to be likely deliverable with
                    low-to-moderate threat levels (this may include unknowns and
                    catch-alls). Undeliverable addresses, disposable domains, and
                    high-risk contacts will be removed.
                  </p>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b">
            <button type="button" class="button-submit sm:ml-3" @click="exportYotpo(job)">
              Export
            </button>
            <button class="button-cancel" @click="showPopup = false">
              Cancel</button>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import DialogContainer from "@/components/common/DialogContainer";
import { reactive, ref } from "vue";
import { mapGetters } from "vuex";
import modalMixin from "@/mixins/modalMixin";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import ProgressBar from "@/components/basic/common/ProgressBar";

export default {
  mixins: [modalMixin],
  components: {
    DialogContainer,
    ProgressBar
  },
  setup() {
    const modalEventName = "show-export-yotpo";
    const isOpen = ref(false);
    const job = reactive({});
    const emailsCount = ref(0);
    const bestDeliveryCount = ref(0);
    const maximumReachCount = ref(0);
    const radioButtons = ref([]);
    const input_bestDeliverability = ref(false);
    const input_maximumReach = ref(false);
    const closeExport = ref(false);
    const radioButton = ref(false);
    const createnewlist = ref(false);
    const showPopup = ref(false);
    const exportCompleted = ref(true);
    const total = ref("");
    const success = ref("");
    const failed = ref("");
    const list_create_per = ref(0);
    const export_per = ref(0);
    const is_new_list = ref("");
    const intervalId = ref(null);
    const job_emails_count = ref(0);
    return {
      job,
      isOpen,
      modalEventName,
      radioButtons,
      input_bestDeliverability,
      input_maximumReach,
      closeExport,
      emailsCount,
      bestDeliveryCount,
      maximumReachCount,
      radioButton,
      createnewlist,
      showPopup,
      exportCompleted,
      total,
      success,
      failed,
      list_create_per,
      export_per,
      is_new_list,
      intervalId,
      job_emails_count
    };
  },
  computed: {
    ...mapGetters("auth", ["accessToken"]),
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId=null;
    }
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      this.enabled = false;
      this.apikeyValue = "";
      this.radioButtons = [];
      this.closeExport = false;
      this.input_bestDeliverability = false;
      this.input_maximumReach = false;
      this.emailsCount = 0;
      this.bestDeliveryCount = 0;
      this.maximumReachCount = 0;
      this.list_create_per = 0;
      this.export_per = 0;
      this.total = "";
      this.batch = "";
      this.success = "";
      this.failed = "";
      this.is_new_list = "";
      this.export_emails_count = "";
      this.job_emails_count = 0;
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    async checkExport(){
      if(this.job){
        const response = await this.$alfredService.yotpoExportDetails(this.job.job_id);
      if (response.export_details?.export_emails_count) {
        this.export_emails_count = response.export_details?.export_emails_count;
        this.total = response.export_details?.total_emails_count;
        this.failed = response.export_details?.export_failed_count;
        this.success = response.export_details?.export_success_count;
        this.exportCompleted = response.export_details?.is_completed;
        this.is_new_list = response.export_details?.is_new_list;
      }
      
      if(this.success>0 || this.failed>0){
        this.export_per = Math.round(((this.success + this.failed)/this.export_emails_count) * 100);
      }else if(this.export_emails_count){
        this.export_per = 1; 
      }
      }
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(this.checkExport, 15000);
    },
    async openModal({ job }) {
      this.exportCompleted = true;
      this.job = job;
      this.job_emails_count = this.job.basicData.emailsCount;
      this.checkExport();
      this.isOpen = true;
      if (
        this.job.result &&
        this.job.result.verification_job &&
        this.job.result.verification_job.scores &&
        this.job.result.verification_job.scores.length > 0
      ) {
        this.bestDeliveryCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 6)
          .reduce((a, b) => a + b.email_count, 0);
        this.maximumReachCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 5)
          .reduce((a, b) => a + b.email_count, 0);
      } else {
        this.bestDeliveryCount = 0;
        this.maximumReachCount = 0;
      }
    
    },
    onSelectList(event) {
      this.radioButton = document.getElementById(event.target.id);
      this.radioButtons.push(this.radioButton);
      if (this.radioButtons.length > 0) {
        this.radioButtons.forEach((item) => {
          if (item.checked) {
            item.checked = false;
          }
        });
      }
      this.radioButton.checked = true;
      this.klaviyoIdImport = event.target.id;
      this.klaviyoImportListName = event.target.name;
    },

    onClose() {
      this.closeModal();
    },
    openPopup() {
      this.createnewlist = document.getElementById("cb-create_new_list").checked;
      this.showPopup = true;
      this.isOpen = false;
    },
    async exportYotpo(job) {
      this.job = job;
      if (
        this.job.result &&
        this.job.result.verification_job &&
        this.job.result.verification_job.scores &&
        this.job.result.verification_job.scores.length > 0
      ) {
        this.bestDeliveryCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 6)
          .reduce((a, b) => a + b.email_count, 0);
        this.maximumReachCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 5)
          .reduce((a, b) => a + b.email_count, 0);
      } else {
        this.bestDeliveryCount = 0;
        this.maximumReachCount = 0;
      }
      let best_deliverability = document.getElementById(
        "cb-bestDeliverability"
      );
      if (best_deliverability.checked) {
        if (this.job.basicData) {
          this.emailsCount = this.bestDeliveryCount;
        } else {
          this.emailsCount = this.bestDeliveryCount;
        }
        this.input_bestDeliverability = true;
      }
      let maximum_reach = document.getElementById("cb-maximumReach");
      if (maximum_reach.checked) {
        if (this.job.basicData) {
          this.emailsCount = this.maximumReachCount;
        } else {
          this.emailsCount = this.maximumReachCount;
        }
        this.input_maximumReach = true;
      }
      if (!this.input_bestDeliverability && !this.input_maximumReach) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Please select atleast one option before Exporting.",
            type: "warning",
          },
        });
        return;
      }
      const modal = useConfirmationModal();
      this.showPopup = false;
      let title = "";
      let message = ""
      if (this.createnewlist) {
        title = "Are you sure you want to a create new list?"
        message = `This process will create a new list with <strong>${this.emailsCount} addresses</strong>.`
      } else {
        title = "Are you sure you want to remove from the list?"
        message = `This process will remove <strong>${this.job_emails_count.replace(/,/g, '')-this.emailsCount} addresses from the list</strong>.`
      }
      await modal.openModal({
        title: title,
        message: message,
        onSubmit: () => this.runExportingJob(),
        onClose: this.onClose,
      });
    },
    async runExportingJob() {
      let scores = [];

      if (this.input_bestDeliverability && this.input_maximumReach) {
        scores.push(6, 7, 8, 9, 10);
      } else if (this.input_bestDeliverability && !this.input_maximumReach) {
        scores.push(7, 8, 9, 10);
      } else if (!this.input_bestDeliverability && this.input_maximumReach) {
        scores.push(10, 9, 8, 7, 6);
      }
      try{
      const fileDownload = await this.$alfredService.yotpoExport({
        list_name: this.job.name,
        job_id: this.job.job_id,
        scores,
        is_new_list: this.createnewlist,
        upload_id: this.job.upload_id
      });
      if (fileDownload) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "The list export initiated successfully!",
            type: "success",
          },
        });
        this.closeModal();
      }
    }catch(e){
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: e.response.data.detail,
            type: "error",
          },
        });
      } 

    },
  },
};
</script>
<style scoped>
.scroll-style::-webkit-scrollbar {
  width: 8px;
  background-color: gainsboro;
  border-radius: 2px;
}

.scroll-style::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}
</style>