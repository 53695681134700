<template>
  <input
    v-model="displayValue"
    type="tel"
    :class="classes"
    :maxlength="maxLength"
    @input="onInput"
    v-maska="mask"
    @change="onChange"
    :disabled="disabled"
    ref="numberInput"
  />
</template>
<script>
import { ref } from "vue";
import formatNumberMixin from "@/mixins/formatNumberMixin";

export default {
  name: "NumberInput",
  inheritAttrs: false,
  mixins: [formatNumberMixin],
  props: {
    decimals: {
      type: [Number, String],
      default: 0,
    },
    modelValue: {
      type: [Number],
      default: null,
    },
    classes: {
      type: String,
      default: "form-field-no-icon block w-full",
    },
    disabled: {
      type: [Boolean, Number],
      default: false,
    },
    isMoney: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 19,
    },
  },
  setup() {
    const displayValue = ref("");
    const realValue = ref(null);

    return {
      displayValue,
      realValue,
    };
  },
  beforeMount() {
    this.realValue = this.modelValue;
    if (this.realValue) {
      this.displayValue = this.formatNumber(
        this.modelValue,
        this.decimals,
        this.maxLength
      );
    }
  },
  watch: {
    modelValue(val) {
      this.realValue = val;
      if (this.realValue !== null) {
        this.displayValue = this.formatNumber(
          val,
          this.decimals,
          this.maxLength
        );
      } else {
        this.displayValue = "";
      }
    },
    disabled(val) {
      if (val === null) {
        this.$nextTick(() => {
          this.$refs.numberInput.focus();
        });
      }
    },
  },
  computed: {
    mask() {
      if (this.decimals > 0) {
        return {
          mask: `${this.isMoney ? "!$" : ""}N*`,
          tokens: { N: { pattern: /[,.0-9]/ } },
        };
      } else {
        return {
          mask: `${this.isMoney ? "!$" : ""}N*`,
          tokens: { N: { pattern: /[,0-9]/ } },
        };
      }
    },
  },
  methods: {
    formatInput() {
      if (String(this.displayValue) !== "") {
        const displayValue = this.isMoney
          ? this.displayValue.substring(1)
          : this.displayValue;
        this.realValue = this.toNumber(displayValue);
        this.displayValue = this.formatNumber(
          displayValue,
          this.decimals,
          this.maxLength
        );
      } else {
        this.realValue = null;
        this.displayValue = "";
      }
    },
    toNumber(num) {
      let { realPart, decimalPart } = this.getDecimalAndRealParts(
        num,
        this.decimals,
        this.maxLength
      );
      return Number(`${realPart}${decimalPart}`);
    },
    onInput() {
      this.formatInput();
      this.$emit("input", this.realValue);
      this.$emit("update:modelValue", this.realValue);
    },
    onChange() {
      this.$emit("change", this.realValue);
    },
  },
};
</script>
<style scoped>
.no-shadow {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
