<template>
  <div
    class="mt-8 flex flex-row flex-wrap items-center justify-center md:justify-start -mx-8"
  >
    <div
      class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 w-72 mx-8 my-4 h-48 flex flex-col text-left"
    >
      <div class="px-4 py-6 sm:p-6">
        <div>Available Credits</div>
        <div class="mt-10">{{ userCreditsDisplay }}</div>
      </div>
      <button
        @click="onBuyCredits"
        class="button-submit py-4 mt-0 rounded-none"
      >
        <span class="text-base">Buy Credits</span>
      </button>
    </div>
    <EditCreditCardModal ref="editCreditCardModal" />
    <CreateCreditCardModal ref="createCreditCardModal" />
    <BillingDetailsModal ref="BillingDetailsModal" />
    <div>
      <BillingDetails :data="BillingAddress" />
    </div>
    <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
      <PaymentMethodCard :data="paymentMethod" :update="fetchPaymentMethods" />
    </div>
    <div class="w-72 flex flex-row justify-center items-center h-48 mx-8 my-4">
      <button
        type="button"
        @click="onAddPaymentMethod"
        class="button-submit p-4 rounded-full"
      >
        <PlusIcon class="h-5 w-5 mr-1" aria-hidden="true" /> Add Credit Card
      </button>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "@heroicons/vue/outline";
import PaymentMethodCard from "@/components/basic/billing-page/PaymentMethodCard";
import BillingDetails from "@/components/basic/billing-page/BillingDetails";
import EditCreditCardModal from "@/components/basic/billing-page/modals/EditCreditCardModal";
import CreateCreditCardModal from "@/components/basic/billing-page/modals/CreateCreditCardModal";
import BillingDetailsModal from "@/components/basic/billing-page/modals/BillingDetailsModal";
import apiMixin from "@/mixins/apiMixin";
import { ref } from "vue";
import { provide } from "@vue/runtime-core";
import eventBus from "@/utils/eventBus";
import { mapGetters } from "vuex";
import humanize from "humanize";

export default {
  name: "CreditCards",
  mixins: [apiMixin],
  components: {
    PlusIcon,
    PaymentMethodCard,
    EditCreditCardModal,
    CreateCreditCardModal,
    BillingDetails,
    BillingDetailsModal
  },
  setup() {
    const editCreditCardModal = ref();
    const createCreditCardModal = ref();
    const BillingDetailsModal = ref();
    const paymentMethods = ref([]);
    const BillingAddress = ref();
    provide("editCreditCardModal", editCreditCardModal);
    provide("BillingDetailsModal", BillingDetailsModal);
    return {
      paymentMethods,
      editCreditCardModal,
      createCreditCardModal,
      BillingDetails,
      BillingDetailsModal,
      BillingAddress
    };
  },
  beforeMount() {
    this.fetchPaymentMethods();
    this.fetchBillingAddress();
    eventBus.on("update-cards", this.onUpdateCards);
    eventBus.on("update", this.onUpdateCards);
  },
  beforeUnmount() {
    eventBus.off("update-cards", this.onUpdateCards);
    eventBus.off("update", this.onUpdateCards);
  },
  computed: {
    ...mapGetters("auth", ["userData"]),
    userCreditsDisplay() {
      let userCredits = 0;
      if(this.userData?.credits == 0){
        userCredits = this.userData?.credits;
      }else{
        userCredits = this.userData?.credits || this.userData?.organization?.credits;
      }
      return humanize.numberFormat(userCredits, 0);
    },
  },
  methods: {
    async fetchPaymentMethods() {
      const paymentMethods = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.fetchPaymentMethods(),
      });
      this.paymentMethods = paymentMethods?.data || [];
    },
    async fetchBillingAddress() {
      const resp = await this.$alfredService.getBillingAddress();
      this.BillingAddress = resp;
    },
    async onAddPaymentMethod() {
      this.createCreditCardModal.openModal();
    },
    onUpdateCards() {
      this.fetchPaymentMethods();
      this.fetchBillingAddress();
    },
    onBuyCredits() {
      eventBus.trigger("buy-credits", {
        onSubmit: this.onCheckout,
      });
    },
    onCheckout(data) {
      eventBus.trigger("checkout", {
        data,
        onSubmit: () => {
          this.$store.dispatch("auth/FETCH_USER_DATA");
          eventBus.trigger("update-transactions", {});
        },
      });
    },
  },
};
</script>
